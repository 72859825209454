import '../../Assets/Styles/home.css';
import '../../Assets/Styles/order.css';
import '../../Assets/Styles/orderStep2.css';
import React from "react";
import { useMediaQuery } from 'react-responsive'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {Link,useNavigate} from 'react-router-dom';

import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button'; 
import { Dialog } from 'primereact/dialog'; 
import { Steps } from 'primereact/steps';
import { ToggleButton } from 'primereact/togglebutton';
import { Tooltip } from 'primereact/tooltip';
import { AutoComplete } from 'primereact/autocomplete';
import { Calendar } from 'primereact/calendar';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Dropdown } from 'primereact/dropdown';
import { addLocale } from 'primereact/api';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Divider } from 'primereact/divider';
import { Skeleton } from 'primereact/skeleton';
import { Panel } from 'primereact/panel';
import { TabView, TabPanel } from 'primereact/tabview';
import { ProgressBar } from 'primereact/progressbar';
import { InputSwitch } from 'primereact/inputswitch';
import { Message } from 'primereact/message';
import { RadioButton } from 'primereact/radiobutton';
import { Accordion, AccordionTab } from 'primereact/accordion';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import ForkRightIcon from '@mui/icons-material/ForkRight';
import StarsIcon from '@mui/icons-material/Stars';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import LocalAirportIcon from '@mui/icons-material/LocalAirport';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

import multiTransportConcept from '../../Assets/Images/multiTransportConcept11.png'; 
import desktopConcept from '../../Assets/Images/desktopConcept.png'; 
import contactConcept from '../../Assets/Images/contactConcept.png'; 
import formPlaceholder from '../../Assets/Images/formPlaceholder.png'; 
import formTopIcons from '../../Assets/Images/formTopIcons.svg'; 
import cornerTop from '../../Assets/Images/cornerTop.svg';
import cornerBottom from '../../Assets/Images/cornerBottom.svg';

import contactWhatsappIcon from '../../Assets/Images/contactWhatsappIcon.svg';
import contactEmailIcon from '../../Assets/Images/contactEmailIcon.svg';
import contactPhoneIcon from '../../Assets/Images/contactPhoneIcon.svg';
import transportPicture from '../../Assets/Images/transportPicture.svg';
import rentcarPicture from '../../Assets/Images/rentcarPicture.svg';
import transferPicture from '../../Assets/Images/transferPicture.svg';
import transportIcon from '../../Assets/Images/transportIcons.svg';
import transferIcon from '../../Assets/Images/transferIcon.svg';
import rentcarIcon from '../../Assets/Images/rentcarIcon.svg';
import tooltipIcon from '../../Assets/Images/tooltipIcon.svg';
import iconAlert from '../../Assets/Images/iconAlert.svg';

import titleTrainIcon from '../../Assets/Images/titleTrainIcon.svg';
import titleBusIcon from '../../Assets/Images/titleBusIcon.svg';
import obbProviderLogo from '../../Assets/Images/obb-provider.svg';
import flixbusProviderLogo from '../../Assets/Images/flixbus-provider.png';

// Load Actions
import * as AppActions from '../../Store/Actions/App'
import * as OrderActions from '../../Store/Actions/Order'
import * as RegisterActions from '../../Store/Actions/Register';

// Load Componentes
import TermsView from './TermsModal';
import MessageView from '../../Components/MessageModal';
import AlertView from '../../Components/AlertModal';
import ExpireView from '../../Components/ExpireModal';
import {
	validarCNPJ,
	validarCPF,
	validarEmail,
	validarTelefone,
	validarNome,
	maskCNPJ,
	maskCPF,
	maskPhone
} from '../../Components/Utils';

import {
	Callto,
	Whatsapp
} from '../../Components/Utils';

/*
import {
	stepsData
} from '../../Components/StaticData';*/

// export default function TransferSearchResult() {
const TransferSearchResult = (props) => {
	
// Set var from props
const {
	setDialog,
	dialog,
	retrieveAppAddressGooglePlaces,
	retrieveAppAddressesGoogleMatrixDistance,
	transportFilters,
	retrievePersonDataByDocument,
	currentAgency
} = props;


//console.log("[transportFilters]--->"+JSON.stringify(transportFilters,null,2));


const [loading, setLoading] = React.useState(true); 
const [saving, setSaving] = React.useState(false); 
const [loadingContent, setLoadingContent] = React.useState(true); 
const [loadingContentTrain, setLoadingContentTrain] = React.useState(true); 
const [loadingContentBus, setLoadingContentBus] = React.useState(true); 

 const [counter, setCounter] = React.useState(1200);
 const [counterInitial, setCounterInitial] = React.useState(1200);
 
 // const [counter, setCounter] = React.useState(20); // for test. Force popup. 
 const [counterLabel, setCounterLabel] = React.useState("00:20:00");
 const [counterValue, setCounterValue] = React.useState(100);

const [loadingRightPanel, setLoadingRightPanel] = React.useState(true); 

// Right Panel Status
const [rightPanel, setRightPanel] = React.useState(false); 
const [rightPanelBlock, setRightPanelBlock] = React.useState(false); 
	
const isDesktopOrLaptop = useMediaQuery({
query: '(min-width: 1024px)'
})
const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1023px)' })

const [email, setEmail] = React.useState('');
const [password, setPassword] = React.useState('');
const [stillConnected, setStillConnected] = React.useState(false);
const onStillConnectedChange = (e) => {
	if (e.checked)
		setStillConnected(true);
	else
		setStillConnected(false);
}  // end function
	
// Alert control
const [alert, setAlert] = React.useState(false); 
const [alertTitle, setAlertTitle] = React.useState("Informações"); 
const [alertBody, setAlertBody] = React.useState("Conteúdo dinâmico"); 

// Expire Dialog control
const [alertExpire, setAlertExpire] = React.useState(false); 
const [alertTitleExpire, setAlertTitleExpire] = React.useState("Informações"); 
const [alertBodyExpire, setAlertBodyExpire] = React.useState("Conteúdo dinâmico"); 
const [alertActionExpire, setAlertActionExpire] = React.useState("restartTransportSearch"); 

const [forgotPassword, setForgotPassword] = React.useState(false); 		

// Transport selection
const [checkedAll, setCheckedAll] = React.useState(false);

const [checkedAirplane, setCheckedAirplane] = React.useState(false);
const [checkedTrain, setCheckedTrain] = React.useState(false);
const [checkedBus, setCheckedBus] = React.useState(false);
const [directServices, setDirectServices] = React.useState(false);

const [departureCityLoading, setDepartureCityLoading] = React.useState(false);
const [destinationCityLoading, setDestinationCityLoading] = React.useState(false);
const [departureCityResult, setDepartureCityResult] = React.useState(false);
const [destinationCityResult, setDestinationCityResult] = React.useState(false);

const [departureLocalCountry, setDepartureLocalCountry] = React.useState("TESTE");
const [departureLocalCity, setDepartureLocalCity] = React.useState(null);
const [destinationLocalCountry, setDestinationLocalCountry] = React.useState(null);
const [destinationLocalCity, setDestinationLocalCity] = React.useState(null);

const [departureDate, setDepartureDate] = React.useState(null);
const [departureTime, setDepartureTime] = React.useState(null);

const [destinationLocal, setDestinationLocal] = React.useState(null);

const [destinationDate, setDestinationDate] = React.useState(null);
const [destinationTime, setDestinationTime] = React.useState(null);	

const [pickReturnDate, setPickReturnDate] = React.useState(false);
const [pickReturn, setPickReturn] = React.useState(false);

const [leaveDate, setLeaveDate] = React.useState(null);
const [returnDate, setReturnDate] = React.useState(null);

const [value, setValue] = React.useState('');
const [items, setItems] = React.useState([]);

/* Person select START */
const [menuOpened,setMenuOpened] = React.useState(false);
const [personAddLabel,setPersonAddLabel] = React.useState("1 Adulto (Adicionar Mais Pessoas)"); // default
const [personAddLabelSimple,setPersonAddLabelSimple] = React.useState("1"); // default
const [personAddAdult,setPersonAddAdult] = React.useState(1); // default adult
const [personAddYoung,setPersonAddYoung] = React.useState(0); // default young
const [personAddTotal,setPersonAddTotal] = React.useState(1); 
const [personAddYoungAges,setPersonAddYoungAges] = React.useState([]); 
const [personAddElder,setPersonAddElder] = React.useState(0); // default elder
const [personAddElderAges,setPersonAddElderAges] = React.useState([]); 


const [luggageAddLabel,setLuggageAddLabel] = React.useState("1 Bagagem (Adicionar Mais)"); // default
const [luggageAdd,setLuggage] = React.useState(1); // default adult
const [menuLugOpened,setMenuLugOpened] = React.useState(false);
const [menuPriceOpened,setMenuPriceOpened] = React.useState(false);

const [selectedElderAge,setSelectedElderAge] = React.useState([]);
const [selectedYoungAge,setSelectedYoungAge] = React.useState([]); 
const [selectedElderAgeLabel,setSelectedElderAgeLabel] = React.useState("");
const [selectedYoungAgeLabel,setSelectedYoungAgeLabel] = React.useState(""); 

const [menuOpenedOrder,setMenuOrderOpened] = React.useState(false);

const [orderByLabel,setOrderByLabel] = React.useState("Ordenar por"); // default
const [orderByFieldLabel,setOrderByFieldLabel] = React.useState(""); // default

const [lastObjectId,setLastObjectId] = React.useState({}); // default
const [selectedTransportPrice,setSelectedTransportPrice] = React.useState("Carregando..."); // default

const [transportType,setTransportType] = React.useState(null); // default

// temp
const [checked, setChecked] = React.useState(false);
const [checkedCPF, setCheckedCPF] = React.useState(true);
const [seat, setSeat] = React.useState('');

/* Passengers*/
const [loadingPersonData, setPersonLoadingData] = React.useState(false);
const [personName, setPersonName] = React.useState('');
const [birthday, setBirthday] = React.useState('');
const [personDocument, setPersonDocument] = React.useState('');
const [personPhone, setPersonPhone] = React.useState('');

const [acceptTerm, setAcceptTerm] = React.useState(false);
const [autoFields, setAutoFields] = React.useState(true); // if true, fields are disabled
const [autoFieldsFill, setAutoFieldsFill] = React.useState(true); // if true, fields are auto filled. No manual.
const [autoFieldsFilled, setAutoFieldsFilled] = React.useState(false); // if true, fields was returned
const [autoPersonFieldsFilled, setAutoPersonFieldsFilled] = React.useState(false); // if true, fields was returned
const [documentReady, setDocumentReady] = React.useState(false); // if true, document is ok and ready
const [personDocumentReady, setPersonDocumentReady] = React.useState(false); // if true, document is ok and ready

const [termsModal, setTermsModal] = React.useState(false); 

let today = new Date();
let month = today.getMonth();
let year = today.getFullYear();
let minDate = new Date();

addLocale('br', {
	firstDayOfWeek: 1,
	dayNames: ['domingo', 'segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado'],
	dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
	dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
	monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
	monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
	today: 'Hoje',
	clear: 'Limpar'
});

const op = React.useRef(null);
const op2 = React.useRef(null);
const opLug = React.useRef(null);
const opPrice = React.useRef(null);

const onHide = () => {
setMenuOpened(false);
}  
const onShow = () => {
setMenuOpened(true);
}    

const onHideOrder = () => {
setMenuOrderOpened(false);
}  
const onShowOrder = () => {
setMenuOrderOpened(true);
}    

const onLugHide = () => {
setMenuLugOpened(false);
}  
const onLugShow = () => {
setMenuLugOpened(true);
}    


const onHidePrice = () => {
setMenuPriceOpened(false);
}  
const onShowPrice = () => {
setMenuPriceOpened(true);
}    
 

 
const setPerson = (operation,type) => {
	
	// Set total passengers
	let currentTotal = personAddAdult + personAddYoung + personAddElder;
	
	if (type == 'adult') {
		
		let newValue = operation == '+' ? personAddAdult + 1 : personAddAdult - 1;
		newValue = newValue < 0 ? 0 : newValue;
		if (currentTotal < 50 || operation == '-') {
			setPersonAddAdult(newValue);
		}
		
	} // end if
	else if (type == 'young') {
		
		let newValue = operation == '+' ? personAddYoung + 1 : personAddYoung - 1;
		newValue = newValue < 0 ? 0 : newValue;
		if (currentTotal < 50 || operation == '-') {
			setPersonAddYoung(newValue);
		}
		
		// reset ages
		if (operation == '-') { setSelectedYoungAge([]); }
		
	} // end if
	else if (type == 'elder') {
		
		let newValue = operation == '+' ? personAddElder + 1 : personAddElder - 1;
		newValue = newValue < 0 ? 0 : newValue;
		if (currentTotal < 50 || operation == '-') {
			setPersonAddElder(newValue);	
		}
		
		// reset ages
		if (operation == '-') { setSelectedElderAge([]); }		
		
	} // end if

	
} // end function
/* Person select END */


const generateTotalLabel = () => {

	// Set total passengers
	let currentTotal = personAddAdult + personAddYoung + personAddElder;

	if (personAddAdult == 1 && personAddYoung == 0 && personAddElder == 0) {
		var label = "1 Viajante ";	
	} // end if
	else if (personAddAdult > 1 && personAddYoung == 0 && personAddElder == 0) {
		var label = ""+personAddAdult+" Viajantes ";	
	} // end else if
	else if (currentTotal == 0) {
		var label = "Nenhum Viajante";
	} // end else if	
	else if (currentTotal == 1) {
		var label = ""+currentTotal+" Viajante ";
	} // end else if	
	else {
		var label = ""+currentTotal+" Viajantes";	
	} // end else
	
	// Set Person Control Label
	setPersonAddLabel(label);
	setPersonAddLabelSimple(currentTotal);
	
}


const processPickReturn = (value) => {
	
	if (value) {
		setPickReturnDate(true);
	} // end if
	else {
		setPickReturnDate(false);
	} // end else

	setPickReturn(value);		
	
}

// set Use of history for PUSH 
var navigate = useNavigate();
	
/* Execute recaptcha to retrieve token*/
const recaptchaRef = window.grecaptcha;
const executeReCaptcha = async () => {
	const token = await recaptchaRef.execute();
	return token;
}

 // Suggested by Laurent
React.useEffect(() => {
	 
	 if (counter > 0) {
		 setTimeout(function() {
			 setCounter(counter - 1)
			 setCounterLabel(secondsToHms(counter - 1));

			// Current %
			var currentPercent = (100 * counter) / counterInitial;
			setCounterValue(currentPercent);
			 
		 },1000);		 
	 } // end if
	 else {
		 
		setAlertExpire(true);
		setAlertTitleExpire("O resultado da busca expirou");
		setAlertBodyExpire("O resultado da busca incluindo preços e trechos expirou. Faça uma nova pesquisa, para ver preços e viagens atualizadas.");
		setAlertActionExpire("restartTransportSearch");
		 
	 } // end else
	 
 }, [counter]);

const secondsToHms = (d) => {
	
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1) : "00";
    var mDisplay = m > 0 ? m + (m == 1) : "00";
    var sDisplay = s > 0 ? s + (s == 1) : "00";

	let hour = ""+hDisplay+"";
	let minutes = ""+mDisplay+"";
	let seconds = ""+sDisplay+"";

    return hour.padStart(2, '0') +":"+ minutes.padStart(2, '0')+":"+ seconds.padStart(2, '0');
}



// Run after mount component
React.useEffect(() => {

	// Retrieve User Data Obj. 
	var result = localStorage.getItem("@memovo/userData", undefined,true);
	var userData = JSON.parse(result);  

	  // If token dont exist, redirect to onboarding or register
	  if (!userData) {
		  navigate("/home");
		  return;
	  } // end if
	  	  
	// If there is no filter, go back to filtering page
	if (!transportFilters.departureLocalCountry) {  
		  navigate("/order/transport");
		  return;
	} // end if	  
	  
	 setLoading(false);

  // set scroll to top
  window.scrollTo(0, 1);
  
// Load stored info
  if (transportFilters.departureLocalCountry) {
	  
		// Transport selection
		setCheckedAll(transportFilters.checkedAll);
		setCheckedAirplane(transportFilters.checkedAirplane);
		setCheckedTrain(transportFilters.checkedTrain);
		setCheckedBus(transportFilters.checkedBus);
		setDirectServices(transportFilters.directServices);
		
		setPersonAddAdult(transportFilters.adultPassengers);
		setPersonAddYoung(transportFilters.youngPassengers);
		setPersonAddTotal(transportFilters.totalPassengers);
		setPersonAddElder(transportFilters.elderPassengers);

		setSelectedElderAge(transportFilters.selectedElderAge);
		setSelectedYoungAge(transportFilters.selectedYoungAge);
		
		var arrayFilter = [...transportFilters.selectedElderAge];
		let elderAgeLabelTmp = convertAgeArray(arrayFilter);
 		setSelectedElderAgeLabel(elderAgeLabelTmp);
		
		var arrayFilter = [...transportFilters.selectedYoungAge];
		let youngAgeLabelTmp = convertAgeArray(arrayFilter);
		setSelectedYoungAgeLabel(youngAgeLabelTmp);		
	  
  } // end if  
  
 /*
  setTimeout(function() {
	  
	setLoadingContentTrain(false);
	setLoadingContent(false);
	  
  }, 4000);

  
  setTimeout(function() {
	  
	setLoadingContentBus(false);
	setLoadingContent(false);
	  
  }, 8000);*/

  
setTimeout(function() {
	
	var objPrice = "129,00 €";
	var objTransportType = "train";
	setTransportType(objTransportType);
	setSelectedTransportPrice(objPrice);
	
	setLoadingRightPanel(false);
	
},1500);  
  
    
  // Runs when UNMOUNT component
  return () => {
    
	// unmount...	
	
  };
  
}, []); // end useEffect 

// Extra values to a plain array
const convertAgeArray = (value) => {
	
	let finalString = "";
	for (var i = 0; i < value.length; i++) {
		
		finalString += value[i].value+" anos,";
		
	} // end for	
	
	var finalStringConcat = finalString.substr(0,finalString.length-1);

	return finalStringConcat;
	
}


// Execute generateTotalLabel if value changes
React.useEffect(() => {

	generateTotalLabel();
		  
}, [personAddAdult,personAddYoung,personAddElder]); // end useEffect 

const stepsData = [
        {
            label: 'Tipo deslocamento',
            command: (event) => {
					navigate("/order");
            }
        },
        {
            label: 'Dados da Viagem',
			 command: (event) => { 
                navigate("/order/transport");
            }
        },
        {
            label: 'Escolha de passagem',
            command: (event) => {
                navigate("/order/transport/searchResult");
            }
        },
        {
            label: 'Confirmação de Dados',
            command: (event) => {
                console.log("[Clicou Step 5]--->");
            }
        },	
        {
            label: 'Confirmação de Reserva',
            command: (event) => {
                console.log("[Clicou Step 4]--->");
            }
        }
];

var youngAgesRangeArray = [];
for (var i = 0; i <= 25; i++) {
	youngAgesRangeArray.push({
		age:i+" anos",
		code:i
	}); 
}
const youngAges = [...youngAgesRangeArray];
const setYoungAge = (index,value) => {

	// Filter for exiting value. IF exist, replace
	var array = [...selectedYoungAge]; // clone
	var indexArray = array.filter(item => item.index != index);	
	
	var arrayObj = {
		index:index,
		value:value
	}
	var finalArray = [...indexArray,arrayObj];
		
	setSelectedYoungAge(finalArray);
}
var elderAgesRangeArray = [];
for (var i = 58; i <= 99; i++) {
	elderAgesRangeArray.push({
		age:i+" anos",
		code:i
	}); 
}
const elderAges = [...elderAgesRangeArray];
const setElderAge = (index,value) => {

	// Filter for exiting value. IF exist, replace
	var array = [...selectedElderAge]; // clone
	var indexArray = array.filter(item => item.index != index);	
	
	var arrayObj = {
		index:index,
		value:value
	}
	var finalArray = [...indexArray,arrayObj];
		
	setSelectedElderAge(finalArray);
}

/*
const checkAll = (e) => {
	setCheckedAll(e.value);
	setCheckedAirplane(false);
	setCheckedTrain(false);
	setCheckedBus(false);
}*/

const executeTransferSearch = ()  => {
	
	// add data to store and set searching mode onChange
	//
	
	// Redirect to searchResultPage	
	navigate("/order/transport/searchResult");	
	
}


const searchTransport = ()  => {
	
	executeTransferSearch();
		
	// Check required fields
	/*if ( (typeof departureLocal !== 'object' && Array.isArray(value) && departureLocal === null) || (typeof destinationLocal !== 'object' && Array.isArray(destinationLocal) && destinationLocal === null) || !departureTime || !departureDate) {
		setAlert(true);
		setAlertTitle("Verifique as informações");
		setAlertBody("Um ou mais campos não estão preenchidos. É necessário que preencha todos os campos marcados com * para prosseguir com o cadastro.");	
		return;
	} // end if	
	
	let currentTotal = personAddAdult + personAddYoung + personAddElder;
	if (currentTotal <= 0) {
		setAlert(true);
		setAlertTitle("Verifique os passageiros");
		setAlertBody("É necessário ao menos um passageiro para fazer a busca.");	
		return;		
	} // end if		
	*/
	
	
}

// If there is no filter, go back to filtering page
if (!transportFilters.departureLocalCountry) {  
	  return(null);
} // end if	 


const togglePanel = (e) => {
	
	// Clean last selectors
	if (lastObjectId.id) {
		
		var element = document.getElementById(lastObjectId.id);
		element.style.border = "0px";	
		
		let objString = lastObjectId.id;
		var tmp = objString.split("_");
		let lastElement = tmp[tmp.length - 1];
		
		var element = document.getElementById("resultBlock_"+lastElement+"");
		// element.style.border = "0px";
		element.style.filter = "unset";
		element.style.boxShadow = "unset";
	} 
	
	// set lastObject Id
	setLastObjectId(e.currentTarget);
			
	// Set current object bordes
	e.currentTarget.style.border = "1px solid purple";
	
	// Retrieve Price to update overall
	// ...
	
	// Get Data Attribute from selected button
	var buttonElement = e.currentTarget;
	var objToken = buttonElement.dataset.token;
	var objTariff = buttonElement.dataset.tariff;
	var objPrice = buttonElement.dataset.price;
	var objClass = buttonElement.dataset.class;
	var objTransportType = buttonElement.dataset.transporttype;
	
	// Set TransportType
	setTransportType(objTransportType);

	// Set Prices
	setSelectedTransportPrice(objPrice);
	
	// Set result block id
	let objString = e.currentTarget.id;
	var tmp = objString.split("_");
	let lastElement = tmp[tmp.length - 1];
  	var element = document.getElementById("resultBlock_"+lastElement+"");
	//element.style.border = "1px solid purple";
	//element.style.filter = "drop-shadow(1px 1px 4px #9C9C9C)";	
	//element.style.boxShadow = "rgba(19, 41, 104, 0.2) 0px 2px 5px 0px;";
	element.style.boxShadow = "0px 2px 5px 0px rgba(19, 41, 104, 0.2)";

	
	// Set loading on
	setLoadingRightPanel(true);
	
	if (rightPanel) {
				
		/*setRightPanelBlock(false);
		
		setTimeout(function() {
			setRightPanel(false);
		},500);		*/
		
		setTimeout(function() {
			setLoadingRightPanel(false);
		},2000);			
		
	} // end if
	else {
		setRightPanel(true);
		
		setTimeout(function() {
			setRightPanelBlock(true);
			
			setTimeout(function() {
				setLoadingRightPanel(false);
			},2500);			
			
		},500);
		
	} // end else
		
}

/* Check if document is valid,. If yes, release button. If not, alert.*/
const handlePersonDocumentChange = (e) => {

	var currentValue = e.target.value;
	
	setPersonDocument(maskCPF(e.target.value));
	
	if (currentValue.length >= 14) {
		
		// check if cnpj is valid
		if (!validarCPF(currentValue)) {
			setAlert(true);
			setAlertTitle("Verifique o CPF");
			setAlertBody("O CPF informado é inválido. Verifique novamente.");	
			return;	
		} // end if	
		else {
			setPersonDocumentReady(true);
		} // end else
		
	} // end if
	else {
		setPersonDocumentReady(false);
		setAutoPersonFieldsFilled(false);
		setPersonName("");		
	} // end else
	
}  // end function


/* Check if document is valid,. If yes, release button. If not, alert.*/
const handlePersonDocumentForeignerChange = (e) => {

	var currentValue = e.target.value;
	
	setPersonDocument(e.target.value);
	
}  // end function



const retrievePersonDocumentInfo = () => {
	
	setPersonLoadingData(true);
	
	var props = {
		setSaving:setSaving,
		setAlert:setAlert,
		setAlertTitle:setAlertTitle,
		setAlertBody:setAlertBody,
		setAutoPersonFieldsFilled:setAutoPersonFieldsFilled,
		setPersonLoadingData:setPersonLoadingData,
		setPersonName:setPersonName,
		setBirthday:setBirthday
	}
	var data = {
	  personDocument: personDocument
	}
	retrievePersonDataByDocument(data,props);
	

} // end retrieveDocumentInfo


const accept = () => {
	navigate("/order/transport/reservation/confirm");
}

const reject = () => {
	
}

const confirm1 = () => {
	confirmDialog({
		message: 'Tem certeza que deseja prosseguir com a efetivação da reserva com os dados selecionados e informados? Ao prosseguir as reservas serão efetivadas.',
		header: 'Tem certeza que quer prosseguir com a reserva?',
		icon: 'pi pi-exclamation-triangle',
		accept,
		reject,
		acceptLabel:"Sim, tenho certeza!",
		rejectLabel:"Fechar",
		contentStyle:{maxWidth:"40vw",width:"40vw"}
	});
};


const handleTermsChange = (e) => {
	if (e.checked)
		setAcceptTerm(true);
	else
		setAcceptTerm(false);
}  // end function


  return (
	<>
	<div id="loginSection" style={{marginTop:"0"}} className={isTabletOrMobile ? "login__wrapper mobile" : "login__wrapper"} >
		<div style={{minHeight:"728px",height:"auto"}} className={isTabletOrMobile ? "login__wrapper_form_wrapper mobile" : "login__wrapper_form_wrapper"}> 		
			<div className={isTabletOrMobile ? "login__form_wrapper mobile" : "login__form_wrapper"} style={{alignItems:"flex-start"}}>
						
				<div style={{flex:1,margin:"2rem",marginTop:"50px",minHeight:"520px",marginLeft:"auto",marginRight:"auto"}}>
				
					<div style={{fontSize:"28px",textAlign:"center",letterSpacing:"0.1em",lineHeight:"120%",fontWeight:"400",fontStyle:"normal",fontFamily:"Titillium Web",color:"#2E0249",flex:"1"}}>Compra de transporte</div>
					
					<div style={{display:"flex",flexDirection:isTabletOrMobile ? "column" : "row",alignItems:"center",justifyContent:"center",gap:"15px",marginTop:"20px"}}> 
					
						<div style={{marginLeft:"auto",marginRight:"auto",display:"flex",flexDirection:isTabletOrMobile ? "column" : "column",alignItems:"center",justifyContent:"center",gap:"15px",width:"100%",paddingBottom:"20px"}}  className="selectBox">
					
							<div>
								<Steps model={stepsData} activeIndex={3} readOnly={false} />
							</div>
							
							<Divider type="dotted" style={{maxWidth:"1170px"}} />
							   
								<div className={loading ? "imagem-zoom serviceTransferSelector" : "imagem-zoom serviceTransferSelector animate"} style={{backgroundColor:"transparent", width:"100%",maxWidth:"1170px", minHeight:"400px",display:"flex"}}> 
					
								
									<div className="searchWrapper" style={{flex:1,width:"100%",height:"100%",margin:"10px",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
									
									
											<Grid container spacing={1} style={{marginTop:"-20px",alignItems:"center",justifyContent:"flex-end"}}>
											
												<div style={{textAlign:"left",justifySelf:"flex-start"}}>
																	
													<Button outlined icon="pi pi-angle-left" className="pT10" label="Alterar seleção" onClick={()=>navigate("/order/transport/searchResult")} />
												
												</div>

												<div style={{flex:1}}></div>

												 <Grid item xs={12} sm={2} style={{marginLeft:"25px"}}>
															
															<div style={{width:"100%",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",marginBottom:"10px"}}> 
															
																<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '14px',lineHeight: '120%',letterSpacing: '0.1em',color: '#814DE5',marginBottom:"10px"}}><ProgressBar className={counterValue < 10 ? "timeBar animate" : "timeBar"} showValue={false} value={counterValue} style={{height:"6px"}}></ProgressBar></div>
																
																<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '14px',lineHeight: '120%',letterSpacing: '0.1em', display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
																
																	<div style={{flex:1,display:"flex"}}>	
																		<div>{counterLabel}</div> 
																		<div className="tooltipTime" data-pr-tooltip="Busca e preços expiram no tempo apresentado,sendo necessário fazer nova busca após expirar o tempo." data-pr-position="bottom"  data-pr-showdelay="300"><img src={tooltipIcon} style={{width:"20px",height:"auto",marginLeft:"10px"}} className="noSelect" /></div> <Tooltip target=".tooltipTime" style={{maxWidth:"200px",fontWeight:"normal",fontSize:"14px"}} />
																	</div>
																
																</div>

															</div>													
															
												 </Grid>											 
											 
												  
											</Grid>	
							
																			
										<Grid container spacing={2} style={{marginTop:"20px"}}>
										 
											<Grid className="leftPanel animate" item xs={12} sm={8} style={{display:"flex",flexDirection:"column",justifyContent:"flex-start",alignItems:"center"}}>
											 	
												 <Grid item xs={12} sm={12} style={{display:checkedTrain ? "flex" : "none",flexDirection:"row",justifyContent:"center",alignItems:"center",width:"100%",maxHeight:"70px",marginBottom:"25px"}}>
												
													<div style={{flex:1,display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"flex-start"}}>
													
														<div style={{marginBottom:"10px",color: "#2E0249", fontFamily: "Titillium Web", fontSize: "28px", fontStyle: "normal", fontWeight: 600, lineHeight: "120%", letterSpacing: "2.8px"}}>Confirmação de dados da reserva</div>
														<div style={{color: "rgba(34, 34, 34, 0.60)", textAlign: "left", fontFamily: "Titillium Web", fontSize: "18px", fontStyle: "normal", fontWeight: 400, lineHeight: "120%", letterSpacing: "1.8px"}}>Verifique os dados abaixo, preencha os dados do responsável pela reserva e dos viajantes, antes de confirmar a reserva</div>
													
													</div>
													
												</Grid>											 

												<div style={{padding:"20px",paddingLeft:"0px",paddingBottom:"0",color:'#222',fontFamily:'Titillium Web',fontSize:'20px',fontStyle:'normal',fontWeight:600,lineHeight:'150%',letterSpacing:'2px',width:"100%",textAlign:"left",boxShadow:"unset"}}>Dados da viagem 
												
												{transportFilters.pickReturn &&
												<>
												 <small style={{marginLeft:"10px"}}>(Ida e Volta)</small>
												</>
												}
												
												{!transportFilters.pickReturn &&
												<>
												 <small style={{marginLeft:"10px"}}>(Ida)</small>
												</>
												}												
												
												</div>
											
												<div id="mainInfo" style={{borderRadius:"10px",width:"100%",margin:"10px",backgroundColor:"#fff",minHeight:"125px",marginBottom:"10px",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",padding:"15px",cursor:"initial"}}>

													<Grid container spacing={1} style={{alignItems:"flex-start"}}>

														<div style={{width:"100%",display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center", gap:"35px",padding:"20px"}}>
														 <Grid item >
											  
																	<div style={{width:"100%",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",marginBottom:"15px"}}> 
																	
																		<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '16px',lineHeight: '120%',letterSpacing: '0.1em',color: '#814DE5',marginBottom:"10px"}}>Local de Partida</div>
																		
																		<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '16px',lineHeight: '120%',letterSpacing: '0.1em', display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
																		
																			<div style={{flex:1}}>	
																				{transportFilters ? transportFilters.departureLocalCountry.name : "Carregando..."}, {transportFilters ? transportFilters.departureLocalCity.name : "Carregando..."}		
																			</div>
																		
																		</div>

																	</div>
																	
														 </Grid>
														  
														 
														<Grid item  style={{textAlign:"left",alignSelf:"center"}}>
															<i className={transportFilters.pickReturn ? "pi pi-arrow-right-arrow-left" : "pi pi-arrow-right" } style={{ color: 'slateblue',fontSize:"30px",width:"100%",height:"auto"}}></i>
														</Grid>
														
														<Grid item >
														
																<div style={{width:"100%",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",marginBottom:"10px"}}> 
																
																	<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '16px',lineHeight: '120%',letterSpacing: '0.1em',color: '#814DE5',marginBottom:"10px"}}>Local de Destino</div>										
																
																	<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '16px',lineHeight: '120%',letterSpacing: '0.1em', display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
																	
																		<div style={{flex:1}}>	
																				{transportFilters ? transportFilters.destinationLocalCountry.name : "Carregando..."}, {transportFilters ? transportFilters.destinationLocalCity.name : "Carregando..."}		
																		</div>
																		
																	</div>

																</div>												
																	
														 </Grid>
														 </div>
														 
														 <div style={{width:"100%",display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center", gap:"35px",padding:"20px",paddingTop:"0px"}}>
														 <Grid item  >
											  
																	<div style={{width:"100%",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",marginBottom:"10px"}}> 
																	
																		<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '16px',lineHeight: '120%',letterSpacing: '0.1em',color: '#814DE5',marginBottom:"10px"}}>Data de Ida</div>
																		
																		<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '16px',lineHeight: '120%',letterSpacing: '0.1em', display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
																		
																			<div style={{flex:1}}>	
																				{transportFilters ? transportFilters.leaveDate.toLocaleDateString('pt-BR', { weekday: 'short',  year: 'numeric', month: 'long', day: 'numeric' }) : "Carregando..."}
																			</div>
																		
																		</div>

																	</div>
																	
														 </Grid>	


														 <Grid item  style={{marginLeft:"25px"}}>
											  
																	{transportFilters.pickReturn &&
																	<div style={{width:"100%",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",marginBottom:"10px"}}> 
																	
																		<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '16px',lineHeight: '120%',letterSpacing: '0.1em',color: '#814DE5',marginBottom:"10px"}}>Data de retorno</div>
																		
																		<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '16px',lineHeight: '120%',letterSpacing: '0.1em', display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
																		
																			<div style={{flex:1}}>	
																				{transportFilters ? transportFilters.returnDate.toLocaleDateString('pt-BR', { weekday: 'short',  year: 'numeric', month: 'long', day: 'numeric' }) : "Carregando..."}
																			</div>
																		
																		</div>

																	</div>
																	}
																	
														 </Grid>
														 </div>
														  
													</Grid>	
										
												
												</div>
																																 
												{/* One way Trip */}
												<div style={{padding:"20px",paddingLeft:"0px",paddingBottom:"0",color:'#222',fontFamily:'Titillium Web',fontSize:'20px',fontStyle:'normal',fontWeight:600,lineHeight:'150%',letterSpacing:'2px',width:"100%",textAlign:"left",boxShadow:"unset"}}>Viagem selecionada <small style={{marginLeft:"5px"}}>(Ida)</small> <span style={{marginLeft:"40px"}}>Trem <img src={titleTrainIcon} style={{marginLeft:"0px",width:"18px",height:"auto"}} className="noSelect" /></span></div>
											
												<div id="resultBlock_1" style={{borderRadius:"10px",width:"100%",margin:"10px",backgroundColor:"#fff",minHeight:"125px",marginBottom:"10px",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",padding:"15px",cursor:"initial"}}>
													
													<div style={{flex:1,display:"flex",flexDirection:"row",alignItems:"stretch",justifyContent:"flex-start"}}>
														
														<div style={{flex:1.3,display:"flex",flexDirection:"column",alignItems:"flex-start",justifyContent:"center",textAlign:"left"}}>
															<div style={{width:"100%",marginTop:"13px"}}><img src={obbProviderLogo} style={{width:"auto",height:"18px"}} className="noSelect" /></div>
															<div style={{width:"100%",marginTop:"13px",marginBottom:"13px"}}>
															
																<div style={{width:"100%",display:"flex",flexDirection:"row"}}>
																
																	<div style={{width:"45%",display:"flex",flexDirection:"row",    justifyContent:"space-between",alignItems:"center"}}>
																	
																		<div style={{color:'#222',fontFamily:'Titillium Web',fontSize:'24px',fontStyle:'normal',fontWeight:600,lineHeight:'120%',letterSpacing:'2.4px'}}>
																		09:00
																		</div>

																		<div style={{color:'rgb(161, 169, 195)',fontSize:'14px',display:'flex',WebkitBoxAlign:'center',alignItems:'center',justifyContent:'space-evenly',textAlign:'center',width:'100%'}}>
																			<div style={{backgroundColor:'rgb(161, 169, 195)',WebkitBoxFlex:2,flexGrow:2,height:'1px',margin:'auto 4px',borderRadius:'1px',opacity:0.45,height:'2px',margin:'auto 10px',width:"20px"}}></div>
																			<div>3h40m</div>
																			<div style={{backgroundColor:'rgb(161, 169, 195)',WebkitBoxFlex:2,flexGrow:2,height:'1px',margin:'auto 4px',borderRadius:'1px',opacity:0.45,height:'2px',margin:'auto 10px',width:"20px"}}></div>
																		</div>
																		
																	</div>
																	
																	<div style={{width:"45%",color:'#222',fontFamily:'Titillium Web',fontSize:'24px',fontStyle:'normal',fontWeight:600,lineHeight:'120%',letterSpacing:'2.4px'}}>
																	12:44
																	</div>
																
																</div>
																
																<div style={{width:"100%",display:"flex",flexDirection:"row"}}>
																
																	<div style={{width:"45%",color:'rgba(34, 34, 34, 0.60)',fontFamily:'Titillium Web',fontSize:'14px',fontStyle:'normal',fontWeight:400,lineHeight:'120%',letterSpacing:'1.4px'}}>
																	Paris Gare du Nord
																	</div>

																	<div style={{width:"45%",color:'rgba(34, 34, 34, 0.60)',fontFamily:'Titillium Web',fontSize:'14px',fontStyle:'normal',fontWeight:400,lineHeight:'120%',letterSpacing:'1.4px'}}>
																	 Amesterdan Central
																	</div>
																   
																</div>															
															
															</div>
														</div>
														
														
														<div style={{flex:1,display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center"}}>
														
															<div styles={{flex:1}}><Button id="buttonBlock_1_1" data-token="acnb-44566-dddkd" data-tariff="standard" data-price="129,00 &euro;" data-class="2" data-transporttype="train" text label="129,00 &euro;" style={{flexDirection:"column",color:"#2E0249",fontFamily:"Titillium Web",fontSize:"24px",fontStyle:"bold",fontWeight:800,lineHeight:"120%",letterSpacing:"2.4px"}}><div style={{display:"block",color:'rgba(34, 34, 34, 0.60)',textAlign:'center',fontFamily:'Titillium Web',fontSize:'14px',fontStyle:'normal',fontWeight:400,lineHeight:'120%',letterSpacing:'1.4px'}}>2ª classe | <i className="pi pi-user" style={{ fontSize: '0.7rem' }}></i> <strong>{personAddLabelSimple}</strong></div></Button></div>
	
															
														</div>														
														
													</div>

													<div style={{textAlign:"left"}}>
														
														<Panel headerTemplate={(options) => {return (<Button iconPos="right" icon="pi pi-angle-down" size="small" rounded text aria-label="Menu" label="1 Conexão (abra para detalhes)" style={{fontFamily:'Titillium Web',fontStyle:'normal',fontWeight:400,fontSize:'14px',lineHeight:'120%',textAlign:'center',letterSpacing:'0.1em',color:'#814DE5',padding:0}}  onClick={options.onTogglerClick} />)}} toggleable collapsed={true} className="cardPanel">
															<p className="m-0">
															
															<Divider />
															
																<div style={{display:"flex"}}>
																
																	<div style={{display:"flex",flexDirection:"column"}}>
																	
																		<div style={{height:"96px",maxHeight:"96px"}}>
																		
																			<div style={{display:'flex',flex:'1 1 0%',flexDirection:'column',WebkitBoxPack:'justify',justifyContent:'space-between',fontSize:'14px',height:'100%'}}>
																			
																				<div>
																					<div style={{color:'#222',fontFamily:'Titillium Web',fontSize:'18px',fontStyle:'normal',fontWeight:600,lineHeight:'120%',letterSpacing:'2.4px'}}>09:00</div>
																					<div style={{color:'rgb(161, 169, 195)',fontSize:'14px',display:'flex',WebkitBoxAlign:'center',alignItems:'center',justifyContent:'flex-start',textAlign:'left',width:'100%'}}>1h30m</div>
																				</div>
																				
																				<div>
																					<div style={{color:'#222',fontFamily:'Titillium Web',fontSize:'18px',fontStyle:'normal',fontWeight:400,lineHeight:'120%',letterSpacing:'2.4px'}}>10:30</div>
																				</div>
																			
																			</div>						
																		
																		</div>

																		<div style={{height:"46px",margin:"5px 0px"}}>
																		</div>

																		<div style={{height:"96px",maxHeight:"96px"}}>
																		
																			<div style={{display:'flex',flex:'1 1 0%',flexDirection:'column',WebkitBoxPack:'justify',justifyContent:'space-between',fontSize:'14px',height:'100%'}}>
																			
																				<div>
																					<div style={{color:'#222',fontFamily:'Titillium Web',fontSize:'18px',fontStyle:'normal',fontWeight:400,lineHeight:'120%',letterSpacing:'2.4px'}}>10:45</div>
																					<div style={{color:'rgb(161, 169, 195)',fontSize:'14px',display:'flex',WebkitBoxAlign:'center',alignItems:'center',justifyContent:'flex-start',textAlign:'left',width:'100%'}}>1h59m</div>
																				</div>
																				
																				<div>
																					<div style={{color:'#222',fontFamily:'Titillium Web',fontSize:'18px',fontStyle:'normal',fontWeight:600,lineHeight:'120%',letterSpacing:'2.4px'}}>12:44</div>
																				</div>
																			
																			</div>																			
																		
																		</div>		
																	
																	</div>
																	
																	<div style={{display:"flex",flexDirection:"column"}}>
																	
																		<div style={{height:"96px",maxHeight:"96px"}}>
																			
																			<div style={{display:'flex',flex:'1 1 0%',flexDirection:'column',WebkitBoxPack:'justify',justifyContent:'space-between',fontSize:'14px',WebkitBoxAlign:'center',alignItems:'center',textAlign:'center',padding:'2px 10px 4px',height:'96px',maxHeight:'96px'}}>
																				
																				<div style={{color:"rgb(66, 84, 134)",width:"16px",height:"16px"}}><svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" color="#425486" size="16"><path d="M12.5 19.584c-4.661 0-8.5-2.87-8.5-7.106 0-2.05 1.371-6.285 1.371-6.285C6.331 3.05 9.21 1 12.5 1c3.29 0 6.032 2.186 7.129 5.193 0 0 1.371 4.236 1.371 6.285 0 4.236-3.839 7.106-8.5 7.106zM7.565 6.602v2.05c0 .82.548 1.367 1.37 1.367h7.13c.822 0 1.37-.547 1.37-1.367v-2.05c0-.82-.548-1.366-1.37-1.366h-7.13c-.822 0-1.37.547-1.37 1.366zm9.048 13.665c.411-.137.685-.273.822-.41L20.315 23h-2.742l-.275-.82H7.565L7.29 23H4.548l2.88-3.143c.274.137.548.273.96.41l-.275.82h8.774l-.274-.82zm0-4.236c.681 0 1.234-.55 1.234-1.23 0-.679-.553-1.23-1.234-1.23-.682 0-1.234.551-1.234 1.23 0 .68.552 1.23 1.234 1.23zm-8.226 0c.682 0 1.234-.55 1.234-1.23 0-.679-.552-1.23-1.234-1.23-.681 0-1.234.551-1.234 1.23 0 .68.553 1.23 1.234 1.23z" fill="currentColor" fill-rule="evenodd"></path></svg></div>
																				
																				<div style={{flex:'1 1 0%',margin:'6px 0px',backgroundColor:'rgb(161, 169, 195)',width:'2px'}}></div>
																				
																				<div style={{width:'12px',height:'12px',border:'2px solid rgb(66, 84, 134)',borderRadius:'6px'}}></div>					
																			</div>	
																			
																		</div>

																		<div style={{display:'flex',flexDirection:'column',WebkitBoxAlign:'center',alignItems:'center',WebkitBoxPack:'center',justifyContent:'center',height:'49px',overflow:'hidden',margin:'4px auto'}}>
																		
																			<div style={{backgroundColor:'rgb(161, 169, 195)',opacity:0.5,width:'2px',height:'3px',marginBottom:'2px'}}></div>
																			<div style={{backgroundColor:'rgb(161, 169, 195)',opacity:0.5,width:'2px',height:'3px',marginBottom:'2px'}}></div>
																			<div style={{backgroundColor:'rgb(161, 169, 195)',opacity:0.5,width:'2px',height:'3px',marginBottom:'2px'}}></div>
																			<div style={{backgroundColor:'rgb(161, 169, 195)',opacity:0.5,width:'2px',height:'3px',marginBottom:'2px'}}></div>
																			<div style={{backgroundColor:'rgb(161, 169, 195)',opacity:0.5,width:'2px',height:'3px',marginBottom:'2px'}}></div>
																			<div style={{backgroundColor:'rgb(161, 169, 195)',opacity:0.5,width:'2px',height:'3px',marginBottom:'2px'}}></div>
																			<div style={{backgroundColor:'rgb(161, 169, 195)',opacity:0.5,width:'2px',height:'3px',marginBottom:'2px'}}></div>
																			<div style={{backgroundColor:'rgb(161, 169, 195)',opacity:0.5,width:'2px',height:'3px',marginBottom:'2px'}}></div>
																			<div style={{backgroundColor:'rgb(161, 169, 195)',opacity:0.5,width:'2px',height:'3px',marginBottom:'2px'}}></div>
																		
																		</div>

																		<div style={{height:"99px",maxHeight:"99px"}}>
																		
																			<div style={{display:'flex',flex:'1 1 0%',flexDirection:'column',WebkitBoxPack:'justify',justifyContent:'space-between',fontSize:'14px',WebkitBoxAlign:'center',alignItems:'center',textAlign:'center',padding:'2px 10px 4px',height:'99px',maxHeight:'99px'}}>
																				
																				<div style={{color:"rgb(66, 84, 134)",width:"16px",height:"16px"}}><svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" color="#425486" size="16"><path d="M12.5 19.584c-4.661 0-8.5-2.87-8.5-7.106 0-2.05 1.371-6.285 1.371-6.285C6.331 3.05 9.21 1 12.5 1c3.29 0 6.032 2.186 7.129 5.193 0 0 1.371 4.236 1.371 6.285 0 4.236-3.839 7.106-8.5 7.106zM7.565 6.602v2.05c0 .82.548 1.367 1.37 1.367h7.13c.822 0 1.37-.547 1.37-1.367v-2.05c0-.82-.548-1.366-1.37-1.366h-7.13c-.822 0-1.37.547-1.37 1.366zm9.048 13.665c.411-.137.685-.273.822-.41L20.315 23h-2.742l-.275-.82H7.565L7.29 23H4.548l2.88-3.143c.274.137.548.273.96.41l-.275.82h8.774l-.274-.82zm0-4.236c.681 0 1.234-.55 1.234-1.23 0-.679-.553-1.23-1.234-1.23-.682 0-1.234.551-1.234 1.23 0 .68.552 1.23 1.234 1.23zm-8.226 0c.682 0 1.234-.55 1.234-1.23 0-.679-.552-1.23-1.234-1.23-.681 0-1.234.551-1.234 1.23 0 .68.553 1.23 1.234 1.23z" fill="currentColor" fill-rule="evenodd"></path></svg></div>
																				
																				<div style={{flex:'1 1 0%',margin:'6px 0px',backgroundColor:'rgb(161, 169, 195)',width:'2px'}}></div>
																				
																				<div style={{width:"20px",height:"20px",color:"rgb(250, 107, 107)"}}>
																				
																					<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" size="20" color="#FA6B6B"><defs><path d="M12 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-8-3a8 8 0 1 1 16 0c0 1.842-1.176 4.053-3.53 6.635L12 22l-4.47-5.365C5.175 14.053 4 11.842 4 10z" id="Pin__a"></path></defs><use fill="currentColor" xlinkHref="#Pin__a" fillRule="evenodd"></use></svg>									
																				
																				</div>					
																			</div>					
																		
																		</div>		
																	
																	</div>

																	<div style={{display:"flex",flexDirection:"column"}}>
																	
																		<div style={{height:"96px",maxHeight:"96px"}}>
																			
																			<div style={{display:'flex',flex:'1 1 0%',flexDirection:'column',WebkitBoxPack:'justify',justifyContent:'space-between',fontSize:'14px',height:'100%'}}>
																			
																				<div>
																					<div style={{color:'#222',fontFamily:'Titillium Web',fontSize:'16px',fontStyle:'normal',fontWeight:600,lineHeight:'120%',letterSpacing:'2.4px'}}>Belgium, Vienna</div>
																					<div style={{color:'rgb(161, 169, 195)',fontSize:'14px',display:'flex',WebkitBoxAlign:'center',alignItems:'center',justifyContent:'flex-start',textAlign:'left',width:'100%'}}>Paris Gare du Nord | ICE 74</div>
																				</div>
																				
																				<div>
																					<div style={{color:'#222',fontFamily:'Titillium Web',fontSize:'16px',fontStyle:'normal',fontWeight:400,lineHeight:'120%',letterSpacing:'2.4px'}}>Génova Piazza Principe</div>
																				</div>
																			
																			</div>																				
																			
																		</div>

																		<div style={{display:'flex',WebkitBoxAlign:'center',alignItems:'center',margin:'5px 0px',height:'47px'}}>
																			
																			<div style={{color:'rgb(161, 169, 195)',width:'20px',height:'20px',marginRight:'8px'}}>
																				<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" size="20" color="#A1A9C3"><path d="M9.692 8.818l.688.636-1.376 1.273-.688-.636A3.04 3.04 0 0 0 6.253 9.3h-2.28C3.435 9.3 3 8.897 3 8.4c0-.498.435-.9.973-.9h2.28c1.29 0 2.527.473 3.44 1.318zm0 6.364c-.913.844-2.15 1.317-3.44 1.317H3.974c-.538 0-.973-.402-.973-.9 0-.496.435-.9.973-.9h2.28a3.04 3.04 0 0 0 2.063-.79l5.503-5.091c.913-.844 2.15-1.319 3.44-1.319h.822V6l1.46 1.2L21 8.4l-1.46 1.2-1.459 1.2V9.3h-.821c-.774 0-1.516.284-2.064.79l-5.504 5.092zm8.389-.482v-1.5L21 15.6 18.08 18v-1.5h-.82c-1.298 0-2.52-.468-3.44-1.318l-.688-.636 1.376-1.274.688.637c.552.51 1.284.79 2.065.79h.82z" fill="currentColor" fill-rule="evenodd"></path></svg>
																			</div>
																			<div style={{height:"20px",color:"rgb(161, 169, 195)",fontSize:"14px"}}>Trocar, 0h15m</div>																			
																		</div>

																		<div style={{height:"120px",maxHeight:"120px"}}>
																		
																			<div style={{display:'flex',flex:'1 1 0%',flexDirection:'column',WebkitBoxPack:'justify',justifyContent:'space-between',height:'115px',maxHeight:'115px'}}>
																			
																				<div>
																					<div style={{color:'#222',fontFamily:'Titillium Web',fontSize:'16px',fontStyle:'normal',fontWeight:400,lineHeight:'120%',letterSpacing:'2.4px'}}>Génova Piazza Principe</div>
																					<div style={{color:'rgb(161, 169, 195)',fontSize:'14px',display:'flex',WebkitBoxAlign:'center',alignItems:'center',justifyContent:'flex-start',textAlign:'left',width:'100%'}}>Train Station  | ICE 706</div>
																				</div>
																				
																				<div>
																					<div style={{color:'#222',fontFamily:'Titillium Web',fontSize:'16px',fontStyle:'normal',fontWeight:600,lineHeight:'120%',letterSpacing:'2.4px'}}>Czech Republic, Salzburg</div>
																					<div style={{color:'rgb(161, 169, 195)',fontSize:'14px',display:'flex',WebkitBoxAlign:'center',alignItems:'center',justifyContent:'flex-start',textAlign:'left',width:'100%'}}>O seu destino</div>
																				</div>
																			
																			</div>		
																		
																		</div>	
																	
																	</div>
																
																
																</div>
																
																<Divider />

																<div style={{marginTop:"20px",marginBottom:"20px",color:"#2E0249",fontFamily:"Titillium Web",fontSize:"16px",fontStyle:"bold",fontWeight:800,lineHeight:"120%",letterSpacing:"2.4px"}}>Preço total da viagem para  <strong>{personAddLabel}</strong> <i className="pi pi-user" style={{ fontSize: '1rem' }}></i></div>
																
																<div style={{marginTop:"10px",marginBottom:"20px",color:"#2E0249",fontFamily:"Titillium Web",fontSize:"16px",fontStyle:"normal",fontWeight:400,lineHeight:"120%",letterSpacing:"2.4px"}}>

																	{personAddAdult > 0 &&
																	<div style={{display:"block",marginBottom:"5px"}}>
																		{personAddAdult} x Adulto
																	</div>
																	}
																	
																	{personAddYoung > 0 &&
																	<div style={{display:"block",marginBottom:"5px"}}>
																		{personAddYoung} x Jovem <small>({selectedYoungAgeLabel})</small>
																	</div>
																	}

																	{personAddElder > 0 &&
																	<div style={{display:"block",marginBottom:"5px"}}>
																		{personAddElder} x Sênior <small>({selectedElderAgeLabel})</small>
																	</div>
																	}																
																																
																</div>																
															
															</p>
														</Panel>														
														
													</div>												
												
												</div>
												
												{/* One way Flexibility */}
												<div style={{padding:"20px",paddingLeft:"0px",paddingBottom:"0",color:'#222',fontFamily:'Titillium Web',fontSize:'20px',fontStyle:'normal',fontWeight:600,lineHeight:'150%',letterSpacing:'2px',width:"100%",textAlign:"left",boxShadow:"unset"}}>Flexibilidade e condições do Bilhete <small style={{marginLeft:"5px"}}>(Ida)</small> </div>
											
												<div style={{borderRadius:"10px",width:"100%",margin:"10px",backgroundColor:"#fff",minHeight:"125px",marginBottom:"10px",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",padding:"15px",cursor:"initial"}}>
													
													<div style={{flex:1,display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"flex-start"}}>

														<div className="flex align-items-center" style={{ cursor: 'pointer', padding:"20px",paddingBottom:"0px",textAlign:"left"}}>
															<div style={{color:'#222',fontFamily:'Titillium Web',fontSize:'16px',fontStyle:'normal',fontWeight:600,lineHeight:'150%',letterSpacing:'2px',marginBottom:"3px"}}>Semiflexível</div>
															<div style={{color:'rgba(34, 34, 34, 0.60)',textAlign:'left',fontFamily:'Titillium Web',fontSize:'16px',fontStyle:'normal',fontWeight:400,lineHeight:'120%',letterSpacing:'1.8px'}}>129,00 &euro;</div>
														</div>
																			
														<div style={{display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column",padding:0}}>
															
															<div style={{width:"100%",padding:"0px"}}>
																																																	<div style={{color:"#2E0249",fontFamily:"Titillium Web",fontSize:"16px",fontStyle:"normal",fontWeight:400,lineHeight:"120%",letterSpacing:"2.4px",textAlign:"left",marginLeft:"0px",padding:"20px"}}>

																	<div style={{display:"block",marginBottom:"10px",fontWeight:"bold"}}>Detalhamento dos viajantes</div>

																	{personAddAdult > 0 &&
																	<div style={{display:"block",marginBottom:"5px"}}>{personAddAdult} x Adulto</div>
																	}
																	
																	{personAddYoung > 0 &&
																	<div style={{display:"block",marginBottom:"5px"}}>{personAddYoung} x Jovem <small>({selectedYoungAgeLabel})</small></div>
																	}

																	{personAddElder > 0 &&
																	<div style={{display:"block",marginBottom:"5px"}}>{personAddElder} x Sênior <small>({selectedElderAgeLabel})</small></div>
																	}															
																</div>										
																																																	<div style={{marginBottom:"20px",color:"#2E0249",fontFamily:"Titillium Web",fontSize:"16px",fontStyle:"normal",fontWeight:400,lineHeight:"120%",letterSpacing:"2.4px",textAlign:"left",marginLeft:"20px",padding:"0px"}}>

																	<div style={{display:"block",marginBottom:"10px",fontWeight:"bold"}}>Resumo da Tarifa</div>

																	<div style={{marginTop:"15px",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"stretch",gap:"10px"}}>
																		<div><i className="pi pi-book" style={{ fontSize: '1rem' }}></i></div>
																		<div style={{flex:1}}> Semi Reembolsável </div>
																	</div>
																	
																	<div style={{marginTop:"10px",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"stretch",gap:"10px"}}>
																		<div><i className="pi pi-times" style={{ fontSize: '1rem' }}></i></div>
																		<div style={{flex:1}}> Sem possibilidade de troca </div>
																	</div>																						
																</div>											
																																						
															</div>
															<div style={{width:"100%",marginTop:"10px"}}>
																
																<Panel headerTemplate={(options) => {return (<Button iconPos="right" icon="pi pi-angle-down" outlined aria-label="Menu" label="Ver condições de troca e reembolso" style={{fontFamily:'Titillium Web',fontStyle:'normal',fontWeight:400,fontSize:'14px',lineHeight:'120%',textAlign:'center',letterSpacing:'0.1em',color:'#814DE5',padding:0,height:"46px",width:"50%",alignSelf:"flex-start"}}  onClick={options.onTogglerClick} />)}} toggleable collapsed={true} className="cardPanel" style={{width:"100%",textAlign:"left"}}>
									
																	<p className="m-0" style={{padding:"20px",paddingTop:"5px"}}>
																	
																		<div style={{textAlign:"left",fontWeight:"bold",fontSize:"16px"}}>Termos da tarifa</div>
																																																			<div style={{textAlign:"left"}}>
																		<br />
																		Este bilhete é válido apenas para o comboio escolhido. <br /><br />

																		Este bilhete pode ser trocado por um voucher, mediante o pagamento de uma taxa de 10€ até ao dia antes da partida. Este bilhete não pode ser reembolsado, nem trocado na data de partida. Viagem gratuita em serviços de transporte público (S-Bahn, U-Bahn, autocarro ou elétrico) incluída no bilhete City-Ticket. Apenas válido se "+City" estiver impresso no bilhete atrás do nome da estação.</div>				
																				
																		<div style={{marginTop:"30px",marginBottom:"20px",fontStyle:"normal",fontWeight:400,textAlign:"left"}}>

																			<div style={{display:"block",marginBottom:"20px",fontWeight:"bold"}}>Taxas de Cancelamento</div>
																																																					<div style={{textAlign:"left"}}>
																				Cancellation fee train flex price: EUR 0.00 cancellation fees from 19.07.2023 EUR 1020.44 cancellation fees from 30.07.2023</div>			

																				<div style={{width:"90%",marginTop:"10px",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center",gap:"10px"}}>
																				
																					<div style={{width:"100%",display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"stretch",gap:"20px"}}>
																						<div style={{flex:1}}>Até 19/07/2023</div>
																						<div style={{flex:1,textAlign:"right"}}> 0,00 &euro;</div>
																					</div>
																					
																					<div style={{width:"100%",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"stretch"}}>
																						<div style={{flex:1}}>Até 30/07/2023</div>
																						<div style={{flex:1,textAlign:"right"}}> 259,00 &euro;</div>
																					</div>
																					
																				</div>
																				
																		</div>
																			
																	</p>
																
																</Panel>									
																
															</div>
															
														</div>								
														
													</div>
											
												
												</div>	

												{/* Seat Reservation One way */}
												<div style={{padding:"20px",paddingLeft:"0px",paddingBottom:"0",color:'#222',fontFamily:'Titillium Web',fontSize:'20px',fontStyle:'normal',fontWeight:600,lineHeight:'150%',letterSpacing:'2px',width:"100%",textAlign:"left",boxShadow:"unset"}}>Reserva de Lugar <small style={{marginLeft:"5px"}}>(Ida)</small> </div>
											
												<div style={{borderRadius:"10px",width:"100%",margin:"10px",backgroundColor:"#fff",minHeight:"125px",marginBottom:"10px",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",padding:"15px",cursor:"initial"}}>
													
													<div style={{flex:1,display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"flex-start"}}>

														<div style={{padding:"0px",paddingBottom:"0",color:'#222',fontFamily:'Titillium Web',fontSize:'16px',fontStyle:'normal',fontWeight:600,lineHeight:'150%',letterSpacing:'2px',display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center",gap:"20px"}}>
															<div>Taxa da reserva de lugar</div>
															<div style={{display:"flex",flexDirection:"row",justifyContent:"flex-end",alignItems:"center"}}><div style={{fontFamily:'Titillium Web',fontStyle:'bold',fontWeight:400,fontSize:'14px',lineHeight:'120%',textAlign:'right',letterSpacing:'0.1em',color:'#814DE5',padding:0,height:"46px",display:"flex",justifyContent:"center",alignItems:"center",marginRight:"10px"}}>+19,90 &euro;</div></div>
														</div>
														
														<div style={{padding:"10px",textAlign:"left"}}>

															<Message severity="success" style={{textAlign:"left",marginBottom:"20px",fontSize:"16px"}} text={"Reservas adicionadas para "+personAddLabel} />
															
															<div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"flex-start",gap:"10px",marginBottom:"10px",fontSize:"14px",textAlign:"left"}}>

																<div style={{color:"#2E0249",fontFamily:"Titillium Web",fontSize:"16px",fontStyle:"normal",fontWeight:400,lineHeight:"120%",letterSpacing:"2.4px",marginBottom:"10px",fontWeight:"bold"}}>Assento selecionado</div>
																	
																<div style={{alignItems:'stretch',display:'flex',gap:'10px',width:'100%'}}>
																	<label htmlFor="seat1" className="ml-2">Open-plan coach 2nd class</label>
																</div>
																
															</div>	
															
															<div style={{marginTop:"30px",fontStyle:"italic",marginLeft:"0px",textAlign:"left"}}>Esta taxa não é reembolsável</div>
															
														</div>
							
														
													</div>
											
												
												</div>													
												
												
												{/* Return trip */}
												{transportFilters.pickReturn &&
												<>
												<div style={{padding:"20px",paddingLeft:"0px",paddingBottom:"0",color:'#222',fontFamily:'Titillium Web',fontSize:'20px',fontStyle:'normal',fontWeight:600,lineHeight:'150%',letterSpacing:'2px',width:"100%",textAlign:"left",boxShadow:"unset"}}>Viagem selecionada <small style={{marginLeft:"5px"}}>(Volta)</small> <span style={{marginLeft:"40px"}}>Trem <img src={titleTrainIcon} style={{marginLeft:"0px",width:"18px",height:"auto"}} className="noSelect" /></span></div>
											
												<div id="resultBlock_1" style={{borderRadius:"10px",width:"100%",margin:"10px",backgroundColor:"#fff",minHeight:"125px",marginBottom:"10px",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",padding:"15px",cursor:"initial"}}>
													
													<div style={{flex:1,display:"flex",flexDirection:"row",alignItems:"stretch",justifyContent:"flex-start"}}>
														
														<div style={{flex:1.3,display:"flex",flexDirection:"column",alignItems:"flex-start",justifyContent:"center",textAlign:"left"}}>
															<div style={{width:"100%",marginTop:"13px"}}><img src={obbProviderLogo} style={{width:"auto",height:"18px"}} className="noSelect" /></div>
															<div style={{width:"100%",marginTop:"13px",marginBottom:"13px"}}>
															
																<div style={{width:"100%",display:"flex",flexDirection:"row"}}>
																
																	<div style={{width:"45%",display:"flex",flexDirection:"row",    justifyContent:"space-between",alignItems:"center"}}>
																	
																		<div style={{color:'#222',fontFamily:'Titillium Web',fontSize:'24px',fontStyle:'normal',fontWeight:600,lineHeight:'120%',letterSpacing:'2.4px'}}>
																		09:00
																		</div>

																		<div style={{color:'rgb(161, 169, 195)',fontSize:'14px',display:'flex',WebkitBoxAlign:'center',alignItems:'center',justifyContent:'space-evenly',textAlign:'center',width:'100%'}}>
																			<div style={{backgroundColor:'rgb(161, 169, 195)',WebkitBoxFlex:2,flexGrow:2,height:'1px',margin:'auto 4px',borderRadius:'1px',opacity:0.45,height:'2px',margin:'auto 10px',width:"20px"}}></div>
																			<div>3h40m</div>
																			<div style={{backgroundColor:'rgb(161, 169, 195)',WebkitBoxFlex:2,flexGrow:2,height:'1px',margin:'auto 4px',borderRadius:'1px',opacity:0.45,height:'2px',margin:'auto 10px',width:"20px"}}></div>
																		</div>
																		
																	</div>
																	
																	<div style={{width:"45%",color:'#222',fontFamily:'Titillium Web',fontSize:'24px',fontStyle:'normal',fontWeight:600,lineHeight:'120%',letterSpacing:'2.4px'}}>
																	12:44
																	</div>
																
																</div>
																
																<div style={{width:"100%",display:"flex",flexDirection:"row"}}>
																
																	<div style={{width:"45%",color:'rgba(34, 34, 34, 0.60)',fontFamily:'Titillium Web',fontSize:'14px',fontStyle:'normal',fontWeight:400,lineHeight:'120%',letterSpacing:'1.4px'}}>
																	Paris Gare du Nord
																	</div>

																	<div style={{width:"45%",color:'rgba(34, 34, 34, 0.60)',fontFamily:'Titillium Web',fontSize:'14px',fontStyle:'normal',fontWeight:400,lineHeight:'120%',letterSpacing:'1.4px'}}>
																	 Amesterdan Central
																	</div>
																   
																</div>															
															
															</div>
														</div>
														
														
														<div style={{flex:1,display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center"}}>
														
															<div styles={{flex:1}}><Button id="buttonBlock_1_1" data-token="acnb-44566-dddkd" data-tariff="standard" data-price="129,00 &euro;" data-class="2" data-transporttype="train" text label="129,00 &euro;" style={{flexDirection:"column",color:"#2E0249",fontFamily:"Titillium Web",fontSize:"24px",fontStyle:"bold",fontWeight:800,lineHeight:"120%",letterSpacing:"2.4px"}}><div style={{display:"block",color:'rgba(34, 34, 34, 0.60)',textAlign:'center',fontFamily:'Titillium Web',fontSize:'14px',fontStyle:'normal',fontWeight:400,lineHeight:'120%',letterSpacing:'1.4px'}}>2ª classe | <i className="pi pi-user" style={{ fontSize: '0.7rem' }}></i> <strong>{personAddLabelSimple}</strong></div></Button></div>
	
															
														</div>														
														
													</div>

													<div style={{textAlign:"left"}}>
														
														<Panel headerTemplate={(options) => {return (<Button iconPos="right" icon="pi pi-angle-down" size="small" rounded text aria-label="Menu" label="1 Conexão (abra para detalhes)" style={{fontFamily:'Titillium Web',fontStyle:'normal',fontWeight:400,fontSize:'14px',lineHeight:'120%',textAlign:'center',letterSpacing:'0.1em',color:'#814DE5',padding:0}}  onClick={options.onTogglerClick} />)}} toggleable collapsed={true} className="cardPanel">
															<p className="m-0">
															
															<Divider />
															
																<div style={{display:"flex"}}>
																
																	<div style={{display:"flex",flexDirection:"column"}}>
																	
																		<div style={{height:"96px",maxHeight:"96px"}}>
																		
																			<div style={{display:'flex',flex:'1 1 0%',flexDirection:'column',WebkitBoxPack:'justify',justifyContent:'space-between',fontSize:'14px',height:'100%'}}>
																			
																				<div>
																					<div style={{color:'#222',fontFamily:'Titillium Web',fontSize:'18px',fontStyle:'normal',fontWeight:600,lineHeight:'120%',letterSpacing:'2.4px'}}>09:00</div>
																					<div style={{color:'rgb(161, 169, 195)',fontSize:'14px',display:'flex',WebkitBoxAlign:'center',alignItems:'center',justifyContent:'flex-start',textAlign:'left',width:'100%'}}>1h30m</div>
																				</div>
																				
																				<div>
																					<div style={{color:'#222',fontFamily:'Titillium Web',fontSize:'18px',fontStyle:'normal',fontWeight:400,lineHeight:'120%',letterSpacing:'2.4px'}}>10:30</div>
																				</div>
																			
																			</div>						
																		
																		</div>

																		<div style={{height:"46px",margin:"5px 0px"}}>
																		</div>

																		<div style={{height:"96px",maxHeight:"96px"}}>
																		
																			<div style={{display:'flex',flex:'1 1 0%',flexDirection:'column',WebkitBoxPack:'justify',justifyContent:'space-between',fontSize:'14px',height:'100%'}}>
																			
																				<div>
																					<div style={{color:'#222',fontFamily:'Titillium Web',fontSize:'18px',fontStyle:'normal',fontWeight:400,lineHeight:'120%',letterSpacing:'2.4px'}}>10:45</div>
																					<div style={{color:'rgb(161, 169, 195)',fontSize:'14px',display:'flex',WebkitBoxAlign:'center',alignItems:'center',justifyContent:'flex-start',textAlign:'left',width:'100%'}}>1h59m</div>
																				</div>
																				
																				<div>
																					<div style={{color:'#222',fontFamily:'Titillium Web',fontSize:'18px',fontStyle:'normal',fontWeight:600,lineHeight:'120%',letterSpacing:'2.4px'}}>12:44</div>
																				</div>
																			
																			</div>																			
																		
																		</div>		
																	
																	</div>
																	
																	<div style={{display:"flex",flexDirection:"column"}}>
																	
																		<div style={{height:"96px",maxHeight:"96px"}}>
																			
																			<div style={{display:'flex',flex:'1 1 0%',flexDirection:'column',WebkitBoxPack:'justify',justifyContent:'space-between',fontSize:'14px',WebkitBoxAlign:'center',alignItems:'center',textAlign:'center',padding:'2px 10px 4px',height:'96px',maxHeight:'96px'}}>
																				
																				<div style={{color:"rgb(66, 84, 134)",width:"16px",height:"16px"}}><svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" color="#425486" size="16"><path d="M12.5 19.584c-4.661 0-8.5-2.87-8.5-7.106 0-2.05 1.371-6.285 1.371-6.285C6.331 3.05 9.21 1 12.5 1c3.29 0 6.032 2.186 7.129 5.193 0 0 1.371 4.236 1.371 6.285 0 4.236-3.839 7.106-8.5 7.106zM7.565 6.602v2.05c0 .82.548 1.367 1.37 1.367h7.13c.822 0 1.37-.547 1.37-1.367v-2.05c0-.82-.548-1.366-1.37-1.366h-7.13c-.822 0-1.37.547-1.37 1.366zm9.048 13.665c.411-.137.685-.273.822-.41L20.315 23h-2.742l-.275-.82H7.565L7.29 23H4.548l2.88-3.143c.274.137.548.273.96.41l-.275.82h8.774l-.274-.82zm0-4.236c.681 0 1.234-.55 1.234-1.23 0-.679-.553-1.23-1.234-1.23-.682 0-1.234.551-1.234 1.23 0 .68.552 1.23 1.234 1.23zm-8.226 0c.682 0 1.234-.55 1.234-1.23 0-.679-.552-1.23-1.234-1.23-.681 0-1.234.551-1.234 1.23 0 .68.553 1.23 1.234 1.23z" fill="currentColor" fill-rule="evenodd"></path></svg></div>
																				
																				<div style={{flex:'1 1 0%',margin:'6px 0px',backgroundColor:'rgb(161, 169, 195)',width:'2px'}}></div>
																				
																				<div style={{width:'12px',height:'12px',border:'2px solid rgb(66, 84, 134)',borderRadius:'6px'}}></div>					
																			</div>	
																			
																		</div>

																		<div style={{display:'flex',flexDirection:'column',WebkitBoxAlign:'center',alignItems:'center',WebkitBoxPack:'center',justifyContent:'center',height:'49px',overflow:'hidden',margin:'4px auto'}}>
																		
																			<div style={{backgroundColor:'rgb(161, 169, 195)',opacity:0.5,width:'2px',height:'3px',marginBottom:'2px'}}></div>
																			<div style={{backgroundColor:'rgb(161, 169, 195)',opacity:0.5,width:'2px',height:'3px',marginBottom:'2px'}}></div>
																			<div style={{backgroundColor:'rgb(161, 169, 195)',opacity:0.5,width:'2px',height:'3px',marginBottom:'2px'}}></div>
																			<div style={{backgroundColor:'rgb(161, 169, 195)',opacity:0.5,width:'2px',height:'3px',marginBottom:'2px'}}></div>
																			<div style={{backgroundColor:'rgb(161, 169, 195)',opacity:0.5,width:'2px',height:'3px',marginBottom:'2px'}}></div>
																			<div style={{backgroundColor:'rgb(161, 169, 195)',opacity:0.5,width:'2px',height:'3px',marginBottom:'2px'}}></div>
																			<div style={{backgroundColor:'rgb(161, 169, 195)',opacity:0.5,width:'2px',height:'3px',marginBottom:'2px'}}></div>
																			<div style={{backgroundColor:'rgb(161, 169, 195)',opacity:0.5,width:'2px',height:'3px',marginBottom:'2px'}}></div>
																			<div style={{backgroundColor:'rgb(161, 169, 195)',opacity:0.5,width:'2px',height:'3px',marginBottom:'2px'}}></div>
																		
																		</div>

																		<div style={{height:"99px",maxHeight:"99px"}}>
																		
																			<div style={{display:'flex',flex:'1 1 0%',flexDirection:'column',WebkitBoxPack:'justify',justifyContent:'space-between',fontSize:'14px',WebkitBoxAlign:'center',alignItems:'center',textAlign:'center',padding:'2px 10px 4px',height:'99px',maxHeight:'99px'}}>
																				
																				<div style={{color:"rgb(66, 84, 134)",width:"16px",height:"16px"}}><svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" color="#425486" size="16"><path d="M12.5 19.584c-4.661 0-8.5-2.87-8.5-7.106 0-2.05 1.371-6.285 1.371-6.285C6.331 3.05 9.21 1 12.5 1c3.29 0 6.032 2.186 7.129 5.193 0 0 1.371 4.236 1.371 6.285 0 4.236-3.839 7.106-8.5 7.106zM7.565 6.602v2.05c0 .82.548 1.367 1.37 1.367h7.13c.822 0 1.37-.547 1.37-1.367v-2.05c0-.82-.548-1.366-1.37-1.366h-7.13c-.822 0-1.37.547-1.37 1.366zm9.048 13.665c.411-.137.685-.273.822-.41L20.315 23h-2.742l-.275-.82H7.565L7.29 23H4.548l2.88-3.143c.274.137.548.273.96.41l-.275.82h8.774l-.274-.82zm0-4.236c.681 0 1.234-.55 1.234-1.23 0-.679-.553-1.23-1.234-1.23-.682 0-1.234.551-1.234 1.23 0 .68.552 1.23 1.234 1.23zm-8.226 0c.682 0 1.234-.55 1.234-1.23 0-.679-.552-1.23-1.234-1.23-.681 0-1.234.551-1.234 1.23 0 .68.553 1.23 1.234 1.23z" fill="currentColor" fill-rule="evenodd"></path></svg></div>
																				
																				<div style={{flex:'1 1 0%',margin:'6px 0px',backgroundColor:'rgb(161, 169, 195)',width:'2px'}}></div>
																				
																				<div style={{width:"20px",height:"20px",color:"rgb(250, 107, 107)"}}>
																				
																					<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" size="20" color="#FA6B6B"><defs><path d="M12 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-8-3a8 8 0 1 1 16 0c0 1.842-1.176 4.053-3.53 6.635L12 22l-4.47-5.365C5.175 14.053 4 11.842 4 10z" id="Pin__a"></path></defs><use fill="currentColor" xlinkHref="#Pin__a" fillRule="evenodd"></use></svg>									
																				
																				</div>					
																			</div>					
																		
																		</div>		
																	
																	</div>

																	<div style={{display:"flex",flexDirection:"column"}}>
																	
																		<div style={{height:"96px",maxHeight:"96px"}}>
																			
																			<div style={{display:'flex',flex:'1 1 0%',flexDirection:'column',WebkitBoxPack:'justify',justifyContent:'space-between',fontSize:'14px',height:'100%'}}>
																			
																				<div>
																					<div style={{color:'#222',fontFamily:'Titillium Web',fontSize:'16px',fontStyle:'normal',fontWeight:600,lineHeight:'120%',letterSpacing:'2.4px'}}>Belgium, Vienna</div>
																					<div style={{color:'rgb(161, 169, 195)',fontSize:'14px',display:'flex',WebkitBoxAlign:'center',alignItems:'center',justifyContent:'flex-start',textAlign:'left',width:'100%'}}>Paris Gare du Nord | ICE 74</div>
																				</div>
																				
																				<div>
																					<div style={{color:'#222',fontFamily:'Titillium Web',fontSize:'16px',fontStyle:'normal',fontWeight:400,lineHeight:'120%',letterSpacing:'2.4px'}}>Génova Piazza Principe</div>
																				</div>
																			
																			</div>																				
																			
																		</div>

																		<div style={{display:'flex',WebkitBoxAlign:'center',alignItems:'center',margin:'5px 0px',height:'47px'}}>
																			
																			<div style={{color:'rgb(161, 169, 195)',width:'20px',height:'20px',marginRight:'8px'}}>
																				<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" size="20" color="#A1A9C3"><path d="M9.692 8.818l.688.636-1.376 1.273-.688-.636A3.04 3.04 0 0 0 6.253 9.3h-2.28C3.435 9.3 3 8.897 3 8.4c0-.498.435-.9.973-.9h2.28c1.29 0 2.527.473 3.44 1.318zm0 6.364c-.913.844-2.15 1.317-3.44 1.317H3.974c-.538 0-.973-.402-.973-.9 0-.496.435-.9.973-.9h2.28a3.04 3.04 0 0 0 2.063-.79l5.503-5.091c.913-.844 2.15-1.319 3.44-1.319h.822V6l1.46 1.2L21 8.4l-1.46 1.2-1.459 1.2V9.3h-.821c-.774 0-1.516.284-2.064.79l-5.504 5.092zm8.389-.482v-1.5L21 15.6 18.08 18v-1.5h-.82c-1.298 0-2.52-.468-3.44-1.318l-.688-.636 1.376-1.274.688.637c.552.51 1.284.79 2.065.79h.82z" fill="currentColor" fill-rule="evenodd"></path></svg>
																			</div>
																			<div style={{height:"20px",color:"rgb(161, 169, 195)",fontSize:"14px"}}>Trocar, 0h15m</div>																			
																		</div>

																		<div style={{height:"120px",maxHeight:"120px"}}>
																		
																			<div style={{display:'flex',flex:'1 1 0%',flexDirection:'column',WebkitBoxPack:'justify',justifyContent:'space-between',height:'115px',maxHeight:'115px'}}>
																			
																				<div>
																					<div style={{color:'#222',fontFamily:'Titillium Web',fontSize:'16px',fontStyle:'normal',fontWeight:400,lineHeight:'120%',letterSpacing:'2.4px'}}>Génova Piazza Principe</div>
																					<div style={{color:'rgb(161, 169, 195)',fontSize:'14px',display:'flex',WebkitBoxAlign:'center',alignItems:'center',justifyContent:'flex-start',textAlign:'left',width:'100%'}}>Train Station  | ICE 706</div>
																				</div>
																				
																				<div>
																					<div style={{color:'#222',fontFamily:'Titillium Web',fontSize:'16px',fontStyle:'normal',fontWeight:600,lineHeight:'120%',letterSpacing:'2.4px'}}>Czech Republic, Salzburg</div>
																					<div style={{color:'rgb(161, 169, 195)',fontSize:'14px',display:'flex',WebkitBoxAlign:'center',alignItems:'center',justifyContent:'flex-start',textAlign:'left',width:'100%'}}>O seu destino</div>
																				</div>
																			
																			</div>		
																		
																		</div>	
																	
																	</div>
																
																
																</div>
																
																<Divider />

																<div style={{marginTop:"20px",marginBottom:"20px",color:"#2E0249",fontFamily:"Titillium Web",fontSize:"16px",fontStyle:"bold",fontWeight:800,lineHeight:"120%",letterSpacing:"2.4px"}}>Preço total da viagem para  <strong>{personAddLabel}</strong> <i className="pi pi-user" style={{ fontSize: '1rem' }}></i></div>
																
																<div style={{marginTop:"10px",marginBottom:"20px",color:"#2E0249",fontFamily:"Titillium Web",fontSize:"16px",fontStyle:"normal",fontWeight:400,lineHeight:"120%",letterSpacing:"2.4px"}}>

																	{personAddAdult > 0 &&
																	<div style={{display:"block",marginBottom:"5px"}}>
																		{personAddAdult} x Adulto
																	</div>
																	}
																	
																	{personAddYoung > 0 &&
																	<div style={{display:"block",marginBottom:"5px"}}>
																		{personAddYoung} x Jovem <small>({selectedYoungAgeLabel})</small>
																	</div>
																	}

																	{personAddElder > 0 &&
																	<div style={{display:"block",marginBottom:"5px"}}>
																		{personAddElder} x Sênior <small>({selectedElderAgeLabel})</small>
																	</div>
																	}																
																																
																</div>																
															
															</p>
														</Panel>														
														
													</div>												
												
												</div>
																								
												{/* One way Flexibility */}
												<div style={{padding:"20px",paddingLeft:"0px",paddingBottom:"0",color:'#222',fontFamily:'Titillium Web',fontSize:'20px',fontStyle:'normal',fontWeight:600,lineHeight:'150%',letterSpacing:'2px',width:"100%",textAlign:"left",boxShadow:"unset"}}>Flexibilidade e condições do Bilhete <small style={{marginLeft:"5px"}}>(Volta)</small> </div>
											
												<div id="resultBlock_1" style={{borderRadius:"10px",width:"100%",margin:"10px",backgroundColor:"#fff",minHeight:"125px",marginBottom:"10px",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",padding:"15px",cursor:"initial"}}>
													
													<div style={{flex:1,display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"flex-start"}}>

														<div className="flex align-items-center" style={{ cursor: 'pointer', padding:"20px",paddingBottom:"0px",textAlign:"left"}}>
															<div style={{color:'#222',fontFamily:'Titillium Web',fontSize:'16px',fontStyle:'normal',fontWeight:600,lineHeight:'150%',letterSpacing:'2px',marginBottom:"3px"}}>Semiflexível</div>
															<div style={{color:'rgba(34, 34, 34, 0.60)',textAlign:'left',fontFamily:'Titillium Web',fontSize:'16px',fontStyle:'normal',fontWeight:400,lineHeight:'120%',letterSpacing:'1.8px'}}>129,00 &euro;</div>
														</div>
																			
														<div style={{display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column",padding:0}}>
															
															<div style={{width:"100%",padding:"0px"}}>
																																																	<div style={{color:"#2E0249",fontFamily:"Titillium Web",fontSize:"16px",fontStyle:"normal",fontWeight:400,lineHeight:"120%",letterSpacing:"2.4px",textAlign:"left",marginLeft:"0px",padding:"20px"}}>

																	<div style={{display:"block",marginBottom:"10px",fontWeight:"bold"}}>Detalhamento dos viajantes</div>

																	{personAddAdult > 0 &&
																	<div style={{display:"block",marginBottom:"5px"}}>{personAddAdult} x Adulto</div>
																	}
																	
																	{personAddYoung > 0 &&
																	<div style={{display:"block",marginBottom:"5px"}}>{personAddYoung} x Jovem <small>({selectedYoungAgeLabel})</small></div>
																	}

																	{personAddElder > 0 &&
																	<div style={{display:"block",marginBottom:"5px"}}>{personAddElder} x Sênior <small>({selectedElderAgeLabel})</small></div>
																	}															
																</div>										
																																																	<div style={{marginBottom:"20px",color:"#2E0249",fontFamily:"Titillium Web",fontSize:"16px",fontStyle:"normal",fontWeight:400,lineHeight:"120%",letterSpacing:"2.4px",textAlign:"left",marginLeft:"20px",padding:"0px"}}>

																	<div style={{display:"block",marginBottom:"10px",fontWeight:"bold"}}>Resumo da Tarifa</div>

																	<div style={{marginTop:"15px",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"stretch",gap:"10px"}}>
																		<div><i className="pi pi-book" style={{ fontSize: '1rem' }}></i></div>
																		<div style={{flex:1}}> Semi Reembolsável </div>
																	</div>
																	
																	<div style={{marginTop:"10px",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"stretch",gap:"10px"}}>
																		<div><i className="pi pi-times" style={{ fontSize: '1rem' }}></i></div>
																		<div style={{flex:1}}> Sem possibilidade de troca </div>
																	</div>																						
																</div>											
																																						
															</div>
															<div style={{width:"100%",marginTop:"10px"}}>
																
																<Panel headerTemplate={(options) => {return (<Button iconPos="right" icon="pi pi-angle-down" outlined aria-label="Menu" label="Ver condições de troca e reembolso" style={{fontFamily:'Titillium Web',fontStyle:'normal',fontWeight:400,fontSize:'14px',lineHeight:'120%',textAlign:'center',letterSpacing:'0.1em',color:'#814DE5',padding:0,height:"46px",width:"50%",alignSelf:"flex-start"}}  onClick={options.onTogglerClick} />)}} toggleable collapsed={true} className="cardPanel" style={{width:"100%",textAlign:"left"}}>
									
																	<p className="m-0" style={{padding:"20px",paddingTop:"5px"}}>
																	
																		<div style={{textAlign:"left",fontWeight:"bold",fontSize:"16px"}}>Termos da tarifa</div>
																																																			<div style={{textAlign:"left"}}>
																		<br />
																		Este bilhete é válido apenas para o comboio escolhido. <br /><br />

																		Este bilhete pode ser trocado por um voucher, mediante o pagamento de uma taxa de 10€ até ao dia antes da partida. Este bilhete não pode ser reembolsado, nem trocado na data de partida. Viagem gratuita em serviços de transporte público (S-Bahn, U-Bahn, autocarro ou elétrico) incluída no bilhete City-Ticket. Apenas válido se "+City" estiver impresso no bilhete atrás do nome da estação.</div>				
																				
																		<div style={{marginTop:"30px",marginBottom:"20px",fontStyle:"normal",fontWeight:400,textAlign:"left"}}>

																			<div style={{display:"block",marginBottom:"20px",fontWeight:"bold"}}>Taxas de Cancelamento</div>
																																																					<div style={{textAlign:"left"}}>
																				Cancellation fee train flex price: EUR 0.00 cancellation fees from 19.07.2023 EUR 1020.44 cancellation fees from 30.07.2023</div>			

																				<div style={{width:"90%",marginTop:"10px",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center",gap:"10px"}}>
																				
																					<div style={{width:"100%",display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"stretch",gap:"20px"}}>
																						<div style={{flex:1}}>Até 19/07/2023</div>
																						<div style={{flex:1,textAlign:"right"}}> 0,00 &euro;</div>
																					</div>
																					
																					<div style={{width:"100%",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"stretch"}}>
																						<div style={{flex:1}}>Até 30/07/2023</div>
																						<div style={{flex:1,textAlign:"right"}}> 259,00 &euro;</div>
																					</div>
																					
																				</div>
																				
																		</div>
																			
																	</p>
																
																</Panel>									
																
															</div>
															
														</div>								
														
													</div>
											
												
												</div>	

												{/* Seat Reservation One way */}
												<div style={{padding:"20px",paddingLeft:"0px",paddingBottom:"0",color:'#222',fontFamily:'Titillium Web',fontSize:'20px',fontStyle:'normal',fontWeight:600,lineHeight:'150%',letterSpacing:'2px',width:"100%",textAlign:"left",boxShadow:"unset"}}>Reserva de Lugar <small style={{marginLeft:"5px"}}>(Volta)</small> </div>
											
												<div style={{borderRadius:"10px",width:"100%",margin:"10px",backgroundColor:"#fff",minHeight:"125px",marginBottom:"10px",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",padding:"15px",cursor:"initial"}}>
													
													<div style={{flex:1,display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"flex-start"}}>

														<div style={{padding:"0px",paddingBottom:"0",color:'#222',fontFamily:'Titillium Web',fontSize:'16px',fontStyle:'normal',fontWeight:600,lineHeight:'150%',letterSpacing:'2px',display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center",gap:"20px"}}>
															<div>Taxa da reserva de lugar</div>
															<div style={{display:"flex",flexDirection:"row",justifyContent:"flex-end",alignItems:"center"}}><div style={{fontFamily:'Titillium Web',fontStyle:'bold',fontWeight:400,fontSize:'14px',lineHeight:'120%',textAlign:'right',letterSpacing:'0.1em',color:'#814DE5',padding:0,height:"46px",display:"flex",justifyContent:"center",alignItems:"center",marginRight:"10px"}}>+19,90 &euro;</div></div>
														</div>
														
														<div style={{padding:"10px",textAlign:"left"}}>

															<Message severity="success" style={{textAlign:"left",marginBottom:"20px",fontSize:"16px"}} text={"Reservas adicionadas para "+personAddLabel} />
															
															<div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"flex-start",gap:"10px",marginBottom:"10px",fontSize:"14px",textAlign:"left"}}>

																<div style={{color:"#2E0249",fontFamily:"Titillium Web",fontSize:"16px",fontStyle:"normal",fontWeight:400,lineHeight:"120%",letterSpacing:"2.4px",marginBottom:"10px",fontWeight:"bold"}}>Assento selecionado</div>
																	
																<div style={{alignItems:'stretch',display:'flex',gap:'10px',width:'100%'}}>
																	<label htmlFor="seat1" className="ml-2">Open-plan coach 2nd class</label>
																</div>
																
															</div>	
															
															<div style={{marginTop:"30px",fontStyle:"italic",marginLeft:"0px",textAlign:"left"}}>Esta taxa não é reembolsável</div>
															
														</div>
							
														
													</div>
											
												
												</div>
												</>
												}		
																								
											 </Grid>
											 
											 
											 <Grid item xs={12} sm={4} className="rightPanel animate">
											 
												 <div style={{position:"sticky",zIndex:"9999",top:"120px"}}>
												 
												{loadingRightPanel &&
												<>
													<div style={{backgroundColor:"transparent", width:"100%",maxWidth:"1170px", marginTop:"30px",marginBottom:"50px",display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"row"}}>
													
															<ProgressSpinner style={{width: '40px', height: '40px',marginRight: "10px",marginLeft: "10px",marginTop:"10px"}} strokeWidth="4" />
															<div style={{fontSize:"16px",marginTop:"10px"}}> Carregando... </div>
																											
													</div>
													
													<Skeleton width="100%" height="8rem" ></Skeleton>
													<br />
													<Skeleton width="100%" height="8rem" ></Skeleton>
													<br />
													<Skeleton width="100%" height="8rem" ></Skeleton>
													
												</>
												}												 
												 
												 
												{!loadingRightPanel &&
												<>		
												
													{/* Reservation Responsible Block */}
													<div style={{marginTop:"0px",display:"flex",flexDirection:"column",alignItems:"flex-start",justifyContent:"center",backgroundColor:"#FFF"}}>
														
														<div style={{padding:"20px",paddingBottom:"0",color:'#222',fontFamily:'Titillium Web',fontSize:'20px',fontStyle:'normal',fontWeight:600,lineHeight:'150%',letterSpacing:'2px'}}>Responsável pela reserva</div>
																									
														
														<div style={{padding:"00px",paddingTop:"0",marginTop:"10px"}}>
														
														
															<div style={{padding:"20px",paddingBottom:"0",color:'#222',fontFamily:'Titillium Web',fontSize:'16px',fontStyle:'normal',fontWeight:400,lineHeight:'150%',letterSpacing:'2px',display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center",gap:"20px"}}>
																<div>É estrangeiro?</div>
																<div style={{display:"flex",flexDirection:"row",justifyContent:"flex-end",alignItems:"center"}}><div><InputSwitch checked={checked} onChange={(e) => {setChecked(e.value)}} style={{display:"flex",justifyContent:"center",alignItems:"center"}} /></div></div>
															</div>
															
															<div style={{padding:"20px",paddingTop:"0px"}}>
															
															{!checked &&
															<>
											
																<Grid container spacing={4} style={{marginTop:"0px"}}>
															
																<Grid item xs={12} sm={12} style={{display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center"}}>
																	<div className="p-float-label w100">
																		<InputText id="personDocument" autoComplete="new-password" disabled={saving ? true : false}  value={personDocument} onBlur={(e) => handlePersonDocumentChange(e)} onChange={(e) => handlePersonDocumentChange(e)} className="w100" tooltip="Digite apenas números" tooltipOptions={{ position: 'bottom',event: 'focus'}}  style={{borderTopRightRadius:0,borderBottomRightRadius:0}} />
																		<label htmlFor="personDocument">CPF <span className="requiredField">*</span></label>
																	</div>
																	<Button label="Buscar" disabled={!personDocumentReady} style={{borderTopLeftRadius:0,borderBottomLeftRadius:0,height:"47px"}} size="small" loading={loadingPersonData} onClick={() => retrievePersonDocumentInfo()} />
																  </Grid>
																
																{/*<Grid item xs={12} sm={12}>
																	<span className="p-float-label" >
																		<InputText id="personName" autoComplete="new-password" disabled={saving || autoFields ? true : false} value={personName} onChange={(e) => setPersonName(e.target.value)} style={{width:"100%"}} />
																		<label htmlFor="peronName">Nome completo <span className="requiredField">*</span></label>
																	</span>		
																</Grid>*/}
																  
																  <Grid item xs={12} sm={12}>
																	<span className="p-float-label" >
																		<InputText id="personName" autoComplete="new-password" disabled={saving || autoFields ? true : false} value={personName} onChange={(e) => setPersonName(e.target.value)} style={{width:"100%"}} />
																		<label htmlFor="personName">Primeiro nome e último sobrenome<span className="requiredField">*</span></label>
																	</span>		
																  </Grid>				
		
																  
																  <Grid item xs={12} sm={12}>
																	<span className="p-float-label" >
																		<InputText id="personBirthday" autoComplete="new-password" disabled={saving || autoFields ? true : false} value={birthday} onChange={(e) => setBirthday(e.target.value)} style={{width:"100%"}} />
																		<label htmlFor="peronName">Data de Nascimento <span className="requiredField">*</span></label>
																	</span>		
																  </Grid>
													  

																  <Grid item xs={12} sm={12}>
																	<span className="p-float-label" >
																		<InputText id="personPhone" autoComplete="new-password" disabled={saving ? true : false} value={personPhone} onChange={(e) => setPersonPhone(maskPhone(e.target.value))} style={{width:"100%"}} tooltip="Digite apenas números" tooltipOptions={{ position: 'bottom',event: 'focus' }} />
																		<label htmlFor="personPhone">Email <span className="requiredField">*</span></label>								
																	</span>	
																  </Grid>
																  
																  <Grid item xs={12} sm={12}>
																	<span className="p-float-label" >
																		<InputText id="personPhone" autoComplete="new-password" value={personPhone} onChange={(e) => setPersonPhone(maskPhone(e.target.value))} style={{width:"100%"}} tooltip="Digite apenas números" tooltipOptions={{ position: 'bottom',event: 'focus' }} />
																		<label htmlFor="personPhone">Telefone Celular <span className="requiredField">*</span></label>								
																	</span>	
																  </Grid>
																  
																</Grid>
																
																<div style={{marginTop:"20px",marginLeft:"10px",textAlign:"left",fontStyle:"italic"}}><i className="pi pi-exclamation-triangle" style={{ color: "red",fontSize:"20px" }}></i> O responsável pela reserva deve ser um dos passageiros abaixo e deve ser o responsável pela forma de pagamento.</div>
															</>
															}
															
															{checked &&
															<>
															<Grid container spacing={4} style={{marginTop:"0px"}}>
															
																<Grid item xs={12} sm={12} style={{display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center"}}>
																	<div className="p-float-label w100">
																		<InputText id="personDocumentForeingner" autoComplete="new-password" disabled={saving ? true : false}  value={personDocument} onBlur={(e) => handlePersonDocumentForeignerChange(e)} onChange={(e) => handlePersonDocumentForeignerChange(e)} className="w100" tooltip="Digite como consta no passaporte" tooltipOptions={{ position: 'bottom',event: 'focus'}}  style={{borderTopRightRadius:0,borderBottomRightRadius:0}} />
																		<label htmlFor="personDocument">Número do Passaporte <span className="requiredField">*</span></label>
																	</div>
																	
																  </Grid>
																
																  <Grid item xs={12} sm={12}>
																	<span className="p-float-label" >
																		<InputText id="personName" autoComplete="new-password" disabled={saving ? true : false} value={personName} onChange={(e) => setPersonName(e.target.value)} style={{width:"100%"}} />
																		<label htmlFor="peronName">Nome completo <span className="requiredField">*</span></label>
																	</span>		
																  </Grid>
																  
																  <Grid item xs={12} sm={12}>
																	<span className="p-float-label" >
																		<InputText id="personBirthday" autoComplete="new-password" disabled={saving ? true : false} value={birthday} onChange={(e) => setBirthday(e.target.value)} style={{width:"100%"}} />
																		<label htmlFor="peronName">Data de Nascimento <span className="requiredField">*</span></label>
																	</span>		
																  </Grid>
													  

																  <Grid item xs={12} sm={12}>
																	<span className="p-float-label" >
																		<InputText id="personPhone" autoComplete="new-password" disabled={saving ? true : false} value={personPhone} onChange={(e) => setPersonPhone(maskPhone(e.target.value))} style={{width:"100%"}} tooltip="Digite apenas números" tooltipOptions={{ position: 'bottom',event: 'focus' }} />
																		<label htmlFor="personPhone">Email <span className="requiredField">*</span></label>								
																	</span>	
																  </Grid>
																  
																  <Grid item xs={12} sm={12}>
																	<span className="p-float-label" >
																		<InputText id="personPhone" autoComplete="new-password" value={personPhone} onChange={(e) => setPersonPhone(maskPhone(e.target.value))} style={{width:"100%"}} tooltip="Digite apenas números" tooltipOptions={{ position: 'bottom',event: 'focus' }} />
																		<label htmlFor="personPhone">Telefone Celular <span className="requiredField">*</span></label>								
																	</span>	
																  </Grid>
																  
																</Grid>
																
																<div style={{marginTop:"20px",marginLeft:"10px",textAlign:"left",fontStyle:"italic"}}>O responsável pela reserva deve ser a mesma pessoa do pagamento.</div>																
															</>
															}														
																
															</div>														
																											
														
														</div>
														
														
													</div>	
													
													
													{/* Reservation Responsible Block */}
													<div style={{marginTop:"15px",display:"flex",flexDirection:"column",alignItems:"flex-start",justifyContent:"center",backgroundColor:"#FFF"}}>
														
														<div style={{padding:"20px",paddingBottom:"0",color:'#222',fontFamily:'Titillium Web',fontSize:'20px',fontStyle:'normal',fontWeight:600,lineHeight:'150%',letterSpacing:'2px'}}>Dados dos passageiros</div>
																					
														<div style={{padding:"10px",paddingTop:"0",marginTop:"10px",width:"100%"}}>
														
															<Accordion multiple activeIndex={[0]}>
																<AccordionTab  header={<div className="flex align-items-center"><span className="vertical-align-middle">Passageiro 1: <small>Adulto</small></span></div>}>
																
																	<div style={{padding:"10px",paddingBottom:"0",color:'#222',fontFamily:'Titillium Web',fontSize:'16px',fontStyle:'normal',fontWeight:400,lineHeight:'150%',letterSpacing:'2px',display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center",gap:"20px"}}>
																		<div>É estrangeiro?</div>
																		<div style={{display:"flex",flexDirection:"row",justifyContent:"flex-end",alignItems:"center"}}><div><InputSwitch checked={checked} onChange={(e) => {setChecked(e.value)}} style={{display:"flex",justifyContent:"center",alignItems:"center"}} /></div></div>
																	</div>	
																
																	<Grid container spacing={4} style={{marginTop:"10px",marginBottom:"10px"}}>
																		<Grid item xs={12} sm={12} style={{display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center"}}>
																			<div className="p-float-label w100">
																				<InputText id="personDocument" autoComplete="new-password" disabled={saving ? true : false}  value={personDocument} onBlur={(e) => handlePersonDocumentChange(e)} onChange={(e) => handlePersonDocumentChange(e)} className="w100" tooltip="Digite apenas números" tooltipOptions={{ position: 'bottom',event: 'focus'}}  style={{borderTopRightRadius:0,borderBottomRightRadius:0}} />
																				<label htmlFor="personDocument">CPF <span className="requiredField">*</span></label>
																			</div>
																			<Button label="Buscar" disabled={!personDocumentReady} style={{borderTopLeftRadius:0,borderBottomLeftRadius:0,height:"47px"}} size="small" loading={loadingPersonData} onClick={() => retrievePersonDocumentInfo()} />
																		  </Grid>
																		
																		  <Grid item xs={12} sm={12}>
																			<span className="p-float-label" >
																				<InputText id="personName" autoComplete="new-password" disabled={saving || autoFields ? true : false} value={personName} onChange={(e) => setPersonName(e.target.value)} style={{width:"100%"}} />
																				<label htmlFor="peronName">Primeiro nome e último sobrenome<span className="requiredField">*</span></label>
																			</span>		
																		  </Grid>
																		  
																		  <Grid item xs={12} sm={12}>
																			<span className="p-float-label" >
																				<InputText id="personBirthday" autoComplete="new-password" disabled={saving || autoFields ? true : false} value={birthday} onChange={(e) => setBirthday(e.target.value)} style={{width:"100%"}} />
																				<label htmlFor="peronName">Data de Nascimento <span className="requiredField">*</span></label>
																			</span>		
																		  </Grid> 
																	</Grid>
																</AccordionTab>
																<AccordionTab header={<div className="flex align-items-center"><span className="vertical-align-middle">Passageiro 2: <small>Jovem (11 anos)</small></span></div>}>
																
																	<div style={{padding:"10px",paddingBottom:"0",color:'#222',fontFamily:'Titillium Web',fontSize:'16px',fontStyle:'normal',fontWeight:400,lineHeight:'150%',letterSpacing:'2px',display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center",gap:"20px"}}>
																		<div style={{minWidth:"130px",textAlign:"left"}}>É estrangeiro?</div>
																		<div style={{display:"flex",flexDirection:"row",justifyContent:"flex-end",alignItems:"center"}}><div><InputSwitch checked={checked} onChange={(e) => {setChecked(e.value)}} style={{display:"flex",justifyContent:"center",alignItems:"center"}} /></div></div>
																	</div>	
																	
																	<div style={{padding:"10px",paddingBottom:"0",color:'#222',fontFamily:'Titillium Web',fontSize:'16px',fontStyle:'normal',fontWeight:400,lineHeight:'150%',letterSpacing:'2px',display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center",gap:"20px"}}>
																		<div style={{minWidth:"130px",textAlign:"left"}}>Usar CPF</div>
																		<div style={{display:"flex",flexDirection:"row",justifyContent:"flex-end",alignItems:"center"}}><div><InputSwitch checked={checkedCPF} onChange={(e) => {setCheckedCPF(e.value)}} style={{display:"flex",justifyContent:"center",alignItems:"center"}} /></div></div>
																	</div>	
																
																	<Grid container spacing={4} style={{marginTop:"10px",marginBottom:"10px"}}>
																		<Grid item xs={12} sm={12} style={{display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center"}}>
																			<div className="p-float-label w100">
																				<InputText id="personDocument" autoComplete="new-password" disabled={saving ? true : false}  value={personDocument} onBlur={(e) => handlePersonDocumentChange(e)} onChange={(e) => handlePersonDocumentChange(e)} className="w100" tooltip="Digite apenas números" tooltipOptions={{ position: 'bottom',event: 'focus'}}  style={{borderTopRightRadius:0,borderBottomRightRadius:0}} />
																				<label htmlFor="personDocument">CPF <span className="requiredField">*</span></label>
																			</div>
																			<Button label="Buscar" disabled={!personDocumentReady} style={{borderTopLeftRadius:0,borderBottomLeftRadius:0,height:"47px"}} size="small" loading={loadingPersonData} onClick={() => retrievePersonDocumentInfo()} />
																		  </Grid>
																		
																		  <Grid item xs={12} sm={12}>
																			<span className="p-float-label" >
																				<InputText id="personName" autoComplete="new-password" disabled={saving || autoFields ? true : false} value={personName} onChange={(e) => setPersonName(e.target.value)} style={{width:"100%"}} />
																				<label htmlFor="peronName">Primeiro nome e último sobrenome<span className="requiredField">*</span></label>
																			</span>		
																		  </Grid>
																		  
																		  <Grid item xs={12} sm={12}>
																			<span className="p-float-label" >
																				<InputText id="personBirthday" autoComplete="new-password" disabled={saving || autoFields ? true : false} value={birthday} onChange={(e) => setBirthday(e.target.value)} style={{width:"100%"}} />
																				<label htmlFor="peronName">Data de Nascimento <span className="requiredField">*</span></label>
																			</span>		
																		  </Grid> 
																	</Grid>
																</AccordionTab>
																<AccordionTab header={<div className="flex align-items-center"><span className="vertical-align-middle">Passageiro 3: <small>Sênior (77 anos)</small></span></div>}>
																	  
																	<div style={{padding:"10px",paddingBottom:"0",color:'#222',fontFamily:'Titillium Web',fontSize:'16px',fontStyle:'normal',fontWeight:400,lineHeight:'150%',letterSpacing:'2px',display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center",gap:"20px"}}>
																		<div>É estrangeiro?</div>
																		<div style={{display:"flex",flexDirection:"row",justifyContent:"flex-end",alignItems:"center"}}><div><InputSwitch checked={checked} onChange={(e) => {setChecked(e.value)}} style={{display:"flex",justifyContent:"center",alignItems:"center"}} /></div></div>
																	</div>	
															
																	<Grid container spacing={4} style={{marginTop:"10px",marginBottom:"10px"}}>
																		  
																		<Grid item xs={12} sm={12} style={{display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center"}}>
																			<div className="p-float-label w100">
																				<InputText id="personDocument" autoComplete="new-password" disabled={saving ? true : false}  value={personDocument} onBlur={(e) => handlePersonDocumentChange(e)} onChange={(e) => handlePersonDocumentChange(e)} className="w100" tooltip="Digite apenas números" tooltipOptions={{ position: 'bottom',event: 'focus'}}  style={{borderTopRightRadius:0,borderBottomRightRadius:0}} />
																				<label htmlFor="personDocument">CPF <span className="requiredField">*</span></label>
																			</div>
																			<Button label="Buscar" disabled={!personDocumentReady} style={{borderTopLeftRadius:0,borderBottomLeftRadius:0,height:"47px"}} size="small" loading={loadingPersonData} onClick={() => retrievePersonDocumentInfo()} />
																		  </Grid>
			
																		  <Grid item xs={12} sm={12}>
																			<span className="p-float-label" >
																				<InputText id="personName" autoComplete="new-password" disabled={saving || autoFields ? true : false} value={personName} onChange={(e) => setPersonName(e.target.value)} style={{width:"100%"}} />
																				<label htmlFor="peronName">Primeiro nome e último sobrenome<span className="requiredField">*</span></label>
																			</span>		
																		  </Grid>
																		  
																		  <Grid item xs={12} sm={12}>
																			<span className="p-float-label" >
																				<InputText id="personBirthday" autoComplete="new-password" disabled={saving || autoFields ? true : false} value={birthday} onChange={(e) => setBirthday(e.target.value)} style={{width:"100%"}} />
																				<label htmlFor="peronName">Data de Nascimento <span className="requiredField">*</span></label>
																			</span>		
																		  </Grid> 
																	</Grid>
																</AccordionTab>
															</Accordion>														
																											
														
														</div>
														
														
													</div>		

													{/* Date warning block Block */}
													{currentAgency.agencyCode &&
													<>
													<div style={{marginTop:"10px",borderLeft:"1px dashed #2E0249",borderRight:"1px dashed #2E0249",borderBottom:"1px dashed #2E0249",borderTop:"4px solid #2E0249",minHeight:"92px",display:"flex",justifyContent:"center",alignItems:"stretch"}}>
													
														<div style={{margin:"20px",flex:1,display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
														
															<div><img src={iconAlert} style={{width:"24px",height:"auto"}} className="noSelect" /></div>
															<div style={{marginLeft:"6px",flex:1,color:'#222',fontFamily:'Titillium Web',fontSize:'16px',fontStyle:'normal',fontWeight:600,lineHeight:'150%',letterSpacing:'1.6px',textAlign:"left"}}>Atenção! Esta reserva está sendo feita para a agência <strong>{currentAgency.agencyName}</strong> e vinculada ao funcionário  <strong>{currentAgency.employeeName}</strong>.</div>
															
														</div>														
														
														 
													</div>	
													</>
													}
																									
													{/* Price details Block */}
													<div style={{marginTop:"15px",display:"flex",flexDirection:"column",alignItems:"flex-start",justifyContent:"center",backgroundColor:"#FFF"}}>
														
														<div style={{padding:"20px",paddingBottom:"0",color:'#222',fontFamily:'Titillium Web',fontSize:'20px',fontStyle:'normal',fontWeight:600,lineHeight:'150%',letterSpacing:'2px'}}>Resumo dos valores</div>
														
														<div style={{padding:"20px",paddingBottom:"0px",color:'#222',fontFamily:'Titillium Web',fontSize:'16px',fontStyle:'bold',fontWeight:600,lineHeight:'150%',letterSpacing:'2px'}}>Ida</div>	
														
														<div style={{padding:"10px",paddingTop:"0",marginTop:"5px",width:"100%"}}>
														
																<div style={{color:"#2E0249",fontFamily:"Titillium Web",fontSize:"16px",fontStyle:"normal",fontWeight:400,width:"100%",display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column",textAlign:"left"}}>

																		<div style={{width:"100%",padding:"0px",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}> 
																			<div style={{width:"100%",padding:"10px",paddingLeft:"20px",paddingBottom:"0px",display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"stretch",gap:"20px"}}> 
																				<div style={{width:"70%"}}>Passagens ({personAddLabel})</div>
																				<div> 270,00 &euro; </div>
																			</div>
																			<div style={{width:"100%",padding:"10px",paddingTop:"0px",paddingBottom:"0px",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"flex-start",paddingLeft:"40px",fontSize:"14px"}}> 
																			{personAddAdult > 0 &&
																			<div style={{display:"block",marginBottom:"5px"}}>{personAddAdult} x Adulto</div>
																			}
																			
																			{personAddYoung > 0 &&
																			<div style={{display:"block",marginBottom:"5px"}}>{personAddYoung} x Jovem <small>({selectedYoungAgeLabel})</small></div>
																			}

																			{personAddElder > 0 &&
																			<div style={{display:"block",marginBottom:"5px"}}>{personAddElder} x Sênior <small>({selectedElderAgeLabel})</small></div>
																			}
																			</div>
																		</div>
																		<div style={{width:"100%",paddingLeft:"0px",paddingRight:"0px",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}> 
																			<div style={{width:"100%",padding:"10px",paddingLeft:"20px",paddingBottom:"0px",display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"stretch",gap:"20px"}}>  
																				<div style={{width:"70%"}}>Reserva de lugares</div>
																				<div> 19,90 &euro; </div>
																			</div>
																		</div>
																		<div style={{width:"100%",paddingLeft:"0px",paddingRight:"0px",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}> 
																			<div style={{width:"100%",padding:"10px",paddingLeft:"20px",paddingBottom:"20px",display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"stretch",gap:"20px",fontSize:"18px"}}> 
																				<div style={{width:"70%"}}>
																				<strong>
																					{transportFilters.pickReturn &&
																						<>Sub-total Ida</>
																					}
																					
																					{!transportFilters.pickReturn &&
																						<>Total</>
																					}									
																				</strong>
																				<small> (taxas incluídas)</small></div>
																				<div> <strong>289,90 &euro;</strong> </div>
																			</div>
																		</div>
																																	
																	</div>														
																											
														
														</div>
														
														
														{transportFilters.pickReturn &&
														<>
														<Divider style={{marginBottom:"5px",marginTop:"5px",width:"90%",marginLeft:"auto",marginRight:"auto"}} />														
														<div style={{padding:"20px",paddingBottom:"0px",color:'#222',fontFamily:'Titillium Web',fontSize:'16px',fontStyle:'bold',fontWeight:600,lineHeight:'150%',letterSpacing:'2px'}}>Volta</div>	
														
														<div style={{padding:"10px",paddingTop:"0",marginTop:"5px",width:"100%"}}>
														
																<div style={{color:"#2E0249",fontFamily:"Titillium Web",fontSize:"16px",fontStyle:"normal",fontWeight:400,width:"100%",display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column",textAlign:"left"}}>

																		<div style={{width:"100%",padding:"0px",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}> 
																			<div style={{width:"100%",padding:"10px",paddingLeft:"20px",paddingBottom:"0px",display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"stretch",gap:"20px"}}> 
																				<div style={{width:"70%"}}>Passagens ({personAddLabel})</div>
																				<div> 270,00 &euro; </div>
																			</div>
																			<div style={{width:"100%",padding:"10px",paddingTop:"0px",paddingBottom:"0px",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"flex-start",paddingLeft:"40px",fontSize:"14px"}}> 
																			{personAddAdult > 0 &&
																			<div style={{display:"block",marginBottom:"5px"}}>{personAddAdult} x Adulto</div>
																			}
																			
																			{personAddYoung > 0 &&
																			<div style={{display:"block",marginBottom:"5px"}}>{personAddYoung} x Jovem <small>({selectedYoungAgeLabel})</small></div>
																			}

																			{personAddElder > 0 &&
																			<div style={{display:"block",marginBottom:"5px"}}>{personAddElder} x Sênior <small>({selectedElderAgeLabel})</small></div>
																			}
																			</div>
																		</div>
																		<div style={{width:"100%",paddingLeft:"0px",paddingRight:"0px",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}> 
																			<div style={{width:"100%",padding:"10px",paddingLeft:"20px",paddingBottom:"0px",display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"stretch",gap:"20px"}}>  
																				<div style={{width:"70%"}}>Reserva de lugares</div>
																				<div> 19,90 &euro; </div>
																			</div>
																		</div>
																		<div style={{width:"100%",paddingLeft:"0px",paddingRight:"0px",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}> 
																			<div style={{width:"100%",padding:"10px",paddingLeft:"20px",paddingBottom:"20px",display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"stretch",gap:"20px",fontSize:"18px"}}> 
																				<div style={{width:"70%"}}>							
																				<strong>
																					{transportFilters.pickReturn &&
																						<>Sub-total Volta</>
																					}
																					
																					{!transportFilters.pickReturn &&
																						<>Total</>
																					}
																				</strong> <small> (taxas incluídas)</small></div>
																				<div> <strong>579,80 &euro;</strong> </div>
																			</div>
																		</div>
																																	
																	</div>														
																											
														
														</div>														
														</>
														}
																												
														{transportFilters.pickReturn &&
														<>
														<Divider style={{marginBottom:"5px",marginTop:"5px",width:"90%",marginLeft:"auto",marginRight:"auto"}} />														
														<div style={{padding:"0px",paddingTop:"0",marginTop:"5px",width:"100%"}}>
														
																<div style={{color:"#2E0249",fontFamily:"Titillium Web",fontSize:"18px",fontStyle:"normal",fontWeight:400,width:"100%",display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column",textAlign:"left"}}>

																		<div style={{width:"100%",paddingLeft:"0px",paddingRight:"0px",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}> 
																			<div style={{width:"100%",padding:"10px",paddingLeft:"20px",paddingBottom:"20px",display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"stretch",gap:"20px",fontSize:"20px"}}> 
																				<div style={{width:"70%"}}>
																				<strong>Total</strong> <small>(taxas incluídas)</small></div>
																				<div> <strong>289,90 &euro;</strong> </div>
																			</div>
																		</div>
																																	
																</div>														
																											
														
														</div>														
														</>
														}														
														
														
														<div className="modalFooterButtons" style={{}}>
														
															<div className="modalFooterButtonsLeft">
																<div className="flex align-items-center" style={{padding:"15px"}}>
																	<Checkbox inputId="acceptTerm" disabled={saving} name="acceptTerm" value="sim" onChange={handleTermsChange} checked={acceptTerm} className="mR10" />
																	<label htmlFor="acceptTerm" className="">Confirmo que estou de acordo com os <Button label="termos e condições da Memovo" link style={{padding:"unset",marginTop:"5px"}} onClick={() => setTermsModal(true)} /></label>
																</div>
															</div>
															
														</div>															
														
													</div>	
										
										
													{/* Continue Button Replication */}
													<div style={{marginTop:"20px",height:"110px",minHeight:"110px",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"stretch"}}>
														
														<div style={{backgroundColor:"#2E0249",flex:"1.5",borderRadius:"6px 0px 0px 6px",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",paddingLeft:"21px"}}>
														
															<div style={{textAlign:"left",marginTop:"5px",marginBottom:"5px",color:'rgba(255, 255, 255, 0.80)',fontFamily:'Titillium Web',fontSize:'14px',fontStyle:'normal',fontWeight:400,lineHeight:'120%',letterSpacing:'1.4px'}}>
															
																{transportFilters.pickReturn &&
																	<>Ida e Volta</>
																}

																{!transportFilters.pickReturn &&
																	<>Ida</>
																}															
															
															</div>
															<div style={{textAlign:"left",marginBottom:"5px",color:'#FFF',fontFamily:'Titillium Web',fontSize:'24px',fontStyle:'normal',fontWeight:700,lineHeight:'120%',letterSpacing:'2.4px'}}>{selectedTransportPrice}
															</div>
															<div style={{textAlign:"left",marginBottom:"5px",color:'rgba(255, 255, 255, 0.80)',fontFamily:'Titillium Web',fontSize:'14px',fontStyle:'normal',fontWeight:400,lineHeight:'120%',letterSpacing:'1.4px'}}>{personAddLabel}</div>
															
														</div>
														
														<div style={{backgroundColor:"#814DE5",flex:"1",borderRadius:"0px 6px 6px 0px",display:"flex",justifyContent:"center",alignItems:"center"}}>
														
															<ConfirmDialog />
															<Button  className="buttonLeftPanel"  label="Confirmar" style={{flexDirection:"column",fontFamily:"Titillium Web",fontSize:"24px",fontStyle:"bold",fontWeight:800,lineHeight:"120%",letterSpacing:"2.4px",height:"100%",justifyContent:"center"}} onClick={() => confirm1()} ><div style={{display:"block",color:'#FFF',textAlign:'center',fontFamily:'Titillium Web',fontSize:'14px',fontStyle:'normal',fontWeight:400,lineHeight:'120%',letterSpacing:'1.4px'}}>e finalizar reserva</div></Button>
																	

														</div>														
														
													</div>													
												</>
												}
											
												
													{/* Continue Button Replication */}
													<div style={{marginTop:"20px",height:"50px",minHeight:"50px",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"stretch"}}>
															
														<div style={{backgroundColor:"transparent",borderColor:"transparent",flex:"1",borderRadius:"6px 6px 6px 6px",display:"flex",justifyContent:"center",alignItems:"center"}}>
															<Button text className="buttonLeftPanel" icon="pi pi-download" iconPos="right" label="Baixar PDF da cotação" style={{flexDirection:"row",gap:"10px",color:'#814DE5',fontFeatureSettings:"'clig' off, 'liga' off",fontFamily:'Roboto',fontSize:'18px',fontStyle:'normal',fontWeight:500,lineHeight:'26px',letterSpacing:'0.46px',height:"100%",justifyContent:"center",backgroundColor:"transparent",borderColor:"transparent",color:"#814DE5"}} onClick={()=>navigate("/order/transport/reservation/confirm")}></Button>
														</div>														
														
													</div>	
													<div style={{marginTop:"10px",height:"50px",minHeight:"50px",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"stretch"}}>
															
														<div style={{backgroundColor:"transparent",borderColor:"transparent",flex:"1",borderRadius:"6px 6px 6px 6px",display:"flex",justifyContent:"center",alignItems:"center"}}>
															<Button text className="buttonLeftPanel"  icon="pi pi-send" iconPos="right" label="Enviar cotação por Email" style={{flexDirection:"row",gap:"10px",color:'#814DE5',fontFeatureSettings:"'clig' off, 'liga' off",fontFamily:'Roboto',fontSize:'18px',fontStyle:'normal',fontWeight:500,lineHeight:'26px',letterSpacing:'0.46px',height:"100%",justifyContent:"center",backgroundColor:"transparent",borderColor:"transparent",color:"#814DE5"}} onClick={()=>navigate("/order/transport/reservation/confirm")}></Button>
														</div>														
														
													</div>													
													
												</div>
												
												
											 </Grid>
											 
											  
										</Grid>	
								
									</div>
								
								</div>


						</div>						

					</div>				
						
				
				</div>
			
			</div> 
		</div>
    </div>
	
	<div id="contatoSection" className="contatoSection__wrapper">

		<div className={isTabletOrMobile ? "contatoSection__wrapper_content_wrapper mobile" : "contatoSection__wrapper_content_wrapper"}  >

			<div className="flex-1 dFlex aItemsC jContentE"> 
			
				<div className="textBlockMargin contatoSection__wrapper_content_text" >
				
					<div className="contatoSection__wrapper_content_header">Central de Relacionamento</div>
				
					Estamos a disposição para atender às suas necessidades e responder às suas perguntas. Conheça as principais formas de entrar em contato conosco:
					
					<div style={{marginTop: "2.8125rem", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "stretch", gap: "15px"}}>

						<div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center",gap:"15px"}}>
							<div><img src={contactWhatsappIcon} style={{width:"49px",height:"auto"}} className="noSelect" /></div>
							<div className="contatoOptionText"><Whatsapp phone="552140209749" style={{color:"#FFF",textDecoration:"none"}}>Whatsapp 4020-9749</Whatsapp></div>
						</div>

						<div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center",gap:"15px"}}>
							<div><img src={contactPhoneIcon} style={{width:"49px",height:"auto"}} className="noSelect"  /></div>
							<div className="contatoOptionText"><Callto phone="+552140209749" style={{color:"#FFF",textDecoration:"none"}}>Telefone 4020-9749</Callto></div>
						</div>
						
						<div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center",gap:"15px"}}>
							<div><img src={contactEmailIcon} style={{width:"49px",height:"auto"}} className="noSelect"  /></div>
							<div className="contatoOptionText"><Button label="Enviar mensagem" rounded className="pT10 mR10 headerButton tColorWhite w100" style={{marginBottom:"0.625rem"}} onClick={()=>setDialog('messageModal',true)} /></div>
						</div>
						

					</div>					
				
				</div> 
				
			</div>
			
			<div className="tAlignL flex-1_5"> 
				<img src={contactConcept} className="contatoSection__wrapper_content_image noSelect" />
			</div>			
			
		</div>
			<TermsView
				setTermsModal={setTermsModal}
				termsModal={termsModal}
				scrollBlock={false}
			/>		
			<AlertView
				alert={alert}
				setAlert={setAlert}
				alertTitle={alertTitle}
				alertBody={alertBody}	
				scrollBlock={false}				
			/>	
			<ExpireView
				alertExpire={alertExpire}
				setAlertExpire={setAlertExpire}
				alertTitleExpire={alertTitleExpire}
				alertBodyExpire={alertBodyExpire}	
				alertActionExpire={alertActionExpire}	
				scrollBlock={false}				
			/>	
			<MessageView
				alert={alert}
				setAlert={setAlert}
				alertTitle={alertTitle}
				alertBody={alertBody}	
				scrollBlock={false}				
			/>	
    </div>
	
	<div id="memovoSection" className="memovoSection__wrapper">
	
		<div className={isTabletOrMobile ? "memovoSection__wrapper_content_wrapper mobile" : "memovoSection__wrapper_content_wrapper"}>
		
			<div className="flex-1"> 
				<img src={desktopConcept} className="memovoSection__wrapper_content_image noSelect" />
			</div>
			<div className="flex-1 dFlex aItemsC jContentS"> 
			
				<div className="textBlockMargin memovoSection__wrapper_content_text" >
				
					<div className="memovoSection__wrapper_content_header">Inovando o Mercado B2B de Mobilidade</div>
									
					A MEMOVO é uma plataforma B2B inovadora que facilita a busca e comercialização de serviços de transporte e mobilidade. Pioneira como marketplace B2B, oferece passagens aéreas, trens, ônibus, aluguel de veículos e transfers em um só lugar, permitindo a criação de roteiros completos de mobilidade. Com interface amigável e processo simples, a plataforma proporciona agilidade e eficiência aos profissionais do setor, atendendo às necessidades dos usuários e impulsionando o crescimento do seu negócio.
				
				</div> 
				
			</div>
			
		</div>
		
    </div>	
	
	</>
  );
    
}


const mapStateToProps = store => ({
  dialog:store.appReducer.dialog,
  transportFilters:store.orderReducer.transportFilters,
  currentAgency:store.appReducer.currentAgency
 });
const mapDispatchToProps = dispatch => bindActionCreators({ ...AppActions,...OrderActions,...RegisterActions }, dispatch);

export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(TransferSearchResult);	


