import '../../Assets/Styles/home.css';
import '../../Assets/Styles/order.css';
import React from "react";
import { useMediaQuery } from 'react-responsive'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {Link,useNavigate} from 'react-router-dom';

import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Checkbox } from 'primereact/checkbox';
import { RadioButton } from "primereact/radiobutton";
import { Button } from 'primereact/button'; 
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import Grid from '@mui/material/Grid';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { OverlayPanel } from 'primereact/overlaypanel';
import { ProgressSpinner } from 'primereact/progressspinner';
import { ProgressBar } from 'primereact/progressbar';
import { Skeleton } from 'primereact/skeleton';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Tooltip } from 'primereact/tooltip';
import { Slider } from "primereact/slider";
import { Galleria } from 'primereact/galleria';
import { TabView, TabPanel } from 'primereact/tabview';

import {APIProvider, Map,Marker} from '@vis.gl/react-google-maps';

import tooltipIcon from '../../Assets/Images/tooltipIcon.svg';

// Load Actions
import * as AppActions from '../../Store/Actions/App'
import * as OrderActions from '../../Store/Actions/Order'

import ExpireView from '../../Components/ExpireModal';
import AboutUs from '../../Components/layout/AboutUs';
import ContactUs from '../../Components/layout/ContactUs';
import RoomSelector from '../../Components/RoomSelector';
import MapViewModal from './MapviewModal';
import ImageGalleryModal from './ImageGalleryModal';

// Load Componentes
import AlertView from '../../Components/AlertModal';

import {
	formatNumberCurrency
} from '../../Components/Utils';
import { Divider } from '@mui/material';

// export default function HomePage() {
const HotelDetails = (props) => {

const apiKey = 'AIzaSyBFNooJFvoHEGeQOJqUuekFHT1PRQ8TbCs';
	
// Set var from props
const {
	setDialog,
	dialog,
	masterUser,
	currentAgency,
	searchType,
	searchParams,
	searchResultStatus,
	searchResultRowsNumber,
	searchResultToken,
	searchResultCurrentPage,
	searchResultLimit,
	searchLocation,
	searchResultObj    
} = props;


console.log('<<<<<<<<<<<<<< searchType >>>>>>>>>>>>>>>>>>>> '+JSON.stringify(searchType,null,2));

const [loadingResults, setLoadingResults] = React.useState(false); 
const [loadingFiltering, setLoadingFiltering] = React.useState(false); 
const [loading, setLoading] = React.useState(true); 
const [saving, setSaving] = React.useState(false); 

const [contentLoading, setContentLoading] = React.useState(true); 
const [contentLoaded, setContentLoaded] = React.useState(false); 
const [hostingOption, setHostingOptions] = React.useState(false); 
const [currentStep, setCurrentStep] = React.useState(1); // 1 continent - 2 country - 3 city  - 4 options

const [selectedItem, setSelectedItem] = React.useState(null); // Set selected item 

const [currentContinent, setCurrentContinent] = React.useState({}); // id and label
const [currentCountry, setCurrentCountry] = React.useState({}); // id and label
const [currentCity, setCurrentCity] = React.useState({}); // id and label

const [breadcrumbArray, setBreadcrumbArray] = React.useState([]); // id and label
	
const isDesktopOrLaptop = useMediaQuery({
query: '(min-width: 1024px)'
})
const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1023px)' })

const [email, setEmail] = React.useState('');
const [password, setPassword] = React.useState('');
const [stillConnected, setStillConnected] = React.useState(false);
const onStillConnectedChange = (e) => {
	if (e.checked)
		setStillConnected(true);
	else
		setStillConnected(false);
}  // end function
	
// Alert control
const [alert, setAlert] = React.useState(false); 
const [alertTitle, setAlertTitle] = React.useState("Informações"); 
const [alertBody, setAlertBody] = React.useState("Conteúdo dinâmico"); 

// Expire Dialog control
const [alertExpire, setAlertExpire] = React.useState(false); 
const [alertTitleExpire, setAlertTitleExpire] = React.useState("Informações"); 
const [alertBodyExpire, setAlertBodyExpire] = React.useState("Conteúdo dinâmico"); 
const [alertActionExpire, setAlertActionExpire] = React.useState("restartSearch"); 

const [currentPlaces, setCurrentPlaces] = React.useState([]); 		

const [hostsAndRooms, setHostsAndRooms] = React.useState(null); 
const [rooms, setRooms] = React.useState([{ adults: 1, children: [] }]); // Default at least 1 adult

const [dateRange, setDateRange] = React.useState(null); 
const [formattedValue, setFormattedValue] = React.useState('');

const [filterByCity, setFilterByCity] = React.useState(null); 
const [filterByCountry, setFilterByCountry] = React.useState(null); 

const [showCancelButton, setShowCancelButton] = React.useState(true); 

const calendarRef = React.useRef(null);
const overlayPanelRef = React.useRef(null);

const [counter, setCounter] = React.useState(1200);
const [counterInitial, setCounterInitial] = React.useState(1200);

// const [counter, setCounter] = React.useState(20); // for test. Force popup. 
const [counterLabel, setCounterLabel] = React.useState("00:20:00");
const [counterValue, setCounterValue] = React.useState(100);

const [locationImage, setLocationImage] = React.useState(null);
const [addressLocationText, setAddressLocationText] = React.useState(null);
const [resultsCards, setResultsCards] = React.useState([]);

const [descriptionDetail, setDescriptionDetail] = React.useState(false);

const [images, setImages] = React.useState(null);
const [activeIndex, setActiveIndex] = React.useState(0);    

const galleria = React.useRef(null);

const [center, setCenter] = React.useState(null);
const [markerCenter, setMarkerCenter] = React.useState(null);


// Cards panel handlers and control
const [visiblePanel, setVisiblePanel] = React.useState(null);

const handleSelect = (id) => {
  setVisiblePanel(id);
};

const handleClose = () => {
  setVisiblePanel(null);
};


// Filters
const [orderByFilter, setOrderByFilter] = React.useState({"code":8,name:"Avaliação (melhor primeiro"});
const [filterPriceRange, setFilterPriceRange] = React.useState([0,3000]);

const [filterAmenitiesValues, setFilterAmenitiesValues] = React.useState([]);
const [showAllAmenities, setShowAllAmenities] = React.useState(false);
const onFilterAmenitiesChange = (e) => {
	let _amenities = [...filterAmenitiesValues];

	if (e.checked)
		_amenities.push(e.value);
	else
	_amenities.splice(_amenities.indexOf(e.value), 1);

	setFilterAmenitiesValues(_amenities);
}

const [filterFoodValues, setFilterFoodValues] = React.useState([]);
const [showAllFood, setShowAllFood] = React.useState(false);
const onFilterFoodChange = (e) => {
	let _food = [...filterFoodValues];

	if (e.checked)
		_food.push(e.value);
	else
	_food.splice(_food.indexOf(e.value), 1);

	setFilterFoodValues(_food);
}


const [filterPOIValues, setFilterPOIValues] = React.useState([]);
const [showAllPOI, setShowAllPOI] = React.useState(false);
const onFilterPOIChange = (e) => {
	let _poi = [...filterPOIValues];

	if (e.checked)
		_poi.push(e.value);
	else
	_poi.splice(_poi.indexOf(e.value), 1);

	setFilterPOIValues(_poi);
}


// Counter control
React.useEffect(() => {
	 
	if (counter > 0) {
		setTimeout(function() {
			setCounter(counter - 1)
			setCounterLabel(secondsToHmsOriginal(counter - 1));

		   // Current %
		   var currentPercent = (100 * counter) / counterInitial;
		   setCounterValue(currentPercent);
			
		},1000);		 
	} // end if
	else {
		
	   setAlertExpire(true);
	   setAlertTitleExpire("O resultado da busca expirou");
	   setAlertBodyExpire("O resultado da busca expirou. Faça uma nova pesquisa, para ver preços e hospedagens atualizados.");
	   setAlertActionExpire("restartSearch");
	   
	} // end else
	
}, [counter]);


const secondsToHmsOriginal = (d) => {
   
	d = Number(d);
	var h = Math.floor(d / 3600);
	var m = Math.floor(d % 3600 / 60);
	var s = Math.floor(d % 3600 % 60);
 
	var hDisplay = h > 0 ? h + (h == 1) : "00";
	var mDisplay = m > 0 ? m + (m == 1) : "00";
	var sDisplay = s > 0 ? s + (s == 1) : "00";
 
	let hour = ""+hDisplay+"";
	let minutes = ""+mDisplay+"";
	let seconds = ""+sDisplay+"";
 
	// return hour.padStart(2, '0') +":"+ minutes.padStart(2, '0')+":"+ seconds.padStart(2, '0');
	return minutes.padStart(2, '0')+":"+ seconds.padStart(2, '0');
 }

const secondsToHms = (d) => {
   
   d = Number(d);
   var h = Math.floor(d / 3600);
   var m = Math.floor(d % 3600 / 60);
   var s = Math.floor(d % 3600 % 60);

   var hDisplay = h > 0 ? h + (h == 1) : "00";
   var mDisplay = m > 0 ? m + (m == 1) : "00";
   var sDisplay = s > 0 ? s + (s == 1) : "00";

   let hour = ""+hDisplay+"";
   let minutes = ""+mDisplay+"";
   let seconds = ""+sDisplay+"";

   // return hour.padStart(2, '0') +":"+ minutes.padStart(2, '0')+":"+ seconds.padStart(2, '0');
   return minutes.padStart(2, '0')+":"+ seconds.padStart(2, '0');
}


const handleRoomSelection = (value) => {
	setHostsAndRooms(value);
	overlayPanelRef.current.hide();
};

const handleClearSelection = () => {
	setRooms([{ adults: 1, children: [] }]);
	// setHostsAndRooms(null);	
	overlayPanelRef.current.hide();
};

// Check if everything is ok with seleciton
const handleCheckSelectedOptions = (rooms) => {

	// Check if any children dont have defined age
	for (let i = 0; i < rooms.length; i++) {
		for (let j = 0; j < rooms[i].children.length; j++) {
			if (rooms[i].children[j].age === "") {
	
				setAlertTitle("Ops! Defina a idade da(s) criança(s)");
				setAlertBody("É necessário que você defina as idades das crianças que foram adicionadas. Abra o seletor de quartos e informe a idade das crianças.");
				setAlert(true);	
								
				handleUpdateInputValue(rooms); // update current selection
				return false;
			}
		}
	}



}

const handleUpdateInputValue = (rooms) => {
	const adultCount = rooms.reduce((acc, room) => acc + room.adults, 0);
	const childCount = rooms.reduce((acc, room) => acc + room.children.length, 0);

	const adultLabel = adultCount > 1 ? 'adultos' : 'adulto';
	const childLabel = childCount > 1 ? 'crianças' : 'criança';
	const roomLabel = rooms.length > 1 ? 'quartos' : 'quarto';

	const parts = [];
	if (adultCount > 0) parts.push(`${adultCount} ${adultLabel}`);
	if (childCount > 0) parts.push(`${childCount} ${childLabel}`);
	if (rooms.length > 0 && (adultCount > 0 || childCount > 0)) parts.push(`${rooms.length} ${roomLabel}`);

	setHostsAndRooms(parts.join(', '));	
};

const handleMoreResults = () => {

	setContentLoading(true);

	setTimeout(function() {

        const nextIndex = resultsCards.length;
        const tmpResults = resultsCardsArray.slice(nextIndex, nextIndex + 5);
        setResultsCards([...resultsCards, ...tmpResults]);
		
		setContentLoading(false);
				
	  },5000);

}

const handleClickScroll = (target) => {
		
	const element = document.getElementById(target);
	if (element) {
		// 👇 Will scroll smoothly to the top of the next section
		var elementPosition = element.offsetTop;
		element.scrollIntoView({ top: elementPosition - 70,behavior: 'smooth' });
	}
	};  

React.useEffect(() => {
	if (!hostsAndRooms) {
		setHostsAndRooms(null);
	}	
}, [hostsAndRooms]);


React.useEffect(() => {
	handleUpdateInputValue(rooms);
}, [rooms]);


React.useEffect(() => {
	if (dateRange && dateRange[0] && dateRange[1]) {
		const diffTime = Math.abs(dateRange[1] - dateRange[0]);
		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
		const formattedRange = formatDateRange(dateRange);
		let nightLabel = diffDays == 1 ? "noite" : "noites";
		setFormattedValue(`${formattedRange} (${diffDays} ${nightLabel})`);
	} else {
		setFormattedValue('');
	}
}, [dateRange]);


const formatDateRange = (range) => {
	if (range && range[0] && range[1]) {
		const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
		return `${range[0].toLocaleDateString('pt-BR', options)} - ${range[1].toLocaleDateString('pt-BR', options)}`;
	}
	return '';
};

  
const Countdown = (props) => {
	const { startTime } = props;
	const [counter, setCounter] = React.useState(startTime);
	const [counterInitial] = React.useState(60);
	const [counterLabel, setCounterLabel] = React.useState("00:60");
	const [counterValue, setCounterValue] = React.useState(100);
  
	React.useEffect(() => {
	  if (counter > 0) {
		const timer = setTimeout(() => {
		  setCounter((prevCounter) => {
			const newCounter = prevCounter - 1;
			setCounterLabel(secondsToHms(newCounter));
			setCounterValue((100 * newCounter) / counterInitial);  
			return newCounter;
		  });
		}, 1000);
  
		return () => clearTimeout(timer);
	  }
	}, [counter]);
  
	return (
	  <>
		{counter > 0 ? (
		  <>Aguarde até {counterLabel} segundos...</>
		) : (
		  <>Redirecionando para resultado...</>
		)}
	  </>
	);
  };   

const customFooter = () => {
	return (<>
		<div className="p-clearfix" style={{ width: '100%',textAlign:"left" }}>
		 <button type="button" onClick={() => calendarRef.current.hide()} className="p-button p-component p-button-text p-button-plain" style={{ float: 'right' }}>
                    Fechar
        </button>
		</div>

		<div className="p-clearfix" style={{ width: '100%',textAlign:"right" }}>
			<button type="button" onClick={() => setDateRange(null)} className="p-button p-component p-button-text p-button-plain" style={{fontWeight:"bold"}}>
				Limpar
			</button>
		</div>
		</>
	);
};

const customFooterInline = () => {
	return (<>
		<div className="p-clearfix" style={{ width: '100%',textAlign:"right" }}>
			<button type="button" onClick={() => setDateRange(null)} className="p-button p-component p-button-text p-button-plain" style={{fontWeight:"bold"}}>
				Limpar
			</button>
		</div>
		</>
	);
};

// Set calendar locale
addLocale('br', {
	firstDayOfWeek: 1,
	dayNames: ['domingo', 'segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado'],
	dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
	dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
	monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
	monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
	today: 'Hoje',
	clear: 'Limpar'
});


// set Use of history for PUSH 
var navigate = useNavigate();
	
// Run after mount component
React.useEffect(() => {

	// Retrieve User Data Obj. 
	var result = localStorage.getItem("@memovo/userData", undefined,true);
	var userData = JSON.parse(result);  

	  // If token dont exist, redirect to onboarding or register
	  if (!userData) {
		  navigate("/home");
		  return;
	  } // end if
	  
  // set scroll to top
  window.scrollTo(0, 1);

   // Set location
   // let addressText = `${currentContinent} `;
   let addressText = `52.35879898,4.893459797`;
   setAddressLocationText(addressText);

   let locationImageUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${addressText}&zoom=11&size=416x142&maptype=roadmap&key=AIzaSyBFNooJFvoHEGeQOJqUuekFHT1PRQ8TbCs`;
   setLocationImage(locationImageUrl);  

   let coordinates = {lat:52.35879898,lng:4.893459797};
   setCenter(coordinates);
   setMarkerCenter(coordinates);   

   // Set Breadcrumb array
   let pathArray = [
	{
		label: 'Europa',
		template: () => <div className="breadcrumbSelectedLabel" style={{fontSize:"1.5rem"}}>Europa</div>
	},
	{
		label: 'Portugal',
		template: () => <div className="breadcrumbSelectedLabel" style={{fontSize:"1.5rem"}}>Portugal</div>
	},		
	{
		label: 'Lisboa',
		template: () => <div className="breadcrumbSelectedLabel" style={{fontSize:"1.5rem"}}>Lisboa</div>
	},
   ];
   setBreadcrumbArray(pathArray);

   let randomImages = [
    {
        itemImageSrc: "https://picsum.photos/id/100/600/400",
        thumbnailImageSrc: "https://picsum.photos/id/100/200/160",
        alt: 'Imagem'
    },
    {
        itemImageSrc: "https://picsum.photos/id/101/600/400",
        thumbnailImageSrc: "https://picsum.photos/id/101/200/160",
        alt: 'Imagem'
    },            
    {
        itemImageSrc: "https://picsum.photos/id/102/600/400",
        thumbnailImageSrc: "https://picsum.photos/id/102/200/160",
        alt: 'Imagem'
    },
    {
        itemImageSrc: "https://picsum.photos/id/103/600/400",
        thumbnailImageSrc: "https://picsum.photos/id/103/200/160",
        alt: 'Imagem'
    },
    {
        itemImageSrc: "https://picsum.photos/id/104/600/400",
        thumbnailImageSrc: "https://picsum.photos/id/104/200/160",
        alt: 'Imagem'
    },            
    {
        itemImageSrc: "https://picsum.photos/id/115/600/400",
        thumbnailImageSrc: "https://picsum.photos/id/115/200/160",
        alt: 'Imagem'
    },   
    {
        itemImageSrc: "https://picsum.photos/id/106/600/400",
        thumbnailImageSrc: "https://picsum.photos/id/106/200/160",
        alt: 'Imagem'
    },
    {
        itemImageSrc: "https://picsum.photos/id/107/600/400",
        thumbnailImageSrc: "https://picsum.photos/id/107/200/160",
        alt: 'Imagem'
    },                                               
];
setImages(randomImages);   

  // set loading false
  setLoading(false);
 
  setTimeout(function() {

	setResultsCards(displayInitialCards);
	setContentLoading(false);
			
  },5000);



  (function (d) {
    "use strict";
  
    //lets add the observer element dynamically.
    var newEl = d.createElement("div");
    newEl.classList.add("myObserver");
    var ref = d.querySelector("#titleBox");
    insertBefore(newEl, ref);
    function insertBefore(el, referenceNode) {
      referenceNode.parentNode.insertBefore(el, referenceNode);
    }
  
    var observer = new IntersectionObserver(
      function (entries) {
        if (entries[0].intersectionRatio === 0) {
          d.querySelector("#titleBox").classList.add("sticky-element");
          //newEl.classList.add("sticky-observer");
        } else if (entries[0].intersectionRatio === 1) {
          d.querySelector("#titleBox").classList.remove("sticky-element");
          //newEl.classList.remove("sticky-observer");
        }
      },
      { threshold: [0, 1] }
    );
  
    observer.observe(d.querySelector(".myObserver"));
  })(document);

  
    
  // Runs when UNMOUNT component
  return () => {

	setContentLoading(true); 
	setContentLoaded(false); 
	setHostingOptions(false); 
	setCurrentStep(1); 

	setCurrentContinent({}); 
	setCurrentCountry({}); 
	setCurrentCity({}); 
	setBreadcrumbArray([]); 

	setCurrentPlaces([]); 		
	setHostsAndRooms(null); 
	setRooms([{ adults: 1, children: [] }]); 
		
	setDateRange(null); 
	setFormattedValue('');
		
	setFilterByCity(null); 
	setFilterByCountry(null); 	
	
  };
  
}, []); // end useEffect 

const clearOptions = () => {

	setHostsAndRooms(null); 
	setRooms([{ adults: 1, children: [] }]);	
	setDateRange(null); 
	setFormattedValue('');

	setFilterByCity(null); 
	setFilterByCountry(null); 

}

const item =  {
    "id": "1",
    "mainImage": "https://picsum.photos/400/262?random=1",
    "details": {
        "name": "GA Palace Hotel & Spa, a XIXth-Century VillaAbre",
        "country": "Portugal",
        "city": "Porto",
        "suburb": "União de Freguesias do Centro",
        "distances": {
            "from_center": "0,7km",
            "metro": "100"
        },
        "address":"R. dos Andradas 600, Porto,União de Freguesias do Centro, Portugal",
        "booking_rating": "9,5",
        "daily_price": "450,00",
        "daily_price_number": 450,
        "amenities": [
            { "name": "Estacionamento", "id": "1" },
            { "name": "Wi-fi grátis", "id": "2" },
            { "name": "Aceita Pets", "id": "4" },
            { "name": "Transfer (Aeroporto)", "id": "5" },
            { "name": "Estacionamento", "id": "1" },
            { "name": "Wi-fi grátis", "id": "2" },
            { "name": "Restaurante", "id": "9" },
            { "name": "Estacionamento", "id": "1" },
            { "name": "Wi-fi grátis", "id": "2" },
            { "name": "Aceita Pets", "id": "4" },
            { "name": "Transfer (Aeroporto)", "id": "5" },
            { "name": "Estacionamento", "id": "1" },
            { "name": "Wi-fi grátis", "id": "2" }                                        
        ]
    }
}

const resultsCardsArray = [
    {
        "id": "1",
        "mainImage": "https://picsum.photos/400/262?random=1",
        "details": {
            "name": "Quarto Deluxe",
            "country": "Portugal",
            "city": "Porto",
            "suburb": "União de Freguesias do Centro",
            "distances": {
                "from_center": "0,7km",
                "metro": "100"
            },
            "stock":3,
            "booking_rating": "9,5",
            "daily_price": "450,00",
            "daily_price_number": 450,
            "amenities": [
                { "name": "Estacionamento", "id": "1" },
                { "name": "Wi-fi grátis", "id": "2" },
                { "name": "Aceita Pets", "id": "4" },
                { "name": "Transfer (Aeroporto)", "id": "5" }
            ]
        }
    },
    {
        "id": "2",
        "mainImage": "https://picsum.photos/400/262?random=2",
        "details": {
            "name": "Quarto Standard",
            "country": "Portugal",
            "city": "Porto",
            "suburb": "Bonfim",
            "distances": {
                "from_center": "0,8km"
            },
            "booking_rating": "8,8",
            "daily_price": "250,00",
            "daily_price_number": 250,
            "amenities": [
                { "name": "Estacionamento", "id": "1" },
                { "name": "Wi-fi grátis", "id": "2" },
                { "name": "Quartos para não fumantes", "id": "6" },
                { "name": "Transfer (Aeroporto)", "id": "5" }
            ]
        }
    },
    {
        "id": "3",
        "mainImage": "https://picsum.photos/400/262?random=3",
        "details": {
            "name": "Suíte Júnior",
            "country": "Portugal",
            "city": "Porto",
            "suburb": "Centro/Baixa",
            "distances": {
                "from_center": "0,4km"
            },
            "booking_rating": "9,0",
            "daily_price": "320,00",
            "daily_price_number": 320,
            "amenities": [
                { "name": "Estacionamento", "id": "1" },
                { "name": "Wi-fi grátis", "id": "2" },
                { "name": "Bar", "id": "7" },
                { "name": "Transfer (Aeroporto)", "id": "5" }
            ]
        }
    },
    {
        "id": "4",
        "mainImage": "https://picsum.photos/400/262?random=4",
        "details": {
            "name": "Quarto Superior",
            "country": "Portugal",
            "city": "Porto",
            "suburb": "Centro Histórico",
            "distances": {
                "from_center": "0,3km"
            },
            "booking_rating": "9,2",
            "daily_price": "370,00",
            "daily_price_number": 370,
            "stock":2,
            "amenities": [
                { "name": "Estacionamento", "id": "1" },
                { "name": "Wi-fi grátis", "id": "2" },
                { "name": "Piscina", "id": "8" },
                { "name": "Transfer (Aeroporto)", "id": "5" }
            ]
        }
    },
    {
        "id": "5",
        "mainImage": "https://picsum.photos/400/262?random=5",
        "details": {
            "name": "Quarto Familiar",
            "country": "Portugal",
            "city": "Porto",
            "suburb": "Massarelos",
            "distances": {
                "from_center": "1,0km"
            },
            "booking_rating": "9,4",
            "daily_price": "480,00",
            "daily_price_number": 480,
            "amenities": [
                { "name": "Estacionamento", "id": "1" },
                { "name": "Wi-fi grátis", "id": "2" },
                { "name": "Restaurante", "id": "9" }
            ]
        }
    },
    {
        "id": "6",
        "mainImage": "https://picsum.photos/400/262?random=6",
        "details": {
            "name": "Quarto Executivo",
            "country": "Portugal",
            "city": "Porto",
            "suburb": "Ribeira",
            "distances": {
                "from_center": "0,5km"
            },
            "booking_rating": "9,3",
            "daily_price": "430,00",
            "daily_price_number": 430,
            "amenities": [
                { "name": "Estacionamento", "id": "1" },
                { "name": "Wi-fi grátis", "id": "2" },
                { "name": "Vista para o rio", "id": "10" }
            ]
        }
    },
    {
        "id": "7",
        "mainImage": "https://picsum.photos/400/262?random=7",
        "details": {
            "name": "Suíte Master",
            "country": "Portugal",
            "city": "Porto",
            "suburb": "Centro Histórico",
            "distances": {
                "from_center": "0,2km"
            },
            "booking_rating": "9,6",
            "daily_price": "510,00",
            "daily_price_number": 510,
            "amenities": [
                { "name": "Estacionamento", "id": "1" },
                { "name": "Wi-fi grátis", "id": "2" },
                { "name": "Spa", "id": "11" }
            ]
        }
    },
    {
        "id": "8",
        "mainImage": "https://picsum.photos/400/262?random=8",
        "details": {
            "name": "Quarto King",
            "country": "Portugal",
            "city": "Porto",
            "suburb": "Massarelos",
            "distances": {
                "from_center": "1,5km"
            },
            "booking_rating": "8,9",
            "daily_price": "290,00",
            "daily_price_number": 290,
            "amenities": [
                { "name": "Estacionamento", "id": "1" },
                { "name": "Wi-fi grátis", "id": "2" },
                { "name": "Academia", "id": "12" }
            ]
        }
    },
    {
        "id": "9",
        "mainImage": "https://picsum.photos/400/262?random=9",
        "details": {
            "name": "Quarto Queen",
            "country": "Portugal",
            "city": "Porto",
            "suburb": "Centro/Baixa",
            "distances": {
                "from_center": "0,6km"
            },
            "booking_rating": "9,1",
            "daily_price": "340,00",
            "daily_price_number": 340,
            "amenities": [
                { "name": "Estacionamento", "id": "1" },
                { "name": "Wi-fi grátis", "id": "2" },
                { "name": "Bar", "id": "7" }
            ]
        }
    },
    {
        "id": "10",
        "mainImage": "https://picsum.photos/400/262?random=10",
        "details": {
            "name": "Suíte Presidencial",
            "country": "Portugal",
            "city": "Porto",
            "suburb": "Centro/Baixa",
            "distances": {
                "from_center": "0,3km"
            },
            "booking_rating": "9,0",
            "daily_price": "360,00",
            "daily_price_number": 360,
            "amenities": [
                { "name": "Estacionamento", "id": "1" },
                { "name": "Wi-fi grátis", "id": "2" },
                { "name": "Piscina", "id": "8" }
            ]
        }
    },
    {
        "id": "11",
        "mainImage": "https://picsum.photos/400/262?random=11",
        "details": {
            "name": "Quarto Triplo",
            "country": "Portugal",
            "city": "Porto",
            "suburb": "Centro/Baixa",
            "distances": {
                "from_center": "0,4km"
            },
            "booking_rating": "9,4",
            "daily_price": "490,00",
            "daily_price_number": 490,
            "amenities": [
                { "name": "Estacionamento", "id": "1" },
                { "name": "Wi-fi grátis", "id": "2" },
                { "name": "Restaurante", "id": "9" }
            ]
        }
    },
    {
        "id": "12",
        "mainImage": "https://picsum.photos/400/262?random=12",
        "details": {
            "name": "Quarto Individual",
            "country": "Portugal",
            "city": "Porto",
            "suburb": "Centro/Baixa",
            "distances": {
                "from_center": "0,5km"
            },
            "booking_rating": "8,7",
            "daily_price": "280,00",
            "daily_price_number": 280,
            "amenities": [
                { "name": "Estacionamento", "id": "1" },
                { "name": "Wi-fi grátis", "id": "2" },
                { "name": "Academia", "id": "12" }
            ]
        }
    }
];
const displayInitialCards = resultsCardsArray.slice(0, 4);

const filterAmenitiesOptions = [
    {
        "id": "1",
        "label": "Estacionamento",
        "value": "estacionamento"
    },
    {
        "id": "2",
        "label": "Wi-Fi gratuito",
        "value": "wifi_gratuito"
    },
    {
        "id": "3",
        "label": "Ar-condicionado",
        "value": "ar_condicionado"
    },
    {
        "id": "4",
        "label": "TV a cabo",
        "value": "tv_cabo"
    },
    {
        "id": "5",
        "label": "Serviço de quarto",
        "value": "servico_quarto"
    },
    {
        "id": "6",
        "label": "Frigobar",
        "value": "frigobar"
    },
    {
        "id": "7",
        "label": "Cofre",
        "value": "cofre"
    },
    {
        "id": "8",
        "label": "Banheira",
        "value": "banheira"
    },
    {
        "id": "9",
        "label": "Secador de cabelo",
        "value": "secador_cabelo"
    },
    {
        "id": "10",
        "label": "Varanda",
        "value": "varanda"
    },
    {
        "id": "11",
        "label": "Vista para o mar",
        "value": "vista_mar"
    },
    {
        "id": "12",
        "label": "Cama king size",
        "value": "cama_king_size"
    },
    {
        "id": "13",
        "label": "Café da manhã incluído",
        "value": "cafe_manha_incluido"
    },
    {
        "id": "14",
        "label": "Mesa de trabalho",
        "value": "mesa_trabalho"
    }
];
const displayedAmenitiesItems = showAllAmenities ? filterAmenitiesOptions : filterAmenitiesOptions.slice(0, 5);

const filterPOIOptions = [
    {
        "id": "1",
        "label": "Praia próxima",
        "value": "praia_proxima"
    },
    {
        "id": "2",
        "label": "Shopping center",
        "value": "shopping_center"
    },
    {
        "id": "3",
        "label": "Restaurantes",
        "value": "restaurantes"
    },
    {
        "id": "4",
        "label": "Parques",
        "value": "parques"
    },
    {
        "id": "5",
        "label": "Museus",
        "value": "museus"
    },
    {
        "id": "6",
        "label": "Teatros",
        "value": "teatros"
    },
    {
        "id": "7",
        "label": "Atrações turísticas",
        "value": "atracoes_turisticas"
    },
    {
        "id": "8",
        "label": "Vida noturna",
        "value": "vida_noturna"
    },
    {
        "id": "9",
        "label": "Parques temáticos",
        "value": "parques_tematicos"
    },
    {
        "id": "10",
        "label": "Zoológico",
        "value": "zoologico"
    },
    {
        "id": "11",
        "label": "Monumentos históricos",
        "value": "monumentos_historicos"
    },
    {
        "id": "12",
        "label": "Centros de convenções",
        "value": "centros_convencoes"
    },
    {
        "id": "13",
        "label": "Estádios",
        "value": "estadios"
    },
    {
        "id": "14",
        "label": "Universidades",
        "value": "universidades"
    }
];
const displayedPOIItems = showAllPOI ? filterPOIOptions : filterPOIOptions.slice(0, 5);

const filterFoodOptions = [
    {
        "id": "1",
        "label": "Café da manhã incluído",
        "value": "cafe_manha_incluido"
    },
    {
        "id": "2",
        "label": "Café e jantar incluídos",
        "value": "cafe_jantar_incluidos"
    },
    {
        "id": "3",
        "label": "Refeições all inclusive",
        "value": "refeicoes_all_inclusive"
    },
    {
        "id": "4",
        "label": "Restaurante no hotel",
        "value": "restaurante_no_hotel"
    },
    {
        "id": "5",
        "label": "Serviço de quarto 24 horas",
        "value": "servico_quarto_24h"
    },
    {
        "id": "6",
        "label": "Bar",
        "value": "bar"
    },
    {
        "id": "7",
        "label": "Cafeteria",
        "value": "cafeteria"
    },
    {
        "id": "8",
        "label": "Lanchonete",
        "value": "lanchonete"
    },
    {
        "id": "9",
        "label": "Buffet de café da manhã",
        "value": "buffet_cafe_manha"
    },
    {
        "id": "10",
        "label": "Menu infantil",
        "value": "menu_infantil"
    },
    {
        "id": "11",
        "label": "Opções veganas",
        "value": "opcoes_veganas"
    },
    {
        "id": "12",
        "label": "Opções sem glúten",
        "value": "opcoes_sem_gluten"
    },
    {
        "id": "13",
        "label": "Opções kosher",
        "value": "opcoes_kosher"
    },
    {
        "id": "14",
        "label": "Room service",
        "value": "room_service"
    }
];
const displayedFoodItems = showAllFood ? filterFoodOptions : filterFoodOptions.slice(0, 5);


const breadcrumbItems = [...breadcrumbArray];
 
const handleTimeout = () => {

	setTimeout(function() {

		setLoadingResults(false);

		setAlertTitle("Resultados não encontrados");
		setAlertBody("Infelizmente não encontramos resultados para a busca. Por favor, revise os dados e tente novamente.");
		setAlert(true);	

	},5000);

};
 
const handleFilterTimeout = () => {

	setTimeout(function() {

		setLoadingFiltering(false);

	},3000);

};

const handleCardClick = (id) => {
	//window.open(`/order/hotel/property/${id}`, '_blank', 'noopener,noreferrer');
	console.log(`NAVITAGE TO >>>> /order/hotel/property/${id}`);
	//navigate(`/order/hotel/property/${id}`);
};

const handleCardImageClick = (id,e) => {

	// Avoid onclick event on parent
	e.stopPropagation();

	//window.open(`/order/hotel/property/${id}`, '_blank', 'noopener,noreferrer');
	console.log(`OPEM IMAGE GALLERY FOR PROPERTY ID >>>> ${id}`);
	
	setSelectedItem(id);
	
	setDialog("ImageGalleryViewModal",true)
	//navigate(`/order/hotel/property/${id}`);
};

const itemTemplate = (item) => {
    return <img src={item.itemImageSrc} alt={item.alt} style={{ width: '100%',height:"auto",borderRadius:"1rem",padding:"0.5rem"}} />;
};

const tabHeaderTemplate = (item,options) => {

    var label = "N/A";

    if (item == 'rooms') {
        label = "Opções de quarto";
    } else if (item == 'location') {
        label = "Localização";
    } else if (item == 'policy') {
        label = "Taxas e políticas";
    }

    return (
        <div className="flex-row align-items-center justify-center" style={{ padding:"1rem",cursor: 'pointer',color: "#1D2939",fontFamily: "Poppins",fontSize: "1.5rem",fontStyle: "normal", fontWeight: 500,lineHeight: "2.12938rem" }} onClick={options.onClick}>
            <span className="font-bold white-space-nowrap">{label}</span>
        </div>
    );
};

  return (
	<>
		{(loadingResults && !loadingFiltering) &&
		<>
		<div className="wrapper" style={{backgroundColor:"#00000066",zIndex:"999999",position:"fixed",left:0,top:0,display:"flex",justifyContent:"center",alignItems:"center",width:"100%",height:"100vh"}}>

				<div style={{backgroundColor:"#FFFFFF",display:"flex",justifyContent:"center",alignItems:"center",width:"50%",height:"45vh",borderRadius:"60px",padding:"30px"}}>

					<div style={{flex:1,display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",width:"30vw"}}>
						<ProgressSpinner />
						<div className="formTitle" style={{marginBottom:"15px",marginTop:"15px"}}>Procurando hotéis...Aguarde! </div>
						<div className="formSubTitle">Na DREAM4 você encontra os melhores quartos em hotéis 4 estrelas...</div>		

						<div style={{marginTop:"30px",display:"flex",flexDirectin:"row",justifyContent:"center",alignItems:"cnter",gap:"15px"}}>
							
							<div style={{display:"flex",justifyContent:"center",alignItems:"center"}}><div style={{flex:1}}><Countdown startTime={60} onTimeout={handleTimeout} /></div></div>
							
							{showCancelButton &&
								<Button label="Cancelar busca" outlined rounded className="pT10 mR10 headerButton" style={{float:"unset"}} onClick={() => {setLoadingResults(false)}}  />
							}

							</div>	
					</div>

				</div>

		</div>
		</>
		}

		{(!loadingResults && loadingFiltering) &&
			<>
			<div className="wrapper" style={{backgroundColor:"#00000066",zIndex:"999999",position:"fixed",left:0,top:0,display:"flex",justifyContent:"center",alignItems:"center",width:"100%",height:"100vh"}}>
	
					<div style={{backgroundColor:"#FFFFFF",display:"flex",justifyContent:"center",alignItems:"center",width:"50%",height:"45vh",borderRadius:"60px",padding:"30px"}}>
	
						<div style={{flex:1,display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",width:"30vw"}}>
							<ProgressSpinner />
							<div className="formTitle" style={{marginBottom:"15px",marginTop:"15px"}}>Aplicando filtros...Aguarde! </div>
							<div className="formSubTitle">DREAM4: hotéis 4 estrelas que você pode sonhar...</div>		
	
						</div>
	
					</div>
	
			</div>
			</>
		}		

	
	<div id="navigationSection" style={{width:"100%",margin:"0",paddingTop:"0px",paddingBottom:"0px",position:"relative",borderRadius:0}} className={isTabletOrMobile ? "login__wrapper mobile" : "login__wrapper"} >

			<div style={{minHeight:"600px",height:"auto"}} className={isTabletOrMobile ? "login__wrapper_form_wrapper mobile" : "login__wrapper_form_wrapper"}> 		
				<div className={isTabletOrMobile ? "login__form_wrapper mobile" : "login__form_wrapper"} style={{alignItems:"flex-start"}}>
							
					<div style={{flex:1,minHeight:"594px",marginLeft:"auto",marginRight:"auto"}}>
						
					<div className="searchWrapper" style={{padding: "3rem",paddingTop:"1.8rem",display: "flex",flexDirection: "column",justifyContent: "center",alignItems: "flex-start",gap: "1.8rem",width: "100%"}}>

                        <div className="backAndsearchFields w100" style={{marginBottom:"1rem"}}>

                                <div className="searchWrapperInput" style={{flex:"1",width:"100%",marginTop:"20px"}}>

                                    <div container style={{width:"100%",maxWidth:"1440px",display:"flex",flexDirection:"row",justifyContent:"end",alignItems:"center",gap:"10px"}}>

                                        <div style={{flex:"1",textAlign:"left"}}>
                                            <Button icon="pi pi-arrow-left" label={searchType == 'location' ? "Voltar para Resultados" : "Fazer nova busca"} text className="pT10 mR10 backButton" onClick={searchType == 'location' ? ()=>{navigate("/order/hotel/searchResult")} : ()=>{navigate("/order")}} style={{color:"#814DE5"}} /> 
                                        </div>                                      

                                        <div style={{flexWrap:"nowrap",flex:"0.7"}}>
                                            <span className="p-float-label">
                                                <Calendar value={dateRange}  minDate={new Date()}  onChange={(e) => setDateRange(e.value)}  locale="br"  id="rangeDate" style={{width:"100%"}} inputStyle={{width:"100%"}} showIcon dateFormat="dd/mm/yy" selectionMode="range" readOnlyInput hideOnRangeSelection numberOfMonths={2} footerTemplate={customFooter} ref={calendarRef} />
                                                <label htmlFor="companyEmail">Data de chegada e de saída<span className="requiredField">*</span></label>

                                                {/* Rende input oiver orioginal to show number of nights */}
                                                {dateRange &&
                                                <input 
                                                    type="text" 
                                                    value={formattedValue} 
                                                    readOnly 
                                                    style={{ position: "absolute",top: "1px",left: "4px",height: "95%",width: "86%",border: "none",background: "transparent",pointerEvents: "none",paddingLeft: "1rem",fontSize: "1rem",color: "#495057",padding: ".75rem .75rem",appearance: "none",borderRadius: "6px",backgroundColor: "#FFF", fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'"}} 
                                                />
                                                }

                                            </span>
                                        </div>
                                        <div style={{flexWrap:"nowrap",flex:"0.4"}}>
                                            <span className="p-float-label">
                                                <InputText id="hosts" autoComplete="new-password" disabled={saving} className="w100" value={hostsAndRooms} onChange={(e) => setHostsAndRooms(e.target.value)} readOnly onClick={(e) => overlayPanelRef.current.toggle(e)}  />

                                                    <OverlayPanel ref={overlayPanelRef}  dismissable={true} showCloseIcon onHide={() => handleCheckSelectedOptions(rooms)}  style={{maxWidth:"18vw",width:"18vw",maxHeight:"65vh"}} >
                                                        <RoomSelector onSelection={handleRoomSelection} setRooms={setRooms} initialRooms={rooms} updateInputValue={handleUpdateInputValue} overlay={true} />
                                                    </OverlayPanel>										

                                                <label htmlFor="companyPhone">Hospedes e quartos <span className="requiredField">*</span></label>
                                            </span>

                                        </div>

                                        <div style={{flexWrap:"nowrap"}}>
                                            <Button label="Atualizar informações" outlined rounded className="pT10 mR10 updateButton" onClick={()=>{setLoadingResults(true);handleTimeout()}} style={{color:"#814DE5",borderColor:"#814DE5"}} />
                                        </div>							
                                        
                                    </div>
                                    
                                    
                                </div>												  
                                    


                        </div>
		
						<div id="titleBox" className="titleBox selectBoxTitleOld animateOld flex-row justify-center align-items-center w100" style={{marginBottom:"0px",color: "#1D2939",fontSize: "2rem",fontStyle: "normal",fontWeight: 700,lineHeight: "2.12938rem",fontFamily:"Poppins",flex:"1",gap:"10px",maxWidth:"1440px"}} >
							
							<div style={{textAlign:"left",flex:1}}>


                                <div  className="resultsCard_details__left flex-row justify-start align-items-center" style={{gap:"1rem",flex:1}}>

                                    <div className="resultsCard_details__name" style={{color: "#1D2939",fontFamily: "Poppins",fontSize: "1.5rem",
                                    fontStyle: "normal",fontWeight: 700,lineHeight: "normal",textAlign:"left"}}>{item.details.name}</div>

                                    <div className="resultsCard_details__location" style={{color: "#878787",fontFamily: "Poppins",fontSize: "1.5rem",fontStyle: "normal",fontWeight: 400,lineHeight: "normal",textAlign:"left"}}>{item.details.country}, {item.details.city} </div>

                                    <div className="resultsCard_details__ratingValue" style={{display: "flex",width: "3.875rem",height: "auto",padding: "0.625rem",flexDirection: "column",justifyContent: "center",alignItems: "center",gap: "0.625rem",borderRadius: "1.8125rem",background: "#00A3FF",color: "#FFF",fontFamily: "Titillium Web",fontSize: "1rem",fontStyle: "normal",fontWeight: 600,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>{item.details.booking_rating}</div>                                    

                                </div>


                            </div>
							<div style={{textAlign:"right"}}>

								<div style={{width:"100%",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",marginBottom:"0px"}}> 
										<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '14px',lineHeight: '120%',letterSpacing: '0.1em',color: '#814DE5',marginBottom:"10px"}}><ProgressBar className={counterValue < 10 ? "timeBar animate" : "timeBar"} showValue={false} value={counterValue} style={{height:"6px"}}></ProgressBar></div>
										
										<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '14px',lineHeight: '120%',letterSpacing: '0.1em', display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
										
											<div style={{flex:1,display:"flex"}}>	
												<div>{counterLabel}</div> 
												<div className="tooltipTime" data-pr-tooltip="Busca e preços expiram no tempo apresentado,sendo necessário fazer nova busca após expirar o tempo." data-pr-position="bottom"  data-pr-showdelay="300"><img src={tooltipIcon} style={{width:"20px",height:"auto",marginLeft:"10px"}} className="noSelect" /></div> <Tooltip target=".tooltipTime" style={{maxWidth:"200px",fontWeight:"normal",fontSize:"14px"}} />
											</div>
										
										</div>

								</div>	

							</div>
							
														
						</div>


						<div className="navigationContentWrapper flex-row justify-center align-items-start" style={{maxWidth:"1440px",marginTop:"0",width:"100%",flex:1,gap:"1rem"}}>

									<div className="resultsWrapper flex-column justify-center align-items-center" style={{gap:"1rem",flex:1}}>
								
                                        {/* HOTEL DETAILS - START */}
                                        <div className="resultsCard flex-row justify-center align-items-stretch w100" style={{gap:"1rem",padding:"2rem",borderRadius: "1.87rem",background: "#FFFFFF"}}>

                                            <div className="flex-column justify-center align-items-center" style={{gap:"2rem",flex:1}}>

                                                <div className="resultsCard_image" style={{width:"38rem",height:"25rem",overflow:"hidden",display:"flex",justifyContent:"center",alignItems:"center",position:"relative"}}>

                                                    <div style={{flex:1}}>
                                                        <Galleria  ref={galleria}   activeIndex={activeIndex} onItemChange={(e) => setActiveIndex(e.index)}  circular value={images} style={{display:"flex",justifyContent:"start",alignItems:"center",borderRadius:"1rem"}} showThumbnails={false}  showItemNavigators showIndicatorsOnItem={false}  item={itemTemplate} />

                                                        <i className="pi pi-search-plus imagePlusIcon applyPointer"  onClick={(e)=>handleCardImageClick(item.id,e)}></i>
                                                    </div>                                                    

                                                </div>

                                                <div  className="resultsCard_details__left flex-column justify-start align-items-start w100" style={{gap:"1rem",flex:1,paddingRight:"2.5rem"}}>

                                                    <div className="resultsCard_details__name" style={{color: "#1D2939",fontFamily: "Poppins",fontSize: "1.1rem",
                                                    fontStyle: "normal",fontWeight: 600,lineHeight: "normal",textAlign:"left"}}>{item.details.name}
                                                        
                                                        {item.details.distances && (<div className="resultsCard_details__distance" style={{color: "#878787",fontFamily: "Titillium Web",fontSize: "0.9rem",fontStyle: "normal",fontWeight: 500,lineHeight: "normal",letterSpacing: "0.02875rem"}}>{item.details.distances.from_center ? item.details.distances.from_center+" do centro" : ""}</div>)}                                                    
                                                    
                                                    </div>

                                                    <div className="resultsCard_details__description" style={{color: "#444",fontFamily: "Poppins", fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "normal",textAlign:"left"}}>
                                                    
                                                        O <strong>GA Palace Hotel & Spa</strong>, localizado no coração de Porto, Portugal, é uma propriedade cinco estrelas situada em uma villa histórica do século XIX. Este hotel combina charme histórico com modernas comodidades, proporcionando uma estadia luxuosa e confortável para seus hóspedes. {!descriptionDetail && (<span className="applyPointer" style={{color: "#814DE5",fontFamily: "Poppins",fontSize: "0.8125rem",fontStyle: "normal",fontWeight: 500,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}} onClick={()=>setDescriptionDetail(true)}>Ver mais</span>)}

                                                        {descriptionDetail && (<>
                                                        <h3>Acomodações</h3>
                                                        <p>Os quartos e suítes do GA Palace Hotel & Spa são desenhados para oferecer conforto e funcionalidade:</p>
                                                        <ul>
                                                            <li><strong>Suite Palace</strong>: Conta com cama king-size, capacidade para duas crianças, vista para a piscina e a cidade, banheiro privativo com produtos de banho, ar condicionado, TV LCD, minibar, secador de cabelo e Wi-Fi.</li>
                                                            <li><strong>Quarto Deluxe com Varanda</strong>: Este quarto luminoso oferece uma cama de casal, banheiro privativo, comodidades para café e chá, minibar, TV LCD e Wi-Fi, além de vistas agradáveis sobre a cidade e os jardins.</li>
                                                            <li><strong>Quarto Deluxe</strong>: Ideal para duas pessoas, disponível com duas camas de solteiro ou uma cama de casal king-size, luz natural e vistas para a cidade e jardins, além das comodidades modernas.</li>
                                                            <li><strong>Quarto King com Terraço</strong>: Espaço amplo e privado com cama de casal king-size de dossel, banheiro privativo, ar condicionado, TV LCD, minibar e Wi-Fi.</li>
                                                            <li><strong>Quarto Queen Prestige</strong>: Equipado com uma cama de casal queen-size, banheiro privativo, ar condicionado, TV LCD, minibar e Wi-Fi.</li>
                                                        </ul>

                                                        <h3>Comodidades</h3>
                                                        <p>O hotel oferece uma gama de comodidades para garantir uma estadia confortável e relaxante:</p>
                                                        <ul>
                                                            <li><strong>Spa e Bem-Estar</strong>: Inclui uma variedade de tratamentos de relaxamento, massagens, sauna, piscina coberta aquecida e uma academia totalmente equipada.</li>
                                                            <li><strong>Restaurante e Bar</strong>: O Acqua Bar & Restaurant serve pratos da culinária portuguesa e internacional, com opção de serviço de quarto para refeições privadas.</li>
                                                            <li><strong>Serviços de Hotel</strong>: Disponibiliza recepção 24 horas, serviço de quarto gratuito, check-in antecipado e check-out tardio (sujeitos a disponibilidade), e Wi-Fi gratuito em todas as áreas do hotel.</li>
                                                        </ul>

                                                        <h3>Localização</h3>
                                                        <p>O GA Palace Hotel & Spa está idealmente situado para explorar as atrações culturais e históricas de Porto. Os hóspedes têm fácil acesso a locais como a Praça da Batalha, a Estação de São Bento e a Torre dos Clérigos. A proximidade com vinícolas renomadas também oferece oportunidades para degustações de vinhos do Porto.</p>

                                                        <h3>Eventos e Reuniões</h3>
                                                        <p>Para eventos e reuniões, o hotel dispõe de espaços versáteis e bem equipados, ideais para conferências, reuniões de negócios, casamentos e outras celebrações. O suporte técnico e os serviços de catering personalizados garantem que cada evento seja um sucesso.</p>

                                                        <p>Com suas instalações de alta qualidade e localização privilegiada, o GA Palace Hotel & Spa é uma escolha excelente para quem procura uma experiência de luxo e conveniência em Porto.</p></>)
                                                        }

                                                        {descriptionDetail && (<span className="applyPointer" style={{color: "#814DE5",fontFamily: "Poppins",fontSize: "0.8125rem",fontStyle: "normal",fontWeight: 500,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}} onClick={()=>setDescriptionDetail(false)}>Ver menos</span>)}
                                                                                                        
                                                    </div>

                                                  
                                                    <div className="resultsCard_details__amenities">
                                                        <ul style={{listStyleType: "none",textAlign:"left", columns: (item.details.amenities.length > 4) ? 3 : 1, padding: 0,lineHeight: "200%",color: "#878787",fontFamily: "Poppins",fontSize: "0.8rem",fontStyle: "normal",fontWeight: 400,columnGap:"60px",margin:0}}>	

                                                        {item.details.amenities.map((itemInner, indexInner) =>(<li ><i style={{color:"#00A3FF"}} className="pi pi-check"></i> {itemInner.name}</li>))}

                                                        <li style={{marginTop:"0.5rem",marginLeft:"0.5rem"}}> <span className="applyPointer" style={{color: "#814DE5",fontFamily: "Poppins",fontSize: "0.8125rem",fontStyle: "normal",fontWeight: 500,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}} onClick={()=>setDescriptionDetail(true)}>Ver mais</span></li>
                                                       
                                                        </ul>
                                                    </div>                                                
                                                

                                                </div>


                                            </div>

                                            <div className="resultsCard_details flex-row justify-start align-items-stretch" style={{gap:"1rem",flex:1}}>

                                                <div  className="resultsCard_details__left flex-column justify-start align-items-start" style={{gap:"1rem",flex:1}}>

                                                    <div>

                                                        <div className="imageGallery grid" style={{ width:"100%",display:"flex",justifyContent:"space-evenly",alignItems:"start",flexDirection:"row",flexWrap:"wrap",gap:"0.75rem",padding:"0.5rem"}}>
                                                        {
                                                            images && images.map((image, index) => {
                                                                let imgEl = <img src={image.thumbnailImageSrc} alt={image.alt} style={{ cursor: 'pointer',width:"100%",height:"auto",flex:1 }} onClick={
                                                                    () => {setActiveIndex(index); galleria.current.show()}
                                                                } />
                                                                return (
                                                                    <div className={activeIndex == index ? "imageGallery_item selected" : "imageGallery_item"} key={index} style={{borderRadius:"1rem",width: "6.5rem",height:"5rem",overflow:"hidden", display:"flex",justifyContent:"center",alignItems:"center"}}>
                                                                        {imgEl}
                                                                    </div>
                                                                )
                                                            })
                                                        }

                                                            <div onClick={(e)=>handleCardImageClick(1,e)} className="imageGallery_item applyPointer" style={{backgroundColor:"#814DE555",borderRadius:"1rem",/*width: "4.875rem",height:"4.625rem"*/width: "6.5rem",height:"5rem",overflow:"hidden", display:"flex",justifyContent:"center",alignItems:"center",position:"relative"}}>
                                                                        <div className="imagePlusIcon" style={{fontFamily: "Titillium Web",fontSize: "0.9rem",fontStyle: "normal",fontWeight: 500,lineHeight: "normal",letterSpacing: "0.02875rem"}}>mais 37 fotos</div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                    
                                                    <div style={{padding:"0.5rem",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",gap:"1rem"}}>

                                                        <div className="filterMapImage applyPointer" style={{position:"relative",width:"100%",height:"auto",borderRadius:"1rem",display:"flex",alignIitems:"center",justifyContent:"center",overflow:"hidden",position:"relative"}}>
                                                            <img src={locationImage} style={{flex:1,width:"100%",height:"100%"}} className="noSelect" />	

                                                           {/* <Button label="Mostrar no mapa" rounded style={{position:"absolute",left:"50%",bottom:"1rem",transform:"translateX(-50%)"}} className="pT10 mR10 headerButton tColorWhite" onClick={()=>{setDialog("mapviewModal",true)}} />*/}

                                                            <i className="pi pi-search-plus addressPlusIcon" onClick={()=>{setDialog("mapviewModal",true)}}></i>

                                                        </div>
                                                        <div style={{color: "#878787",fontFamily: "Poppins",fontSize: "0.75",fontStyle: "normal",fontWeight: 400,lineHeight: "normal",textAlign:"left"}}>
                                                            {item.details.address}
                                                        </div>
                                                      

                                                    </div>


                                                    <div className="flex-column justify-center align-items-center w100" style={{gap:"1.5rem",textAlign:"left"}}>

                                                        <div className="flex-row jutify-center align-items-center" style={{color: "#1D2939",fontFamily: "Poppins", fontSize: "1.125rem",fontStyle: "normal",fontWeight: 400,
                                                      lineHeight: "normal",textAlign:"left",width:"100%"}}>
                                                            <div style={{flex:1}}>O que há nos arredores</div>
                                                            <div className="applyPointer" style={{alignSelf:"end",marginLeft:"0.5rem",color: "#814DE5",fontFamily: "Poppins",fontSize: "0.8125rem",fontStyle: "normal",fontWeight: 500,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}} onClick={()=>console.log("Abre Modal locais")}>Ver mais</div>
                                                        </div>

                                                        <div className="flex-column justify-center align-items-center w100" style={{gap:"1rem"}}>

                                                                <div className="flex-row justify-center align-items-start w100" style={{gap:"0.5rem"}}>
                                                                    <div>                                                                     
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5334 20.1756C9.5071 18.3783 5 13.9884 5 10.5C5 6 8 3 12.5 3C17 3 20 6.75 20 10.5C20 13.4266 15.432 18.1804 13.426 20.1269C12.8989 20.6383 12.0828 20.6629 11.5334 20.1756ZM15 9.5C15 10.8807 13.8807 12 12.5 12C11.1193 12 10 10.8807 10 9.5C10 8.11929 11.1193 7 12.5 7C13.8807 7 15 8.11929 15 9.5Z" fill="#00A3FF"/>
                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5334 20.1756C9.5071 18.3783 5 13.9884 5 10.5C5 6 8 3 12.5 3C17 3 20 6.75 20 10.5C20 13.4266 15.432 18.1804 13.426 20.1269C12.8989 20.6383 12.0828 20.6629 11.5334 20.1756ZM15 9.5C15 10.8807 13.8807 12 12.5 12C11.1193 12 10 10.8807 10 9.5C10 8.11929 11.1193 7 12.5 7C13.8807 7 15 8.11929 15 9.5Z" fill="#v"/>
                                                                        </svg>   
                                                                    </div>
                                                                    <div style={{flex:1,color: "#878787",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "normal"}}>Bairro da Liberdade</div>
                                                                    <div style={{color: "#00A3FF",textAlign: "right",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "normal"}}>720m</div>
                                                                </div>

                                                                <div className="flex-row justify-center align-items-start w100" style={{gap:"0.5rem"}}>
                                                                    <div>                                                                     
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5334 20.1756C9.5071 18.3783 5 13.9884 5 10.5C5 6 8 3 12.5 3C17 3 20 6.75 20 10.5C20 13.4266 15.432 18.1804 13.426 20.1269C12.8989 20.6383 12.0828 20.6629 11.5334 20.1756ZM15 9.5C15 10.8807 13.8807 12 12.5 12C11.1193 12 10 10.8807 10 9.5C10 8.11929 11.1193 7 12.5 7C13.8807 7 15 8.11929 15 9.5Z" fill="#00A3FF"/>
                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5334 20.1756C9.5071 18.3783 5 13.9884 5 10.5C5 6 8 3 12.5 3C17 3 20 6.75 20 10.5C20 13.4266 15.432 18.1804 13.426 20.1269C12.8989 20.6383 12.0828 20.6629 11.5334 20.1756ZM15 9.5C15 10.8807 13.8807 12 12.5 12C11.1193 12 10 10.8807 10 9.5C10 8.11929 11.1193 7 12.5 7C13.8807 7 15 8.11929 15 9.5Z" fill="#v"/>
                                                                        </svg>   
                                                                    </div>
                                                                    <div style={{flex:1,color: "#878787",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "normal"}}>Praça da República</div>
                                                                    <div style={{color: "#00A3FF",textAlign: "right",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "normal"}}>450m</div>
                                                                </div>

                                                                <div className="flex-row justify-center align-items-start w100" style={{gap:"0.5rem"}}>
                                                                    <div>                                                                     
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5334 20.1756C9.5071 18.3783 5 13.9884 5 10.5C5 6 8 3 12.5 3C17 3 20 6.75 20 10.5C20 13.4266 15.432 18.1804 13.426 20.1269C12.8989 20.6383 12.0828 20.6629 11.5334 20.1756ZM15 9.5C15 10.8807 13.8807 12 12.5 12C11.1193 12 10 10.8807 10 9.5C10 8.11929 11.1193 7 12.5 7C13.8807 7 15 8.11929 15 9.5Z" fill="#00A3FF"/>
                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5334 20.1756C9.5071 18.3783 5 13.9884 5 10.5C5 6 8 3 12.5 3C17 3 20 6.75 20 10.5C20 13.4266 15.432 18.1804 13.426 20.1269C12.8989 20.6383 12.0828 20.6629 11.5334 20.1756ZM15 9.5C15 10.8807 13.8807 12 12.5 12C11.1193 12 10 10.8807 10 9.5C10 8.11929 11.1193 7 12.5 7C13.8807 7 15 8.11929 15 9.5Z" fill="#v"/>
                                                                        </svg>   
                                                                    </div>
                                                                    <div style={{flex:1,color: "#878787",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "normal"}}>Rua 25 de Março</div>
                                                                    <div style={{color: "#00A3FF",textAlign: "right",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "normal"}}>1.2km</div>
                                                                </div>

                                                                <div className="flex-row justify-center align-items-start w100" style={{gap:"0.5rem"}}>
                                                                    <div>                                                                     
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5334 20.1756C9.5071 18.3783 5 13.9884 5 10.5C5 6 8 3 12.5 3C17 3 20 6.75 20 10.5C20 13.4266 15.432 18.1804 13.426 20.1269C12.8989 20.6383 12.0828 20.6629 11.5334 20.1756ZM15 9.5C15 10.8807 13.8807 12 12.5 12C11.1193 12 10 10.8807 10 9.5C10 8.11929 11.1193 7 12.5 7C13.8807 7 15 8.11929 15 9.5Z" fill="#00A3FF"/>
                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5334 20.1756C9.5071 18.3783 5 13.9884 5 10.5C5 6 8 3 12.5 3C17 3 20 6.75 20 10.5C20 13.4266 15.432 18.1804 13.426 20.1269C12.8989 20.6383 12.0828 20.6629 11.5334 20.1756ZM15 9.5C15 10.8807 13.8807 12 12.5 12C11.1193 12 10 10.8807 10 9.5C10 8.11929 11.1193 7 12.5 7C13.8807 7 15 8.11929 15 9.5Z" fill="#v"/>
                                                                        </svg>   
                                                                    </div>
                                                                    <div style={{flex:1,color: "#878787",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "normal"}}>São Paulo (GRU-Guarulhos - Aeroporto Internacional Governador André Franco Montoro</div>
                                                                    <div style={{color: "#00A3FF",textAlign: "right",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "normal"}}>12.5km</div>
                                                                </div>


                                                        </div>


                                                    </div>




                                                </div>

                                            </div>

                                        </div>
                                        {/* HOTEL DETAILS - END */}                                

                                        {/* ROOMS AND TABS - START */}
                                        <div className="resultsCard flex-row justify-center align-items-stretch w100" style={{gap:"1rem",padding:"0rem",borderRadius: "1.87rem",background: "transparent"}}>

                                            <div className="flex-column justify-center align-items-center" style={{flex:1}}>

                                            <TabView className="customTab w100" style={{flex:1}}>

                                                <TabPanel headerTemplate={(options)=>tabHeaderTemplate('rooms',options)} headerClassName="customTab flex-row align-items-center justify-center w100" style={{backgroundColor:"transparent !important"}}>

                                                <div className="resultsWrapper flex-column justify-center align-items-center w100" style={{paddingTop:"1rem",backgroundColor:"transparent",gap:"1rem",flex:1}}>

                                                    {resultsCards.map((item, index) =>{

                                                        return (
                                                            <div key={item.id} className="resultsCard flex-row justify-center align-items-stretch w100" style={{height:"17rem",gap:"1.5rem",padding:"1.5rem",borderRadius: "2.5rem",background: "#FFFFFF",position:"relative"}}>


                                                                {/* Room person selector START */}
                                                                <div id={`cardPanel_${item.id}`} className={visiblePanel === item.id ? 'highIndex cardPanel flex-row justify-center align-items-stretch fade-in' : 'lowIndex cardPanel flex-row justify-center align-items-stretch fade-out'} style={{top:"0",right:"0",position:"absolute",width:"17rem",height:"100%",borderRadius:"0rem 2.5rem 2.5rem 0rem",backgroundColor:"#FFF",boxShadow:"0px 0px 84px 0px rgba(56, 102, 128, 0.16)"}}>

                                                                    <Button id={`close_${item.id}`} icon="pi pi-times" rounded  onClick={handleClose} style={{fontsize:"0.45",position:"absolute",top:"-1rem",left:"-1rem",zIndex:"9999",height:"1rem",width:"1rem",padding:"1rem"}} />
    
                                                                    <div className="flex-column align-items-stretch" style={{gap:"0.5rem",padding:"1.5rem",flex:1,justifyContent:"space-between"}}>

                                                                        <div className="flex-column justify-center align-items-center" style={{gap:"0.2rem"}}>
                                                                            <div style={{"color":"#1D2939","textAlign":"center","fontFamily":"Poppins","fontSize":"0.9rem","fontStyle":"normal","fontWeight":"500","lineHeight":"120%"}}>Selecione a quantidade de pessoas no quarto</div>
                                                                            <div style={{"color":"#878787","textAlign":"center","fontFamily":"Poppins","fontSize":"0.775rem","fontStyle":"normal","fontWeight":"400","lineHeight":"normal"}}>(quarto acomoda até 3 pessoas)</div>    
                                                                        </div>
                                                                        
                                                                        
                                                                        <div>

                                                                            <div className="room-controls">
                                                                                <div className="room-each-control">
                                                                                    <h4 className="prevent-select">Adultos</h4>
                                                                                    <div className="control-buttons">
                                                                                        <Button icon="pi pi-minus" onClick={() => console.log("OK")} />
                                                                                        <span className="quantity prevent-select">1</span>
                                                                                        <Button icon="pi pi-plus" onClick={() => console.log("OK")} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="room-each-control">
                                                                                    <h4 className="prevent-select">Crianças</h4>
                                                                                    <div className="control-buttons">
                                                                                        <Button icon="pi pi-minus" onClick={() => console.log("OK")} />
                                                                                        <span className="quantity prevent-select">0</span>
                                                                                        <Button icon="pi pi-plus" onClick={() => console.log("OK")} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        
                                                                        <div>

                                                                            <Button label="Confirmar seleção" rounded className="pT10 tColorWhite" onClick={() => handleClose(item.id)} style={{fontsize:"0.85",float:"unset"}} />

                                                                        </div>


                                                                    </div>
                                                                    

                                                                </div>
                                                                {/* Room person selector END */}


                                                                <div  className="resultsCard_image" style={{width:"12rem",height:"12rem",overflow:"hidden",display:"flex",justifyContent:"center",alignItems:"center",borderRadius:"1.5rem",position:"relative"}}>

                                                                    <img src={item.mainImage} style={{flex:1}} />

                                                                </div>

                                                                <div className="resultsCard_details flex-row justify-start align-items-stretch" style={{gap:"1rem",flex:1}}>

                                                                    <div  className="resultsCard_details__left flex-column justify-start align-items-start" style={{gap:"0.3rem",flex:1}}>

                                                                        <div className="resultsCard_details__name" style={{color: "#1D2939",fontFamily: "Poppins",fontSize: "1.1rem",
                                                                        fontStyle: "normal",fontWeight: 600,lineHeight: "normal",textAlign:"left"}}>{item.details.name}</div>

                                                                        <div className="resultsCard_details__amenities">
                                                                            <ul style={{listStyleType: "none",textAlign:"left", columns: (item.details.amenities.length > 4) ? 1 : 1, padding: 0,lineHeight: "200%",color: "#878787",fontFamily: "Poppins",fontSize: "0.8rem",fontStyle: "normal",fontWeight: 400}}>																	
                                                                            {item.details.amenities.map((itemInner, indexInner) =>(<li ><i style={{color:"#00A3FF"}} className="pi pi-check"></i> {itemInner.name}</li>))}

                                                                            <li style={{marginTop:"0.5rem",marginLeft:"0.5rem"}}> <span className="applyPointer" style={{color: "#814DE5",fontFamily: "Poppins",fontSize: "0.8125rem",fontStyle: "normal",fontWeight: 500,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}} onClick={()=>console.log("SEE ALL")}>Ver tudo</span></li>

                                                                            </ul>
                                                                        </div>

                                                                    </div>

                                                                    <div  className="resultsCard_details__right flex-column justify-start align-items-start" style={{gap:"1rem",flex:1.1}}>

                                                                        <div className="flex-column justify-center align-items-center resultsCard_details__rating w80" style={{display: "flex",justifyContent: "start",alignItems: "start",flex:1,gap:"0.5rem"}}>

                                                                            <div className="w100 ">
                                                                                    <div className="flex-row justify-center align-items-center w100" style={{gap:"0.5rem"}}>
                                                                                    <div style={{flex:1,textAlign:"left",color: "#878787",fontFamily: "Poppins",fontSize: "0.85rem",fontStyle: "normal",fontWeight: 400,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>Política de cancelamento</div>
                                                                                    </div>
                                                                            </div>
                                                                            <div className="w100 ">
                                                                                <div className="flex-row justify-center align-items-center w100" style={{gap:"1rem"}}>
                                                                                    <div className="flex-row justify-start align-items-center w100" style={{gap:"1rem",flex:1,textAlign:"left",color: "#878787",fontFamily: "Poppins",fontSize: "0.75rem",fontStyle: "normal",fontWeight: 400,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}> 
                                                                                        <RadioButton inputId="policy1" name="policy1" value="1" checked={true} />
                                                                                        <label htmlFor="policy1" className="ml-2">Até 15/10/24</label>
                                                                                    </div>
                                                                                    <div style={{color: "#00A3FF",textAlign: "right",fontSize: "0.75rem",fontStyle: "normal",lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>Gratuito</div>
                                                                                </div>
                                                                            </div>  
                                                                            <div className="w100 ">
                                                                                <div className="flex-row justify-center align-items-center w100" style={{gap:"0.5rem"}}>
                                                                                    <div className="flex-row justify-start align-items-center w100" style={{gap:"1rem",flex:1,textAlign:"left",color: "#878787",fontFamily: "Poppins",fontSize: "0.75rem",fontStyle: "normal",fontWeight: 400,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>
                                                                                        <RadioButton inputId="policy2" name="policy1" value="2" />
                                                                                        <label htmlFor="policy2" className="ml-2">Até 25/10/24</label>
                                                                                    </div>
                                                                                    <div style={{color: "#00A3FF",textAlign: "right",fontSize: "0.75rem",fontStyle: "normal",lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>+25,00 &euro;</div>
                                                                                </div>
                                                                            </div>  

                                                                        </div>

                                                                        <div className="flex-column justify-center align-items-center resultsCard_details__rating w80" style={{display: "flex",justifyContent: "start",alignItems: "start",flex:1}}>

                                                                            <div className="w100 ">
                                                                                    <div className="flex-row justify-center align-items-center w100" style={{gap:"0.5rem"}}>
                                                                                    <div style={{flex:1,textAlign:"left",color: "#878787",fontFamily: "Poppins",fontSize: "0.85rem",fontStyle: "normal",fontWeight: 400,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>Extras</div>
                                                                                    </div>
                                                                            </div>
                                                                            <div className="w100 ">
                                                                                <div className="flex-row justify-center align-items-center w100" style={{gap:"0.5rem"}}>
                                                                                    <div style={{flex:1,textAlign:"left",color: "#878787",fontFamily: "Poppins",fontSize: "0.75rem",fontStyle: "normal",fontWeight: 400,lineHeight: "1.625rem",letterSpacing: "0.02875rem",textWrap:"nowrap"}}><i style={{color:"#00A3FF"}} className="pi pi-check"></i> Café da manhã</div>
                                                                                    <div style={{flex:1,color: "#00A3FF",textAlign: "right",fontSize: "0.75rem",fontStyle: "normal",lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>Incluído</div>
                                                                                </div>
                                                                            </div>                                                                        

                                                                        </div>

                                                                    </div>

                                                                    <div  className="resultsCard_details__right flex-column justify-start align-items-center" style={{gap:"1rem",flex:1}}>

                                                                        <div className="resultsCard_details__rating w100" style={{display: "flex",justifyContent: "end",alignItems: "start",flex:1}}>
                                                                  
                                                                        </div>


                                                                        <div className="resultsCard_details__prices flex-column justify-start align-items-center w100" style={{gap:"0.5rem",textAlign:"right"}}>

                                                                                <div className="resultsCard_details__prices___dailyPrice w100" style={{color: "#878787",fontFamily: "Titillium Web",fontSize: "0.85rem",fontStyle: "normal",fontWeight: 400,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>Diária: {formatNumberCurrency(item.details.daily_price_number)}  <br /> 5 diárias, 1 quarto</div>
                                                                            
                                                                                <div className="resultsCard_details__prices___dailyPrice w100" style={{color:" #00A3FF",fontFamily: "Poppins",fontSize: "1.5rem",fontStyle: "normal",fontWeight: 600,lineHeight: "2.12938rem"}}><span style={{fontSize: "0.9375rem"}}>Total </span> {formatNumberCurrency(item.details.daily_price_number * 5)}</div>	

                                                                                <div style={{marginTop:"1rem",textAlign:"right",width:"100%"}}>

                                                                                    {item.details.stock < 4 &&
                                                                                        <div className="resultsCard_details__prices___dailyPrice w100" style={{color: "red",fontFamily: "Titillium Web",fontSize: "0.8rem",fontStyle: "normal",fontWeight: 400,lineHeight: "1.625rem",letterSpacing: "0.02875rem",marginBottom:"0.5rem"}}>* Apenas 3 quartos disponíveis</div>
                                                                                    }    

                                                                                    <Button id={`select_${item.id}`}  label="Selecionar opção" rounded className="pT10 tColorWhite headerButton" onClick={() => handleSelect(item.id)}  style={{fontsize:"0.85",float:"unset"}} />
                                                                                </div>	                                                                                 				
                                                                        </div>

                                                                    </div>
                                                                    
                                                                </div>

                                                            </div>

                                                        );
                                                    })}

                                                    {/* LOADING ACTION  */}
                                                    {contentLoading &&
                                                        <>						
                                                            <div className="flex-row justify-center align-items-center" width="100%" height="16rem" style={{width: "100%",height: "16rem",borderRadius: "1.5rem",gap:"1rem",marginBottom:"1rem"}} >
                                                                <Skeleton width="25rem" height="15rem" style={{width: "25rem",height: "15rem",borderRadius: "1.5rem"}} ></Skeleton>
                                                                <Skeleton height="15rem" tyle={{flex:1,borderRadius: "1.5rem",height: "15rem"}} ></Skeleton>
                                                            </div>

                                                            <div className="flex-row justify-center align-items-center" width="100%" height="16rem" style={{width: "100%",height: "16rem",borderRadius: "1.5rem",gap:"1rem",marginBottom:"1rem"}} >
                                                                <Skeleton width="25rem" height="15rem" style={{width: "25rem",height: "15rem",borderRadius: "1.5rem"}} ></Skeleton>
                                                                <Skeleton height="15rem" tyle={{flex:1,borderRadius: "1.5rem",height: "15rem"}} ></Skeleton>
                                                            </div>

                                                            <div className="flex-row justify-center align-items-center" width="100%" height="16rem" style={{width: "100%",height: "16rem",borderRadius: "1.5rem",gap:"1rem",marginBottom:"1rem"}} >
                                                                <Skeleton width="25rem" height="15rem" style={{width: "25rem",height: "15rem",borderRadius: "1.5rem"}} ></Skeleton>
                                                                <Skeleton height="15rem" tyle={{flex:1,borderRadius: "1.5rem",height: "15rem"}} ></Skeleton>
                                                            </div>

                                                            <div className="flex-row justify-center align-items-center" width="100%" height="16rem" style={{width: "100%",height: "16rem",borderRadius: "1.5rem",gap:"1rem",marginBottom:"1rem"}} >
                                                                <Skeleton width="25rem" height="15rem" style={{width: "25rem",height: "15rem",borderRadius: "1.5rem"}} ></Skeleton>
                                                                <Skeleton height="15rem" tyle={{flex:1,borderRadius: "1.5rem",height: "15rem"}} ></Skeleton>
                                                            </div>

                                                            <div className="flex-row justify-center align-items-center" width="100%" height="16rem" style={{width: "100%",height: "16rem",borderRadius: "1.5rem",gap:"1rem",marginBottom:"1rem"}} >
                                                                <Skeleton width="25rem" height="15rem" style={{width: "25rem",height: "15rem",borderRadius: "1.5rem"}} ></Skeleton>
                                                                <Skeleton height="15rem" tyle={{flex:1,borderRadius: "1.5rem",height: "15rem"}} ></Skeleton>
                                                            </div>
                                                        </>				
                                                    }										


                                                    </div>

                                                </TabPanel>

                                                <TabPanel headerTemplate={(options)=>tabHeaderTemplate('location',options)} headerClassName="customTab flex-row align-items-center justify-center w100">
                                               
                                                    <div className="flex-row justify-center align-items-stretch w100" style={{padding:"2rem",backgroundColor:"transparent",gap:"3rem",flex:1}}>


                                                        <div className="flex-column justify-start align-items-center w100" style={{gap:"1.5rem",textAlign:"left",flex:0.7}}>


                                                            <div className="flex-row jutify-center align-items-center" style={{color: "#1D2939",fontFamily: "Poppins", fontSize: "1.125rem",fontStyle: "normal",fontWeight: 400,
                                                        lineHeight: "normal",textAlign:"left",width:"100%"}}>
                                                                <div style={{flex:1}}>Endereço</div>
                                                            </div>

                                                            <div className="flex-column justify-center align-items-center w100" style={{gap:"1rem",marginBottom:"1rem"}}>

                                                                <div style={{flex:1,color: "#878787",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "normal",width:"100%",texxtAlign:"left"}}>R. dos Andradas 600, Porto,União de Freguesias do Centro, Portugal</div>                                                                   

                                                            </div> 


                                                            <div className="flex-row jutify-center align-items-center" style={{color: "#1D2939",fontFamily: "Poppins", fontSize: "1.125rem",fontStyle: "normal",fontWeight: 400,
                                                        lineHeight: "normal",textAlign:"left",width:"100%"}}>
                                                                <div style={{flex:1}}>O que há nos arredores</div>
                                                            </div>

                                                            <div className="flex-column justify-center align-items-center w100" style={{gap:"1rem"}}>

                                                                    <div className="flex-row justify-center align-items-start w100" style={{gap:"0.5rem"}}>
                                                                        <div>                                                                     
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5334 20.1756C9.5071 18.3783 5 13.9884 5 10.5C5 6 8 3 12.5 3C17 3 20 6.75 20 10.5C20 13.4266 15.432 18.1804 13.426 20.1269C12.8989 20.6383 12.0828 20.6629 11.5334 20.1756ZM15 9.5C15 10.8807 13.8807 12 12.5 12C11.1193 12 10 10.8807 10 9.5C10 8.11929 11.1193 7 12.5 7C13.8807 7 15 8.11929 15 9.5Z" fill="#00A3FF"/>
                                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5334 20.1756C9.5071 18.3783 5 13.9884 5 10.5C5 6 8 3 12.5 3C17 3 20 6.75 20 10.5C20 13.4266 15.432 18.1804 13.426 20.1269C12.8989 20.6383 12.0828 20.6629 11.5334 20.1756ZM15 9.5C15 10.8807 13.8807 12 12.5 12C11.1193 12 10 10.8807 10 9.5C10 8.11929 11.1193 7 12.5 7C13.8807 7 15 8.11929 15 9.5Z" fill="#v"/>
                                                                            </svg>   
                                                                        </div>
                                                                        <div style={{flex:1,color: "#878787",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "normal"}}>Bairro da Liberdade</div>
                                                                        <div style={{color: "#00A3FF",textAlign: "right",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "normal"}}>720m</div>
                                                                    </div>

                                                                    <div className="flex-row justify-center align-items-start w100" style={{gap:"0.5rem"}}>
                                                                        <div>                                                                     
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5334 20.1756C9.5071 18.3783 5 13.9884 5 10.5C5 6 8 3 12.5 3C17 3 20 6.75 20 10.5C20 13.4266 15.432 18.1804 13.426 20.1269C12.8989 20.6383 12.0828 20.6629 11.5334 20.1756ZM15 9.5C15 10.8807 13.8807 12 12.5 12C11.1193 12 10 10.8807 10 9.5C10 8.11929 11.1193 7 12.5 7C13.8807 7 15 8.11929 15 9.5Z" fill="#00A3FF"/>
                                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5334 20.1756C9.5071 18.3783 5 13.9884 5 10.5C5 6 8 3 12.5 3C17 3 20 6.75 20 10.5C20 13.4266 15.432 18.1804 13.426 20.1269C12.8989 20.6383 12.0828 20.6629 11.5334 20.1756ZM15 9.5C15 10.8807 13.8807 12 12.5 12C11.1193 12 10 10.8807 10 9.5C10 8.11929 11.1193 7 12.5 7C13.8807 7 15 8.11929 15 9.5Z" fill="#v"/>
                                                                            </svg>   
                                                                        </div>
                                                                        <div style={{flex:1,color: "#878787",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "normal"}}>Praça da República</div>
                                                                        <div style={{color: "#00A3FF",textAlign: "right",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "normal"}}>450m</div>
                                                                    </div>

                                                                    <div className="flex-row justify-center align-items-start w100" style={{gap:"0.5rem"}}>
                                                                        <div>                                                                     
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5334 20.1756C9.5071 18.3783 5 13.9884 5 10.5C5 6 8 3 12.5 3C17 3 20 6.75 20 10.5C20 13.4266 15.432 18.1804 13.426 20.1269C12.8989 20.6383 12.0828 20.6629 11.5334 20.1756ZM15 9.5C15 10.8807 13.8807 12 12.5 12C11.1193 12 10 10.8807 10 9.5C10 8.11929 11.1193 7 12.5 7C13.8807 7 15 8.11929 15 9.5Z" fill="#00A3FF"/>
                                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5334 20.1756C9.5071 18.3783 5 13.9884 5 10.5C5 6 8 3 12.5 3C17 3 20 6.75 20 10.5C20 13.4266 15.432 18.1804 13.426 20.1269C12.8989 20.6383 12.0828 20.6629 11.5334 20.1756ZM15 9.5C15 10.8807 13.8807 12 12.5 12C11.1193 12 10 10.8807 10 9.5C10 8.11929 11.1193 7 12.5 7C13.8807 7 15 8.11929 15 9.5Z" fill="#v"/>
                                                                            </svg>   
                                                                        </div>
                                                                        <div style={{flex:1,color: "#878787",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "normal"}}>Rua 25 de Março</div>
                                                                        <div style={{color: "#00A3FF",textAlign: "right",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "normal"}}>1.2km</div>
                                                                    </div>

                                                                    <div className="flex-row justify-center align-items-start w100" style={{gap:"0.5rem"}}>
                                                                        <div>                                                                     
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5334 20.1756C9.5071 18.3783 5 13.9884 5 10.5C5 6 8 3 12.5 3C17 3 20 6.75 20 10.5C20 13.4266 15.432 18.1804 13.426 20.1269C12.8989 20.6383 12.0828 20.6629 11.5334 20.1756ZM15 9.5C15 10.8807 13.8807 12 12.5 12C11.1193 12 10 10.8807 10 9.5C10 8.11929 11.1193 7 12.5 7C13.8807 7 15 8.11929 15 9.5Z" fill="#00A3FF"/>
                                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5334 20.1756C9.5071 18.3783 5 13.9884 5 10.5C5 6 8 3 12.5 3C17 3 20 6.75 20 10.5C20 13.4266 15.432 18.1804 13.426 20.1269C12.8989 20.6383 12.0828 20.6629 11.5334 20.1756ZM15 9.5C15 10.8807 13.8807 12 12.5 12C11.1193 12 10 10.8807 10 9.5C10 8.11929 11.1193 7 12.5 7C13.8807 7 15 8.11929 15 9.5Z" fill="#v"/>
                                                                            </svg>   
                                                                        </div>
                                                                        <div style={{flex:1,color: "#878787",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "normal"}}>São Paulo (GRU-Guarulhos - Aeroporto Internacional Governador André Franco Montoro</div>
                                                                        <div style={{color: "#00A3FF",textAlign: "right",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "normal"}}>12.5km</div>
                                                                    </div>


                                                            </div> 

                                                        </div> 

                                                        <div className="flex-column justify-center align-items-center w100" style={{flex:1,height:"50vh",borderRadius:"10px",overflow:"hidden"}}>

                                                            {!center && 1 == 2 &&
                                                                    <div style={{backgroundColor:"transparent",display:"flex",justifyContent:"center",alignItems:"center",width:"50%",height:"45vh",padding:"30px"}}>

                                                                        <div style={{flex:1,display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",width:"20vw",textAlign:"center"}}>
                                                                            <ProgressSpinner />
                                                                            <div className="formTitle" style={{marginBottom:"15px",marginTop:"15px",fontSize:"2rem"}}>Carregando mapa...Aguarde! </div>
                                                                        </div>

                                                                    </div>           
                                                            }

                                                            {center &&
                                                            <div style={{ flex:1,height: 'inherit', width: 'inherit' }}>
                                                            <APIProvider apiKey={apiKey}>
                                                                <Map
                                                                defaultZoom={12}
                                                                /*center={{lat: parseFloat(coordinates.lat), lng:parseFloat(coordinates.lng)}}*/
                                                                defaultCenter={center}
                                                                gestureHandling={'greedy'}
                                                                disableDefaultUI={false}
                                                                fullscreenControlOptions={false}
                                                                mapTypeControl={false}
                                                                streetViewControl={false}
                                                                fullscreenControl={false}
                                                                > 
                                                                <Marker position={{lat: parseFloat(markerCenter.lat), lng:parseFloat(markerCenter.lng)}} />
                                                                </Map>
                                                            </APIProvider>
                                                            </div>
                                                            }

                                                        </div>




                                                    </div>

                                                </TabPanel>

                                                <TabPanel headerTemplate={(options)=>tabHeaderTemplate('policy',options)} headerClassName="customTab flex-row align-items-center justify-center w100">

                                                    <div className="flex-row justify-start align-items-stretch w100" style={{padding:"2rem",backgroundColor:"transparent",gap:"2rem",flex:1,flexWrap:"wrap"}}>


                                                        {[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15].map((item, index) =>{ 
                                                            
                                                            if (index === 0) {
                                                                return(
                                                                    <div className="flex-column justify-center align-items-stretch" style={{gap:"0.5rem",width:"100%"}}>
                                                                        <div style={{color:"#1D2939",fontFamily:"Poppins",fontSize:"1rem",fontStyle:"normal",fontWeight:"600",lineHeight:"normal",textAlign:"left"}}>Política de cancelamento</div>
                                                                        <div style={{color: "#878787",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "normal",textAlign:"left"}}>Each room in this reservation is subject to the following cancellation policy: Cancellations before 01/07/2025, 10:00 PM (Europe/Lisbon) are fully refundable. Bookings cancelled after 01/07/2025, 10:00 PM (Europe/Lisbon) are subject to a fee of 274.16 USD. There is no refund for no-shows, early checkouts, or cancellations after 01/15/2025, 10:00 AM (Europe/Lisbon).</div>
                                                                    </div>
                                                                )
                                                            } 
                                                           else if (index === 1) {
                                                                return(
                                                                    <div className="flex-column justify-center align-items-stretch" style={{gap:"0.5rem",width:"100%"}}>
                                                                        <div style={{color:"#1D2939",fontFamily:"Poppins",fontSize:"1rem",fontStyle:"normal",fontWeight:"600",lineHeight:"normal",textAlign:"left"}}>Taxas cobradas no local</div>
                                                                        <div style={{color: "#878787",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "normal",textAlign:"left"}}>Each room in this reservation is subject to the following cancellation policy: Cancellations before 01/07/2025, 10:00 PM (Europe/Lisbon) are fully refundable. Bookings cancelled after 01/07/2025, 10:00 PM (Europe/Lisbon) are subject to a fee of 274.16 USD. There is no refund for no-shows, early checkouts, or cancellations after 01/15/2025, 10:00 AM (Europe/Lisbon).</div>
                                                                    </div>
                                                                )
                                                            } 
                                                           else if (index === 2) {
                                                                return(
                                                                    <div className="flex-column justify-center align-items-stretch" style={{gap:"0.5rem",width:"100%"}}>
                                                                        <div style={{color:"#1D2939",fontFamily:"Poppins",fontSize:"1rem",fontStyle:"normal",fontWeight:"600",lineHeight:"normal",textAlign:"left"}}>Instruções de checkin</div>
                                                                        <div style={{color: "#878787",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "normal",textAlign:"left"}}>Guests are required to show a photo ID and credit card upon check-in. Please note that all Special Requests are subject to availability and additional charges may apply. In response to the coronavirus (COVID-19), additional safety and sanitation measures are in effect at this property. Food and beverage services at this property may be limited or unavailable due to the coronavirus (COVID-19). Due to the coronavirus (COVID-19), this property is taking steps to protect the safety of guests and staff. Certain services and amenities may be reduced or unavailable as a result. Due to the coronavirus (COVID-19), this property has reduced reception and service hours. In accordance with government guidelines to minimize transmission of the coronavirus (COVID-19), this property may request additional documentation from guests to validate identity, travel itinerary, and other relevant info on dates where such guidelines exist. Spa and gym facilities at this property are unavailable due to the coronavirus (COVID-19). Due to coronavirus (COVID-19), this property adheres to strict physical distancing measures. Due to the coronavirus (COVID-19), make sure you're booking this property in accordance with the destination's local government guidelines, including (but not limited to) the purpose of travel and maximum group size. Due to the coronavirus (COVID-19), wearing a face mask is mandatory in all indoor common areas.</div>
                                                                    </div>
                                                                )
                                                            } else {
                                                            return(
                                                                <div className="flex-column justify-center align-items-stretch" style={{gap:"0.5rem",width:"100%"}}>
                                                                    <div style={{color:"#1D2939",fontFamily:"Poppins",fontSize:"1rem",fontStyle:"normal",fontWeight:"600",lineHeight:"normal",textAlign:"left"}}>Regra ou Taxa</div>
                                                                    <div style={{color: "#878787",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "normal",textAlign:"left"}}>Lorem ipsum dolor sit amet consectetur. Arcu purus vitae bibendum sit dignissim</div>
                                                                </div>
                                                            )
                                                            }

                                                        }

                                                        )}
                                                                                                                                       
                                                    </div>
                                                </TabPanel>
                                            </TabView>

                                            </div>

                                        </div>                                
                                         {/* ROOMS AND TABS - END */}


									</div>
                                    
                                    {/* RIGHT COLUMN WITH CART DETAILS*/}
									<div className="filterWrapper flex-column justify-center align-items-start" style={{width:"24rem",height:"auto",gap:"1.5rem"/*,position:"sticky",top:"90px"*/}}>

										<div className="filterOptions flex-column justify-center align-items-center" style={{width:"100%",height:"auto",borderRadius:"1rem",backgroundColor:"#FFFFFF",padding:"1rem",gap:"2rem"}}>

											<div className="filterOptions_title w100" style={{color:"#1D2939",fontFamily: "Poppins",fontSize: "1.5rem",fontStyle: "normal",fontWeight: 500,lineHeight: "2.12938rem",textAlign:"left",marginTop:"0.5rem"}}>Resumo das seleções</div>

											{/* DATE GUESTS BOX */}
											<div className="filterOptions_priceFilter flex-column justify-center align-items-center w100" style={{gap:"0.5rem"}}>

												<div className="filterOptions_priceFilter__wrapper flex-column justify-center align-items-center" style={{width:"100%",gap:"0.5rem"}}>
												
													<div className="filterOptions_priceFilter__options flex-column justify-center align-items-center" style={{gap:"1rem",width:"100%"}}>

                                                        <div className="flex-row justify-center align-items-center w100" style={{gap:"0.5rem",paddingBottom:"1rem",borderBottom:"1px solid #F8F8F8"}}>
                                                            <div style={{flex:1,textAlign:"left",color: "#475467",fontFamily: "Poppins",fontSize: "1.125rem",fontStyle: "normal",fontWeight: 400,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>Período</div>
                                                            <div style={{flex:1.5,flexWrap:"nowrap",textAlign:"right",color: "#475467",fontFamily: "Poppins",fontSize: "0.875rem",fontStyle: "normal",fontWeight: 400,lineHeight: "160.5%",letterSpacing: "0.0875rem"}}>01/10/24 até 12/10/24</div>
                                                        </div>
                                                                                                                
                                                        <div className="flex-row justify-center align-items-center w100" style={{gap:"0.5rem",paddingBottom:"1rem",borderBottom:"1px solid #F8F8F8"}}>
                                                            <div style={{flex:1,textAlign:"left",color: "#475467",fontFamily: "Poppins",fontSize: "1.125rem",fontStyle: "normal",fontWeight: 400,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>11 diárias</div>
                                                            <div style={{flex:1.5,color: "#00A3FF",textAlign: "right",fontSize: "0.9375rem",fontStyle: "normal",lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>130,00 &euro; / dia</div>
                                                        </div>
                                                        
                                                        <div className="flex-row justify-center align-items-center w100" style={{gap:"0.5rem",paddingBottom:"1rem",borderBottom:"1px solid #F8F8F8"}}>
                                                            <div style={{flex:1,textAlign:"left",color: "#475467",fontFamily: "Poppins",fontSize: "1.125rem",fontStyle: "normal",fontWeight: 400,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>Hospedes</div>
                                                            <div style={{flex:1.5,flexWrap:"nowrap",textAlign:"right",color: "#475467",fontFamily: "Poppins",fontSize: "0.875rem",fontStyle: "normal",fontWeight: 400,lineHeight: "160.5%",letterSpacing: "0.0875rem"}}>2 adultos, 1 criança (12)</div>
                                                        </div>

                                                        <div className="flex-row justify-center align-items-center w100" style={{gap:"0.5rem",paddingBottom:"1rem",borderBottom:"1px solid #F8F8F8"}}>
                                                            <div style={{flex:1,textAlign:"left",color: "#475467",fontFamily: "Poppins",fontSize: "1.125rem",fontStyle: "normal",fontWeight: 400,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>Quartos</div>
                                                            <div style={{flex:1.5,flexWrap:"nowrap",textAlign:"right",color: "#475467",fontFamily: "Poppins",fontSize: "0.875rem",fontStyle: "normal",fontWeight: 400,lineHeight: "160.5%",letterSpacing: "0.0875rem"}}>1 quarto</div>
                                                        </div>
                                                                                                                                                                        

                                                      

													</div>
												
												</div>

											</div>

											{/* ROOM BOX */}
											<div className="filterOptions_priceFilter flex-column justify-center align-items-center w100" style={{gap:"0.5rem"}}>

												<div className="filterOptions_priceFilter__wrapper flex-column justify-center align-items-center" style={{width:"100%",gap:"0.5rem"}}>
												
                                                    <div className="filterOptions_priceFilter__options" style={{color: "#475467", fontFamily: "Poppins", fontSize: "1.125rem", fontStyle: "normal", fontWeight: 500, lineHeight: "1.625rem",letterSpacing: "0.02875rem",width:"100%",textAlign:"left"}}>Quarto 1</div>

                                                    <div className="flex-row justify-center align-items-center w100" style={{gap:"0.5rem",paddingBottom:"1rem",borderBottom:"1px solid #F8F8F8"}}>
                                                            <div style={{flex:1,textAlign:"left",color: "#475467",fontFamily: "Poppins",fontSize: "0.875rem",fontStyle: "normal",fontWeight: 400,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>Double Classic</div>
                                                            <div style={{flex:1,textAlign:"right"}}><span className="applyPointer" style={{color: "#814DE5",fontFamily: "Poppins",fontSize: "0.8125rem",fontStyle: "normal",fontWeight: 500,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}} onClick={()=>console.log("remover")}>Remover</span></div>
                                                    </div>      

													<div className="filterOptions_priceFilter__options flex-column justify-center align-items-center" style={{padding:"0.5rem",gap:"0.5rem",width:"100%"}}>

                                                        <div className="flex-column justify-center align-items-center w100" style={{gap:"0.5rem",paddingBottom:"1rem",borderBottom:"1px solid #F8F8F8"}}>
                                                            <div className="w100 ">
                                                                <div className="flex-row justify-center align-items-center w100" style={{gap:"0.5rem"}}>
                                                                <div style={{flex:1,textAlign:"left",color: "#878787",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>Cancelamento</div>
                                                                </div>
                                                            </div>
                                                            <div className="w100 ">
                                                                <div className="flex-row justify-center align-items-center w100" style={{gap:"0.5rem"}}>
                                                                <div style={{flex:1,textAlign:"left",color: "#878787",fontFamily: "Poppins",fontSize: "0.85rem",fontStyle: "normal",fontWeight: 400,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>Até 15/10/2023</div>
                                                                <div style={{flex:1,color: "#00A3FF",textAlign: "right",fontSize: "0.85",fontStyle: "normal",lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>Gratuito</div>
                                                                </div>
                                                            </div>
                                                            <div className="w100 ">
                                                                <div className="flex-row justify-center align-items-center w100" style={{gap:"0.5rem"}}>
                                                                <div style={{flex:1,textAlign:"left",color: "#878787",fontFamily: "Poppins",fontSize: "0.85rem",fontStyle: "normal",fontWeight: 400,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>Após 15/10/2023</div>
                                                                <div style={{flex:1,color: "#00A3FF",textAlign: "right",fontSize: "0.85",fontStyle: "normal",lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>+100,00 &euro;</div>
                                                                </div>
                                                            </div>                                                            
                                                        </div>

                                                        <div className="flex-column justify-center align-items-center w100" style={{gap:"0.5rem",paddingBottom:"1rem",borderBottom:"1px solid #F8F8F8"}}>
                                                            <div className="w100 ">
                                                                <div className="flex-row justify-center align-items-center w100" style={{gap:"0.5rem"}}>
                                                                <div style={{flex:1,textAlign:"left",color: "#878787",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>Extras</div>
                                                                </div>
                                                            </div>
                                                            <div className="w100 ">
                                                                <div className="flex-row justify-center align-items-center w100" style={{gap:"0.5rem"}}>
                                                                <div style={{flex:1,textAlign:"left",color: "#878787",fontFamily: "Poppins",fontSize: "0.85rem",fontStyle: "normal",fontWeight: 400,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>Café da manhã</div>
                                                                <div style={{flex:1,color: "#00A3FF",textAlign: "right",fontSize: "0.85",fontStyle: "normal",lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>Incluído</div>
                                                                </div>
                                                            </div>                                                           
                                                        </div>   

                                                        <div className="flex-column justify-center align-items-center w100" style={{gap:"0.5rem",paddingBottom:"1rem",borderBottom:"1px solid #F8F8F8"}}>
                                                            <div className="w100 ">
                                                                <div className="flex-row justify-center align-items-center w100" style={{gap:"0.5rem"}}>
                                                                <div style={{flex:1,textAlign:"left",color: "#878787",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>Hospedes</div>
                                                                <div style={{flex:1,color: "#475467",textAlign: "right",fontSize: "0.85",fontStyle: "normal",lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>1 adulto</div>
                                                                </div>
                                                            </div>                                                           
                                                        </div>   

													</div>
												
												</div>


												<div className="filterOptions_priceFilter__wrapper flex-column justify-center align-items-center" style={{width:"100%",gap:"0.5rem"}}>
												
                                                    <div className="filterOptions_priceFilter__options" style={{color: "#475467", fontFamily: "Poppins", fontSize: "1.125rem", fontStyle: "normal", fontWeight: 500, lineHeight: "1.625rem",letterSpacing: "0.02875rem",width:"100%",textAlign:"left"}}>Quarto 2</div>

                                                    <div className="flex-row justify-center align-items-center w100" style={{gap:"0.5rem",paddingBottom:"1rem",borderBottom:"1px solid #F8F8F8"}}>
                                                            <div style={{flex:1,textAlign:"left",color: "#475467",fontFamily: "Poppins",fontSize: "0.875rem",fontStyle: "normal",fontWeight: 400,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>Double Platinum Master Class 4</div>
                                                            <div style={{flex:1,textAlign:"right"}}><span className="applyPointer" style={{color: "#814DE5",fontFamily: "Poppins",fontSize: "0.8125rem",fontStyle: "normal",fontWeight: 500,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}} onClick={()=>console.log("remover")}>Remover</span></div>
                                                    </div>      

													<div className="filterOptions_priceFilter__options flex-column justify-center align-items-center" style={{padding:"0.5rem",gap:"0.5rem",width:"100%"}}>

                                                        <div className="flex-column justify-center align-items-center w100" style={{gap:"0.5rem",paddingBottom:"1rem",borderBottom:"1px solid #F8F8F8"}}>
                                                            <div className="w100 ">
                                                                <div className="flex-row justify-center align-items-center w100" style={{gap:"0.5rem"}}>
                                                                <div style={{flex:1,textAlign:"left",color: "#878787",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>Cancelamento</div>
                                                                </div>
                                                            </div>
                                                            <div className="w100 ">
                                                                <div className="flex-row justify-center align-items-center w100" style={{gap:"0.5rem"}}>
                                                                <div style={{flex:1,textAlign:"left",color: "#878787",fontFamily: "Poppins",fontSize: "0.85rem",fontStyle: "normal",fontWeight: 400,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>Após 10/10/2023</div>
                                                                <div style={{flex:1,color: "#00A3FF",textAlign: "right",fontSize: "0.85",fontStyle: "normal",lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>+100,00 &euro;</div>
                                                                </div>
                                                            </div>                                                            
                                                        </div>

                                                        <div className="flex-column justify-center align-items-center w100" style={{gap:"0.5rem",paddingBottom:"1rem",borderBottom:"1px solid #F8F8F8"}}>
                                                            <div className="w100 ">
                                                                <div className="flex-row justify-center align-items-center w100" style={{gap:"0.5rem"}}>
                                                                <div style={{flex:1,textAlign:"left",color: "#878787",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>Extras</div>
                                                                </div>
                                                            </div>
                                                            <div className="w100 ">
                                                                <div className="flex-row justify-center align-items-center w100" style={{gap:"0.5rem"}}>
                                                                <div style={{flex:1,textAlign:"left",color: "#878787",fontFamily: "Poppins",fontSize: "0.85rem",fontStyle: "normal",fontWeight: 400,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>Café da manhã</div>
                                                                <div style={{flex:1,color: "#00A3FF",textAlign: "right",fontSize: "0.85",fontStyle: "normal",lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>+35,00 &euro; / pessoa</div>
                                                                </div>
                                                            </div>                                                           
                                                        </div>   

                                                        <div className="flex-column justify-center align-items-center w100" style={{gap:"0.5rem",paddingBottom:"1rem",borderBottom:"1px solid #F8F8F8"}}>
                                                            <div className="w100 ">
                                                                <div className="flex-row justify-center align-items-center w100" style={{gap:"0.5rem"}}>
                                                                <div style={{flex:1,textAlign:"left",color: "#878787",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>Hospedes</div>
                                                                <div style={{flex:1,color: "#475467",textAlign: "right",fontSize: "0.85",fontStyle: "normal",lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>2 adultos, 1 criança</div>
                                                                </div>
                                                            </div>                                                           
                                                        </div>   

													</div>
												
												</div>


												<div className="filterOptions_priceFilter__wrapper flex-column justify-center align-items-center" style={{width:"100%",gap:"0.5rem"}}>
  

													<div className="filterOptions_priceFilter__options flex-column justify-center align-items-center" style={{padding:"0.5rem",gap:"0.5rem",width:"100%"}}>

                                                        <div className="flex-column justify-center align-items-right w100">

                                                             <div className="resultsCard_details__prices___dailyPrice w100" style={{color:" #00A3FF",fontFamily: "Poppins",fontSize: "1.5rem",fontStyle: "normal",fontWeight: 600,lineHeight: "2.12938rem",textAlign:"right"}}><span style={{fontSize: "0.9375rem"}}>Total </span> {formatNumberCurrency(item.details.daily_price_number * 5)}</div>		

                                                            <div className="resultsCard_details__prices___dailyPrice w100" style={{color: "#878787",fontFamily: "Titillium Web",fontSize: "0.85rem",fontStyle: "normal",fontWeight: 400,lineHeight: "1.625rem",letterSpacing: "0.02875rem",textAlign:"right"}}>inclui impostos e taxas</div>

                                                            <div style={{marginTop:"1rem"}}>
                                                                <Button label="Iniciar reserva" rounded className="pT10 mR10 headerButton tColorWhite w100" onClick={()=>{console.log("RESERVAR")}} />
                                                            </div>	                                                            
                                                            
                                                        </div>   

													</div>
												
												</div>



											</div>


										</div>

									</div>
			                                    

						</div>

					</div>				
					
					</div>

				</div> 
			</div>

    </div>	

		<ImageGalleryModal
			propertyId={selectedItem}
			setSelectedItem={setSelectedItem}
			scrollBlock={true}				
		/>

		<MapViewModal
			type="address" // or coordinates
			address={addressLocationText} // set if type is address		
			coordinates={null} // set if type is coordinates		
			scrollBlock={true}				
		/>

		<AlertView
			alert={alert}
			setAlert={setAlert}
			alertTitle={alertTitle}
			alertBody={alertBody}	
			scrollBlock={false}				
		/>

		<ExpireView
				alertExpire={alertExpire}
				setAlertExpire={setAlertExpire}
				alertTitleExpire={alertTitleExpire}
				alertBodyExpire={alertBodyExpire}	
				alertActionExpire={alertActionExpire}	
				scrollBlock={false}				
			/>	

		{/* Contac Us Section */}
		<ContactUs />

		{/* About Us Section */}
		<AboutUs />
	
	</>
  );
    
}

const mapStateToProps = store => ({
  dialog:store.appReducer.dialog,
  masterUser:store.appReducer.masterUser,
  currentAgency:store.appReducer.currentAgency,

  searchType:store.orderReducer.searchType,
  searchParams:store.orderReducer.searchParams,
  searchResultStatus:store.orderReducer.searchResultStatus,
  searchResultRowsNumber:store.orderReducer.searchResultRowsNumber,
  searchResultToken:store.orderReducer.searchResultToken,
  searchResultCurrentPage:store.orderReducer.searchResultCurrentPage,
  searchResultLimit:store.orderReducer.searchResultLimit,
  searchLocation:store.orderReducer.searchLocation,
  searchResultObj:store.orderReducer.searchResultObj
 });
const mapDispatchToProps = dispatch => bindActionCreators({ ...AppActions,...OrderActions }, dispatch);

export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(HotelDetails);	


