// src/components/HotelCards.js
import React, { useState, useEffect } from 'react';
import './AuthLogin.css';
import AgencySelector from './AgencySelector';

import { FaBuilding, FaUser } from 'react-icons/fa';

const AuthLogin = ({masterUser,agencyUser,agencyName,menuOpened,op,currentAgency,setCurrentAgencyAction}) => {

  return (
      <div className="user-card mousePointer">
        <div className="company-info"  onClick={!menuOpened ? (e) => {op.current.toggle(e);e.currentTarget.blur()} : (e) => {op.current.toggle(e);e.currentTarget.blur()} }>
            <div className="icon-wrapper">

                {!masterUser && 
                <>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5 4C5 2.89543 5.89543 2 7 2H17C18.1046 2 19 2.89543 19 4V21H13.5V18C13.5 17.4477 13.0523 17 12.5 17H11.5C10.9477 17 10.5 17.4477 10.5 18V21H5V4ZM8 5C8 4.44772 8.44772 4 9 4H10C10.5523 4 11 4.44772 11 5V6C11 6.55229 10.5523 7 10 7H9C8.44772 7 8 6.55228 8 6V5ZM14 4C13.4477 4 13 4.44772 13 5V6C13 6.55228 13.4477 7 14 7H15C15.5523 7 16 6.55229 16 6V5C16 4.44772 15.5523 4 15 4H14ZM8 9C8 8.44772 8.44772 8 9 8H10C10.5523 8 11 8.44772 11 9V10C11 10.5523 10.5523 11 10 11H9C8.44772 11 8 10.5523 8 10V9ZM9 12C8.44772 12 8 12.4477 8 13V14C8 14.5523 8.44772 15 9 15H10C10.5523 15 11 14.5523 11 14V13C11 12.4477 10.5523 12 10 12H9ZM13 13C13 12.4477 13.4477 12 14 12H15C15.5523 12 16 12.4477 16 13V14C16 14.5523 15.5523 15 15 15H14C13.4477 15 13 14.5523 13 14V13Z" fill="#D9D9D9"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5 4C5 2.89543 5.89543 2 7 2H17C18.1046 2 19 2.89543 19 4V21H13.5V18C13.5 17.4477 13.0523 17 12.5 17H11.5C10.9477 17 10.5 17.4477 10.5 18V21H5V4ZM8 5C8 4.44772 8.44772 4 9 4H10C10.5523 4 11 4.44772 11 5V6C11 6.55229 10.5523 7 10 7H9C8.44772 7 8 6.55228 8 6V5ZM14 4C13.4477 4 13 4.44772 13 5V6C13 6.55228 13.4477 7 14 7H15C15.5523 7 16 6.55229 16 6V5C16 4.44772 15.5523 4 15 4H14ZM8 9C8 8.44772 8.44772 8 9 8H10C10.5523 8 11 8.44772 11 9V10C11 10.5523 10.5523 11 10 11H9C8.44772 11 8 10.5523 8 10V9ZM9 12C8.44772 12 8 12.4477 8 13V14C8 14.5523 8.44772 15 9 15H10C10.5523 15 11 14.5523 11 14V13C11 12.4477 10.5523 12 10 12H9ZM13 13C13 12.4477 13.4477 12 14 12H15C15.5523 12 16 12.4477 16 13V14C16 14.5523 15.5523 15 15 15H14C13.4477 15 13 14.5523 13 14V13Z" fill="#00A3FF"/>
                    <rect x="13" y="8" width="3" height="3" rx="1" fill="white"/>
                    <g opacity="0.3">
                        <path d="M3 22.9881C3 21.8901 3.89009 21 4.98807 21H19.0119C20.1099 21 21 21.8901 21 22.9881C21 22.9947 20.9947 23 20.9881 23H3.01193C3.00534 23 3 22.9947 3 22.9881Z" fill="#D9D9D9"/>
                        <path d="M3 22.9881C3 21.8901 3.89009 21 4.98807 21H19.0119C20.1099 21 21 21.8901 21 22.9881C21 22.9947 20.9947 23 20.9881 23H3.01193C3.00534 23 3 22.9947 3 22.9881Z" fill="#00A3FF"/>
                    </g>
                </svg>               
                </>
                }

                {masterUser && 
                <>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4 4.00006L11.6314 2.56917C11.875 2.52349 12.125 2.52349 12.3686 2.56917L20 4.00006V11.9033C20 15.696 18.0462 19.2212 14.83 21.2313L12.53 22.6688C12.2057 22.8715 11.7943 22.8715 11.47 22.6688L9.17001 21.2313C5.95382 19.2212 4 15.696 4 11.9033L4 4.00006Z" fill="#00A3FF"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4 4.00006L11.6314 2.56917C11.875 2.52349 12.125 2.52349 12.3686 2.56917L20 4.00006V11.9033C20 15.696 18.0462 19.2212 14.83 21.2313L12.53 22.6688C12.2057 22.8715 11.7943 22.8715 11.47 22.6688L9.17001 21.2313C5.95382 19.2212 4 15.696 4 11.9033L4 4.00006Z" fill="#00A3FF"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.1758 14.75C10.9362 14.75 10.6966 14.6542 10.5049 14.4625L8.58828 12.5458C8.20495 12.1625 8.20495 11.5875 8.58828 11.2042C8.97161 10.8208 9.59453 10.8208 9.92995 11.2042L11.1758 12.45L14.3383 9.2875C14.7216 8.90417 15.2966 8.90417 15.6799 9.2875C16.0633 9.67083 16.0633 10.2458 15.6799 10.6292L11.8466 14.4625C11.6549 14.6542 11.4154 14.75 11.1758 14.75Z" fill="#00A3FF"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.1758 14.75C10.9362 14.75 10.6966 14.6542 10.5049 14.4625L8.58828 12.5458C8.20495 12.1625 8.20495 11.5875 8.58828 11.2042C8.97161 10.8208 9.59453 10.8208 9.92995 11.2042L11.1758 12.45L14.3383 9.2875C14.7216 8.90417 15.2966 8.90417 15.6799 9.2875C16.0633 9.67083 16.0633 10.2458 15.6799 10.6292L11.8466 14.4625C11.6549 14.6542 11.4154 14.75 11.1758 14.75Z" fill="white"/>
                </svg>               
                </>
                }

            </div>
            <div className="company-name">{agencyName}</div>
        </div>
        <div className="user-info"  onClick={!menuOpened ? (e) => {op.current.toggle(e);e.currentTarget.blur()} : (e) => {op.current.toggle(e);e.currentTarget.blur()} }>
            <div className="icon-wrapper">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                    <path d="M9 8C6.79086 8 5 6.20914 5 4C5 1.79086 6.79086 0 9 0C11.2091 0 13 1.79086 13 4C13 6.20914 11.2091 8 9 8Z" fill="#00A3FF"/>
                    <path d="M0.000651684 17.1992C0.388259 12.4265 4.26191 10 8.98334 10C13.7712 10 17.7049 12.2932 17.9979 17.2C18.0096 17.3955 17.9979 18 17.2467 18C13.5411 18 8.03472 18 0.727502 18C0.476712 18 -0.0204618 17.4592 0.000651684 17.1992Z" fill="#D9D9D9"/>
                    <path d="M0.000651684 17.1992C0.388259 12.4265 4.26191 10 8.98334 10C13.7712 10 17.7049 12.2932 17.9979 17.2C18.0096 17.3955 17.9979 18 17.2467 18C13.5411 18 8.03472 18 0.727502 18C0.476712 18 -0.0204618 17.4592 0.000651684 17.1992Z" fill="#00A3FF"/>
                </svg>                
            </div>
            <div className="user-name">{agencyUser}</div>
        </div>
        <div className="dropdown-icon"  onClick={!menuOpened ? (e) => {op.current.toggle(e);e.currentTarget.blur()} : (e) => {op.current.toggle(e);e.currentTarget.blur()} }>

            {menuOpened && 
                <>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <rect width="16" height="16" rx="8" transform="matrix(1 0 0 -1 0 16)" fill="#814DE5"/>
                    <path d="M11.62 9.39674C11.8407 9.66486 11.8407 10.0517 11.62 10.3199V10.3199C11.3297 10.6724 10.7899 10.6727 10.4993 10.3205L8 7.2915L5.50071 10.3205C5.21007 10.6727 4.67028 10.6724 4.38002 10.3199V10.3199C4.15927 10.0517 4.15927 9.66486 4.38002 9.39674L7.33192 5.81143C7.67808 5.39099 8.32192 5.39099 8.66808 5.81143L11.62 9.39674Z" fill="white"/>
                </svg>                
                </>
            }

            {!menuOpened && 
                <>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="16" height="16" rx="8" fill="#814DE5"/>
                    <path d="M11.62 6.60326C11.8407 6.33514 11.8407 5.94826 11.62 5.68014C11.3297 5.32759 10.7899 5.3273 10.4993 5.67954L8 8.7085L5.50071 5.67954C5.21007 5.3273 4.67028 5.32759 4.38002 5.68014C4.15927 5.94826 4.15927 6.33514 4.38001 6.60326L7.33192 10.1886C7.67808 10.609 8.32192 10.609 8.66808 10.1886L11.62 6.60326Z" fill="white"/>
                </svg>              
                </>
            }            

        </div>

        {/* Agency selector Component */}
        <AgencySelector masterUser={masterUser} currentAgency={currentAgency} setCurrentAgencyAction={setCurrentAgencyAction} />

     </div>
  );


}

export default AuthLogin;
