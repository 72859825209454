import React from 'react';
import './ImageGrid.css';

const getRandomImages = (num) => {
    const urls = [
        'https://picsum.photos/600/400?random=1',
        'https://picsum.photos/600/400?random=2',
        'https://picsum.photos/600/400?random=3',
        'https://picsum.photos/600/400?random=4',
        'https://picsum.photos/600/400?random=5',
    ];
    const shuffled = urls.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, num);
};

const randomImages = getRandomImages(5);

const ImageGrid = () => {
    return (
        <div className="image-grid">
            <div className="image-column image-column1">
                <div className="image-one"><img src={randomImages[0]} className="image-card-image" /></div>
                <div className="image-two"><img src={randomImages[1]} className="image-card-image image-two" /></div>
            </div>
            <div className="image-column image-column2">
                <div className="image-three"><img src={randomImages[2]} className="image-card-image image-three" /></div>
                <div className="image-four"><img src={randomImages[3]} className="image-card-image image-four" /></div>
            </div>
        </div>
    );
};

export default ImageGrid;
