import axios from 'axios';
import jwt_decode from "jwt-decode";
import * as types from '../actionTypes';
import * as config from '../../Config/api.js';
import { Navigate } from 'react-router-dom';
	

export const setSearchType = (obj) => {
    return {
        type: types.SET_SEARCHTYPE,
        payload: obj
    }
};

export const setSearchParams = (obj) => {
    return {
        type: types.SET_SEARCHPARAMS,
        payload: obj
    }
};

export const setSearchFilters = (obj) => {
    return {
        type: types.SET_SEARCHFILTERS,
        payload: obj
    }
};

export const setSearchCurrency = (obj) => {
    return {
        type: types.SET_SEARCHCURRENCY,
        payload: obj
    }
};

export const setExchangeRates = (obj) => {
    return {
        type: types.SET_EXCHANGERATES,
        payload: obj
    }
};

export const setSearchResStatus = (obj) => {
    return {
        type: types.SET_SEARCHRESSTATUS,
        payload: obj
    }
};

export const setSearchResRowsNumber = (obj) => {
    return {
        type: types.SET_SEARCHRESROWSNUMBER,
        payload: obj
    }
};

export const setSearchResToken = (obj) => {

    return {
        type: types.SET_SEARCHRESTOKEN,
        payload: obj
    }
};

export const setSearchResCurrentPage = (obj) => {
    return {
        type: types.SET_SEARCHRESCURRENTPAGE,
        payload: obj
    }
};

export const setSearchResLimit = (obj) => {
    return {
        type: types.SET_SEARCHRESLIMIT,
        payload: obj
    }
};

export const setSearchLocation = (obj) => {
    return {
        type: types.SET_SEARCHLOCATION,
        payload: obj
    }
};

export const setSearchResObject = (obj) => {
    return {
        type: types.SET_SEARCHRESOBJECT,
        payload: obj
    }
};



// Retrieve all reservations
export const retrieveDropdownCountries  = (data,props) => { 
		
	return dispatch => {
		
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = localStorage.getItem("@memovo/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
				// decode JWT 
				var decoded = jwt_decode(userObj.userToken);
							
				// Set general data
				const preparedData = {};
				preparedData.keyword =  data.keyword;				
				
				// Connect to Endpoint
				axios({
					   url    : config.API_URL+'/order/dropdown/retrieveCountries',
					   method : 'POST',
					   data   : preparedData,
					   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
				})							
				
				.then((response) => {

					// Document already registered
					if (response.data.notFound) {
						
						props.setRows([]);
						return;
						
					} // end if

					if (response.data.success) {
						
						// Set list
						props.setRows(response.data.data);				
						return;
				
						
					} // end if
					else {
						
						props.setRows([]);																				
						return;
						
					} // end else
					
				})
				.catch(error=> {
					
					props.setRows([]);						
					return;
				});
				
} // end dispatch

};


export const retrieveDropdownCities  = (data,props) => { 
		
	return dispatch => {
		
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = localStorage.getItem("@memovo/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
				// decode JWT 
				var decoded = jwt_decode(userObj.userToken);
							
				// Set general data
				const preparedData = {};
				preparedData.keyword =  data.keyword;				
				preparedData.countryCode =  data.countryCode;
				
				// Connect to Endpoint
				axios({
					   url    : config.API_URL+'/order/dropdown/retrieveCities',
					   method : 'POST',
					   data   : preparedData,
					   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
				})							
				
				.then((response) => {

					// Document already registered
					if (response.data.notFound) {
						
						props.setRows([]);
						return;
						
					} // end if

					if (response.data.success) {
						
						// Set list
						props.setRows(response.data.data);				
						return;
				
						
					} // end if
					else {
						
						props.setRows([]);																				
						return;
						
					} // end else
					
				})
				.catch(error=> {
					
					props.setRows([]);						
					return;
				});
				
} // end dispatch

};

export const retrieveDropdownProperties  = (data,props) => { 
		
	return dispatch => {
		
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = localStorage.getItem("@memovo/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
				// decode JWT 
				var decoded = jwt_decode(userObj.userToken);
							
				// Set general data
				const preparedData = {};
				preparedData.keyword =  data.keyword;				
				
				// Connect to Endpoint
				axios({
					   url    : config.API_URL+'/order/dropdown/retrieveProperties',
					   method : 'POST',
					   data   : preparedData,
					   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
				})							
				
				.then((response) => {

					// Document already registered
					if (response.data.notFound) {
						
						props.setRows([]);
						return;
						
					} // end if

					if (response.data.success) {
						
						// Set list
						props.setRows(response.data.data);				
						return;
				
						
					} // end if
					else {
						
						props.setRows([]);																				
						return;
						
					} // end else
					
				})
				.catch(error=> {
					
					props.setRows([]);						
					return;
				});
				
} // end dispatch

};


// Create search and return and store search token id for reference
export const processLocalitySearch  = (data,props) => { 
	
	return async (dispatch, getState) => {
		
		try {

				// Check if there is a local USER OBEJECt with a TOKEN
				var result = localStorage.getItem("@memovo/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
				// decode JWT 
				var decoded = jwt_decode(userObj.userToken);
							
				// Set general data
				const preparedData = {};
				preparedData.country =  data.country;		
				preparedData.city =  data.city;		
				preparedData.dateRange =  data.dateRange;		
				preparedData.dateRangeDays =  data.dateRangeDays;		
				preparedData.rooms =  data.rooms;		

				// set original room array to avoid obj change
				let originalRooms = [];
				for (let i = 0; i < data.rooms.length; i++) {
					let obj = {
						adults:data.rooms[i].adults,
						children:data.rooms[i].children
					}
					originalRooms.push(obj);
				}
				data.originalRooms = originalRooms;
				
				// Connect to Endpoint
				axios({
					   url    : config.API_URL+'/order/properties/search/create',
					   method : 'POST',
					   data   : preparedData,
					   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
				})							
				
				.then((response) => {

					//console.log('RESPONSE >>>>>> '+JSON.stringify(response.data,null,2));

					// Document already registered
					if (response.data.notFound) {
						
						props.handleTimeout('cancel');

						// For interface feedback
						props.setLoadingResults(false);

						props.setAlertTitle("Nenhuma propriedade encontrada!");
						props.setAlertBody("Não encontramos nenhuma propriedade disponível com as informações fornecidas. Verifique as datas e quartos e tente novamente.");
						props.setAlert(true);	

						return;
						
					} // end if
 
					if (response.data.success) {

						// Cancel timer
						props.handleTimeout('cancel');

						dispatch(setSearchResToken(response.data.searchResultToken));
						dispatch(setSearchResRowsNumber(response.data.searchResultRowsNumber));
						dispatch(setSearchType("location"));

						// Set search result filters for interface
						dispatch(setSearchFilters(response.data.interfaceFilters.searchFilter));

						// Set search currency for result interface
						dispatch(setSearchCurrency(response.data.currency));

						// Set exchange rates
						dispatch(setExchangeRates(response.data.exchangeRates));

						// set search location
						let location = {
							continent:data.country.continentName,
							country:data.country,
							city:data.city
						} 
						dispatch(setSearchLocation(location));
						dispatch(setSearchParams(data));

						props.setCounterStatus(true); // Stop counter

						// For interface feedback
						setTimeout(function() {
							props.setLoadingResults(false); 

							if (data.screen == 'searchResult') {
								props.executeRetrieveSearchResult(1,'review_rating',[],0,response.data.searchResultToken); // reset all controls
							} else {
								props.navigate("/order/hotel/searchResult");	
							}
													
						},1000);						
							
						return;
				
						
					} // end if
					else {
						
						// Cancel timer
						props.handleTimeout('cancel');

						// For interface feedback
						setTimeout(function() {
							props.setLoadingResults(false);
						},2000)						

						props.setAlertTitle("Ops! Ocorreu um erro!");
						props.setAlertBody("Ocorreu um erro ao realizar a busca com os dados informados. Tente novamente e caso o problema persista, entre em contato com o suporte. Erro: [Order 1]");
						props.setAlert(true);																		
						return;
						
					} // end else
					
				})
				.catch(error=> {

					// Cancel timer
					props.handleTimeout('cancel');

						// For interface feedback
						setTimeout(function() {
							props.setLoadingResults(false);
						},2000)					
					
					props.setAlertTitle("Ops! Ocorreu um erro!");
					props.setAlertBody("Ocorreu um erro ao realizar a busca. Tente novamente e caso o problema persista, entre em contato com o suporte. Erro: [Order 2]");
					props.setAlert(true);	
							
					return;
				});

			} catch (error) {
				console.error('Error fetching locality search:', error);

				// Cancel timer
				props.handleTimeout('cancel');

				dispatch(setSearchResToken(null));
				dispatch(setSearchResRowsNumber(0));

				props.setAlertTitle("Ops! Ocorreu um erro!");
				props.setAlertBody("Ocorreu um erro ao realizar a busca. Tente novamente e caso o problema persista, entre em contato com o suporte. Erro: [Order 3]");
				props.setAlert(true);	
				return;				
			}				
	
	} // end dispatch

};

export const retrieveSearchResult  = (data,props) => { 
	
	return async (dispatch, getState) => {
		
		try {

				// Check if there is a local USER OBEJECt with a TOKEN
				var result = localStorage.getItem("@memovo/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
				// decode JWT 
				var decoded = jwt_decode(userObj.userToken);
							
				// Set general data
				const preparedData = {};
				preparedData.searchResultToken =  data.searchResultToken;
				preparedData.page =  data.page;
				preparedData.sortBy =  data.sortBy;
				preparedData.filterBy =  data.filterBy;
				
				// Connect to Endpoint
				axios({
					   url    : config.API_URL+'/order/properties/search/retrieve',
					   method : 'POST',
					   data   : preparedData,
					   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
				})							
				
				.then((response) => {

					// console.log('RESPONSE >>>>>> '+JSON.stringify(response.data,null,2));

					// Document already registered
					if (response.data?.notFound || response.data?.noToken == true) {
						
						props.setContentLoading(false);
						props.setLoadingResults(false);
						
						props.setAlertExpire(true);
						props.setAlertTitleExpire("O resultado da busca expirou");
						props.setAlertBodyExpire("O resultado da busca expirou. Faça uma nova pesquisa, para ver preços e hospedagens atualizados.");
						props.setAlertActionExpire("restartSearch");

						return;
						
					} // end if

					if (response.data.success) {
						
						//dispatch(setSearchResToken(response.data.data.searchResultToken));
						//dispatch(setSearchResRowsNumber(response.data.data.searchResultRowsNumber));
						//dispatch(setSearchType("location"));

						props.setContentLoading(false);
						props.setLoadingResults(false);

						//props.setResultsCards(response.data.data.results);

						const newResults = response.data.data.results;
						//props.setResultsCards((prevResults) => [...prevResults, ...newResults]);

						props.setResultsCards((prevResults) => {
							// Concatena os itens antigos com os novos
							const allResults = [...prevResults, ...newResults];
						
							// Filtra para remover itens duplicados com base no "id"
							const uniqueResults = allResults.filter((item, index, self) => 
							  index === self.findIndex((t) => t._id === item._id)
							);

							// set current paginated results
							props.setPaginatedResults(uniqueResults.length);
						
							return uniqueResults;
						});						

						// For interface feedback
						//setTimeout(function() {
							//props.setLoadingResults(false);
							//props.navigate("/order/hotel/searchResult");							
						//},2000);						
							
						return;
				
						
					} // end if
					else {
						
						props.setContentLoading(false);
						props.setLoadingResults(false);

						// For interface feedback
						setTimeout(function() {
						//	props.setLoadingResults(false);
						},2000);						

						props.setAlertTitle("Ops! Ocorreu um erro!");
						props.setAlertBody("Ocorreu um erro ao realizar a busca. Tente novamente e caso o problema persista, entre em contato com o suporte. Erro: [1]");
						props.setAlert(true);																		
						return;
						
					} // end else
					
				})
				.catch(error=> {

					console.log('ERRO CATCH >>>>>>>>>> '+JSON.stringify(error.message,null,2));

					props.setContentLoading(false);
					props.setLoadingResults(false);

						// For interface feedback
						setTimeout(function() {
						//	props.setLoadingResults(false);
						},2000)					
					
					props.setAlertTitle("Ops! Ocorreu um erro!");
					props.setAlertBody("Ocorreu um erro ao realizar a busca. Tente novamente e caso o problema persista, entre em contato com o suporte. Erro: [2]");
					props.setAlert(true);	
							
					return;
				});

			} catch (error) {

				props.setContentLoading(false);
				props.setLoadingResults(false);

				console.error('Error fetching locality search:', error);
				props.setAlertTitle("Ops! Ocorreu um erro!");
				props.setAlertBody("Ocorreu um erro ao realizar a busca. Tente novamente e caso o problema persista, entre em contato com o suporte. Erro: [3]");
				props.setAlert(true);	
				return;				
			}				
	
	} // end dispatch

};

export const checkSearchResult  = (data,props) => { 
	
	return async (dispatch, getState) => {
		
		try {

				// Check if there is a local USER OBEJECt with a TOKEN
				var result = localStorage.getItem("@memovo/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
				// decode JWT 
				var decoded = jwt_decode(userObj.userToken);
							
				// Set general data
				const preparedData = {};
				preparedData.searchResultToken =  data.searchResultToken;
				
				// Connect to Endpoint
				axios({
					   url    : config.API_URL+'/order/properties/search/check',
					   method : 'POST',
					   data   : preparedData,
					   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
				})							
				
				.then((response) => {

					// Document already registered
					if (!response.data?.searchExist || response.data?.noToken) {
						
						props.setAlertExpire(true);
						props.setAlertTitleExpire("O resultado da busca expirou");
						props.setAlertBodyExpire("O resultado da busca expirou. Faça uma nova pesquisa, para ver preços e hospedagens atualizados.");
						props.setAlertActionExpire("restartSearch");
						return;
						
					} // end if
					
				})
				.catch(error=> {
					console.log('ERRO CATCH >>>>>>>>>> '+JSON.stringify(error.message,null,2));
				});

			} catch (error) {
				console.error('Error fetching locality search:', error);
				return;				
			}				
	
	} // end dispatch

};


export const deleteSearchResult  = (data,props) => { 
	
	return async (dispatch, getState) => {
		
		try {

				// Check if there is a local USER OBEJECt with a TOKEN
				var result = localStorage.getItem("@memovo/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
				// decode JWT 
				var decoded = jwt_decode(userObj.userToken);
							
				// Set general data
				const preparedData = {};
				preparedData.searchResultToken =  data.searchResultToken;
				preparedData.afterAction =  data.afterAction;
				
				// Connect to Endpoint
				axios({
					   url    : config.API_URL+'/order/properties/search/delete',
					   method : 'DELETE',
					   data   : preparedData,
					   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
				})							
				
				.then((response) => {

					//console.log('RESPONSE >>>>>> '+JSON.stringify(response.data,null,2));

					// Document already registered
					if (response.data?.notFound) {
						
						props.setContentLoading(false);
						
						props.setAlertExpire(true);
						props.setAlertTitleExpire("O resultado da busca expirou");
						props.setAlertBodyExpire("O resultado da busca expirou. Faça uma nova pesquisa, para ver preços e disponibilidade atualizados.");
						props.setAlertActionExpire("restartSearch");

						return;
						
					} // end if

					if (response.data.success) {
						
						dispatch(setSearchResToken(null));
						dispatch(setSearchResRowsNumber(0));
						dispatch(setSearchType("location"));

						// If reset search
						if (data.afterAction == 'reset') {
							props.navigate("/order"); 
						}
						// If delete and search
						else {
							props.executeProcessSearch();
						}								
							
						return;
				
						
					} // end if
					else {
						
						props.setContentLoading(false);

						// For interface feedback
						setTimeout(function() {
						//	props.setLoadingResults(false);
						},2000);						

						props.setAlertTitle("Ops! Ocorreu um erro!");
						props.setAlertBody("Ocorreu um erro ao realizar a busca. Tente novamente e caso o problema persista, entre em contato com o suporte. Erro: [1]");
						props.setAlert(true);																		
						return;
						
					} // end else
					
				})
				.catch(error=> {

					props.setContentLoading(false);

						// For interface feedback
						setTimeout(function() {
						//	props.setLoadingResults(false);
						},2000)					
					
					props.setAlertTitle("Ops! Ocorreu um erro!");
					props.setAlertBody("Ocorreu um erro ao realizar a busca. Tente novamente e caso o problema persista, entre em contato com o suporte. Erro: [2]");
					props.setAlert(true);	
							
					return;
				});

			} catch (error) {
				console.error('Error fetching locality search:', error);
				props.setAlertTitle("Ops! Ocorreu um erro!");
				props.setAlertBody("Ocorreu um erro ao realizar a busca. Tente novamente e caso o problema persista, entre em contato com o suporte. Erro: [3]");
				props.setAlert(true);	
				return;				
			}				
	
	} // end dispatch

};


export const processPropertySearch  = (data,props) => { 
	
	return async (dispatch, getState) => {
		
		try {

				// Check if there is a local USER OBEJECt with a TOKEN
				var result = localStorage.getItem("@memovo/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
				// decode JWT 
				var decoded = jwt_decode(userObj.userToken);
							

				// Set general data
				const preparedData = {};
				preparedData.origin =  data.property.source;		
				preparedData.propertyId =  data.property.code;		
				
				// Connect to Endpoint
				axios({
					   url    : config.API_URL+'/order/property/retrieve',
					   method : 'POST',
					   data   : preparedData,
					   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
				})							
				
				.then((response) => {

					
					// Document already registered
					if (response.data.notFound) {
						
						props.handleTimeout('cancel');

						// For interface feedback
						setTimeout(function() {
							props.setLoadingResults(false);
						},2000)
						
						props.setAlertTitle("Nenhuma propriedade encontrada!");
						props.setAlertBody("Não encontramos nenhuma propriedade com as informações fornecidas. Verifique e tente novamente.");
						props.setAlert(true);	

						return;
						
					} // end if

					if (response.data.success) {

						props.handleTimeout('cancel');

						dispatch(setSearchType("property"));
						
						// For interface feedback
						setTimeout(function() {
							props.setLoadingResults(false);
							props.navigate(`/order/hotel/view/${response.data.data[0].code}`);							
						},2000);						
							
						return;
				
						
					} // end if
					else {
						
						props.handleTimeout('cancel');

						// For interface feedback
						setTimeout(function() {
							props.setLoadingResults(false);
						},2000)						

						props.setAlertTitle("Ops! Ocorreu um erro!");
						props.setAlertBody("Ocorreu um erro ao realizar a busca. Tente novamente e caso o problema persista, entre em contato com o suporte. Erro: [1]");
						props.setAlert(true);																		
						return;
						
					} // end else
					
				})
				.catch(error=> {

					props.handleTimeout('cancel');

						// For interface feedback
						setTimeout(function() {
							props.setLoadingResults(false);
						},2000)					
					
					props.setAlertTitle("Ops! Ocorreu um erro!");
					props.setAlertBody("Ocorreu um erro ao realizar a busca. Tente novamente e caso o problema persista, entre em contato com o suporte. Erro: [2]");
					props.setAlert(true);	
							
					return;
				});

			} catch (error) {
				console.error('Error fetching locality search:', error);
				dispatch(setSearchResToken(null));
				dispatch(setSearchResRowsNumber(0));
				props.setAlertTitle("Ops! Ocorreu um erro!");
				props.setAlertBody("Ocorreu um erro ao realizar a busca. Tente novamente e caso o problema persista, entre em contato com o suporte. Erro: [3]");
				props.setAlert(true);	
				return;				
			}				
	
	} // end dispatch

};

export const retrieveSearchResultProperty  = (data,props) => { 
	
	return async (dispatch, getState) => {
		
		try {

				// Check if there is a local USER OBEJECt with a TOKEN
				var result = localStorage.getItem("@memovo/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
				// decode JWT 
				var decoded = jwt_decode(userObj.userToken);
							
				// Set general data
				const preparedData = {};
				preparedData.searchResultToken =  data.searchResultToken;
				preparedData.propertyId =  data.propertyId;
								
				// Connect to Endpoint
				axios({
					   url    : config.API_URL+'/order/properties/search/single/retrieve',
					   method : 'POST',
					   data   : preparedData,
					   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
				})						
				
				.then((response) => {

					// console.log('RESPONSE >>>>>> '+JSON.stringify(response.data,null,2));

					// Document already registered
					if (response.data?.notFound) {
						
						props.setContentLoading(false);

						// Close the modal
						// ...
												
						props.setAlertExpire(true);
						props.setAlertTitleExpire("O resultado da busca expirou");
						props.setAlertBodyExpire("O resultado da busca expirou. Faça uma nova pesquisa, para ver preços e hospedagens atualizados.");
						props.setAlertActionExpire("restartSearch");

						return;
						
					} // end if

					if (response.data.success) {
						
						props.setContentLoading(false);

						const newResults = response.data.data.results;
						props.setResultCard(newResults);			
							
						return;
				
						
					} // end if
					else {
						
						props.setContentLoading(false);

						props.setAlertTitle("Ops! Ocorreu um erro!");
						props.setAlertBody("Ocorreu um erro ao realizar a busca. Tente novamente e caso o problema persista, entre em contato com o suporte. Erro: [1]");
						props.setAlert(true);																		
						return;
						
					} // end else
					
				})
				.catch(error=> {

					console.log('ERRO CATCH >>>>>>>>>> '+JSON.stringify(error.message,null,2));

					props.setContentLoading(false);
					
					props.setAlertTitle("Ops! Ocorreu um erro!");
					props.setAlertBody("Ocorreu um erro ao realizar a busca. Tente novamente e caso o problema persista, entre em contato com o suporte. Erro: [2]");
					props.setAlert(true);	
							
					return;
				});

			} catch (error) {

				props.setContentLoading(false);

				console.error('Error fetching locality search:', error);
				props.setAlertTitle("Ops! Ocorreu um erro!");
				props.setAlertBody("Ocorreu um erro ao realizar a busca. Tente novamente e caso o problema persista, entre em contato com o suporte. Erro: [3]");
				props.setAlert(true);	
				return;				
			}				
	
	} // end dispatch

};


