import '../../Assets/Styles/home.css';
import '../../Assets/Styles/order.css';
import React from "react";
import { useMediaQuery } from 'react-responsive'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {useLocation,useNavigate} from 'react-router-dom';

import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button'; 
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import Grid from '@mui/material/Grid';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { OverlayPanel } from 'primereact/overlaypanel';
import { ProgressSpinner } from 'primereact/progressspinner';
import { ProgressBar } from 'primereact/progressbar';
import { Skeleton } from 'primereact/skeleton';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Tooltip } from 'primereact/tooltip';
import { Slider } from "primereact/slider";
import { SelectButton } from 'primereact/selectbutton';
import { AutoComplete } from "primereact/autocomplete";
import moment from "moment";
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';

// import cofeeIcon from '../../Assets/Images/cofeeIcon.svg';

// Load Actions
import * as AppActions from '../../Store/Actions/App'
import * as OrderActions from '../../Store/Actions/Order'

import ExpireView from '../../Components/ExpireModal';
import AboutUs from '../../Components/layout/AboutUs';
import ContactUs from '../../Components/layout/ContactUs';
import RoomSelector from '../../Components/RoomSelector';
import MapViewModal from './MapviewModal';
import ImageGalleryModal from './ImageGalleryModal';

// Load Componentes
import AlertView from '../../Components/AlertModal';

import {
	formatNumberCurrency,
	padronizarDecimal
} from '../../Components/Utils';

// export default function HomePage() {
const HomePage = (props) => {
	
// Set var from props
const {
	setDialog,
	dialog,
	masterUser,
	currentAgency,
	retrieveDropdownCountries,
	retrieveDropdownCities,
	retrieveDropdownProperties,
	retrieveSearchResult,
	searchType,
	searchParams,
	searchResultStatus,
	searchResultRowsNumber,
	searchResultToken,
	searchResultCurrentPage,
	searchResultLimit,
	searchLocation,
	searchResultObj,
	processLocalitySearch,
	processPropertySearch,
	setSearchResToken,
	deleteSearchResult,
	checkSearchResult,
	searchFilters,
	searchCurrency,
	exchangeRates
} = props;

console.log('<<<<<<<<<<<<<<< exchangeRates >>>>>>>>>>>>>> '+JSON.stringify(exchangeRates,null,2)); 

const [loadingResults, setLoadingResults] = React.useState(false); 
const [loadingFiltering, setLoadingFiltering] = React.useState(false); 
const [loading, setLoading] = React.useState(true); 
const [saving, setSaving] = React.useState(false); 

const [contentLoading, setContentLoading] = React.useState(true); 
const [contentLoaded, setContentLoaded] = React.useState(false); 
const [hostingOption, setHostingOptions] = React.useState(false); 
const [currentStep, setCurrentStep] = React.useState(1); // 1 continent - 2 country - 3 city  - 4 options

const [currentContinent, setCurrentContinent] = React.useState({}); // id and label
const [currentCountry, setCurrentCountry] = React.useState({}); // id and label
const [currentCity, setCurrentCity] = React.useState({}); // id and label

const [selectedItem, setSelectedItem] = React.useState(null); // Set selected item 

const [breadcrumbArray, setBreadcrumbArray] = React.useState([]); // id and label
const [sortBy, setSortBy] = React.useState('review_rating'); // Default filter (review_rating | distanceToCityCenter | total_with_markup)
const [page, setPage] = React.useState(1); // id and label
const [filterBy, setFilterBy] = React.useState(null); // No filter as default
const [paginatedResults, setPaginatedResults] = React.useState(0); // No filter as default

	
const isDesktopOrLaptop = useMediaQuery({
query: '(min-width: 1024px)'
})
const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1023px)' })

const [email, setEmail] = React.useState('');
const [password, setPassword] = React.useState('');
const [stillConnected, setStillConnected] = React.useState(false);
const onStillConnectedChange = (e) => {
	if (e.checked)
		setStillConnected(true);
	else
		setStillConnected(false);
}  // end function
	
// Alert control
const [alert, setAlert] = React.useState(false); 
const [alertTitle, setAlertTitle] = React.useState("Informações"); 
const [alertBody, setAlertBody] = React.useState("Conteúdo dinâmico"); 

// Expire Dialog control
const [alertExpire, setAlertExpire] = React.useState(false); 
const [alertTitleExpire, setAlertTitleExpire] = React.useState("Informações"); 
const [alertBodyExpire, setAlertBodyExpire] = React.useState("Conteúdo dinâmico"); 
const [alertActionExpire, setAlertActionExpire] = React.useState("restartSearch"); 

const [currentPlaces, setCurrentPlaces] = React.useState([]); 		

const [hostsAndRooms, setHostsAndRooms] = React.useState(null); 
const [hostsAndRoomsLabel, setHostsAndRoomsLabel] = React.useState(null); 
const [rooms, setRooms] = React.useState([{ adults: 1, children: [] }]); // Default at least 1 adult


const [dateRange, setDateRange] = React.useState(null); 
const [dateRangeDays, setDateRangeDays] = React.useState(null); 
const [formattedValue, setFormattedValue] = React.useState('');

const [filterByCity, setFilterByCity] = React.useState(null); 
const [filterByCountry, setFilterByCountry] = React.useState(null); 
const [filterByProperty, setFilterByProperty] = React.useState(null); 

const [filterAmenitiesOptions, setFilterAmenitiesOptions] = React.useState([]); 
const [filterAmenitiesFoodOptions, setFilterAmenitiesFoodOptions] = React.useState([]); 
const [filterPOIOptions, setFilterPOIOptions] = React.useState([]); 
const [filterNeighborhoodOptions, setFilterNeighborhoodOptions] = React.useState([]); 


const [showCancelButton, setShowCancelButton] = React.useState(true); 
const [counterStatus, setCounterStatus] = React.useState(false); 

/* Get out and get in to screen control.  */
const [lastHiddenTime, setLastHiddenTime] = React.useState(null);
const timeoutRef = React.useRef(null);
const timeoutMinutes = 30; // Tempo em minutos

const options = [{label:'Por localidade',value:'locality'},{label:'Por propriedade',value:'property'}];
const [searchOption, setSearchOption] = React.useState(options[0].value);
const setSearchMethod = (value) => {
	setSearchOption(value);
}

const calendarRef = React.useRef(null);
const overlayPanelRef = React.useRef(null);

const [countriesList, setCountriesList] = React.useState([]); 
const searchCountries = (event=null) => {
	
	var props = {
		setRows:setCountriesList
	}
	var data = {
	  keyword:event ? event.query : null
	}
	retrieveDropdownCountries(data,props);	

}

const [citiesList, setCitiesList] = React.useState([]); 
const searchCities = (event) => {

	var props = {
		setRows:setCitiesList
	}
	var data = {
	  keyword:event ? event.query : null,
	  countryCode:filterByCountry
	}
	retrieveDropdownCities(data,props);	

}
const processSelectedCity = (e) => {

	if (!filterByCountry) {

		let countryObj = {
			code:e.value.country_code,
			name:e.value.country
		}
		setFilterByCountry(countryObj);
		setFilterByCity(e.value);

	} else {
		setFilterByCity(e.value);
	}
	
}


const [propertiesList, setPropertiesList] = React.useState([]); 
const searchProperties = (event) => {

	var props = {
		setRows:setPropertiesList
	}
	var data = {
	  keyword:event ? event.query : null,
	}
	retrieveDropdownProperties(data,props);		

}

const [counter, setCounter] = React.useState(1200);
const [counterInitial, setCounterInitial] = React.useState(1200);

// const [counter, setCounter] = React.useState(20); // for test. Force popup. 
const [counterLabel, setCounterLabel] = React.useState("00:20:00");
const [counterValue, setCounterValue] = React.useState(100);

const [locationImage, setLocationImage] = React.useState(null);
const [addressLocationText, setAddressLocationText] = React.useState(null);
const [addressZoom, setAddressZoom] = React.useState(11);
const [resultsCards, setResultsCards] = React.useState([]);

// Filters
const [orderByFilter, setOrderByFilter] = React.useState({"code":"review_rating",name:"Avaliação (melhor primeiro)"});
const [filterPriceRange, setFilterPriceRange] = React.useState([]);

// Exchange rate USD
const [usdExchangeRate, setUsdExchangeRate] = React.useState(null);
const [exchangeRateInitials, setExchangeRateInitial] = React.useState("USD");

const [filterAmenitiesValues, setFilterAmenitiesValues] = React.useState([]);
const [showAllAmenities, setShowAllAmenities] = React.useState(false);
const onFilterAmenitiesChange = (e) => {
	let _amenities = [...filterAmenitiesValues];

	if (e.checked)
		_amenities.push(e.value);
	else
	_amenities.splice(_amenities.indexOf(e.value), 1);

	setFilterAmenitiesValues(_amenities);
}

const [filterAmenitiesFoodValues, setFilterAmenitiesFoodValues] = React.useState([]);
const [showAllAmenitiesFood, setShowAllAmenitiesFood] = React.useState(false);
const onFilterAmenitiesFoodChange = (e) => {
	let _amenities = [...filterAmenitiesFoodValues];

	if (e.checked)
		_amenities.push(e.value);
	else
	_amenities.splice(_amenities.indexOf(e.value), 1);

	setFilterAmenitiesFoodValues(_amenities);
}


const [filterPOIValues, setFilterPOIValues] = React.useState([]);
const [showAllPOI, setShowAllPOI] = React.useState(false);
const onFilterPOIChange = (e) => {
	let _poi = [...filterPOIValues];

	if (e.checked)
		_poi.push(e.value);
	else
	_poi.splice(_poi.indexOf(e.value), 1);

	setFilterPOIValues(_poi);
}


const [filterNeighborhoodValues, setFilterNeighborhoodValues] = React.useState([]);
const [showAllNeighborhood, setShowAllNeighborhood] = React.useState(false);
const onFilterNeighborhoodChange = (e) => {
	let _poi = [...filterNeighborhoodValues];

	if (e.checked)
		_poi.push(e.value);
	else
	_poi.splice(_poi.indexOf(e.value), 1);

	setFilterNeighborhoodValues(_poi);
}

const secondsToHmsOriginal = (d) => {
   
	d = Number(d);
	var h = Math.floor(d / 3600);
	var m = Math.floor(d % 3600 / 60);
	var s = Math.floor(d % 3600 % 60);
 
	var hDisplay = h > 0 ? h + (h == 1) : "00";
	var mDisplay = m > 0 ? m + (m == 1) : "00";
	var sDisplay = s > 0 ? s + (s == 1) : "00";
 
	let hour = ""+hDisplay+"";
	let minutes = ""+mDisplay+"";
	let seconds = ""+sDisplay+"";
 
	// return hour.padStart(2, '0') +":"+ minutes.padStart(2, '0')+":"+ seconds.padStart(2, '0');
	return minutes.padStart(2, '0')+":"+ seconds.padStart(2, '0');
 }

// Função para converter segundos no formato mm:ss
const convertSecondsToHms = (seconds) => {
	return moment.utc(seconds * 1000).format("mm:ss");
}  


 const secondsToHms = (d) => {
	d = Number(d);
  
	// Calcula horas, minutos e segundos
	const h = Math.floor(d / 3600);
	const m = Math.floor((d % 3600) / 60);
	const s = Math.floor(d % 60);
  
	// Formata para 2 dígitos sempre
	const hDisplay = h.toString().padStart(2, "0");
	const mDisplay = m.toString().padStart(2, "0");
	const sDisplay = s.toString().padStart(2, "0");
  
	// Retorna o formato mm:ss (sem as horas no momento)
	return `${mDisplay}:${sDisplay}`;
  };
  


const handleRoomSelection = (value) => {
	setHostsAndRooms(value);
	overlayPanelRef.current.hide();
};

const handleClearSelection = () => {
	setRooms([{ adults: 1, children: [] }]);
	// setHostsAndRooms(null);	
	overlayPanelRef.current.hide();
};


// Check if everything is ok with seleciton
const handleCheckSelectedOptions = (rooms) => {

	// Check if any children dont have defined age
	for (let i = 0; i < rooms.length; i++) {
		for (let j = 0; j < rooms[i].children.length; j++) {
			if (rooms[i].children[j].age === "") {
	
				setAlertTitle("Ops! Defina a idade da(s) criança(s)");
				setAlertBody("É necessário que você defina as idades das crianças que foram adicionadas. Abra o seletor de quartos e informe a idade das crianças.");
				setAlert(true);	
								
				handleUpdateInputValue(rooms); // update current selection
				return false;
			}
		}
	}


}

const handleUpdateInputValue = (rooms) => {
	const adultCount = rooms.reduce((acc, room) => acc + room.adults, 0);
	const childCount = rooms.reduce((acc, room) => acc + room.children.length, 0);

	const adultLabel = adultCount > 1 ? 'adultos' : 'adulto';
	const childLabel = childCount > 1 ? 'crianças' : 'criança';
	const roomLabel = rooms.length > 1 ? 'quartos' : 'quarto';

	const parts = [];
	if (adultCount > 0) parts.push(`${adultCount} ${adultLabel}`);
	if (childCount > 0) parts.push(`${childCount} ${childLabel}`);
	if (rooms.length > 0 && (adultCount > 0 || childCount > 0)) parts.push(`${rooms.length} ${roomLabel}`);

	setHostsAndRooms(parts.join(', '));	
};

const handleUpdateInputValueLabel = (rooms) => {
	const adultCount = rooms.reduce((acc, room) => acc + room.adults, 0);
	const childCount = rooms.reduce((acc, room) => acc + room.children.length, 0);

	const adultLabel = adultCount > 1 ? 'adultos' : 'adulto';
	const childLabel = childCount > 1 ? 'crianças' : 'criança';
	const roomLabel = rooms.length > 1 ? 'quartos' : 'quarto';

	const parts = [];
	if (adultCount > 0) parts.push(`${adultCount} ${adultLabel}`);
	if (childCount > 0) parts.push(`${childCount} ${childLabel}`);
	if (rooms.length > 0 && (adultCount > 0 || childCount > 0)) parts.push(`${rooms.length} ${roomLabel}`);

	setHostsAndRoomsLabel(parts.join(', '));	
};

const handleMoreResults = () => {

	setContentLoading(true);

	setPage((prevPage) => {

		const updatedPage = prevPage + 1;
		
		// Use o valor atualizado da página
		executeRetrieveSearchResult(updatedPage);
	  
		return updatedPage;
	  });	

}


const handleSortResults = (value) => {

	setResultsCards([]);
	setPaginatedResults(0);
	setContentLoading(true);

	setPage(1);

	setSortBy((prevSort) => {

		// Use o valor atualizado da página
		executeRetrieveSearchResult(1,value.code,[]);	
	  
		return value.code;
	});	

	
}


const handleClickScroll = (target) => {
		
	const element = document.getElementById(target);
	if (element) {
		// 👇 Will scroll smoothly to the top of the next section
		var elementPosition = element.offsetTop;
		element.scrollIntoView({ top: elementPosition - 70,behavior: 'smooth' });
	}
	};  

React.useEffect(() => {
	if (!hostsAndRooms) {
		setHostsAndRooms(null);
	}
}, [hostsAndRooms]);


React.useEffect(() => {
	handleUpdateInputValue(rooms);
}, [rooms]);


React.useEffect(() => {
	if (dateRange && dateRange[0] && dateRange[1]) {
		const diffTime = Math.abs(dateRange[1] - dateRange[0]);
		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
		const formattedRange = formatDateRange(dateRange);
		let nightLabel = diffDays == 1 ? "noite" : "noites";
		setFormattedValue(`${formattedRange} (${diffDays} ${nightLabel})`);
	} else {
		setFormattedValue('');
	}
}, [dateRange]);


const formatDateRange = (range) => {
	if (range && range[0] && range[1]) {
		const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
		return `${range[0].toLocaleDateString('pt-BR', options)} - ${range[1].toLocaleDateString('pt-BR', options)}`;
	}
	return '';
};

  
const Countdown = (props) => {
	const { startTime, stop } = props;
	const [counter, setCounter] = React.useState(startTime);
	const [counterInitial] = React.useState(startTime);
  
	const [counterLabel, setCounterLabel] = React.useState(() => convertSecondsToHms(startTime));
  
	React.useEffect(() => {
	  if (counter > 0 && !stop) {
		const timer = setTimeout(() => {
		  setCounter((prevCounter) => {
			const newCounter = prevCounter - 1;
			setCounterLabel(secondsToHms(newCounter));
			return newCounter;
		  });
		}, 1000);
  
		return () => clearTimeout(timer);
	  }
	}, [counter, stop]);
  
	return (
	  <>
		{counter > 0 && !stop ? (
		  <>Aguarde até {counterLabel} para processarmos a sua solicitação...</>
		) : (
		  <>Tudo certo! Preparando resultado...</>
		)}
	  </>
	);
  };
  

const customFooter = () => {
	return (<>
		<div className="p-clearfix" style={{ width: '100%',textAlign:"left" }}>
		 <button type="button" onClick={() => calendarRef.current.hide()} className="p-button p-component p-button-text p-button-plain" style={{ float: 'right' }}>
                    Fechar
        </button>
		</div>

		<div className="p-clearfix" style={{ width: '100%',textAlign:"right" }}>
			<button type="button" onClick={() => setDateRange(null)} className="p-button p-component p-button-text p-button-plain" style={{fontWeight:"bold"}}>
				Limpar
			</button>
		</div>
		</>
	);
};

const customFooterInline = () => {
	return (<>
		<div className="p-clearfix" style={{ width: '100%',textAlign:"right" }}>
			<button type="button" onClick={() => setDateRange(null)} className="p-button p-component p-button-text p-button-plain" style={{fontWeight:"bold"}}>
				Limpar
			</button>
		</div>
		</>
	);
};

// Set calendar locale
addLocale('br', {
	firstDayOfWeek: 1,
	dayNames: ['domingo', 'segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado'],
	dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
	dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
	monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
	monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
	today: 'Hoje',
	clear: 'Limpar'
});


// set Use of history for PUSH 
var navigate = useNavigate();
	

// Delete Search properties method
const executeDeleteSearchResult = (afterAction=null) => {

	// Prepare props and data
	var props = {
		setAlertTitle:setAlertTitle,
		setAlertBody:setAlertBody,
		setAlert:setAlert,
		setContentLoading:setContentLoading,
		setResultsCards:setResultsCards,
		setPaginatedResults:setPaginatedResults,
		navigate:navigate,
		executeProcessSearch:executeProcessSearch
	}
	var data = {
		searchResultToken:searchResultToken,		
		afterAction:afterAction
	}		
	// if searchType is location
	deleteSearchResult(data,props);
 
}

// Search properties method
const executeRetrieveSearchResult = (currentPage=page,currentSort=sortBy,currentCards=resultsCards,currentPaginatedResults=paginatedResults,currentSearchResultToken=searchResultToken) => {

	// Keep default values or override 
	setResultsCards(currentCards);
	setPage(currentPage);
	setSortBy(currentSort);
	setPaginatedResults(currentPaginatedResults); 

	// Set loading ON and timer ON
	setContentLoading(true);

	// Prepare props and data
	var props = {
		setAlertTitle:setAlertTitle,
		setAlertBody:setAlertBody,
		setAlert:setAlert,
		setAlertExpire:setAlertExpire,
		setAlertTitleExpire:setAlertTitleExpire,
		setAlertBodyExpire:setAlertBodyExpire,
		setAlertActionExpire:setAlertActionExpire,		
		setContentLoading:setContentLoading,
		setLoadingResults:setLoadingResults,
		setResultsCards:setResultsCards,
		setPaginatedResults:setPaginatedResults
	}
	var data = {
		searchResultToken:currentSearchResultToken,
		sortBy:currentSort,
		page:currentPage,
		filterBy:!filterBy ? null : filterBy
	}		

	// if searchType is location
	retrieveSearchResult(data,props);
 
}


// Search properties method
const executeSearch = () => {

	// IF searchType is Location, Check if COUNTRY is selected
	if (searchOption == 'locality') {

		if (!filterByCountry) {
			setAlertTitle("Verifique as informações");
			setAlertBody("Para prosseguir com a pesquisa é necessário informar ao menos o país.");
			setAlert(true);	
			return;		
		}

	} 
	// IF searchType is property, Check if PROPERTY is selected
	else  {
		
		if (!filterByProperty) {
			setAlertTitle("Verifique as informações");
			setAlertBody("Para prosseguir com a pesquisa é necessário informar a propriedade desejada.");
			setAlert(true);	
			return;		
		}

	}

	let countryProperties = filterByCountry.propertiesQuantity;
	if (countryProperties > 100 && !filterByCity) {
		setAlertTitle("Selecione uma cidade");
		setAlertBody("O país selecionado tem muitas propriedades. Para prosseguir com a pesquisa é necessário informar uma cidade no país selecionado.");
		setAlert(true);	
		return;		
	}	

	// Check if Range date is selected
	if (!dateRange) {
		setAlertTitle("Verifique as informações");
		setAlertBody("Para prosseguir com a pesquisa é necessário informar a data de chegada e de saída da estadia.");
		setAlert(true);	
		return;		
	}	

	// Verify if any search params changed to create a new search.
	const changes = compareData(searchParams, filterByCountry, filterByCity, dateRange, rooms);
	if (!hasChanges(changes)) {
		setAlertTitle("Verifique as informações da busca");
		setAlertBody("O filtro de busca atual permanece inalterado. Para realizar uma busca diferente, modifique pelo menos um dos parâmetros.");
		setAlert(true);	
		return;	
	} 
	
	// Check integrity of dates (AVOID HACKING)
	const firstDate = moment(dateRange[0]).startOf('hour'); // Normalizando a comparação para início da hora
	const secondDate = moment(dateRange[1]).startOf('hour');
	const nowPlus72Hours = moment().add(72, 'hours').startOf('hour'); // Normalizando para início da hora
	const minDateString = moment(nowPlus72Hours).format("DD/MM/YYYY");
	
	// Verificar se a primeira data é igual a 72 horas a partir de agora
	if (!firstDate.isSameOrAfter(nowPlus72Hours)) {
		setAlertTitle("Verifique as informações");
		setAlertBody(`A data de chegada é inferior a data mínima: ${minDateString} `);
		setAlert(true);	
		return;	
	} 
	
	// Verificar se a segunda data é maior que a primeira
	if (!secondDate.isAfter(firstDate)) {
		setAlertTitle("Verifique as informações");
		setAlertBody("A data de saíd anão pode ser inferior a data de chegada");
		setAlert(true);	
		return;	
	} 

	// Check if Kids age was selected
	for (let i = 0; i < rooms.length; i++) {
		for (let j = 0; j < rooms[i].children.length; j++) {
			if (rooms[i].children[j].age === "") {
	
				setAlertTitle("Atenção! Defina a idade da(s) criança(s)");
				setAlertBody("Para prosseguir é necessário que você defina as idades das crianças que foram adicionadas aos quartos. Abra o seletor de quartos e informe a(s) idade(s).");
				setAlert(true);	
				return false;
			}
		}
	}

	// IF location navigation, check if at least country and city are selected
	// ...[TO-DO]	

	// Set counter to stop = false
	setCounterStatus(false);
	setSortBy("review_rating");
	setOrderByFilter({"code":"review_rating",name:"Avaliação (melhor primeiro)"});

	// Set loading ON and timer ON
	setLoadingResults(true);
	handleTimeout();	


	// Execute delete search and after execute search
	executeDeleteSearchResult('search');
	
}

// Função para verificar se houve mudanças
const hasChanges = (changes) => Object.keys(changes).length > 0;

// Compare search params data
const compareData = (originalData, newCountry, newCity, newDateRange, newRooms) => {
	
	const changes = {};
  
	if (JSON.stringify(originalData.country) !== JSON.stringify(newCountry)) {
	  changes.country = {
		original: originalData.country,
		new: newCountry,
	  };
	}
  
	if (originalData.city !== newCity) {
	  changes.city = {
		original: originalData.city,
		new: newCity,
	  };
	}
  
	if (JSON.stringify(originalData.dateRange) !== JSON.stringify(newDateRange)) {
	  changes.dateRange = {
		original: originalData.dateRange,
		new: newDateRange,
	  };
	}

	if (JSON.stringify(originalData.originalRooms) !== JSON.stringify(newRooms)) {
	  changes.rooms = {
		original: originalData.rooms,
		new: newRooms,
	  };
	}
  
	return changes;
  };

const executeProcessSearch = () => {

		// Reset filters
		setFilterPriceRange([]);

		// Prepare props and data
		var props = {
			setAlertTitle:setAlertTitle,
			setAlertBody:setAlertBody,
			setAlert:setAlert,
			navigate:navigate,
			setLoadingResults:setLoadingResults,
			handleTimeout:handleTimeout,
			setSearchResToken:setSearchResToken,
			executeRetrieveSearchResult:executeRetrieveSearchResult,
			setCounterStatus:setCounterStatus
		}
		var data = {
			searchOption:searchOption,
			country:filterByCountry,
			city:filterByCity,
			dateRange:dateRange,
			dateRangeDays:dateRangeDays,
			property:filterByProperty,
			rooms:rooms,
			screen:"searchResult"
		}		
		// if searchType is location
		if (searchOption == 'locality') {
			processLocalitySearch(data,props);
		} 
		// if searchType is property
		else {
			processPropertySearch(data,props);
		}

}


// Reload Controls, breadcrumb and map elements
const loadInterfaceElements = () => {

	console.log('RODIU LOAD INTERFACE.............');

	// Set location
   // let addressText = `${currentContinent} `;
   let addressText = `${searchLocation.continent ? ', ' + searchLocation.continent : ''} ${searchLocation.country.name} ${searchLocation.city ? ', ' + searchLocation.city.name : ''}`;
   let zoomSize = searchLocation.city ? 11 : 6;
   setAddressZoom(zoomSize);
   setAddressLocationText(addressText);

   let locationImageUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${addressText}&zoom=${zoomSize}&size=416x176&maptype=roadmap&key=AIzaSyBFNooJFvoHEGeQOJqUuekFHT1PRQ8TbCs`;
   setLocationImage(locationImageUrl);  

  	// Helper function to create breadcrumb item
	const createBreadcrumbItem = (label) => ({
		label: label,
		template: () => <div className="breadcrumbSelectedLabel" style={{ fontSize: "1.5rem" }}>{label}</div>
	});

	// Create path array with locations that exist
	const pathArray = [
		searchLocation.continent && createBreadcrumbItem(searchLocation.continent),
		createBreadcrumbItem(searchLocation.country.name),
		searchLocation.city && createBreadcrumbItem(searchLocation.city.name)
	].filter(Boolean); // Filter out any undefined items	

   setBreadcrumbArray(pathArray);  	

   // Set current locaiton filters
   setFilterByCountry(searchParams.country);
   setFilterByCity(searchParams.city);

   // Set date range and rooms
   setDateRange(searchParams.dateRange);
   setDateRangeDays(searchParams.dateRangeDays);
   setRooms(searchParams.rooms);	

   // Set Rooms label for list
   handleUpdateInputValueLabel(searchParams.rooms);		

   let priceRange = [];
   priceRange.push(searchFilters?.priceFilter?.low);
   priceRange.push(searchFilters?.priceFilter?.high);
   setFilterPriceRange(priceRange);

   // set amenities filters
   let amenitiesOptions = searchFilters.amenitiesFilter;
   setFilterAmenitiesOptions(amenitiesOptions);

   // set amenities food filters
   let amenitiesFoodOptions = [];//searchFilters.amenitiesFoodFilter;
   setFilterAmenitiesFoodOptions(amenitiesFoodOptions);

   // set poi filters
   let poisOptions = [];//searchFilters.poisFilter;
   setFilterPOIOptions(poisOptions);

   // set neighborhood filters
   let neighborhoodOptions = searchFilters.neighborhoodFilter;

   setFilterNeighborhoodOptions(neighborhoodOptions);

   // Set USD exchange Rate and currency initials
   if (searchParams?.country?.code == "BR") {
		const result = exchangeRates.find(item => item.targetCurrencyInitials === "USD");
		const exchangeRateValue = result.value;
		setUsdExchangeRate(exchangeRateValue); 
		setExchangeRateInitial("BRL"); 
	} else {
		setExchangeRateInitial("USD"); 
	}  

}

// Run after mount component
React.useEffect(() => {

	// Retrieve User Data Obj. 
	var result = localStorage.getItem("@memovo/userData", undefined,true);
	var userData = JSON.parse(result);  

	  // If token dont exist, redirect to onboarding or register
	  if (!userData) {
		  navigate("/home");
		  return;
	  } // end if

	  // If order toek ins NULL, REDIRECT to /order
	  if (!searchResultToken) {

		// Reset local storage control
		// ...

		navigate("/order");
		return;
	  }
	  
  // set scroll to top
  window.scrollTo(0, 1);

	// Retrieve all countries
	searchCountries();  

	// set loading false
	setLoading(false);	

   // Retrieve Search and show results
   executeRetrieveSearchResult();

   // Set USD exchange Rate and currency initials
   if (searchParams?.country?.code == "BR") {
		const result = exchangeRates.find(item => item.targetCurrencyInitials === "USD");
		const exchangeRateValue = result.value;
		setUsdExchangeRate(exchangeRateValue); 
		setExchangeRateInitial("BRL"); 
   }
    
  // Runs when UNMOUNT component
  return () => {

	setContentLoading(true); 
	setContentLoaded(false); 
	setHostingOptions(false); 
	setCurrentStep(1); 

	setCurrentContinent({}); 
	setCurrentCountry({}); 
	setCurrentCity({}); 
	setBreadcrumbArray([]); 

	setCurrentPlaces([]); 		
	setHostsAndRooms(null); 
	setRooms([{ adults: 1, children: [] }]); 
		
	setDateRange(null); 
	setFormattedValue('');
		
	setFilterByCity(null); 
	setFilterByCountry(null); 
	setFilterByProperty(null);	
	
  };
  
}, []); // end useEffect 


// Run when searchtoken change
React.useEffect(() => {
	
    console.log('Token atualizado:', searchResultToken);

	console.log('Atualizando interface...');
	loadInterfaceElements();

}, [searchResultToken]);

const clearOptions = () => {

	setHostsAndRooms(null); 
	setRooms([{ adults: 1, children: [] }]);	
	setDateRange(null); 
	setFormattedValue('');

	setFilterByCity(null); 
	setFilterByCountry(null); 

}


const displayedAmenitiesItems = showAllAmenities ? filterAmenitiesOptions : filterAmenitiesOptions.slice(0, 10);

const displayedPOIItems = showAllPOI ? filterPOIOptions : filterPOIOptions.slice(0, 5);

const displayedNeighborhoodItems = showAllNeighborhood ? filterNeighborhoodOptions : filterNeighborhoodOptions.slice(0, 10);

const displayedFoodItems = showAllAmenitiesFood ? filterAmenitiesFoodOptions : filterAmenitiesFoodOptions.slice(0, 5);


let searchTimer;
const handleTimeout = (action='start') => {

	if (action == 'cancel') {
		clearTimeout(searchTimer);
	} else {

		searchTimer = setTimeout(function() {

			setAlertTitle("Tempo esgotado na busca");
			setAlertBody("Infelizmente o tempo máximo da busca foi atingido. Por favor tente novamente.");
			setAlert(true);	
			return;

		},59000);

	}

};
 
const handleFilterTimeout = () => {

	setTimeout(function() {

		setLoadingFiltering(false);

	},3000);

};

const handleCardClick = (id) => {
	//window.open(`/order/hotel/property/${id}`, '_blank', 'noopener,noreferrer');
	console.log(`NAVITAGE TO >>>> /order/hotel/view/${id}`);
	navigate(`/order/hotel/view/${id}`);
};

const handleCardImageClick = (id,e) => {

	// Avoid onclick event on parent
	e.stopPropagation();

	//window.open(`/order/hotel/property/${id}`, '_blank', 'noopener,noreferrer');
	console.log(`OPEM IMAGE GALLERY FOR PROPERTY ID >>>> ${id}`);
	
	setSelectedItem(id);
	
	setDialog("ImageGalleryViewModal",true)
	//navigate(`/order/hotel/property/${id}`);
};


	/* Logout prompt START  */
    const accept = (e) => {
		executeDeleteSearchResult('reset');
	 };
 
	 const reject = (e) => {
		
	 };
	 
	 const confirm = (event) => {
		 confirmPopup({
			 target: event.currentTarget,
			 message: <div>Tem certeza que deseja <br /> recomeçar a busca? <br /> Os resultados da busca serão excluídos.</div>,
			 icon: 'pi pi-exclamation-triangle',
			 accept,
			 reject,
			 rejectLabel:"Não",
			 acceptLabel:"Sim"
		 });
	 };
	 /* Logout prompt END  */	


  // Função que será chamada ao retornar após X minutos
  const handleReturnAfterTimeout = () => {

		// Prepare props and data
		var props = {
			setAlertExpire:setAlertExpire,
			setAlertTitleExpire:setAlertTitleExpire,
			setAlertBodyExpire:setAlertBodyExpire,
			setAlertActionExpire:setAlertActionExpire,
		}
		var data = {
			searchResultToken:searchResultToken
		}	
		checkSearchResult(data,props);

  };
  React.useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        // Quando a aba fica inativa, salva o tempo atual
        setLastHiddenTime(Date.now());
      } else if (document.visibilityState === "visible") {
        // Quando a aba volta a ficar ativa
        if (lastHiddenTime) {
          const elapsedMinutes = (Date.now() - lastHiddenTime) / (1000 * 60); // Calcula o tempo em minutos
          if (elapsedMinutes >= timeoutMinutes) {
            handleReturnAfterTimeout();
          }
        }
        setLastHiddenTime(null); // Reseta o último tempo ao retornar
      }
    };

    // Adiciona o listener para detectar mudança de visibilidade
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      // Remove o listener ao desmontar o componente
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [lastHiddenTime]);


  return (
	<>
		{(loadingResults && !loadingFiltering) &&
		<>
		<div className="wrapper" style={{backgroundColor:"#00000066",zIndex:"999999",position:"fixed",left:0,top:0,display:"flex",justifyContent:"center",alignItems:"center",width:"100%",height:"100vh"}}>

				<div style={{backgroundColor:"#FFFFFF",display:"flex",justifyContent:"center",alignItems:"center",width:"50%",height:"45vh",borderRadius:"60px",padding:"30px"}}>

					<div style={{flex:1,display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",width:"30vw"}}>
						<ProgressSpinner />
						<div className="formTitle" style={{marginBottom:"15px",marginTop:"15px"}}>Procurando hotéis...Aguarde! </div>
						<div className="formSubTitle">Na DREAM4 você encontra os melhores quartos em hotéis 4 estrelas...</div>		

						<div style={{marginTop:"30px",display:"flex",flexDirectin:"row",justifyContent:"center",alignItems:"cnter",gap:"15px"}}>
							
							<div style={{display:"flex",justifyContent:"center",alignItems:"center"}}><div style={{flex:1}}>{<Countdown stop={counterStatus} startTime={120} onTimeout={handleTimeout} />}</div></div>
							
							{/*showCancelButton &&
								<Button label="Cancelar busca" outlined rounded className="pT10 mR10 headerButton" style={{float:"unset"}} onClick={() => {setLoadingResults(false)}}  />
							*/}

							</div>
					</div>

				</div>

		</div>
		</>
		}

		{(!loadingResults && loadingFiltering) &&
			<>
			<div className="wrapper" style={{backgroundColor:"#00000066",zIndex:"999999",position:"fixed",left:0,top:0,display:"flex",justifyContent:"center",alignItems:"center",width:"100%",height:"100vh"}}>
	
					<div style={{backgroundColor:"#FFFFFF",display:"flex",justifyContent:"center",alignItems:"center",width:"50%",height:"45vh",borderRadius:"60px",padding:"30px"}}>
	
						<div style={{flex:1,display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",width:"30vw"}}>
							<ProgressSpinner />
							<div className="formTitle" style={{marginBottom:"15px",marginTop:"15px"}}>Aplicando filtros...Aguarde! </div>
							<div className="formSubTitle">DREAM4: hotéis 4 estrelas que você pode sonhar...</div>		
	
						</div>
	
					</div>
	
			</div>
			</>
		}		

	<div id="loginSection" style={{marginTop:"0",paddingTop:"0px",paddingBottom:"0px",position:"relative"}} className={isTabletOrMobile ? "login__wrapper mobile" : "login__wrapper"} >

		{/* Add layer above selections if master and did not select agency */}
		{!masterUser && !currentAgency.agencyCode &&
		<>
		<div style={{position:"absolute",top:0,left:0,height:"100%",width:"100%",backgroundColor:"rgb(255,255,255,0.90)",zIndex:9,borderRadius:"10px",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",gap:"10px", borderRadius:"30px"}}>
			<div>
				<svg fill="#814DE5" height="85px" width="85px" version="1.1" viewBox="0 0 24 24">
					<g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
					<g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
					<g id="SVGRepo_iconCarrier">
						<g id="select">
							<path d="M14.8,24l-3.3-4.3l-3.2,4.2L5.8,6.9l16,7.2L16.4,16l3.2,4.3L14.8,24z M11.6,16.4l3.6,4.8l1.6-1.3L13.1,15l3.3-1.1l-8.1-3.6 l1.3,8.7L11.6,16.4z"></path>
							<path d="M4,18H0v-4h2v2h2V18z M2,12H0V6h2V12z M18,10h-2V6h2V10z M18,4h-2V2h-2V0h4V4z M2,4H0V0h4v2H2V4z M12,2H6V0h6V2z"></path>
						</g>
					</g>
				</svg>
			</div>
			<div style={{fontSize:"22px",textAlign:"center",letterSpacing:"0.1em",lineHeight:"120%",fontWeight:"600",fontStyle:"normal",fontFamily:"Titillium Web",color:"#814DE5"}}> Selecione uma agência <br /> para prosseguir com a busca </div>
		</div>
		</>
		}

		<div style={{minHeight:"unset",height:"unset"}} className={isTabletOrMobile ? "login__wrapper_form_wrapper mobile" : "login__wrapper_form_wrapper"}> 		
			<div className={isTabletOrMobile ? "login__form_wrapper mobile" : "login__form_wrapper"} style={{alignItems:"flex-start"}}>
						
				<div style={{flex:1,minHeight:"220px",marginLeft:"auto",marginRight:"auto"}}>
					
					<div className="searchWrapper" style={{padding: "3rem",display: "flex",flexDirection: "column",justifyContent: "center",alignItems: "flex-start",gap: "1rem",width: "100%",marginLeft: "auto",marginRight: "auto"}}> 

						<div lassName="searchWrapperTitle" style={{color: "#1D2939",fontSize: "2rem",fontStyle: "normal",fontWeight: 700,lineHeight: "2.12938rem",fontFamily:"Poppins",flex:"1"}} className={loading ? "selectBoxTitle" : "selectBoxTitle animate"}>Onde e como será a estadia?</div>

						<div lassName="searchWrapperDescription" style={{color: "#475467",fontSize: "1.125rem",fontStyle: "normal",fontWeight: 400,lineHeight: "160.5%",LetterSpacing: "0.1125rem",fontFamily:"Titillium Web",flex:"1",textAlign:"left"}} className={loading ? "selectBoxTitle" : "selectBoxTitle animate"}>Informe os dados da hospedagem para pesquisa de opções disponíveis. </div>

						<div className="searchWrapperInput" style={{flex:"1",width:"100%",marginTop:"20px"}}>

							<div style={{width:"100%",maxWidth:"1440px",display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"flex-start",marginBottom:"30px"}}>
								<SelectButton value={searchOption} onChange={(e) => {setSearchMethod(e.value)}} options={options} optionLabel="label" optionValue="value" unselectable={false} allowEmpty={false}  />
							</div>

							<div container style={{width:"100%",maxWidth:"1440px",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",gap:"10px"}}>
								
								<div style={{flex:searchOption != 'locality' ? "1.5" : "1"}}>
								{searchOption == 'locality' && (<span className="p-float-label" style={{position:"relative"}}>
										{/*<Dropdown showClear value={filterByCountry} onChange={(e) => setFilterByCountry(e.target.value)} options={[{"code":1,name:"Brazil"},{"code":3,name:"Portugal"},{"code":9,name:"Espanha"}]} optionLabel="name" placeholder="Selecione o Status" className="w100" style={{textAlign:"left"}}></Dropdown>*/}
										<AutoComplete forceSelection={true} showClear={true} showEmptyMessage={true} delay={500} minLength={3} placeholder="Digite ao menos 3 caracteres" className="w100 searchInput" style={{textAlign:"left"}} field="name" value={filterByCountry} suggestions={countriesList} completeMethod={searchCountries} onChange={(e) => {setFilterByCountry(e.value);setFilterByCity(null)}}  dropdown />
										<label htmlFor="companyLegalName">País<span className="requiredField">*</span></label>
										{filterByCountry && (
											<Button
											icon="pi pi-times"
											className="p-button-rounded p-button-text"
											onClick={()=>{setFilterByCountry(null);setFilterByCity(null)}}
											style={{
												position: 'absolute',
												top: '50%',
												right: '50px',
												transform: 'translateY(-50%)',
												zIndex: 1,
												padding: '0',
												width: '24px',
												height: '24px',
											}}
											aria-label="Clear"
											/>
										)}
									</span>)
								}

								{searchOption != 'locality' && (<span className="p-float-label" style={{position:"relative"}}>
										{/*<Dropdown showClear value={filterByProperty} onChange={(e) => setFilterByProperty(e.target.value)} options={[{"code":1,name:"Clube Med Rio das pedras"},{"code":3,name:"Portugal"},{"code":9,name:"Espanha"}]} optionLabel="name" placeholder="Selecione o Status" className="w100" style={{textAlign:"left"}}></Dropdown>*/}										
										<AutoComplete forceSelection={true} showClear={true} showEmptyMessage={true} delay={1000} minLength={4} placeholder="Digite ao menos 4 caracteres" className="w100 searchInput" style={{textAlign:"left"}} field="name" value={filterByProperty} suggestions={propertiesList} completeMethod={searchProperties} onChange={(e) => setFilterByProperty(e.value)}  />
										<label htmlFor="companyLegalName">Hotel (Exemplo: Nome do hotel, cidade e/ou país)<span className="requiredField">*</span></label>
										{filterByProperty && (
											<Button
											icon="pi pi-times"
											className="p-button-rounded p-button-text"
											onClick={()=>setFilterByProperty(null)}
											style={{
												position: 'absolute',
												top: '50%',
												right: '10px',
												transform: 'translateY(-50%)',
												zIndex: 1,
												padding: '0',
												width: '24px',
												height: '24px',
											}}
											aria-label="Clear"
											/>
										)}

									</span>)
								}								
								</div>
								
								{searchOption == 'locality' && (<div style={{flex:"1"}}>
									<div className="p-float-label" style={{position:"relative"}}>
										{/*<Dropdown showClear value={filterByCity} onChange={(e) => setFilterByCity(e.target.value)} options={[{"code":1,name:"Rio de Janeiro"},{"code":3,name:"São Paulo"},{"code":9,name:"Porto Alegre"}]} optionLabel="name" placeholder="Selecione o Status" className="w100" style={{textAlign:"left"}}></Dropdown>*/}										
										<AutoComplete forceSelection={true} showClear={true} showEmptyMessage={true} delay={500} minLength={3} placeholder="Digite ao menos 3 caracteres" className="w100 searchInput" style={{textAlign:"left",flex:1}} field="name" value={filterByCity} suggestions={citiesList} completeMethod={searchCities} onChange={(e) => setFilterByCity(e.value)} onSelect={(e) => processSelectedCity(e)} dropdown={filterByCountry ? true : false} />
										<label htmlFor="companyFantasyName">Cidade</label>
										{filterByCity && (
											<Button
											icon="pi pi-times"
											className="p-button-rounded p-button-text"
											onClick={()=>setFilterByCity(null)}
											style={{
												position: 'absolute',
												top: '50%',
												right: filterByCountry ? '50px' : '10px',
												transform: 'translateY(-50%)',
												zIndex: 1,
												padding: '0',
												width: '24px',
												height: '24px',
											}}
											aria-label="Clear"
											/>
										)}

									</div>
								</div>)}								
								
								<div style={{flex:"1.2"}}>
									<span className="p-float-label">
										<Calendar value={dateRange}  minDate={new Date()}  onChange={(e) => setDateRange(e.value)}  locale="br"  id="rangeDate" style={{width:"100%"}} inputStyle={{width:"100%"}} showIcon dateFormat="dd/mm/yy" selectionMode="range" readOnlyInput hideOnRangeSelection numberOfMonths={2} footerTemplate={customFooter} ref={calendarRef} />
										<label htmlFor="companyEmail">Data de chegada e de saída<span className="requiredField">*</span></label>

										{/* Rende input oiver orioginal to show number of nights */}
										{dateRange &&
										<input 
											type="text" 
											value={formattedValue} 
											readOnly 
											style={{ position: "absolute",top: "1px",left: "4px",height: "95%",width: "86%",border: "none",background: "transparent",pointerEvents: "none",paddingLeft: "1rem",fontSize: "1rem",color: "#495057",padding: ".75rem .75rem",appearance: "none",borderRadius: "6px",backgroundColor: "#FFF", fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'"}} 
										/>
										}

									</span>
								</div>
								<div style={{flex:"1.1"}}>
									<span className="p-float-label">
										<InputText id="hosts" autoComplete="new-password" disabled={saving} className="w100" value={hostsAndRooms} onChange={(e) => setHostsAndRooms(e.target.value)} readOnly onClick={(e) => overlayPanelRef.current.toggle(e)}  />

											<OverlayPanel ref={overlayPanelRef}  dismissable={true} showCloseIcon onHide={() => handleCheckSelectedOptions(rooms)}  style={{maxWidth:"18vw",width:"18vw",maxHeight:"65vh"}} >
												<RoomSelector onSelection={handleRoomSelection} setRooms={setRooms} initialRooms={rooms} updateInputValue={handleUpdateInputValue} overlay={true} />
											</OverlayPanel>										
										
										{/*hostsAndRooms && 
										<Button 
											icon="pi pi-times" 
											className="p-button-text" 
											onClick={handleClearSelection} 
											style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }} 
										/>
										*/}

										<label htmlFor="companyPhone">Hospedes e quartos <span className="requiredField">*</span></label>
									</span>

								</div>

								<div style={{flex:"0.6",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center",gap:"10px"}}>
									<Button label="Pesquisar" rounded className="pT10 headerButton tColorWhite" onClick={()=>{executeSearch()}} />

									<ConfirmPopup />
									<Button label="Recomeçar" text className="pT10 headerButton" onClick={(e) => {confirm(e)}} />
										
								</div>							
								
							</div>
							
							
						</div>												  
							
							  

	
					</div>				
				
				</div>

			</div> 
		</div>
    </div>


	<div id="navigationSection" style={{marginTop:"0",paddingTop:"0px",paddingBottom:"0px",position:"relative"}} className={isTabletOrMobile ? "login__wrapper mobile" : "login__wrapper"} >

			<div style={{minHeight:"600px",height:"auto"}} className={isTabletOrMobile ? "login__wrapper_form_wrapper mobile" : "login__wrapper_form_wrapper"}> 		
				<div className={isTabletOrMobile ? "login__form_wrapper mobile" : "login__form_wrapper"} style={{alignItems:"flex-start"}}>
							
					<div style={{flex:1,minHeight:"594px",marginLeft:"auto",marginRight:"auto"}}>
						
					<div className="searchWrapper" style={{padding: "3rem",display: "flex",flexDirection: "column",justifyContent: "center",alignItems: "flex-start",gap: "1rem",width: "100%"}}>
		
						<div className="selectBoxTitle animate flex-row justify-center align-items-center w100" style={{marginBottom:"10px",color: "#1D2939",fontSize: "2rem",fontStyle: "normal",fontWeight: 700,lineHeight: "2.12938rem",fontFamily:"Poppins",flex:"1",gap:"10px",maxWidth:"1440px"}} >
							
							<div style={{textAlign:"left",flex:1}}>{searchResultRowsNumber} resultados para {`${searchLocation.country.name}${searchLocation.city ? ', ' + searchLocation.city.name : ''}`}</div>
							<div style={{textAlign:"right"}}>


							</div> 
							
														
						</div>

						<div className="searchWrapperInput" style={{maxWidth:"1440px",width:"100%",flex:"1",marginTop:"10px",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
							
							<div className="navigationBreadcrumb" style={{width:"100%",flex:1,display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center",gap:"1rem"}}>

								<BreadCrumb model={breadcrumbArray} />
									
							</div>

							<div className="flex-row justify-center align-items-center" style={{gap:"10px"}}>
								
								<div style={{color: "#1D2939",fontFamily: "Roboto",fontSize: "1rem",fontStyle: "normal",fontWeight: 400}}>Ordenado por</div>
								<div>
									<Dropdown value={orderByFilter} onChange={(e) => {setOrderByFilter(e.target.value);handleSortResults(e.value)}} options={[{"code":'review_rating',name:"Avaliação (melhor primeiro)"},{"code":'distanceToCityCenter',name:"Distância do Centro (mais perto primeiro)"},{"code":'total_with_markup',name:"Preço (mais baixo primeiro)"}]} optionLabel="name" placeholder="Ordenar por" className="w100" style={{textAlign:"left",borderRadius: "1.5rem",border: "1px solid #814DE5",backgroundColor:"transparent"}}></Dropdown>
								</div>
								
							</div>
							
						</div>

						<div className="navigationContentWrapper flex-row justify-center align-items-start" style={{maxWidth:"1440px",marginTop:"2.3rem",width:"100%",flex:1,gap:"1.5rem"}}>

									<div className="filterWrapper flex-column justify-center align-items-start" style={{width:"28rem",height:"auto",gap:"1.5rem"}}>

										<div className="filterMap" style={{width:"100%",height:"13rem",borderRadius:"1rem",backgroundColor:"#FFFFFF",padding:"1rem"}}>
											
											<div className="filterMapImage" style={{position:"relative",width:"100%",height:"100%",borderRadius:"1rem",display:"flex",alignIitems:"center",justifyContent:"center",overflow:"hidden"}}>
												<img src={locationImage} style={{flex:1,width:"100%",height:"100%"}} className="noSelect" />	

												<Button label="Mostrar no mapa" rounded style={{position:"absolute",left:"50%",bottom:"1rem",transform:"translateX(-50%)"}} className="pT10 mR10 headerButton tColorWhite" onClick={()=>{setDialog("mapviewModal",true)}} />

											</div>

										</div>

										<div className="filterOptions flex-column justify-center align-items-center" style={{width:"100%",height:"auto",borderRadius:"1rem",backgroundColor:"#FFFFFF",padding:"2rem",gap:"2rem"}}>

											<div className="filterOptions_title w100" style={{color:"#1D2939",fontFamily: "Poppins",fontSize: "1.5rem",fontStyle: "normal",fontWeight: 500,lineHeight: "2.12938rem",textAlign:"left"}}>Filtrar resultados por:</div>

											{/* PRICE FILTER BOX */}
											<div className="filterOptions_priceFilter flex-column justify-center align-items-center w100" style={{gap:"1rem"}}>

												<div className="filterOptions_priceFilter__title w100" style={{textAlign:"left",color: "#475467",fontFamily: "Poppins",fontSize: "1.125rem",fontStyle: "normal",fontWeight: 400,lineHeight: "160.5%",letterSpacing: "0.1125rem"}}>Preço total da estadia</div>

												<div className="filterOptions_priceFilter__wrapper flex-column justify-center align-items-center" style={{width:"100%",height:"auto",borderRadius: "1.5rem",background: "#F9FAFD",padding:"1rem",gap:"2rem"}}>
												
													<div className="filterOptions_priceFilter__options flex-column justify-center align-items-center" style={{gap:"1rem",width:"90%"}}>
													
														<div style={{flex:1,width:"100%",textAlign:"left",color: "#475467",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "160.5%",letterSpacing: "0.1125rem"}}>{formatNumberCurrency(filterPriceRange[0],searchCurrency)} até {filterPriceRange[1] == searchFilters?.priceFilter?.high ? formatNumberCurrency(filterPriceRange[1],searchCurrency)+'+' : formatNumberCurrency(filterPriceRange[1],searchCurrency)}</div>
														
														<div style={{flex:1,width:"100%"}}><Slider value={filterPriceRange} min={searchFilters?.priceFilter?.low} max={searchFilters?.priceFilter?.high} onChange={(e) => setFilterPriceRange(e.value)} className="w100" range /></div>
														
													</div>
													
													<div className="filterOptions_priceFilter__actions" style={{width:"100%",borderRadius: "2.75rem",backgroundColor: "#FFFFFF",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>

														<div style={{flex:1,textAlign:"left"}}><Button label="Redefinir" text className="pT10 mR10 headerButton" onClick={()=>{setFilterPriceRange([searchFilters?.priceFilter?.low,searchFilters?.priceFilter?.high])}} /></div>
														<div style={{flex:1,textAlign:"right",padding:"5px"}}><Button label="Aplicar" rounded className="pT10 tColorWhite" onClick={()=>{executeRetrieveSearchResult()}} /></div>
														
													</div>													
												
												</div>

											</div>

											{/* AMENITIES FILTER BOX */}
											<div className="filterOptions_priceFilter flex-column justify-center align-items-center w100" style={{gap:"1rem"}}>

												<div className="filterOptions_priceFilter__title w100" style={{textAlign:"left",color: "#475467",fontFamily: "Poppins",fontSize: "1.125rem",fontStyle: "normal",fontWeight: 400,lineHeight: "160.5%",letterSpacing: "0.1125rem"}}>Comodidades</div>

												<div className="filterOptions_priceFilter__wrapper flex-column justify-center align-items-center" style={{width:"100%",height:"auto",borderRadius: "1.5rem",background: "#F9FAFD",padding:"1rem",gap:"2rem"}}>
												
													<div className="filterOptions_priceFilter__options flex-column justify-start align-items-center" style={{gap:"1rem",width:"90%"}}>
													
													{displayedAmenitiesItems.map((item, index) => (
														<div className="flex-row justify-start align-items-center w100" style={{wdith:"100%",gap:"1rem",textAlign:"left"}} key={index}>
															<Checkbox name="filterAmenitiesOption" inputId={"amenitie_"+item.amenity_code_ppn} value={item.amenity_code_ppn} onChange={onFilterAmenitiesChange} checked={filterAmenitiesValues.includes(item.amenity_code_ppn)} />
															<label htmlFor={"amenitie_"+item.amenity_code_ppn} >{item.amenity_name}</label>
														</div>
													))}

													<Button style={{marginTop:"0.5rem"}} label={showAllAmenities ? "Mostrar menos" : "Mostrar todos"} text className="pT10 mR10 headerButton" onClick={()=>{setShowAllAmenities(!showAllAmenities)}}  icon={showAllAmenities ? "pi pi-angle-up" : "pi pi-angle-down"} iconPos="right" />

													</div>

													<div className="filterOptions_priceFilter__actions" style={{width:"100%",borderRadius: "2.75rem",backgroundColor: "#FFFFFF",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>

														<div style={{flex:1,textAlign:"left"}}><Button label="Redefinir" text className="pT10 mR10 headerButton" onClick={()=>{setFilterAmenitiesValues([])}} /></div>
														<div style={{flex:1,textAlign:"right",padding:"5px"}}><Button label="Aplicar" rounded className="pT10 tColorWhite" onClick={()=>{setLoadingFiltering(true);handleFilterTimeout()}} /></div>
														
													</div>													
												
												</div>

											</div>


											{/* REFEICOES FILTER BOX */}
											{displayedFoodItems.length > 0 &&
											{/*	<div className="filterOptions_priceFilter flex-column justify-center align-items-center w100" style={{gap:"1rem"}}>

												<div className="filterOptions_priceFilter__title w100" style={{textAlign:"left",color: "#475467",fontFamily: "Poppins",fontSize: "1.125rem",fontStyle: "normal",fontWeight: 400,lineHeight: "160.5%",letterSpacing: "0.1125rem"}}>Refeições</div>

												<div className="filterOptions_priceFilter__wrapper flex-column justify-center align-items-center" style={{width:"100%",height:"auto",borderRadius: "1.5rem",background: "#F9FAFD",padding:"1rem",gap:"2rem"}}>
												
													<div className="filterOptions_priceFilter__options flex-column justify-start align-items-center" style={{gap:"1rem",width:"90%"}}>
													
													{displayedFoodItems.map((item, index) => (
														<div className="flex-row justify-start align-items-center w100" style={{wdith:"100%",gap:"1rem",textAlign:"left"}} key={index}>
															<Checkbox name="filterAFoodOption" inputId={"food_"+item.value} value={item.value} onChange={onFilterAmenitiesFoodChange} checked={filterAmenitiesFoodValues.includes(item.value)} />
															<label htmlFor={"amenitie_"+item.value} >{item.label}</label>
														</div>
													))}

													<Button style={{marginTop:"0.5rem"}} label={showAllAmenitiesFood ? "Mostrar menos" : "Mostrar todos"} text className="pT10 mR10 headerButton" onClick={()=>{setShowAllAmenitiesFood(!showAllAmenitiesFood)}}  icon={showAllAmenitiesFood ? "pi pi-angle-up" : "pi pi-angle-down"} iconPos="right" />

													</div>

													<div className="filterOptions_priceFilter__actions" style={{width:"100%",borderRadius: "2.75rem",backgroundColor: "#FFFFFF",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>

														<div style={{flex:1,textAlign:"left"}}><Button label="Redefinir" text className="pT10 mR10 headerButton" onClick={()=>{setFilterAmenitiesFoodValues([])}} /></div>
														<div style={{flex:1,textAlign:"right",padding:"5px"}}><Button label="Aplicar" rounded className="pT10 tColorWhite" onClick={()=>{setLoadingFiltering(true);handleFilterTimeout()}} /></div>
														
													</div>													
												
												</div>

											</div>*/}
											}

											{/* PoI FILTER BOX */}
											{/*<div className="filterOptions_priceFilter flex-column justify-center align-items-center w100" style={{gap:"1rem"}}>

												<div className="filterOptions_priceFilter__title w100" style={{textAlign:"left",color: "#475467",fontFamily: "Poppins",fontSize: "1.125rem",fontStyle: "normal",fontWeight: 400,lineHeight: "160.5%",letterSpacing: "0.1125rem"}}>Próximo aos locais de Interesse</div>

												<div className="filterOptions_priceFilter__wrapper flex-column justify-center align-items-center" style={{width:"100%",height:"auto",borderRadius: "1.5rem",background: "#F9FAFD",padding:"1rem",gap:"2rem"}}>
												
													<div className="filterOptions_priceFilter__options flex-column justify-start align-items-center" style={{gap:"1rem",width:"90%"}}>
													
													{displayedPOIItems.map((item, index) => (
														<div className="flex-row justify-start align-items-center w100" style={{wdith:"100%",gap:"1rem",textAlign:"left"}} key={index}>
															<Checkbox name="filterPOIOption" inputId={"poi_"+item.poiid_ppn} value={item.poiid_ppn} onChange={onFilterPOIChange} checked={filterPOIValues.includes(item.poiid_ppn)} />
															<label htmlFor={"amenitie_"+item.poiid_ppn} >{item.poi_name}</label>
														</div>
													))}

													<Button style={{marginTop:"0.5rem"}} label={showAllPOI ? "Mostrar menos" : "Mostrar todos"} text className="pT10 mR10 headerButton" onClick={()=>{setShowAllPOI(!showAllPOI)}}  icon={showAllPOI ? "pi pi-angle-up" : "pi pi-angle-down"} iconPos="right" />

													</div>

													<div className="filterOptions_priceFilter__actions" style={{width:"100%",borderRadius: "2.75rem",backgroundColor: "#FFFFFF",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>

														<div style={{flex:1,textAlign:"left"}}><Button label="Redefinir" text className="pT10 mR10 headerButton" onClick={()=>{setFilterPOIValues([])}} /></div>
														<div style={{flex:1,textAlign:"right",padding:"5px"}}><Button label="Aplicar" rounded className="pT10 tColorWhite" onClick={()=>{setLoadingFiltering(true);handleFilterTimeout()}} /></div>
														
													</div>													
												
												</div>

											</div>*/}


											{/* Neighborhood FILTER BOX */}
											{displayedNeighborhoodItems.length > 0 &&
											<div className="filterOptions_priceFilter flex-column justify-center align-items-center w100" style={{gap:"1rem"}}>

												<div className="filterOptions_priceFilter__title w100" style={{textAlign:"left",color: "#475467",fontFamily: "Poppins",fontSize: "1.125rem",fontStyle: "normal",fontWeight: 400,lineHeight: "160.5%",letterSpacing: "0.1125rem"}}>Vizinhança</div>

												<div className="filterOptions_priceFilter__wrapper flex-column justify-center align-items-center" style={{width:"100%",height:"auto",borderRadius: "1.5rem",background: "#F9FAFD",padding:"1rem",gap:"2rem"}}>
												
													<div className="filterOptions_priceFilter__options flex-column justify-start align-items-center" style={{gap:"1rem",width:"90%"}}>
													
													{displayedNeighborhoodItems.map((item, index) => (
														<div className="flex-row justify-start align-items-center w100" style={{wdith:"100%",gap:"1rem",textAlign:"left"}} key={index}>
															<Checkbox name="filterPOIOption" inputId={"neighborhood_"+item.id} value={item.code} onChange={onFilterNeighborhoodChange} checked={filterNeighborhoodValues.includes(item.code)} />
															<label htmlFor={"neighborhood_"+item.code} >{item.label}</label>
														</div>
													))}

													{displayedNeighborhoodItems > 10 &&
													<>
														<Button style={{marginTop:"0.5rem"}} label={showAllNeighborhood ? "Mostrar menos" : "Mostrar todos"} text className="pT10 mR10 headerButton" onClick={()=>{setShowAllPOI(!showAllNeighborhood)}}  icon={showAllNeighborhood ? "pi pi-angle-up" : "pi pi-angle-down"} iconPos="right" />
													</>
													}

													</div>

													<div className="filterOptions_priceFilter__actions" style={{width:"100%",borderRadius: "2.75rem",backgroundColor: "#FFFFFF",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>

														<div style={{flex:1,textAlign:"left"}}><Button label="Redefinir" text className="pT10 mR10 headerButton" onClick={()=>{setFilterPOIValues([])}} /></div>
														<div style={{flex:1,textAlign:"right",padding:"5px"}}><Button label="Aplicar" rounded className="pT10 tColorWhite" onClick={()=>{setLoadingFiltering(true);handleFilterTimeout()}} /></div>
														
													</div>													
												
												</div>

											</div>
											}


										</div>

									</div>
			

									<div className="resultsWrapper flex-column justify-center align-items-center" style={{gap:"1rem",flex:1}}>

										{resultsCards.map((item, index) =>{

											return (
												<div onClick={()=>handleCardClick(item._id+index)} key={item._id+index} className="resultsCard flex-row justify-center align-items-stretch w100 applyPointer" style={{gap:"1.5rem",padding:"1.5rem",borderRadius: "2.5rem",background: "#FFFFFF",position:"relative"}}>

													{/* Free Breakfast ribbon */}	
													{(item.value_adds && item.value_adds['value-add'].code == 'free_breakfast') &&
													<>
														<div className="ribbon">Café da manhã incluído</div>
													</>
													}													

													<div onClick={(e)=>handleCardImageClick(item.id,e)} className="resultsCard_image" style={{width:"15rem",height:"15rem",overflow:"hidden",display:"flex",justifyContent:"center",alignItems:"center",borderRadius:"1.5rem",position:"relative"}}>
							
														<img src={item.thumbnail_md} style={{flex:1}} />

														<i className="pi pi-search-plus imagePlusIcon"></i>

													</div>

													<div className="resultsCard_details flex-row justify-start align-items-stretch" style={{gap:"1rem",flex:1}}>

														<div  className="resultsCard_details__left flex-column justify-start align-items-start" style={{gap:"0.3rem",flex:1}}>

															<div className="resultsCard_details__name" style={{color: "#1D2939",fontFamily: "Poppins",fontSize: "1.1rem",
															fontStyle: "normal",fontWeight: 600,lineHeight: "normal",textAlign:"left"}}>{item.hotel_name}</div>

															<div className="resultsCard_details__location" style={{color: "#878787",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "normal",textAlign:"left"}}>{item.country}, {item.city} {item.neighborhood ? ", "+item.neighborhood : ""}</div>

															{item.distanceToCityCenter && (<div className="resultsCard_details__distance" style={{color: "#878787",fontFamily: "Titillium Web",fontSize: "0.9rem",fontStyle: "normal",fontWeight: 500,lineHeight: "normal",letterSpacing: "0.02875rem"}}>{item.distanceToCityCenter ? (item.distanceToCityCenter < 700 ? (item.distanceToCityCenter+" m do centro") : (item.distanceToCityCenter / 1000).toFixed(2)+" km do centro") : ""}</div>)}

															<div className="resultsCard_details__amenities">
																<ul style={{listStyleType: "none",textAlign:"left", columns: (item.amenities.length > 4) ? 1 : 1, padding: 0,lineHeight: "200%",color: "#878787",fontFamily: "Poppins",fontSize: "0.8rem",fontStyle: "normal",fontWeight: 400}}>

																{item.amenities.slice(0, 5).map((itemInner, indexInner) =>(<li ><i style={{color:"#00A3FF"}} className="pi pi-check"></i> {itemInner.amenity_name}</li>))}
																</ul>
															</div>
 
														</div>

														<div  className="resultsCard_details__right flex-column justify-start align-items-center" style={{gap:"1rem",flex:0.6}}>

															<div className="resultsCard_details__rating w100" style={{display: "flex",justifyContent: "end",alignItems: "start",flex:1}}>
																<div className="resultsCard_details__ratingValue" style={{display: "flex",width: "3.875rem",height: "auto",padding: "0.625rem",flexDirection: "column",justifyContent: "center",alignItems: "center",gap: "0.625rem",borderRadius: "1.8125rem",background: "#00A3FF",color: "#FFF",fontFamily: "Titillium Web",fontSize: "1rem",fontStyle: "normal",fontWeight: 600,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>{padronizarDecimal(item.review_rating)}</div>
															</div>


															<div className="resultsCard_details__prices flex-column justify-start align-items-center w100" style={{gap:"0.5rem",textAlign:"right"}}>

																	<div className="resultsCard_details__prices___dailyPrice w100" style={{color: "#878787",fontFamily: "Titillium Web",fontSize: "0.85rem",fontStyle: "normal",fontWeight: 400,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}> {/*Diária: {formatNumberCurrency(item.total_with_markup / dateRangeDays,item.display_price.currency)}  <br />*/}   {dateRangeDays > 1 ? `${dateRangeDays} diárias` : `${dateRangeDays} diária`} {/*, {rooms.length > 1 ? `${rooms.length} quartos` : `${rooms.length} quarto`} */} <br /> {hostsAndRoomsLabel}</div>
																
																	<div className="resultsCard_details__prices___dailyPrice w100" style={{color:" #814DE5",fontFamily: "Poppins",fontSize: "1.5rem",fontStyle: "normal",fontWeight: 600,lineHeight: "2.1rem"}}><span style={{fontSize: "0.9rem"}}>À partir de </span> {formatNumberCurrency(exchangeRateInitials == "BRL" ? item.total_with_markup * usdExchangeRate : item.total_with_markup,exchangeRateInitials)}</div>																
																	

															</div>

														</div>
														
													</div>

												</div>

											);
										})}
								

										{!contentLoading &&
											<div className="w100 flex-row justify-center align-items-center" style={{marginTop:"2rem"}}>
												{searchResultRowsNumber > paginatedResults && 
													(<Button label="Mostrar mais resultados" rounded className="pT10 tColorWhite" onClick={()=>{handleMoreResults()}} />)
												}
											</div>	
										}		


										{/* LOADING ACTION  */}
										{contentLoading &&
											<>						
												<div className="flex-row justify-center align-items-center" width="100%" height="16rem" style={{width: "100%",height: "16rem",borderRadius: "1.5rem",gap:"1rem",marginBottom:"1rem"}} >
													<Skeleton width="25rem" height="15rem" style={{width: "25rem",height: "15rem",borderRadius: "1.5rem"}} ></Skeleton>
													<Skeleton height="15rem" tyle={{flex:1,borderRadius: "1.5rem",height: "15rem"}} ></Skeleton>
												</div>

												<div className="flex-row justify-center align-items-center" width="100%" height="16rem" style={{width: "100%",height: "16rem",borderRadius: "1.5rem",gap:"1rem",marginBottom:"1rem"}} >
													<Skeleton width="25rem" height="15rem" style={{width: "25rem",height: "15rem",borderRadius: "1.5rem"}} ></Skeleton>
													<Skeleton height="15rem" tyle={{flex:1,borderRadius: "1.5rem",height: "15rem"}} ></Skeleton>
												</div>

												<div className="flex-row justify-center align-items-center" width="100%" height="16rem" style={{width: "100%",height: "16rem",borderRadius: "1.5rem",gap:"1rem",marginBottom:"1rem"}} >
													<Skeleton width="25rem" height="15rem" style={{width: "25rem",height: "15rem",borderRadius: "1.5rem"}} ></Skeleton>
													<Skeleton height="15rem" tyle={{flex:1,borderRadius: "1.5rem",height: "15rem"}} ></Skeleton>
												</div>

												<div className="flex-row justify-center align-items-center" width="100%" height="16rem" style={{width: "100%",height: "16rem",borderRadius: "1.5rem",gap:"1rem",marginBottom:"1rem"}} >
													<Skeleton width="25rem" height="15rem" style={{width: "25rem",height: "15rem",borderRadius: "1.5rem"}} ></Skeleton>
													<Skeleton height="15rem" tyle={{flex:1,borderRadius: "1.5rem",height: "15rem"}} ></Skeleton>
												</div>

												<div className="flex-row justify-center align-items-center" width="100%" height="16rem" style={{width: "100%",height: "16rem",borderRadius: "1.5rem",gap:"1rem",marginBottom:"1rem"}} >
													<Skeleton width="25rem" height="15rem" style={{width: "25rem",height: "15rem",borderRadius: "1.5rem"}} ></Skeleton>
													<Skeleton height="15rem" tyle={{flex:1,borderRadius: "1.5rem",height: "15rem"}} ></Skeleton>
												</div>
											</>				
										}										


									</div>

						</div>

					</div>				
					
					</div>

				</div> 
			</div>

    </div>	

		<ImageGalleryModal
			propertyId={selectedItem}
			setSelectedItem={setSelectedItem}
			showPropertyInfo={true}
			scrollBlock={true}				
		/>

		<MapViewModal
			type="address" // or coordinates
			address={addressLocationText} // set if type is address	
			zoom={addressZoom}	
			coordinates={null} // set if type is coordinates		
			scrollBlock={true}				
		/>

		<AlertView
			alert={alert}
			setAlert={setAlert}
			alertTitle={alertTitle}
			alertBody={alertBody}	
			scrollBlock={false}				
		/>

		<ExpireView
				alertExpire={alertExpire}
				setAlertExpire={setAlertExpire}
				alertTitleExpire={alertTitleExpire}
				alertBodyExpire={alertBodyExpire}	
				alertActionExpire={alertActionExpire}	
				scrollBlock={false}				
			/>	

		{/* Contac Us Section */}
		<ContactUs />

		{/* About Us Section */}
		<AboutUs />
	
	</>
  );
    
}

const mapStateToProps = store => ({
	dialog:store.appReducer.dialog,
	masterUser:store.appReducer.masterUser,
	currentAgency:store.appReducer.currentAgency,

	searchType:store.orderReducer.searchType,
	searchParams:store.orderReducer.searchParams,
	searchResultStatus:store.orderReducer.searchResultStatus,
	searchResultRowsNumber:store.orderReducer.searchResultRowsNumber,
	searchResultToken:store.orderReducer.searchResultToken,
	searchResultCurrentPage:store.orderReducer.searchResultCurrentPage,
	searchResultLimit:store.orderReducer.searchResultLimit,
	searchLocation:store.orderReducer.searchLocation,
	searchResultObj:store.orderReducer.searchResultObj,
	searchFilters:store.orderReducer.searchFilters,
	searchCurrency:store.orderReducer.searchCurrency,
	exchangeRates:store.orderReducer.exchangeRates
 });
const mapDispatchToProps = dispatch => bindActionCreators({ ...AppActions, ...OrderActions }, dispatch);

export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(HomePage);	


