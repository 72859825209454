import '../../Assets/Styles/register.css';
import React from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Button } from 'primereact/button'; 
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { Divider } from 'primereact/divider';
import { Panel } from 'primereact/panel';
import { Password } from 'primereact/password';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Skeleton } from 'primereact/skeleton';
import { Card } from 'primereact/card';
import { Menu } from 'primereact/menu';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { OverlayPanel } from 'primereact/overlaypanel';

import { ScrollPanel } from 'primereact/scrollpanel';

import { Toolbar } from 'primereact/toolbar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';


import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import moment from "moment";

// Load Actions
import * as AppActions from '../../Store/Actions/App';
import * as AgencyActions from '../../Store/Actions/Agency';

import titleTrainIcon from '../../Assets/Images/titleTrainIcon.svg';
import titleBusIcon from '../../Assets/Images/titleBusIcon.svg';
import titleTransferIcon from '../../Assets/Images/titleBusIcon.svg';
import titleRentCarIcon from '../../Assets/Images/titleBusIcon.svg';

// Load Components
import AlertView from '../../Components/AlertModal';
import ValidateView from '../../Components/ValidateModal';
import {
	validarCNPJ,
	validarCPF,
	validarEmail,
	validarTelefone,
	validarNome,
	maskCNPJ,
	maskCPF,
	maskPhone,
	formatCurrency,
	formatCurrencyNoSymbol,
	formatCurrencyWithDecimal,
	isJsonString,
	toJsonOrEmpty,	
	formatValueNoDecimals,
	formatPercentageNumber,
	formatCurrencyNoSymbolLimit,
	formatCurrencyDecimals,
	formatCurrencyDecimalsWithLimit,
	createDateAsLocal
} from '../../Components/Utils';

// UpdateService dialog component
const AgencyView = (props) => {

// set vars from props
const { 
	dialog,
	setDialog,
	retrieveAgency,
	retrieveAgenciesGroupProfiles,
	retrieveAgenciesComissionProfiles,
	selectedAgency,
	scrollBlock,
	loadLazyData,
	blockAgency,
	unblockAgency,
	reviewAgency,
	updateAgency
} = props;		

// useRef
const dt = React.useRef(null);

const comissionOverlay = React.useRef(null);
const dailyCreditOverlay = React.useRef(null);

// Loaders
const [loading, setLoading] = React.useState(true); 
const [saving, setSaving] = React.useState(false); 
const [blocking, setBlocking] = React.useState(false); 
const [changing, setChanging] = React.useState(false); 

const [loadingData, setLoadingData] = React.useState(true); 
const [loadingAction, setLoadingAction] = React.useState(false); // Loading action process

// set agency obj
const [row, setRow] = React.useState({});

const [companyDocument, setCompanyDocument] = React.useState('Carregando...');
const [companyLegalName, setCompanyLegalName] = React.useState('Carregando...');
const [companyFantasyName, setCompanyFantasyName] = React.useState('Carregando...');
const [companyType, setCompanyType] = React.useState(null);

const [companyEmail, setCompanyEmail] = React.useState('Carregando...');
const [companyPhone, setCompanyPhone] = React.useState('Carregando...');

const [personCompanyName, setPersonCompanyName] = React.useState('Carregando...');
const [comissionProfile, setComissionProfile] = React.useState({});
const [groupProfile, setGroupProfile] = React.useState({});
const [dailyCreditLimit, setDailyCreditLimit] = React.useState('Carregando...');

const [comissionProfilePrevious, setComissionProfilePrevious] = React.useState({});
const [groupProfilePrevious, setGroupProfilePrevious] = React.useState({});
const [dailyCreditLimitPrevious, setDailyCreditLimitPrevious] = React.useState('Carregando...');
const [companyEmailPrevious, setCompanyEmailPrevious] = React.useState('Carregando...');
const [companyPhonePrevious, setCompanyPhonePrevious] = React.useState('Carregando...');

const [companyStateAuto, setCompanyStateAuto] = React.useState('Carregando...');
const [companyCityAuto, setCompanyCityAuto] = React.useState('Carregando...');

const [personName, setPersonName] = React.useState('Carregando...');
const [personDocument, setPersonDocument] = React.useState('Carregando...');
const [personBirthdate, setPersonBirthdate] = React.useState('Carregando...');
const [personPhone, setPersonPhone] = React.useState('Carregando...');
const [email, setEmail] = React.useState('Carregando...');

const [rfb, setRfb] = React.useState({});
const [socios, setSocios] = React.useState([]);

const [acceptTerm, setAcceptTerm] = React.useState(false);

// Set comissions linked to Comission Group
const [generalComission, setGeneralComission] = React.useState({});
const [trainCustomComission, setTrainCustomComission] = React.useState({});
const [busCustomComission, setBusCustomComission] = React.useState({});
const [transferCustomComission, setTransferCustomComission] = React.useState({});
const [rentcarCustomComission, setRentCarCustomComission] = React.useState({});

// Set Company custom comission
const [trainCompanyCustomComission, setTrainCompanyCustomComission] = React.useState({});
const [busCompanyCustomComission, setBusCompanyCustomComission] = React.useState({});
const [transferCompanyCustomComission, setTransferCompanyCustomComission] = React.useState({});
const [rentcarCompanyCustomComission, setRentCarCompanyCustomComission] = React.useState({});

// Alert control
const [alert, setAlert] = React.useState(false); 
const [alertTitle, setAlertTitle] = React.useState("Informações"); 
const [alertAction, setAlertAction] = React.useState(""); 
const [alertBody, setAlertBody] = React.useState("Conteúdo dinâmico"); 

const [comissionProfilesOptions, setComissionProfilesOptions] = React.useState([]);
const [groupProfilesOptions, setGroupProfilesOptions] = React.useState([]);

const [items, setItems] = React.useState([]); // Menu actions Items

const accept = (action) => {

	// Set loading content true
	setLoadingAction(true);		

	if (action == "review") {

		// Disable body scroll
		/*setTimeout(function() {

			setLoadingAction(false);
			
			setAlert(true);
			setAlertTitle("Tudo certo! Status alterado!");
			setAlertBody("A empresa foi colocada em análise.");	
			setAlertAction("closeAgencyModal");	

			// Load Parent grid
			loadLazyData();
			
		},3000);	*/

		var props = {
			setLoadingAction:setLoadingAction,
			setAlert:setAlert,
			setAlertTitle:setAlertTitle,
			setAlertBody:setAlertBody,
			setAlertAction:setAlertAction,
			loadLazyData:loadLazyData
		}
		var data = {
		  agencyId: selectedAgency.id,
		  reviewReason:null,

		}
		reviewAgency(data,props);			

	} else if (action == "delete") {

		// Disable body scroll
		setTimeout(function() {

			setLoadingAction(false);
			
			setAlert(true);
			setAlertTitle("Tudo certo! Cadastro excluído!");
			setAlertBody("A empresa e todos os usuários e dados foram excluídos da plataforma.");	
			setAlertAction("closeAgencyModal");	

			// Load Parent grid
			loadLazyData();
			
		},3000);	

	} else if (action == "update") {

		// set if occured any change and status i approved
		let anychangeFlag = false;
		if ((companyEmail != companyEmailPrevious || companyPhone != companyPhonePrevious || comissionProfile.code != comissionProfilePrevious.code || groupProfile.code != groupProfilePrevious.code) && row.status === 1) {
			anychangeFlag = true;
		} // end if

		var props = {
			setLoadingAction:setLoadingAction,
			setAlert:setAlert,
			setAlertTitle:setAlertTitle,
			setAlertBody:setAlertBody,
			setAlertAction:setAlertAction,
			loadLazyData:loadLazyData,
			retrieveAgencyInfo:retrieveAgencyInfo
		}
		var data = {
		  agencyId: selectedAgency.id,
		  companyEmail:companyEmail != companyEmailPrevious ? companyEmail : null,
		  companyPhone:companyPhone != companyPhonePrevious ? companyPhone : null,
		  comissionProfile:comissionProfile.code != comissionProfilePrevious.code ? comissionProfile.code : null,
		  groupProfile:groupProfile.code != groupProfilePrevious.code ? groupProfile.code : null,
		  dailyCreditLimit:dailyCreditLimit != dailyCreditLimitPrevious ? dailyCreditLimit : null,
		  notifyAgency:anychangeFlag		 
		}
		updateAgency(data,props);			

	} else if (action == "unblock") {

		// Disable body scroll
		/*setTimeout(function() {

			setLoadingAction(false);
			
			setAlert(true);
			setAlertTitle("Tudo certo! Cadastro desbloqueado!");
			setAlertBody("A empresa foi desbloqueada e o responsável foi notificado. À partir de agora os usuários vinculados a empresa conseguirão utilizar a plataforma.");	
			setAlertAction("closeAgencyModal");	

			// Load Parent grid
			loadLazyData();
			
		},3000);	*/

		var props = {
			setLoadingAction:setLoadingAction,
			setAlert:setAlert,
			setAlertTitle:setAlertTitle,
			setAlertBody:setAlertBody,
			setAlertAction:setAlertAction,
			loadLazyData:loadLazyData
		}
		var data = {
		  agencyId: selectedAgency.id
		}
		unblockAgency(data,props);			

	} 	

	// Disable body scroll
	setTimeout(function() {
		document.body.classList.add('p-overflow-hidden');	
	},300);	

}

const reject = () => {
	console.log('NAO GRAVAR....');

	// Disable body scroll
	setTimeout(function() {
		document.body.classList.add('p-overflow-hidden');	
	},300);		

}


/* Update prompt confirm */
const updateConfirm = (updateComissionProfile=false,updateGroupProfile=false,updateDailyCreditLimit=false,updateCompanyEmail=false,updateCompanyPhone=false) => {

	// Set updated fields
	let updatedFields = "<ul>";
	if (updateComissionProfile) updatedFields += "<li>Perfil de comissão</li>";
	if (updateGroupProfile) updatedFields += "<li>Perfil da agência</li>";
	if (updateDailyCreditLimit) updatedFields += "<li>Limite de crédito diário</li>";
	if (updateCompanyEmail) updatedFields += "<li>Email da empresa</li>";
	if (updateCompanyPhone) updatedFields += "<li>Telefone da empresa</li>";
	updatedFields += "</ul>";

	confirmDialog({
		message: <UpdateMessage updateComissionProfile={updateComissionProfile} updateGroupProfile={updateGroupProfile} updateDailyCreditLimit={updateDailyCreditLimit} updateCompanyEmail={updateCompanyEmail} updateCompanyPhone={updateCompanyPhone} />,
		header: 'Tem certeza que quer prosseguir com a atualização?',
		icon: 'pi pi-exclamation-triangle',
		accept: () => accept('update'), // Aqui passamos o parâmetro 'update'
		reject,
		acceptLabel:"Sim, tenho certeza!",
		rejectLabel:"Fechar",
		contentStyle:{maxWidth:"40vw",width:"40vw"}
	});
};

/* Reprove message custom component to add input and checkbox */
const UpdateMessage = ({updateComissionProfile,updateGroupProfile,updateDailyCreditLimit,updateCompanyEmail,updateCompanyPhone}) => {

    const updates = [
        { key: 'updateComissionProfile', label: 'Perfil de comissão', state:updateComissionProfile ? true : false },
        { key: 'updateGroupProfile', label: 'Perfil da agência', state:updateGroupProfile ? true : false },
        { key: 'updateDailyCreditLimit', label: 'Limite de crédito diário', state:updateDailyCreditLimit ? true : false },
        { key: 'updateCompanyEmail', label: 'Email da empresa', state:updateCompanyEmail ? true : false },
        { key: 'updateCompanyPhone', label: 'Telefone da empresa', state:updateCompanyPhone ? true : false }
    ];
 
	// set if occured anychange 
	let anychangeFlag = false;
	if (updateComissionProfile || updateGroupProfile || updateCompanyEmail || updateCompanyPhone) {
		anychangeFlag = true;
	} // end if

    // Filtrar e criar uma lista de elementos JSX para os campos atualizados
    const updatedFieldsList = updates
        .filter(item => item.key && item.state === true) // Substitua item.key pelo seu estado correspondente se estiver usando estado
        .map((item, index) => <li key={index}>{item.label}</li>);	

return (
	<div>
		<p>
			Verifique abaixo as informações que serão atualizadas e confirme.
		</p>
		<ul>{updatedFieldsList}</ul>  
		{(row.status == 1 && anychangeFlag) &&
			<>
			<p><strong>ATENÇÃO:</strong> Será enviado um email para a agência com as informação que foram alteradas, com exceção do limite de crédito.</p>
			</>         
		}
	</div>
);
};

/* Accept action for Reprove  */
const acceptBlock = (action, reason) => {

    console.log('GRAVAR....acao: ' + action);
    console.log('Motivo:', reason);
    console.log('BLOCK....');

	// Set loading content true
	setLoadingAction(true);		

	if (action == "block") {

		// Disable body scroll
	/*	setTimeout(function() {

			setLoadingAction(false);
			
			setAlert(true);
			setAlertTitle("Tudo certo! Cadastro bloqueado!");
			setAlertBody("A empresa foi bloqueada e o responsável foi notificado com o motivo do bloqueio. À partir de agora nenhum usuário relacionado a empresa conseguirá utilizar a plataforma.");	
			setAlertAction("closeAgencyModal");	

			// Load Parent grid
			loadLazyData();
			
		},3000);*/	

		var props = {
			setLoadingAction:setLoadingAction,
			setAlert:setAlert,
			setAlertTitle:setAlertTitle,
			setAlertBody:setAlertBody,
			setAlertAction:setAlertAction,
			loadLazyData:loadLazyData
		}
		var data = {
		  agencyId: selectedAgency.id,
		  blockReason:reason,

		}
		blockAgency(data,props);	

	} 	

    // Disable body scroll
    setTimeout(function() {
        document.body.classList.add('p-overflow-hidden');  
    },300); 

}

/* Block  message custom component to add input  */
const ConfirmWithReason = ({onReasonChange}) => {

    const [reason, setReason] = React.useState('');
    
    // Callbacks para atualizar o estado no componente pai
    const handleReasonChange = (e) => {
        setReason(e.target.value);
        onReasonChange(e.target.value);
    };

    return (
        <div>
            <p>
				Ao realizar o bloqueio dessa empresa nenhum usuário vinculado a ela conseguirá fazer login ou utilizar a plataforma. O responsável receberá
                uma notificação sobre o bloqueio, porém, o motivo é armazenado para uso interno.
            </p>
            <InputTextarea
                value={reason}
                onChange={handleReasonChange}
                rows={3}
                cols={50}
                placeholder="Digite o motivo do bloqueio para controle interno."
            />
        </div>
    );
};

/* Block prompt confirm */
const blockConfirm = () => {

	let reason = ''

	confirmDialog({
		header: 'Tem certeza que quer bloquear o cadastro?',
		message: <ConfirmWithReason onReasonChange={r => reason = r} />,
		icon: 'pi pi-exclamation-triangle',
		accept: () => acceptBlock('block', reason),
		reject,
		acceptLabel:"Sim, tenho certeza!",
		rejectLabel:"Fechar",
		contentStyle:{maxWidth:"40vw",width:"40vw"}
	});
};

/* Unblock prompt confirm */
const unblockConfirm = () => {

	let reason = ''

	confirmDialog({
		header: 'Tem certeza que quer desbloquear o cadastro?',
		message: "Ao desbloquear o cadastro todos os usuários vinculados a essa agência terão acesso a plataforma.",
		icon: 'pi pi-exclamation-triangle',
		accept: () => accept('unblock', reason),
		reject,
		acceptLabel:"Sim, tenho certeza!",
		rejectLabel:"Fechar",
		contentStyle:{maxWidth:"40vw",width:"40vw"}
	});
};

/* Delete prompt confirm */
const excludeConfirm = () => {
	confirmDialog({
		header: 'Tem certeza que quer excluir esse cadastro?',
		message: 'Ao realizar a exclusão, que é irreversível, a empresa e qualquer usuário vinculado a ela serão excluídos da plataforma.',
		icon: 'pi pi-exclamation-triangle',
		accept: () => accept('delete'), // Aqui passamos o parâmetro 'update'
		reject,
		acceptLabel:"Sim, tenho certeza!",
		rejectLabel:"Fechar",
		contentStyle:{maxWidth:"40vw",width:"40vw"}
	});
};

/* Review prompt confirm */
const reviewConfirm = () => {
	confirmDialog({
		header: 'Tem certeza que quer colocar cadastro em análise?',
		message: 'Ao realizar essa ação, a empresa entrará em análise. Até que aprovada, qualquer usuário vinculado a ela não conseguirá utilizar a plataforma.',
		icon: 'pi pi-exclamation-triangle',
		accept: () => accept('review'), // Aqui passamos o parâmetro 'update'
		reject,
		acceptLabel:"Sim, tenho certeza!",
		rejectLabel:"Fechar",
		contentStyle:{maxWidth:"40vw",width:"40vw"}
	});
};

const retrieveAgencyInfo = () => {
	
	setLoadingData(true);

	setGeneralComission({});
	setTrainCustomComission({});
	setBusCustomComission({});
	setTransferCustomComission({});
	setRentCarCustomComission({});
	
	setTrainCompanyCustomComission({});
	setBusCompanyCustomComission({});
	setTransferCompanyCustomComission({});
	setRentCarCompanyCustomComission({});

	setComissions([]);
	
	var props = {
		setSaving:setSaving,
		setAlert:setAlert,
		setAlertTitle:setAlertTitle,
		setAlertBody:setAlertBody,
		setLoadingData:setLoadingData,
		setRow:setRow
	}
	var data = {
	  agencyId: selectedAgency.id
	}
	retrieveAgency(data,props);

} // end retrieveDocumentInfo

// Retrieve dropdown valures from API
const retrieveDropdownsValues = () => {
		
	var props = {
		setAlert:setAlert,
		setAlertTitle:setAlertTitle,
		setAlertBody:setAlertBody,
		setRows:setGroupProfilesOptions,
		setLoading:setLoading,
	}
	var data = {
	}
	retrieveAgenciesGroupProfiles(data,props);

	var props = {
		setAlert:setAlert,
		setAlertTitle:setAlertTitle,
		setAlertBody:setAlertBody,
		setRows:setComissionProfilesOptions,
		setLoading:setLoading,
	}
	var data = {
	}
	retrieveAgenciesComissionProfiles(data,props);

} // end retrieveDropdownsValues


/* Update Company */
const update = () => {


	// Check required fields
	if ( !companyEmail || !companyPhone || !groupProfile || !comissionProfile || (!dailyCreditLimit || dailyCreditLimit === 0) ) {
		setAlert(true);
		setAlertTitle("Verifique as informações");
		setAlertBody("Um ou mais campos não estão preenchidos. É necessário que preencha todos os campos marcados com * para prosseguir com o cadastro.");	
		return;
	} // end if
	
	// Check any field changed
	if ( (companyEmail == companyEmailPrevious) && (companyPhone == companyPhonePrevious) && (groupProfile.code == groupProfilePrevious.code) && (comissionProfile.code == comissionProfilePrevious.code) && (dailyCreditLimit == dailyCreditLimitPrevious) ) {
		setAlert(true);
		setAlertTitle("Nada foi alterado!");
		setAlertBody("Nenhum campo que pode ser editado foi alterado. Não há o que gravar.");	
		return;
	} // end if
	
	
	if (!validarEmail(companyEmail)) {
		setAlert(true);
		setAlertTitle("Verifique o Email");
		setAlertBody("O email da empresa informado é inválido. Verifique novamente.");	
		return;		
	} // end if
	if (!validarTelefone(companyPhone)) {
		setAlert(true);
		setAlertTitle("Verifique o Telefone");
		setAlertBody("O telefone da empresa informado é inválido. Verifique novamente.");	
		return;		
	} // end if
	
	// Set fields to be update
	let comissionProfileFlag = false; 
	let groupProfileFlag =  false; 
	let dailyCreditLimitFlag = false; 
	let companyEmailFlag =  false;
	let companyPhoneFlag =  false;
	if (comissionProfile.code != comissionProfilePrevious.code) { comissionProfileFlag = true; }
	if (groupProfile.code != groupProfilePrevious.code) { groupProfileFlag = true; }
	if (dailyCreditLimit != dailyCreditLimitPrevious) { dailyCreditLimitFlag = true; }
	if (companyEmail != companyEmailPrevious) { companyEmailFlag = true; }
	if (companyPhone != companyPhonePrevious) { companyPhoneFlag = true; }
	
	// Prompt update
	updateConfirm(comissionProfileFlag,groupProfileFlag,dailyCreditLimitFlag,companyEmailFlag,companyPhoneFlag);
	
		
	}
	

/* Reset form fields and close dialog */
const closeDialog = () => {
	
	// Reset form fields
	// ...

	// close dialog
	setDialog('agencyModal',false);
		
}

// Execute when component opens
React.useEffect(() => {

	// Enter and closes modal
	setDialog('agencyModal',false);
	
	// Set loading content true
	setLoadingData(true);	
			
},[]);

const menuLeft = React.useRef(null);

// Execute when dialog opens
React.useEffect(() => {

	// Only runs when  dialog opens
	if (dialog.target == 'agencyModal' && dialog.status) {

		// Retrieve current reservation
		retrieveAgencyInfo();

		// Load dropdown values for advanced filter 
		retrieveDropdownsValues();  		
			
	} // end if
	else {
				
		// Set loading content true
		setLoadingData(true);	

	} // end else
	
	
	// Reset all fields when closes or open dialog
	// When closes
    return () => {
      
		setRow({});
		setCompanyDocument('Carregando...');
		setCompanyLegalName('Carregando...');
		setCompanyFantasyName('Carregando...');
		setCompanyEmail('Carregando...');
		setCompanyPhone('Carregando...');	
		setPersonCompanyName('Carregando...');
		setComissionProfile({});
		setGroupProfile({});
		setDailyCreditLimit('Carregando...');
		setCompanyStateAuto('Carregando...');
		setCompanyCityAuto('Carregando...');
		setPersonName('Carregando...');
		setPersonDocument('Carregando...');
		setPersonBirthdate('Carregando...');
		setPersonPhone('Carregando...');
		setEmail('Carregando...');
		setRfb({});		
		setSocios([]);
		setComissions([]); 

		setCompanyEmailPrevious('Carregando...');	
		setCompanyPhonePrevious('Carregando...');
		setComissionProfilePrevious({});
		setGroupProfilePrevious({});		
		setDailyCreditLimitPrevious('Carregando...');		

		setGeneralComission({});
		setTrainCustomComission({});
		setBusCustomComission({});
		setTransferCustomComission({});
		setRentCarCustomComission({});
		
		setTrainCompanyCustomComission({});
		setBusCompanyCustomComission({});
		setTransferCompanyCustomComission({});
		setRentCarCompanyCustomComission({});	

		setEditComissionDialog(false);
		setComissionDialog(false);
		setDeleteComissionDialog(false);
		setDeleteComissionsDialog(false);

		// Enable body scroll if user close faster than remote call - need to adjust
		if (dialog.target == 'agencyModal' && !dialog.status) {
			setTimeout(function() {
				console.log('FECHOU [2]');
				document.body.classList.remove('p-overflow-hidden');	
			},2000);	
		} // end if
		
    };	
			
},[dialog]);


// Execute when row change
React.useEffect(() => {

	// Only runs if id is set
	if (row.id) { 

			let companyDocumentValue = row.CompanyDocument.formattedValue;
			setCompanyDocument(companyDocumentValue);
			
			let companyLegalNameValue = row.companyName;
			setCompanyLegalName(companyLegalNameValue);
			
			let companyFantasyNameValue = row.tradingName;
			setCompanyFantasyName(companyFantasyNameValue);
			
			let companyEmailValue = row.email;
			setCompanyEmail(companyEmailValue);

			let companyTypeValue = row.type;
			setCompanyType(companyTypeValue);			
			
			let companyPhoneValue = maskPhone(row.phone);
			setCompanyPhone(companyPhoneValue);
			
			let companyEmailPreviousValue = row.email;
			setCompanyEmailPrevious(companyEmailPreviousValue);
			
			let companyPhonePreviousValue = maskPhone(row.phone);
			setCompanyPhonePrevious(companyPhonePreviousValue);
			
			let personCompanyNameValue = row.CompanyPeople[0].completeName;
			setPersonCompanyName(personCompanyNameValue);
			
			let comissionProfileValue = {code: row.CompanyComissionGroup.id, name: row.CompanyComissionGroup.name+' - '+row.CompanyComissionGroup.alias};
			setComissionProfile(comissionProfileValue);
			
			let groupProfileValue = {code: row.CompanyProfileGroup.id, name: row.CompanyProfileGroup.name};
			setGroupProfile(groupProfileValue);
			
			let comissionProfilePreviousValue = {code: row.CompanyComissionGroup.id, name: row.CompanyComissionGroup.name+' - '+row.CompanyComissionGroup.alias};
			setComissionProfilePrevious(comissionProfilePreviousValue);
			
			let groupProfilePreviousValue = {code: row.CompanyProfileGroup.id, name: row.CompanyProfileGroup.name};
			setGroupProfilePrevious(groupProfilePreviousValue);
			
			let dailyCreditLimitValue = (row.CompanyDailyCreditLimits?.length ?? "0") > 0 ? formatValueNoDecimals(row.CompanyDailyCreditLimits[0].limit) : "0"; 
			setDailyCreditLimit(dailyCreditLimitValue);
			
			let dailyCreditLimitPreviousValue = (row.CompanyDailyCreditLimits?.length ?? "0") > 0 ? formatValueNoDecimals(row.CompanyDailyCreditLimits[0].limit) : "0";  
			setDailyCreditLimitPrevious(dailyCreditLimitPreviousValue);
			
			let companyStateAutoValue = row.StateLabel;
			setCompanyStateAuto(companyStateAutoValue);
			
			let companyCityAutoValue =row.CityLabel;
			setCompanyCityAuto(companyCityAutoValue);
			
			let personNameValue = row.CompanyPeople[0].completeName;
			setPersonName(personNameValue);
			
			let personDocumentValue = row.CompanyPeople[0].CompanyPersonDocuments[0].formattedValue;
			setPersonDocument(personDocumentValue);
			
			let personBirthdateValue = row.CompanyPeople[0].birthDate ? moment(row.CompanyPeople[0].birthDate, 'YYYY-MM-DD').format('DD/MM/YYYY') : "Não definida";
			setPersonBirthdate(personBirthdateValue);
			
			let personPhoneValue = maskPhone(row.CompanyPeople[0].phone);
			setPersonPhone(personPhoneValue);
			
			let emailValue = row.CompanyPeople[0].CompanyPersonUsers[0].email;
			setEmail(emailValue);
			
			let inicioAtividadeItem = row.CompanyDocumentMeta.find(item => item.key === "inicioAtividade");
			const inicioAtividadeData = isJsonString(inicioAtividadeItem.value) ? toJsonOrEmpty(inicioAtividadeItem.value) : "Não definido";
		
			let simplesNacionalItem = row.CompanyDocumentMeta.find(item => item.key === "simplesNacional");
			const simplesNacionalData = isJsonString(simplesNacionalItem.value) ? toJsonOrEmpty(simplesNacionalItem.value) : "Não definido";
						
			let cnaeItem = row.CompanyDocumentMeta.find(item => item.key === "cnae");
			const cnaeData = isJsonString(cnaeItem.value) ? toJsonOrEmpty(cnaeItem.value) : "Não definido";
			
			let enderecoItem = row.CompanyDocumentMeta.find(item => item.key === "matrizEndereco");
			const enderecoData = isJsonString(enderecoItem.value) ? toJsonOrEmpty(enderecoItem.value) : "Não definido";
			
			let naturezaItem = row.CompanyDocumentMeta.find(item => item.key === "naturezaJuridica");
			const naturezaData = isJsonString(naturezaItem.value) ? toJsonOrEmpty(naturezaItem.value) : "Não definido";
			
			let situacaoItem = row.CompanyDocumentMeta.find(item => item.key === "situacao");
			const situacaoData = isJsonString(situacaoItem.value) ? toJsonOrEmpty(situacaoItem.value) : "Não definido";
			
			let porteItem = row.CompanyDocumentMeta.find(item => item.key === "porte");
			const porteData = isJsonString(porteItem.value) ? toJsonOrEmpty(porteItem.value) : "Não definido";

			let sociosItem = row.CompanyDocumentMeta.find(item => item.key === "socios");
			const sociosData = sociosItem ?  (isJsonString(sociosItem.value) ? toJsonOrEmpty(sociosItem.value) : "Não definido") : null;
			setSocios(Array.isArray(sociosData) ? sociosData : []);

			let capitalSocialItem = row.CompanyDocumentMeta.find(item => item.key === "capitalSocial");
			const capitalSocialData = isJsonString(capitalSocialItem.value) ? toJsonOrEmpty(capitalSocialItem.value) : "Não definido";
			
			let rfbValue = {
				inicioAtividade: inicioAtividadeData, 
				simplesNacional: simplesNacionalData,
				capitalSocial: capitalSocialData,
				matrizEndereco: enderecoData,
				situacao: situacaoData,
				naturezaJuridica: naturezaData,
				cnae: cnaeData,
				cnae_secundario: Array.isArray(cnaeData.secundario) ? cnaeData.secundario : [],			
				porte: porteData, 
				socios: Array.isArray(sociosData) ? sociosData : []
			};
			setRfb(rfbValue);
 
			/* COMISSIONS */

			// Set General comission
			if ((row.CompanyComissionGroup?.CompanyComissionGroupPaymentMethods?.length ?? 0) > 0) {

				let findPix = row.CompanyComissionGroup.CompanyComissionGroupPaymentMethods.find(method => method.SystemRepoPaymentMethod.code === "pix");
				const pixValue = findPix === undefined ? null : findPix.comission;

				let findCc = row.CompanyComissionGroup.CompanyComissionGroupPaymentMethods.find(method => method.SystemRepoPaymentMethod.code === "cc");
				const ccValue = findCc === undefined ? null : findCc.comission;				

				setGeneralComission({
					pix:pixValue,
					cc:ccValue
				}); 
			} // end if

			
			// Set Bus custom comission
			if ((row.CompanyComissionGroup?.CompanyComissionGroupCustoms?.length ?? 0) > 0) {

				let findPix = row.CompanyComissionGroup.CompanyComissionGroupCustoms.find(method => method.SystemRepoPaymentMethod.code === "pix" && method.SystemRepoTransport.transportType === "bus");
				const pixValue = findPix === undefined ? null : findPix.comission;

				let findCc = row.CompanyComissionGroup.CompanyComissionGroupCustoms.find(method => method.SystemRepoPaymentMethod.code === "cc" && method.SystemRepoTransport.transportType === "bus");
				const ccValue = findCc === undefined ? null : findCc.comission;				

				if (pixValue || ccValue) {
					setBusCustomComission({
						pix:pixValue,
						cc:ccValue
					}); 
				} // end if
			} // end if			

			// Set Train custom comission
			if ((row.CompanyComissionGroup?.CompanyComissionGroupCustoms?.length ?? 0) > 0) {

				let findPix = row.CompanyComissionGroup.CompanyComissionGroupCustoms.find(method => method.SystemRepoPaymentMethod.code === "pix" && method.SystemRepoTransport.transportType === "train");
				const pixValue = findPix === undefined ? null : findPix.comission;

				let findCc = row.CompanyComissionGroup.CompanyComissionGroupCustoms.find(method => method.SystemRepoPaymentMethod.code === "cc" && method.SystemRepoTransport.transportType === "train");
				const ccValue = findCc === undefined ? null : findCc.comission;				

				if (pixValue || ccValue) {
					setTrainCustomComission({
						pix:pixValue,
						cc:ccValue
					}); 
				} // end if
			} // end if	

			// Set Transfer custom comission
			if ((row.CompanyComissionGroup?.CompanyComissionGroupCustoms?.length ?? 0) > 0) {

				let findPix = row.CompanyComissionGroup.CompanyComissionGroupCustoms.find(method => method.SystemRepoPaymentMethod.code === "pix" && method.SystemRepoTransport.transportType === "transfer");
				const pixValue = findPix === undefined ? null : findPix.comission;

				let findCc = row.CompanyComissionGroup.CompanyComissionGroupCustoms.find(method => method.SystemRepoPaymentMethod.code === "cc" && method.SystemRepoTransport.transportType === "transfer");
				const ccValue = findCc === undefined ? null : findCc.comission;				

				if (pixValue || ccValue) {
					setTransferCustomComission({
						pix:pixValue,
						cc:ccValue
					}); 
				} // end if
			} // end if			
						
			
			// Set Rent Car custom comission
			if ((row.CompanyComissionGroup?.CompanyComissionGroupCustoms?.length ?? 0) > 0) {

				let findPix = row.CompanyComissionGroup.CompanyComissionGroupCustoms.find(method => method.SystemRepoPaymentMethod.code === "pix" && method.SystemRepoTransport.transportType === "car");
				const pixValue = findPix === undefined ? null : findPix.comission;

				let findCc = row.CompanyComissionGroup.CompanyComissionGroupCustoms.find(method => method.SystemRepoPaymentMethod.code === "cc" && method.SystemRepoTransport.transportType === "car");
				const ccValue = findCc === undefined ? null : findCc.comission;				

				if (pixValue || ccValue) {
					setRentCarCustomComission({
						pix:pixValue,
						cc:ccValue
					}); 
				} // end if
			} // end if				

			
			// Set Company Custom comissions

			// Company Train  custom comission
			if ((row.CompanyCustomComissions?.length ?? 0) > 0) {

				let findPix = row.CompanyCustomComissions.find(method => method.SystemRepoPaymentMethod.code === "pix" && method.SystemRepoTransport.transportType === "train");
				const pixValue = findPix === undefined ? null : findPix.comission;
				const pixExpireAt = findPix === undefined ? null : moment(findPix.expireAt).format("DD/MM/YYYY");

				let findCc = row.CompanyCustomComissions.find(method => method.SystemRepoPaymentMethod.code === "cc" && method.SystemRepoTransport.transportType === "train");
				const ccValue = findCc === undefined ? null : findCc.comission;	
				const ccExpireAt = findCc === undefined ? null : moment(findCc.expireAt).format("DD/MM/YYYY");

				if (pixValue || ccValue) {
					setTrainCompanyCustomComission({
						pix:{value: pixValue , expireAt: pixExpireAt},
						cc:{value: ccValue , expireAt: ccExpireAt}
					}); 
				} // end if
			} // end if

			// Company Bus custom comission
			if ((row.CompanyCustomComissions?.length ?? 0) > 0) {

				let findPix = row.CompanyCustomComissions.find(method => method.SystemRepoPaymentMethod.code === "pix" && method.SystemRepoTransport.transportType === "bus");
				const pixValue = findPix === undefined ? null : findPix.comission;
				const pixExpireAt = findPix === undefined ? null : moment(findPix.expireAt).format("DD/MM/YYYY");

				let findCc = row.CompanyCustomComissions.find(method => method.SystemRepoPaymentMethod.code === "cc" && method.SystemRepoTransport.transportType === "bus");
				const ccValue = findCc === undefined ? null : findCc.comission;				
				const ccExpireAt = findCc === undefined ? null : moment(findCc.expireAt).format("DD/MM/YYYY");

				if (pixValue || ccValue) {
					setBusCompanyCustomComission({
						pix:{value: pixValue , expireAt: pixExpireAt},
						cc:{value: ccValue , expireAt: ccExpireAt}
					}); 
				} // end if
			} // end if			

			
			// Company Transfer custom comission
			if ((row.CompanyCustomComissions?.length ?? 0) > 0) {

				let findPix = row.CompanyCustomComissions.find(method => method.SystemRepoPaymentMethod.code === "pix" && method.SystemRepoTransport.transportType === "transfer");
				const pixValue = findPix === undefined ? null : findPix.comission;
				const pixExpireAt = findPix === undefined ? null : moment(findPix.expireAt).format("DD/MM/YYYY");

				let findCc = row.CompanyCustomComissions.find(method => method.SystemRepoPaymentMethod.code === "cc" && method.SystemRepoTransport.transportType === "transfer");
				const ccValue = findCc === undefined ? null : findCc.comission;	
				const ccExpireAt = findCc === undefined ? null : moment(findCc.expireAt).format("DD/MM/YYYY");

				if (pixValue || ccValue) {
					setTransferCompanyCustomComission({
						pix:{value: pixValue , expireAt: pixExpireAt},
						cc:{value: ccValue , expireAt: ccExpireAt}
					}); 
				} // end if
			} // end if				
			
			// Company Rent Car custom comission
			if ((row.CompanyCustomComissions?.length ?? 0) > 0) {

				let findPix = row.CompanyCustomComissions.find(method => method.SystemRepoPaymentMethod.code === "pix" && method.SystemRepoTransport.transportType === "car");
				const pixValue = findPix === undefined ? null : findPix.comission;
				const pixExpireAt = findPix === undefined ? null : moment(findPix.expireAt).format("DD/MM/YYYY");

				let findCc = row.CompanyCustomComissions.find(method => method.SystemRepoPaymentMethod.code === "cc" && method.SystemRepoTransport.transportType === "car");
				const ccValue = findCc === undefined ? null : findCc.comission;		
				const ccExpireAt = findCc === undefined ? null : moment(findCc.expireAt).format("DD/MM/YYYY");		

				if (pixValue || ccValue) {
					setRentCarCompanyCustomComission({
						pix:{value: pixValue , expireAt: pixExpireAt},
						cc:{value: ccValue , expireAt: ccExpireAt}
					}); 
				} // end if
			} // end if				

			// Set Company Custom Rules GRID Items
			if ((row.CompanyCustomComissions?.length ?? 0) > 0) {
				setComissions(row.CompanyCustomComissions);
			} // end if

			// Set main array
			const itemsList = [
				{
					label: 'Ações disponíveis',
					items: []
				}
			];	

			// Set buttons
			let blockButton = {
				label: 'Bloquear cadastro',
				icon: 'pi pi-ban red',
				command: () => {
					blockConfirm()
				}
			}		
			let unblockButton = {
				label: 'Desbloquear cadastro',
				icon: 'pi pi-ban red',
				command: () => {
					unblockConfirm()
				}
			}	
			let reviewButton = {
				label: 'Colocar em análise',
				icon: 'pi pi-info orange',
				command: () => {
					reviewConfirm()
				}
			}		
			if (row.status === 3) {
				itemsList[0].items.push(unblockButton);
			} 
			else if (row.status === 1) {
				itemsList[0].items.push(reviewButton);
				itemsList[0].items.push(blockButton);
			}
			setItems(itemsList);			
			
	} // end if	
			
},[row]);

// Check and open comission panel
const openComissionsPanel = (e) => {
 	comissionOverlay.current.toggle(e);
} 


/*********************************/
/* Dynamic Comission Table START */
/*********************************/
const [comissions, setComissions] = React.useState([]);
const [comissionDialog, setComissionDialog] = React.useState(false);
const [editComissionDialog, setEditComissionDialog] = React.useState(false);
const [deleteComissionDialog, setDeleteComissionDialog] = React.useState(false);
const [deleteComissionsDialog, setDeleteComissionsDialog] = React.useState(false);
const [comission, setComission] = React.useState({});
const [selectedComissions, setSelectedComissions] = React.useState(null);

const [selectedCompanyCustomComission, setSelectedCompanyCustomComission] = React.useState(null);


const openNew = () => {
	setComission({});
	setComissionDialog(true);
};


const editComission = (Comission) => {

	setSelectedCompanyCustomComission(Comission);
	setEditComissionDialog(true);
};

const confirmDeleteComission = (Comission) => {

	setSelectedCompanyCustomComission(Comission);
	setDeleteComissionDialog(true);
};


const confirmDeleteSelected = () => {
	setDeleteComissionsDialog(true);
};


// Field value format and enrichement
const bodyTemplate = (type,data) => {

	let rowValue = "Não definido";
	if (type == "paymentMethod") {

		rowValue = (
		<>
		<div>{data.SystemRepoPaymentMethod.name}</div>
		</>
		);	
		
	}

	if (type == "transportType") {		
	
		rowValue = (
		<>
		<div>{data.SystemRepoTransport.name}</div>
		</>
		);
	}	
	
	if (type == "comission") {		
	
		rowValue = (
		<>
		<div>{parseFloat(data.comission).toFixed(1).replace(".", ",")}%</div>
		</>
		);
	}	
	
	if (type == "expireAt") {		
		rowValue = moment(data.expireAt).format("DD/MM/YYYY")
	}	


	if (type == "cpf_cnpj_socio") {	

		let document = (data.tipo == "Pessoa Jurídica") ? maskCNPJ(data.cpf_cnpj_socio) : data.cpf_cnpj_socio;

		rowValue = (
		<>
		<div>{document}</div>
		</>
		);
	}		
	
	if (type == "data_entrada") {		

		rowValue = (
			<>
			<div>{moment(data).format("DD/MM/YYYY")}</div>
			</>
			);		
	}	

    return rowValue;

};
 
const RenderStatus = () => {

	if (row.status == 1) {
		var activationStatus = "ativado";
		var activationStatusLabel = "Aprovada";
	} // end if
	else if (row.status == 2) {
		var activationStatus = "bloqueado";
		var activationStatusLabel = "Reprovada";
	} // end if	
	else if (row.status == 3) {
		var activationStatus = "encerrado";
		var activationStatusLabel = "Bloqueado";
	} // end if			
	else if (row.status == 4) {
		var activationStatus = "encerrado";
		var activationStatusLabel = "Cancelado";
	} // end if			 
	else if (row.status == 0) {
		var activationStatus = "review";
		var activationStatusLabel = "Aguardando análise";
	} // end if	
	else if (row.status == 9) {
		var activationStatus = "encerrado";
		var activationStatusLabel = "Excluído";
	} // end if

	// If master agency
	if (companyType == 2) {
		return(
			<div style={{height:"47px", marginLeft:"15px",fontSize:"1rem",padding:"5px",minWidth:"130px",maxWidth:"unset",backgroundColor:"gold"}} className={`flex-row justify-center align-items-center product-badge status-`}>AGÊNCIA MASTER</div>
		)
	}	
		
	return (
	<>
	<div style={{height:"47px", marginLeft:"15px",fontSize:"1rem",padding:"5px",minWidth:"120px",maxWidth:"unset"}} className={`flex-row justify-center align-items-center product-badge status-${activationStatus}`}>{activationStatusLabel}</div>
	</>
	)	

}	

const leftToolbarTemplate = () => {
	return (
		<div className="flex flex-wrap gap-2">
			<Button label="Nova regra" icon="pi pi-plus" severity="success" onClick={openNew} style={{marginRight:"15px"}} />
			<Button label="Excluir selecionadas" icon="pi pi-trash" severity="danger" onClick={confirmDeleteSelected} disabled={!selectedComissions || !selectedComissions.length} />
		</div>
	);
};

const actionBodyTemplate = (rowData) => {
	return (
		<React.Fragment>
			<Button icon="pi pi-pencil" size="small" rounded outlined className="mr-2" onClick={() => editComission(rowData)} style={{marginRight:"15px",width:"30px",height:"35px"}} />
			<Button icon="pi pi-trash" size="small" rounded outlined severity="danger" onClick={() => confirmDeleteComission(rowData)} style={{width:"30px",height:"35px"}}  />
		</React.Fragment>
	);
};

const header = (
	<div className="flex flex-wrap gap-2 align-items-center justify-content-between">
		<h4 className="m-0">Regras customizadas de comissões</h4>
	</div>
);


// Set calendar locale
addLocale('br', {
	firstDayOfWeek: 1,
	dayNames: ['domingo', 'segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado'],
	dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
	dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
	monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
	monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
	today: 'Hoje',
	clear: 'Limpar'
});

/*********************************/
/* Dynamic Comission Table END */
/*********************************/


// Modal footer 	
const footerContent = (
	<div className="modalFooterButtons" style={{display:loadingData || loadingAction ? "none" : "flex"}}>
	
		<div className="modalFooterButtonsLeft">
			<div className="flex-row justify-start align-items-center">
				
			<Menu model={items} popup ref={menuLeft} id="popup_menu_left" style={{minWidth:"230px",justifyContent:"center",alignItems:"center"}} />

			{companyType != 2 &&
			<>
            	<Button label="Abrir ações" icon="pi pi-align-left" severity="secondary" className="mr-2" onClick={(event) => menuLeft.current.toggle(event)} aria-controls="popup_menu_left" aria-haspopup />
			</>
			}

			<RenderStatus />
			</div>
		</div>
		<div className="modalFooterButtonsRight">

			<ConfirmDialog />
			<Button label="Fechar" disabled={saving} icon="pi pi-times" className="p-button-text" onClick={() => closeDialog()} />

			{companyType != 2 &&
			<>
				<Button label="Gravar alterações" icon="pi pi-save" loading={saving} onClick={() => update()} />
			</>
			}
		</div>
		
	</div>
);		

	return (
	<>
			<Dialog header={selectedAgency.id ? selectedAgency.companyName : "Carregando..."} visible={dialog.target == 'agencyModal' && dialog.status} style={{ width: '65vw',height:"85vh" }} breakpoints={{ '1400px': '75vw','1024px': '85vw','960px': '85vw', '641px': '100vw' }} onHide={() => closeDialog()} footer={footerContent} blockScroll={true} maximizable={saving || loadingAction ? false : true} closable={saving || loadingAction ? false : true} closeOnEscape={saving || loadingAction ? false : true} >
			
				<Box
				  sx={{
					width: "100%",
				  }}
				>
				
				{/* LOADING ACTION  */}
				{(!loadingData && loadingAction) &&
					<>
						<div style={{backgroundColor:"transparent", width:"100%",height:"55vh",maxWidth:"1170px", marginTop:"30px",marginBottom:"50px",display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column",gap:"20px"}}>
						
								<ProgressSpinner style={{width: '60px', height: '60px',marginRight: "20px",marginLeft:"20px"}} strokeWidth="4" />
								<div style={{fontSize:"22px",marginTop:"0px"}}> Trabalhando...Aguarde! </div>
																				
						</div>
					</>				
				}
				
				{/* LOADING  */}
				{(loadingData && !loadingAction) &&
					<>
						<div style={{backgroundColor:"transparent", width:"100%",maxWidth:"1170px", marginTop:"30px",marginBottom:"50px",display:"flex",alignItems:"center",justifyContent:"flex-start",flexDirection:"row"}}>
						
								<ProgressSpinner style={{width: '40px', height: '40px',marginRight: "20px",marginLeft:"20px"}} strokeWidth="4" />
								<div style={{fontSize:"16px",marginTop:"0px"}}> Buscando dados da agência... </div>
																				
						</div>
						
						<Skeleton width="100%" height="4rem" ></Skeleton>
						<br />
						<Skeleton width="100%" height="4rem" ></Skeleton>
						<br />
						<Skeleton width="100%" height="4rem" ></Skeleton>
						<br />
						<Skeleton width="100%" height="4rem" ></Skeleton>
						
					</>				
				}
				
				{/* CONTENT  */}
				{(!loadingData && !loadingAction) &&
				<>
					<div className="agencyModalIntro"> Veja os dados da agência selecionada abaixo. <br /><i> As ações disponíveis estão no rodapé dessa janela. </i></div>	

					<Divider />
					  					  
											<Panel header="Dados da empresa" style={{marginBottom:"20px"}} toggleable>
												<Grid container spacing={5} style={{marginTop:"-20px"}}>
												  <Grid item xs={12} sm={6} style={{display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center"}}>
													<div className="p-float-label w100">
														<InputText  id="companyDocument" autoComplete="new-password" disabled={true} className="w100" value={companyDocument} style={{borderTopRightRadius:0,borderBottomRightRadius:0,color:"#000"}}  />
														<label htmlFor="companydocument">CNPJ</label>
													</div>
												  </Grid>
												  <Grid item xs={12} sm={6} style={{textAlign:"left"}}>

													<small><i className="pi pi-exclamation-triangle" style={{color:"orange",marginRight:"5px"}}></i> Os dados abaixo foram trazidos diretamente da RFB. <i>Apenas o email ou telefone podem ser alterados</i>. Caso queira alterar outra informação, entre em contato com o suporte</small>
													
												  </Grid>
												  <Grid item xs={12} sm={6}>
													<span className="p-float-label">
														<InputText id="companyLegalName" autoComplete="new-password" disabled={true} className="w100 fBlack" value={companyLegalName} />
														<label htmlFor="companyLegalName">Razão Social</label>
													</span>
												  </Grid>
												  <Grid item xs={12} sm={6}>
													<div className="p-float-label">
														<InputText id="companyFantasyName" autoComplete="new-password" disabled={true} className="w100 fBlack" value={companyFantasyName} />
														<label htmlFor="companyFantasyName">Nome Fantasia</label>
													</div>
												  </Grid>
												  <Grid item xs={12} sm={6}>
													<span className="p-float-label">
														<InputText id="companyEmail" disabled={false}  autoComplete="new-password" className="w100 fBlack" value={companyEmail} onChange={(e) => setCompanyEmail(e.target.value)} tooltip="Insira o email principal da empresa. Evite emails pessoais." tooltipOptions={{ position: 'bottom',event: 'focus' }}  />
														<label htmlFor="companyEmail">Email da empresa <span className="requiredField">*</span></label>
													</span>
												  </Grid>
												  <Grid item xs={12} sm={6}>
													<span className="p-float-label">
														<InputText id="companyPhone" disabled={false}  autoComplete="new-password" className="w100 fBlack" value={companyPhone} onChange={(e) => setCompanyPhone(maskPhone(e.target.value))} tooltip="Digite apenas números" tooltipOptions={{ position: 'bottom',event: 'focus' }} />
														<label htmlFor="companyPhone">Telefone <span className="requiredField">*</span></label>
													</span>
												  </Grid>
												  <Grid item xs={12} sm={4}>
													<span className="p-float-label" >
														<InputText id="personCompanyName" autoComplete="new-password" className="fBlack" disabled={true} value={personCompanyName} onChange={(e) => setPersonCompanyName(e.target.value)} style={{width:"100%"}} />
														<label htmlFor="personCompanyName">Nome do responsável Legal </label>
													</span>		
												  </Grid>							  
												  <Grid item xs={12} sm={3}>

													<span className="p-float-label">
														<InputText id="companyStateAuto" autoComplete="new-password" disabled={true} className="w100 fBlack" value={companyStateAuto} />
														<label htmlFor="companyStateAuto">Estado </label>
													</span>	
							
												  </Grid>
												  <Grid item xs={12} sm={4}>

														<span className="p-float-label">
															<InputText id="companyCityAuto" autoComplete="new-password" disabled={true} className="w100 fBlack" value={companyCityAuto} />
															<label htmlFor="companyCityAuto">Cidade </label>
														</span>	
													
												  </Grid>

												</Grid>
											</Panel>	

											<Panel header="Dados do responsável legal pela empresa" style={{marginBottom:"20px"}} toggleable>

												<Grid container spacing={5} style={{marginTop:"-20px"}}>
											
												  <Grid item xs={12} sm={6}>
													<span className="p-float-label" >
														<InputText id="personName" autoComplete="new-password" className="fBlack" disabled={true} value={personName} style={{width:"100%"}} />
														<label htmlFor="peronName">Nome completo</label>
													</span>		
												  </Grid>

												  <Grid item xs={12} sm={6} style={{textAlign:"left"}}>

													<small><i className="pi pi-exclamation-triangle" style={{color:"orange",marginRight:"5px"}}></i> Os dados Nome e data de nascimento foram trazidos diretamente da RFB através da consulta do CPF. </small>
													
												  </Grid>												  
												  
												<Grid item xs={12} sm={6} style={{display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center"}}>
													<div className="p-float-label w100">
														<InputText id="personDocument" autoComplete="new-password" disabled={true}  value={personDocument} className="w100 fBlack" style={{borderTopRightRadius:0,borderBottomRightRadius:0}} />
														<label htmlFor="personDocument">CPF</label>
													</div>
												 </Grid>												  

												 <Grid item xs={12} sm={6} style={{display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center"}}>
													<div className="p-float-label w100">
														<InputText id="personBirthdate" autoComplete="new-password" disabled={true}  value={personBirthdate} className="w100 fBlack" style={{borderTopRightRadius:0,borderBottomRightRadius:0}} />
														<label htmlFor="personBirthdate">Data de Nascimento</label>
													</div>
												 </Grid>	

												<Grid item xs={12} sm={6}>
													<span className="p-float-label" >
														<InputText id="email" autoComplete="new-password" disabled={true} value={email} className="w100 fBlack" />
														<label htmlFor="email">E-mail</label>
													</span>		
												  </Grid>												  
												  
												  <Grid item xs={12} sm={6}>
													<span className="p-float-label" >
														<InputText id="personPhone" autoComplete="new-password" disabled={true} value={personPhone} className="w100 fBlack" />
														<label htmlFor="personPhone">Telefone Celular</label>
													</span>	
												  </Grid>												
												  
												</Grid>

											</Panel>	

											<Panel header="Dados complementares da empresa na RFB" style={{marginBottom:"20px"}} toggleable>

												<Grid container spacing={2} style={{marginTop:"-20px"}}>
											
												  <Grid item xs={12} sm={6}>
													<div className="panel_data_container">
														<div className="panel_data_title">Empresa Optante pelo SIMPLES</div>
														<div className="panel_data_value">{rfb.simplesNacional ? rfb.simplesNacional.optante : "Carregando..."}</div>											
													</div>															
												  </Grid>
												  
												<Grid item xs={12} sm={6}>
													<div className="panel_data_container">
														<div className="panel_data_title">Situação</div>
														<div className="panel_data_value">{rfb.situacao ? rfb.situacao.nome : "Carregando..."}</div>											
													</div>														
												  </Grid>	

												  <Grid item xs={12} sm={6}>
													<div className="panel_data_container">
														<div className="panel_data_title">Início das atividades</div>
														<div className="panel_data_value">{rfb.inicioAtividade ? rfb.inicioAtividade : "Carregando..."}</div>											
													</div>															
												  </Grid>
												  
												<Grid item xs={12} sm={6}>
													<div className="panel_data_container">
														<div className="panel_data_title">Capital Social</div>
														<div className="panel_data_value">{rfb.capitalSocial ? formatCurrencyWithDecimal(rfb.capitalSocial) : "Carregando..."}</div>											
													</div>														
												  </Grid>													  

												<Grid item xs={12} sm={12} style={{display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center"}}>
													<div className="panel_data_container">
														<div className="panel_data_title">Endereço</div>
														<div className="panel_data_value">{rfb.matrizEndereco ? (rfb.matrizEndereco.tipo + " " +rfb.matrizEndereco.logradouro+", "+rfb.matrizEndereco.numero+", "+rfb.matrizEndereco.complemento+" - "+rfb.matrizEndereco.bairro+" - "+rfb.matrizEndereco.cidade+", "+rfb.matrizEndereco.uf+" - CEP: "+rfb.matrizEndereco.cep) : "Carregando..."}</div>											
													</div>														
												 </Grid>	
												 
												  <Grid item xs={12} sm={6}>
													<div className="panel_data_container">
														<div className="panel_data_title">Natureza Jurídica</div>
														<div className="panel_data_value">{rfb.naturezaJuridica ? (rfb.naturezaJuridica.codigo+" / "+(rfb.naturezaJuridica.codigo == '2135' ? "Empresário (Individual)" : rfb.naturezaJuridica.descricao)) : "Carregando..."}</div>											
													</div>														
												  </Grid>

												  <Grid item xs={12} sm={6}>
													<div className="panel_data_container">
														<div className="panel_data_title">Porte da empresa</div>
														<div className="panel_data_value">{rfb.porte ? (rfb.porte.descricao) : "Carregando..."}</div>											
													</div>														
												  </Grid>												  													  
												  
												  <Grid item xs={12} sm={6}>
													<div className="panel_data_container">
														<div className="panel_data_title">CNAE principal</div>
														<div className="panel_data_value">{rfb.cnae ? (rfb.cnae.subClasse+" - "+rfb.cnae.descricao) : "Carregando..."}</div>											
													</div>	
												  </Grid>

												  <Grid item xs={12} sm={6}>
													<div className="panel_data_container">
														<div className="panel_data_title">CNAE secundário</div>

														{(rfb.cnae?.secundarias?.length ?? 0) === 0 && (
															<>
																<div className="panel_data_value">Não há CNAE secundário.</div>
															</>
														)}

														{(rfb.cnae?.secundarias?.length ?? 0) > 0 && (
															<>
																{rfb.cnae.secundarias.map(cnaeSecundario => (
																	<div className="panel_data_value" key={cnaeSecundario.subclasse}>
																		{cnaeSecundario.subclasse + " - " + cnaeSecundario.descricao}
																	</div>
																))}
															</>
														)}
													</div>	
												  </Grid>												  

												  <Grid item xs={12} sm={12}>

													<div className="panel_data_container">
															<div className="panel_data_title">Relação de sócios</div>
															<div className="panel_data_value">

															{((socios?.length ?? 0) === 0 && (rfb.naturezaJuridica?.codigo ?? 0) != '2135') && (
															<>
																<div className="panel_data_value">Não há sócios.</div>
															</>
															)}

															{((socios?.length ?? 0) === 0 && (rfb.naturezaJuridica?.codigo ?? 0) == '2135') && (
															<>
																<div className="panel_data_value">Empresário (Individual). O próprio empresário é o único sócio.</div>
															</>
															)}

															{(socios?.length ?? 0) > 0 && (
																<DataTable value={socios} tableStyle={{ minWidth: '50rem',marginTop:"15px" }}>
																	<Column field="nome" header="Nome"></Column>
																	<Column field="tipo" header="Tipo"></Column>
																	<Column field="cpf_cnpj_socio" header="CPF / CNPJ" body={(data)=>bodyTemplate('cpf_cnpj_socio',data)}></Column>
																	<Column field="data_entrada" header="Data Entrada" body={(data)=>bodyTemplate('data_entrada',data.data_entrada)}></Column>
																	<Column field="faixa_etaria" header="Faixa etária"></Column>
																	<Column field="qualificacao_socio.descricao" header="Qualificação"></Column>
																</DataTable>
															)}
																												
																
															</div>											
													</div>	
													
												  </Grid>												  
												  
												</Grid>

											</Panel>											
										  
											{companyType != 2 &&
											<>
											<Panel header="Perfil na plataforma" style={{marginBottom:"20px"}} toggleable>

												<Grid container spacing={5} style={{marginTop:"-20px"}}>
											
												  <Grid item xs={12} sm={6}>
													<span className="p-float-label" >
														
														<Dropdown showClear value={groupProfile} onChange={(e) => setGroupProfile(e.target.value)} options={groupProfilesOptions} optionLabel="name" placeholder="Selecione o perfil da agência" className="w100 fBlack"></Dropdown>

														<label htmlFor="peronName">Perfil da agência</label>
													</span>		
												  </Grid>												  
							

												  <Grid item xs={12} sm={6}>
													<div className="flex-row justify-center align-items-center modalWrapper">
													
														<div className="p-float-label w90">
															<Dropdown showClear value={comissionProfile} onChange={(e) => setComissionProfile(e.target.value)} options={comissionProfilesOptions} optionLabel="name" placeholder="Selecione o perfil de comissão" className="w100 fBlack" style={{borderTopRightRadius:0,borderBottomRightRadius:0}}></Dropdown>
															<label htmlFor="peronName">Perfil de comissionamento</label>
														</div>
														<Button icon="pi pi-eye" severity="secondary" aria-label="Search" style={{width:"50px",borderTopLeftRadius:0,borderBottomLeftRadius:0}} onClick={(e) => comissionOverlay.current.toggle(e)} />

														<OverlayPanel ref={comissionOverlay} showCloseIcon closeOnEscape dismissable={true}>
															<ScrollPanel style={{ width: '100%', height: '350px', padding:"15px" }} className="custombar2">
															
															 <ComissionPanel comissionProfile={comissionProfile} selectedAgency={selectedAgency} />

															</ScrollPanel>															
														</OverlayPanel>														
														
													</div>		
												  </Grid>
												  
												  <Grid item xs={12} sm={6}>
												  <div className="flex-row justify-center align-items-center ">
														<div className="p-float-label w90">
																<InputText id="personName" autoComplete="new-password"  className="fBlack" onChange={(e) => setDailyCreditLimit(formatCurrency(e.target.value))}  value={formatCurrency(dailyCreditLimit)} style={{width:"100%",borderTopRightRadius:0,borderBottomRightRadius:0}} />
																<label htmlFor="peronName">Limite de crédito diário (em € EURO) </label>
														</div>
														<Button icon="pi pi-history" severity="secondary" aria-label="Search" style={{width:"50px",borderTopLeftRadius:0,borderBottomLeftRadius:0}} onClick={(e) => dailyCreditOverlay.current.toggle(e)} />

														<OverlayPanel ref={dailyCreditOverlay} showCloseIcon closeOnEscape dismissable={true}>
															<ScrollPanel style={{ width: '100%', height: '350px', padding:"15px" }} className="custombar2">
															
															 <DailyCreditHistoryPanel selectedAgency={selectedAgency} />

															</ScrollPanel>															
														</OverlayPanel>															
												   </div>														
												  </Grid>												  
												  
												  <Grid item xs={12} sm={6} style={{textAlign:"left",display:"flex",justifyContent:"flex-start",alignItems:"center"}}>

													<small><i className="pi pi-exclamation-triangle" style={{color:"orange",marginRight:"5px"}}></i> Atenção ao alterar o limite de crédito diário.</small>
													
												  </Grid>												  

												  <Grid item xs={12} sm={12}>

														<div style={{marginBottom:"20px",textAlign:"left",fontSize:"26px",letterSpacing:"0.1em",lineHeight:"120%",fontWeight:"600",fontStyle:"normal",fontFamily:"Titillium Web",color:"#2E0249"}}> Comissões por serviço  </div>

														<div style={{margin:"20px",maxWidth:"90%"}}>
															
															<div style={{marginTop:"30px",marginBottom:"20px",textAlign:"left",fontSize:"22px",letterSpacing:"0.1em",lineHeight:"120%",fontWeight:"600",fontStyle:"normal",fontFamily:"Titillium Web",color:"#2E0249"}}> Pagamento com Cartão de crédito </div>
															
															<div style={{display:"flex",alignItems:"center",justifyContent:"flex-start",gap:"20px",flexDirection:"row",flexWrap:"wrap",textAlign:"center"}}>
															
																{/* Train comission CREDIT CARD */}
																<Card title={<div style={{backgroundColor:"#814DE5",fontSize:"16px",padding:"5px",color:"white",borderRadius:"10px"}}>Trem <img src={titleTrainIcon} style={{width:"20px",height:"auto",filter:"brightness(0) invert(1)"}} className="noSelect" /> </div>} style={{width:"220px",height:"130px",backgroundColor:"whitesmoke"}}>
																	{trainCompanyCustomComission.cc?.value	&&
																	<>
																		<div style={{fontSize:"40px"}}>{formatPercentageNumber(trainCompanyCustomComission.cc.value)}</div>
																		<div style={{fontSize:"14px",color:"red"}}>
																			Condição customizada <br />
																			<small>Válida até {trainCompanyCustomComission.cc.expireAt}</small>
																		</div>																		
																	</>
																	}

																	{(!trainCompanyCustomComission.cc?.value && trainCustomComission.cc) &&
																	<>
																		<div style={{fontSize:"40px"}}>{formatPercentageNumber(trainCustomComission.cc)}</div>
																	</>
																	}	

																	{(!trainCompanyCustomComission.cc?.value && !trainCustomComission.cc) &&
																	<>
																		<div style={{fontSize:"40px"}}>{formatPercentageNumber(generalComission.cc)}</div>
																		<div style={{fontSize:"14px",color:"silver"}}>
																			Comissão global
																		</div>																			
																	</>
																	}		
																</Card>	
																
																{/* Bus comission CREDIT CARD */}
																<Card title={<div style={{backgroundColor:"#814DE5",fontSize:"16px",padding:"5px",color:"white",borderRadius:"10px"}}>Ônibus <img src={titleBusIcon} style={{width:"20px",height:"auto",filter:"brightness(0) invert(1)"}} className="noSelect" /> </div>} style={{width:"220px",height:"130px",backgroundColor:"whitesmoke"}}>
																	{busCompanyCustomComission.cc?.value &&
																	<>
																		<div style={{fontSize:"40px"}}>{formatPercentageNumber(busCompanyCustomComission.cc.value)}</div>
																		<div style={{fontSize:"14px",color:"red"}}>
																			Condição customizada <br />
																			<small>Válida até {busCompanyCustomComission.cc.expireAt}</small>
																		</div>																		
																	</>
																	}

																	{(!busCompanyCustomComission.cc?.value && busCustomComission.cc) &&
																	<>
																		<div style={{fontSize:"40px"}}>{formatPercentageNumber(busCustomComission.cc)}</div>
																	</>
																	}	

																	{(!busCompanyCustomComission.cc?.value && !busCustomComission.cc) &&
																	<>
																		<div style={{fontSize:"40px"}}>{formatPercentageNumber(generalComission.cc)}</div>
																		<div style={{fontSize:"14px",color:"silver"}}>
																			Comissão global
																		</div>																			
																	</>
																	}																		
																</Card>	
																
																{/* Transfer comission CREDIT CARD */}
																<Card title={<div style={{backgroundColor:"#814DE5",fontSize:"16px",padding:"5px",color:"white",borderRadius:"10px"}}>Transfer <img src={titleTrainIcon} style={{width:"20px",height:"auto",filter:"brightness(0) invert(1)"}} className="noSelect" /> </div>} style={{width:"220px",height:"130px",backgroundColor:"whitesmoke"}}>
																	{transferCompanyCustomComission.cc?.value &&
																	<>
																		<div style={{fontSize:"40px"}}>{formatPercentageNumber(transferCompanyCustomComission.cc.value)}</div>
																		<div style={{fontSize:"14px",color:"red"}}>
																			Condição customizada <br />
																			<small>Válida até {transferCompanyCustomComission.cc.expireAt}</small>
																		</div>																		
																	</>
																	}

																	{(!transferCompanyCustomComission.cc?.value && transferCustomComission.cc) &&
																	<>
																		<div style={{fontSize:"40px"}}>{formatPercentageNumber(transferCustomComission.cc)}</div>
																	</>
																	}	

																	{(!transferCompanyCustomComission.cc?.value && !transferCustomComission.cc) &&
																	<>
																		<div style={{fontSize:"40px"}}>{formatPercentageNumber(generalComission.cc)}</div>
																		<div style={{fontSize:"14px",color:"silver"}}>
																			Comissão global
																		</div>																			
																	</>
																	}	
																</Card>	

																{/* RentCar comission CREDIT CARD */}
																<Card title={<div style={{backgroundColor:"#814DE5",fontSize:"16px",padding:"5px",color:"white",borderRadius:"10px"}}>Aluguel de carro <img src={titleTrainIcon} style={{width:"20px",height:"auto",filter:"brightness(0) invert(1)"}} className="noSelect" /> </div>} style={{width:"220px",height:"130px",backgroundColor:"whitesmoke"}}>
																	{rentcarCompanyCustomComission.cc?.value &&
																	<>
																		<div style={{fontSize:"40px"}}>{formatPercentageNumber(rentcarCompanyCustomComission.cc.value)}</div>
																		<div style={{fontSize:"14px",color:"red"}}>
																			Condição customizada <br />
																			<small>Válida até {rentcarCompanyCustomComission.cc.expireAt}</small>
																		</div>																		
																	</>
																	}

																	{(!rentcarCompanyCustomComission.cc?.value && rentcarCustomComission.cc) &&
																	<>
																		<div style={{fontSize:"40px"}}>{formatPercentageNumber(rentcarCustomComission.cc)}</div>
																	</>
																	}	

																	{(!rentcarCompanyCustomComission.cc?.value && !rentcarCustomComission.cc) &&
																	<>
																		<div style={{fontSize:"40px"}}>{formatPercentageNumber(generalComission.cc)}</div>
																		<div style={{fontSize:"14px",color:"silver"}}>
																			Comissão global
																		</div>																			
																	</>
																	}	
																</Card>
															
															</div>		

															<div style={{marginTop:"20px",marginBottom:"20px",textAlign:"left",fontSize:"22px",letterSpacing:"0.1em",lineHeight:"120%",fontWeight:"600",fontStyle:"normal",fontFamily:"Titillium Web",color:"#2E0249"}}> Pagamento com PIX </div>
															
															<div style={{display:"flex",alignItems:"center",justifyContent:"flex-start",gap:"20px",flexDirection:"row",flexWrap:"wrap",textAlign:"center"}}>
															
																{/* Train comission PIX */}
																<Card title={<div style={{backgroundColor:"#814DE5",fontSize:"16px",padding:"5px",color:"white",borderRadius:"10px"}}>Trem <img src={titleTrainIcon} style={{width:"20px",height:"auto",filter:"brightness(0) invert(1)"}} className="noSelect" /> </div>} style={{width:"220px",height:"130px",backgroundColor:"whitesmoke"}}>
																	{trainCompanyCustomComission.pix?.value &&
																	<>
																		<div style={{fontSize:"40px"}}>{formatPercentageNumber(trainCompanyCustomComission.pix.value)}</div>
																		<div style={{fontSize:"14px",color:"red"}}>
																			Condição customizada <br />
																			<small>Válida até {trainCompanyCustomComission.pix.expireAt}</small>
																		</div>																		
																	</>
																	}

																	{(!trainCompanyCustomComission.pix?.value && trainCustomComission.pix) &&
																	<>
																		<div style={{fontSize:"40px"}}>{formatPercentageNumber(trainCustomComission.pix)}</div>
																	</>
																	}	

																	{(!trainCompanyCustomComission.pix?.value && !trainCustomComission.pix) &&
																	<>
																		<div style={{fontSize:"40px"}}>{formatPercentageNumber(generalComission.pix)}</div>
																		<div style={{fontSize:"14px",color:"silver"}}>
																			Comissão global
																		</div>																			
																	</>
																	}	
																</Card>	
																
																{/* Bus  comission PIX */}
																<Card title={<div style={{backgroundColor:"#814DE5",fontSize:"16px",padding:"5px",color:"white",borderRadius:"10px"}}>Ônibus <img src={titleBusIcon} style={{width:"20px",height:"auto",filter:"brightness(0) invert(1)"}} className="noSelect" /> </div>} style={{width:"220px",height:"130px",backgroundColor:"whitesmoke"}}>
																	{busCompanyCustomComission.pix?.value &&
																	<>
																		<div style={{fontSize:"40px"}}>{formatPercentageNumber(busCompanyCustomComission.pix.value)}</div>
																		<div style={{fontSize:"14px",color:"red"}}>
																			Condição customizada <br />
																			<small>Válida até {busCompanyCustomComission.pix.expireAt}</small>
																		</div>																		
																	</>
																	}

																	{(!busCompanyCustomComission.pix?.value && busCustomComission.pix) &&
																	<>
																		<div style={{fontSize:"40px"}}>{formatPercentageNumber(busCustomComission.pix)}</div>
																	</>
																	}	

																	{(!busCompanyCustomComission.pix?.value && !busCustomComission.pix) &&
																	<>
																		<div style={{fontSize:"40px"}}>{formatPercentageNumber(generalComission.pix)}</div>
																		<div style={{fontSize:"14px",color:"silver"}}>
																			Comissão global
																		</div>																			
																	</>
																	}																		
																</Card>	
																
																{/* Transfer comission PIX */}
																<Card title={<div style={{backgroundColor:"#814DE5",fontSize:"16px",padding:"5px",color:"white",borderRadius:"10px"}}>Transfer <img src={titleTrainIcon} style={{width:"20px",height:"auto",filter:"brightness(0) invert(1)"}} className="noSelect" /> </div>} style={{width:"220px",height:"130px",backgroundColor:"whitesmoke"}}>
																	{transferCompanyCustomComission.pix?.value &&
																	<>
																		<div style={{fontSize:"40px"}}>{formatPercentageNumber(transferCompanyCustomComission.pix.value)}</div>
																		<div style={{fontSize:"14px",color:"red"}}>
																			Condição customizada <br />
																			<small>Válida até {transferCompanyCustomComission.pix.expireAt}</small>
																		</div>																		
																	</>
																	}

																	{(!transferCompanyCustomComission.pix?.value && transferCustomComission.pix) &&
																	<>
																		<div style={{fontSize:"40px"}}>{formatPercentageNumber(transferCustomComission.pix)}</div>
																	</>
																	}	

																	{(!transferCompanyCustomComission.pix?.value && !transferCustomComission.pix) &&
																	<>
																		<div style={{fontSize:"40px"}}>{formatPercentageNumber(generalComission.pix)}</div>
																		<div style={{fontSize:"14px",color:"silver"}}>
																			Comissão global
																		</div>																			
																	</>
																	}	
																</Card>	

																{/* RentCar comission PIX */}
																<Card title={<div style={{backgroundColor:"#814DE5",fontSize:"16px",padding:"5px",color:"white",borderRadius:"10px"}}>Aluguel de carro <img src={titleTrainIcon} style={{width:"20px",height:"auto",filter:"brightness(0) invert(1)"}} className="noSelect" /> </div>} style={{width:"220px",height:"130px",backgroundColor:"whitesmoke"}}>
																	{rentcarCompanyCustomComission.pix?.value &&
																	<>
																		<div style={{fontSize:"40px"}}>{formatPercentageNumber(rentcarCompanyCustomComission.pix.value)}</div>
																		<div style={{fontSize:"14px",color:"red"}}>
																			Condição customizada <br />
																			<small>Válida até {rentcarCompanyCustomComission.pix.expireAt}</small>
																		</div>																		
																	</>
																	}

																	{(!rentcarCompanyCustomComission.pix?.value && rentcarCustomComission.pix) &&
																	<>
																		<div style={{fontSize:"40px"}}>{formatPercentageNumber(rentcarCustomComission.pix)}</div>
																	</>
																	}	

																	{(!rentcarCompanyCustomComission.pix?.value && !rentcarCustomComission.pix) &&
																	<>
																		<div style={{fontSize:"40px"}}>{formatPercentageNumber(generalComission.pix)}</div>
																		<div style={{fontSize:"14px",color:"silver"}}>
																			Comissão global
																		</div>																			
																	</>
																	}	
																</Card>
															
															</div>															
														
														</div>
														

												  </Grid>		

												  
												  <Grid item xs={12} sm={12}>

														<div style={{marginBottom:"20px",textAlign:"left",fontSize:"26px",letterSpacing:"0.1em",lineHeight:"120%",fontWeight:"600",fontStyle:"normal",fontFamily:"Titillium Web",color:"#2E0249"}}> Regras de comissões customizadas </div>

														<div style={{margin:"20px",maxWidth:"90%"}}>

															<Toolbar className="mb-4" start={leftToolbarTemplate}></Toolbar>

															<DataTable ref={dt} value={comissions} selection={selectedComissions} onSelectionChange={(e) => setSelectedComissions(e.value)}
															emptyMessage="Nenhuma regra cadastrada"
																	dataKey="id"  paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
																	paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
																	currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} regras" header={header}>
																<Column selectionMode="multiple" exportable={false}></Column>
																<Column field="SystemRepoPaymentMethod.description" header="Forma de pagamento" body={(data)=>bodyTemplate('paymentMethod',data)} ></Column>
																<Column field="SystemRepoTransport.name" header="Tipo de transporte" body={(data)=>bodyTemplate('transportType',data)} ></Column>
																<Column field="comission" header="Comissão" body={(data)=>bodyTemplate('comission',data)}></Column>
																<Column field="expireAt" header="Válida até" body={(data)=>bodyTemplate('expireAt',data)} sortable style={{ minWidth: '8rem' }}></Column>
																<Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
															</DataTable>										
														
														</div>

														<CompanyCustomComissionPanel
															selectedAgency={selectedAgency} 
															comissionDialog={comissionDialog} 
															editComissionDialog={editComissionDialog}
															deleteComissionDialog={deleteComissionDialog}
															deleteComissionsDialog={deleteComissionsDialog}
															setComissionDialog={setComissionDialog}
															setEditComissionDialog={setEditComissionDialog}
															setDeleteComissionDialog={setDeleteComissionDialog}
															setDeleteComissionsDialog={setDeleteComissionsDialog}
															selectedComissions={selectedComissions}
															setSelectedComissions={setSelectedComissions}
															selectedCompanyCustomComission={selectedCompanyCustomComission}
															setLoadingAction={setLoadingAction}
															retrieveAgencyInfo={retrieveAgencyInfo}	
															setAlert={setAlert}
															setAlertTitle={setAlertTitle}
															setAlertBody={setAlertBody}
															setAlertAction={setAlertAction}
														 /> 

												  </Grid>														  										
												  
												</Grid>

											</Panel>
											</>
											}

					</>	
				}

			</Box>
			
			<AlertView
				alert={alert}
				setAlert={setAlert}
				alertTitle={alertTitle}
				alertBody={alertBody}
				alertAction={alertAction}
				scrollBlock={true}				
			/>			
            </Dialog>	
	</>	
	);
	
}

const mapStateToProps = store => ({
	dialog:store.appReducer.dialog,
	selectedAgency:store.agencyReducer.selectedAgency
   });
  const mapDispatchToProps = dispatch => bindActionCreators({ ...AppActions, ...AgencyActions }, dispatch);
  

export const CompanyCustomComissionPanel = connect(
	mapStateToProps,
	mapDispatchToProps
)((props) => {	

const { 
	selectedAgency,
	comissionDialog,
	editComissionDialog,
	deleteComissionDialog,
	deleteComissionsDialog,
	setComissionDialog,
	setEditComissionDialog,
	setDeleteComissionDialog,
	setDeleteComissionsDialog,
	selectedComissions,
	setSelectedComissions,
	selectedCompanyCustomComission,
	setLoadingAction,
	retrieveAgencyInfo,
	createCompanyCustomComission,
	deleteCompanyCustomComission,
	updateCompanyCustomComission
} = props;	

const [saving, setSaving] = React.useState(false);

const [newPaymentMethod, setNewPaymentMethod] = React.useState(null);
const [newTransportType, setNewTransportType] = React.useState(null);
const [newComission, setNewComission] = React.useState(null);
const [newExpire, setNewExpire] = React.useState(null);

const [paymentMethod, setPaymentMethod] = React.useState(null);
const [transportType, setTransportType] = React.useState(null);
const [comission, setComission] = React.useState(null);
const [expire, setExpire] = React.useState(null);

const [previousPaymentMethod, setPreviousPaymentMethod] = React.useState(null);
const [previousTransportType, setPreviousTransportType] = React.useState(null);
const [previousComission, setPreviousComission] = React.useState(null);
const [previousExpire, setPreviousExpire] = React.useState(null);

// Execute when component opens
/*React.useEffect(() => {


	return () => {


	}
			
},[]);*/

// Execute when row change
React.useEffect(() => {


	// Only runs if id is set
	if ((selectedCompanyCustomComission?.id ?? false) && editComissionDialog == true) {

		setPaymentMethod({"code":selectedCompanyCustomComission.SystemRepoPaymentMethodId,name:selectedCompanyCustomComission.SystemRepoPaymentMethod.name});
		setTransportType({"code":selectedCompanyCustomComission.SystemRepoTransportId,name:selectedCompanyCustomComission.SystemRepoTransport.name});
		
		let comissionValue = parseFloat(selectedCompanyCustomComission.comission).toFixed(2);
		setComission(comissionValue);

		setExpire(createDateAsLocal(selectedCompanyCustomComission.expireAt));		

		setPreviousPaymentMethod({"code":selectedCompanyCustomComission.SystemRepoPaymentMethodId,name:selectedCompanyCustomComission.SystemRepoPaymentMethod.name});
		setPreviousTransportType({"code":selectedCompanyCustomComission.SystemRepoTransportId,name:selectedCompanyCustomComission.SystemRepoTransport.name});
		setPreviousComission(comissionValue);

		setPreviousExpire(createDateAsLocal(selectedCompanyCustomComission.expireAt));

	} // end if	
			
},[editComissionDialog]);


/* START action method */
const saveComission = (props) => {

	// Check required fields
	if ( !newPaymentMethod || !newTransportType || !newComission || !newExpire ) {
		props.setAlert(true);
		props.setAlertTitle("Verifique as informações");
		props.setAlertBody("Um ou mais campos não estão preenchidos. É necessário que preencha todos os campos marcados com * para prosseguir com o cadastro.");	
		return;
	} // end if
	
	if (parseFloat(newComission) > 30) {
		props.setAlert(true);
		props.setAlertTitle("Verifique a comissão");
		props.setAlertBody("O valor da comissão é mais alto que o permitido. Verifique novamente.");	
		return;		
	} // end if

	setSaving(true);

	var props = {
		setSaving:setSaving,
		setAlert:props.setAlert,
		setAlertTitle:props.setAlertTitle,
		setAlertBody:props.setAlertBody,
		setAlertAction:props.setAlertAction,
		retrieveAgencyInfo:retrieveAgencyInfo,
		hideDialog:hideDialog
	}
	var data = {
	  agencyId:selectedAgency.id,
	  paymentMethod:newPaymentMethod,
	  transportType:newTransportType,
	  comission:newComission,
	  expireAt:newExpire
	}
	createCompanyCustomComission(data,props);	
	
};


const updateComission = (props) => {

	// Check required fields
	if ( !selectedAgency.id || !selectedCompanyCustomComission.id || !paymentMethod || !transportType || !comission || !expire ) {
		props.setAlert(true);
		props.setAlertTitle("Verifique as informações");
		props.setAlertBody("Um ou mais campos não estão preenchidos. É necessário que preencha todos os campos marcados com * para prosseguir com o cadastro.");	
		return;
	} // end if
	 
	// Check any field changed
	let expireDate = moment(expire).format("YYYY-MM-DD");
	let expirePreviousDate = moment(previousExpire).format("YYYY-MM-DD");
if ( (paymentMethod.code == previousPaymentMethod.code) && (transportType.code == previousTransportType.code) && (comission == previousComission) && (expireDate == expirePreviousDate)  ) {
		props.setAlert(true);
		props.setAlertTitle("Nada foi alterado!");
		props.setAlertBody("Nenhum campo que pode ser editado foi alterado. Não há o que gravar.");	
		return;
	} // end if
	
	if (parseFloat(comission) > 30) {
		props.setAlert(true);
		props.setAlertTitle("Verifique a comissão");
		props.setAlertBody("O valor da comissão é mais alto que o permitido. Verifique novamente.");	
		return;		
	} // end if

	setSaving(true);

	var props = {
		setSaving:setSaving,
		setAlert:props.setAlert,
		setAlertTitle:props.setAlertTitle,
		setAlertBody:props.setAlertBody,
		setAlertAction:props.setAlertAction,
		retrieveAgencyInfo:retrieveAgencyInfo,
		hideEditDialog:hideEditDialog
	}
	var data = {
	  agencyId:selectedAgency.id,
	  customComissionId: selectedCompanyCustomComission.id,
	  paymentMethod:paymentMethod,
	  transportType:transportType,
	  comission:comission,
	  expireAt:expire
	}
	updateCompanyCustomComission(data,props);	

};

const deleteComission = (props) => {

	setSaving(true);

	var props = {
		setSaving:setSaving,
		setAlert:props.setAlert,
		setAlertTitle:props.setAlertTitle,
		setAlertBody:props.setAlertBody,
		setAlertAction:props.setAlertAction,
		retrieveAgencyInfo:retrieveAgencyInfo,
		hideDeleteComissionDialog:hideDeleteComissionDialog,
		hideDeleteComissionsDialog:hideDeleteComissionsDialog
	}
	var data = {
	  agencyId:selectedAgency.id,
	  companyCustomComissionsIds: [selectedCompanyCustomComission.id],
	}
	deleteCompanyCustomComission(data,props);
	
};

const deleteSelectedComissions = (props) => {

	setSaving(true);

	let companyCustomComissionsIds = [];
	{selectedComissions.map(comission => companyCustomComissionsIds.push(comission.id))}
	
	var props = {
		setSaving:setSaving,
		setAlert:props.setAlert,
		setAlertTitle:props.setAlertTitle,
		setAlertBody:props.setAlertBody,
		setAlertAction:props.setAlertAction,
		retrieveAgencyInfo:retrieveAgencyInfo,
		hideDeleteComissionDialog:hideDeleteComissionDialog,
		hideDeleteComissionsDialog:hideDeleteComissionsDialog
	}
	var data = {
	  agencyId:selectedAgency.id,
	  companyCustomComissionsIds: companyCustomComissionsIds
	}
	deleteCompanyCustomComission(data,props);
	
};
/* END action method */


const hideEditDialog = () => {

	setEditComissionDialog(false);

	setPaymentMethod(null);
	setTransportType(null);
	setComission(null);
	setExpire(null);
	setPreviousPaymentMethod(null);
	setPreviousTransportType(null);
	setPreviousComission(null);
	setPreviousExpire(null);

};


const hideDialog = () => {
	setComissionDialog(false);
};

const hideDeleteComissionDialog = () => {
	setDeleteComissionDialog(false);
};

const hideDeleteComissionsDialog = () => {
	setDeleteComissionsDialog(false);
};



const ComissionDialogFooter = (
	<React.Fragment>
		<Button label="Cancelar" icon="pi pi-times"  disabled={saving} outlined onClick={hideDialog} />
		<Button label="Gravar" icon="pi pi-check"  loading={saving} disabled={saving} onClick={()=>saveComission(props)} />
	</React.Fragment>
);

const EditComissionDialogFooter = (
	<React.Fragment>
		<Button label="Cancelar" icon="pi pi-times" disabled={saving} outlined onClick={hideEditDialog} />
		<Button label="Gravar" icon="pi pi-check"  loading={saving} disabled={saving} onClick={()=>updateComission(props)} />
	</React.Fragment>
);
const deleteComissionDialogFooter = (
	<React.Fragment>
		<Button label="Não" icon="pi pi-times"   disabled={saving}  outlined onClick={hideDeleteComissionDialog} />
		<Button label="Sim" icon="pi pi-check"  loading={saving} disabled={saving}  severity="danger" onClick={()=>deleteComission(props)} />
	</React.Fragment>
);
const deleteComissionsDialogFooter = (
	<React.Fragment>
		<Button label="Não" icon="pi pi-times"  disabled={saving}  outlined onClick={hideDeleteComissionsDialog} />
		<Button label="Sim" icon="pi pi-check"  loading={saving} disabled={saving}  severity="danger" onClick={()=>deleteSelectedComissions(props)} />
	</React.Fragment>
);

return (
<>
	<Dialog visible={comissionDialog} closable={saving ? false : true} closeOnEscape={saving ? false : true}  style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Nova regra de comissão" modal className="p-fluid" footer={ComissionDialogFooter} onHide={hideDialog}>
															
		<div className="field" style={{marginBottom:"10px"}}>
			<label htmlFor="description" className="font-bold">
				Forma de pagamento
			</label>
			<Dropdown value={newPaymentMethod} onChange={(e) => setNewPaymentMethod(e.target.value)}  options={[{"code":2,name:"Cartão de crédito"},{"code":1,name:"PIX"}]} optionLabel="name" placeholder="Selecione a forma de pagamento" className="w100"></Dropdown>
		</div>

		<div className="field" style={{marginBottom:"10px"}}>
			<label htmlFor="description" className="font-bold">
				Tipo de transporte
			</label>
			<Dropdown value={newTransportType} onChange={(e) => setNewTransportType(e.target.value)}  options={[{"code":2,name:"Trem"},{"code":3,name:"Ônibus"},{"code":4,name:"Transfer"},{"code":1,name:"Aluguel de carro"}/*,{"code":2,name:"Avião"}*/]} optionLabel="name" placeholder="Selecione o tipo de transporte" className="w100"></Dropdown>
		</div>	

		<div className="field" style={{marginBottom:"10px"}}>
			<label htmlFor="name" className="font-bold">
				Comissão (%)
			</label>
			<InputText id="comissionInput" onChange={(e) => setNewComission(formatCurrencyDecimalsWithLimit(e.target.value,30))}  value={newComission}  />
		</div>

		<div className="field" style={{marginBottom:"10px"}}>
			<label htmlFor="name" className="font-bold">
				Válido até
			</label>
			<Calendar id="buttondisplay"  onChange={(e) => setNewExpire(e.target.value)}  value={newExpire} locale="br" dateFormat="dd/mm/yy" minDate={new Date()} showIcon />
		</div>																																											

	</Dialog>

	<Dialog closable={saving ? false : true} closeOnEscape={saving ? false : true} visible={editComissionDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Alteração de regra de comissão" modal className="p-fluid" footer={EditComissionDialogFooter} onHide={hideEditDialog}>
															
		<div className="field" style={{marginBottom:"10px"}}>
			<label htmlFor="description" className="font-bold">
				Forma de pagamento
			</label>
			<Dropdown value={paymentMethod} onChange={(e) => setPaymentMethod(e.target.value)}  options={[{"code":2,name:"Cartão de Crédito"},{"code":1,name:"PIX"}]} optionLabel="name" placeholder="Selecione a forma de pagamento" className="w100"></Dropdown>
		</div>

		<div className="field" style={{marginBottom:"10px"}}>
			<label htmlFor="description" className="font-bold">
				Tipo de transporte
			</label>
			<Dropdown value={transportType} onChange={(e) => setTransportType(e.target.value)}  options={[{"code":2,name:"Trem"},{"code":3,name:"Ônibus"},{"code":4,name:"Transfer"},{"code":1,name:"Aluguel de carro"}/*,{"code":2,name:"Avião"}*/]} optionLabel="name" placeholder="Selecione o tipo de transporte" className="w100"></Dropdown>
		</div>	

		<div className="field" style={{marginBottom:"10px"}}>
			<label htmlFor="name" className="font-bold">
				Comissão (%)
			</label>
			<InputText id="comissionInput" onChange={(e) => setComission(formatCurrencyDecimalsWithLimit(e.target.value,30))}  value={formatCurrencyDecimalsWithLimit(comission)}  />
		</div>

		<div className="field" style={{marginBottom:"10px"}}>
			<label htmlFor="name" className="font-bold">
				Válido até
			</label>
			<Calendar id="buttondisplay" onChange={(e) => setExpire(e.target.value)}  value={expire} locale="br" dateFormat="dd/mm/yy" minDate={new Date()} showIcon />
		</div>																																											
	</Dialog>



	<Dialog visible={deleteComissionDialog} closable={saving ? false : true} closeOnEscape={saving ? false : true}  style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm" modal footer={deleteComissionDialogFooter} onHide={hideDeleteComissionDialog}>
		<div className="confirmation-content">
			<i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
			
				<span>
					Tem certeza que deseja excluir essa regra de comissão?
				</span>
			
		</div>
	</Dialog>

	<Dialog visible={deleteComissionsDialog} closable={saving ? false : true} closeOnEscape={saving ? false : true}  style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm" modal footer={deleteComissionsDialogFooter} onHide={hideDeleteComissionsDialog}>
		<div className="confirmation-content">
			<i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
			<span>Tem certeza que deseja excluir todas as regras de comissão selecionadas?</span>
		</div>
	</Dialog>	
</>
)

});

export const ComissionPanel = connect(
	mapStateToProps,
	mapDispatchToProps
)((props) => {	

const { 
	comissionProfile,
	selectedAgency,
	retrieveProfileComissions
} = props;	

const [comissionRow, setComissionRow] = React.useState([]);

// Loading
const [loadingComissions, setLoadingComissions] = React.useState(true);

// Set comissions linked to Comission Group
const [generalComission, setGeneralComission] = React.useState({});
const [trainCustomComission, setTrainCustomComission] = React.useState({});
const [busCustomComission, setBusCustomComission] = React.useState({});
const [transferCustomComission, setTransferCustomComission] = React.useState({});
const [rentcarCustomComission, setRentCarCustomComission] = React.useState({});

// Set Company custom comission
const [trainCompanyCustomComission, setTrainCompanyCustomComission] = React.useState({});
const [busCompanyCustomComission, setBusCompanyCustomComission] = React.useState({});
const [transferCompanyCustomComission, setTransferCompanyCustomComission] = React.useState({});
const [rentcarCompanyCustomComission, setRentCarCompanyCustomComission] = React.useState({});	

// Execute when component opens
React.useEffect(() => {

	var props = {
		setLoading:setLoadingComissions,
		setRows:setComissionRow
	}
	var data = {
	  agencyId: selectedAgency.id,
	  comissionProfile:comissionProfile.code
	}	
	retrieveProfileComissions(data,props);

	/*setTimeout(function() {

		setLoadingComissions(false);
		
	},2000);*/
			
},[]);


// Execute when row change
React.useEffect(() => {


	// Only runs if id is set
	if (comissionRow.CompanyComissions) {


			/* COMISSIONS */

			// Set General comission
			if ((comissionRow.CompanyComissions[0]?.CompanyComissionGroupPaymentMethods?.length ?? 0) > 0) {

				let findPix = comissionRow.CompanyComissions[0].CompanyComissionGroupPaymentMethods.find(method => method.SystemRepoPaymentMethod.code === "pix");
				const pixValue = findPix === undefined ? null : findPix.comission;

				let findCc = comissionRow.CompanyComissions[0].CompanyComissionGroupPaymentMethods.find(method => method.SystemRepoPaymentMethod.code === "cc");
				const ccValue = findCc === undefined ? null : findCc.comission;				

				setGeneralComission({
					pix:pixValue,
					cc:ccValue
				}); 
			} // end if

			
			// Set Bus custom comission
			if ((comissionRow.CompanyComissions[0]?.CompanyComissionGroupCustoms?.length ?? 0) > 0) {

				let findPix = comissionRow.CompanyComissions[0].CompanyComissionGroupCustoms.find(method => method.SystemRepoPaymentMethod.code === "pix" && method.SystemRepoTransport.transportType === "bus");
				const pixValue = findPix === undefined ? null : findPix.comission;

				let findCc = comissionRow.CompanyComissions[0].CompanyComissionGroupCustoms.find(method => method.SystemRepoPaymentMethod.code === "cc" && method.SystemRepoTransport.transportType === "bus");
				const ccValue = findCc === undefined ? null : findCc.comission;				

				if (pixValue || ccValue) {
					setBusCustomComission({
						pix:pixValue,
						cc:ccValue
					}); 
				} // end if
			} // end if			

			// Set Train custom comission
			if ((comissionRow.CompanyComissions[0]?.CompanyComissionGroupCustoms?.length ?? 0) > 0) {

				let findPix = comissionRow.CompanyComissions[0].CompanyComissionGroupCustoms.find(method => method.SystemRepoPaymentMethod.code === "pix" && method.SystemRepoTransport.transportType === "train");
				const pixValue = findPix === undefined ? null : findPix.comission;

				let findCc = comissionRow.CompanyComissions[0].CompanyComissionGroupCustoms.find(method => method.SystemRepoPaymentMethod.code === "cc" && method.SystemRepoTransport.transportType === "train");
				const ccValue = findCc === undefined ? null : findCc.comission;				

				if (pixValue || ccValue) {
					setTrainCustomComission({
						pix:pixValue,
						cc:ccValue
					}); 
				} // end if
			} // end if	

			// Set Transfer custom comission
			if ((comissionRow.CompanyComissions[0]?.CompanyComissionGroupCustoms?.length ?? 0) > 0) {

				let findPix = comissionRow.CompanyComissions[0].CompanyComissionGroupCustoms.find(method => method.SystemRepoPaymentMethod.code === "pix" && method.SystemRepoTransport.transportType === "transfer");
				const pixValue = findPix === undefined ? null : findPix.comission;

				let findCc = comissionRow.CompanyComissions[0].CompanyComissionGroupCustoms.find(method => method.SystemRepoPaymentMethod.code === "cc" && method.SystemRepoTransport.transportType === "transfer");
				const ccValue = findCc === undefined ? null : findCc.comission;				

				if (pixValue || ccValue) {
					setTransferCustomComission({
						pix:pixValue,
						cc:ccValue
					}); 
				} // end if
			} // end if			
						
			
			// Set Rent Car custom comission
			if ((comissionRow.CompanyComissions[0]?.CompanyComissionGroupCustoms?.length ?? 0) > 0) {

				let findPix = comissionRow.CompanyComissions[0].CompanyComissionGroupCustoms.find(method => method.SystemRepoPaymentMethod.code === "pix" && method.SystemRepoTransport.transportType === "rentCar");
				const pixValue = findPix === undefined ? null : findPix.comission;

				let findCc = comissionRow.CompanyComissions[0].CompanyComissionGroupCustoms.find(method => method.SystemRepoPaymentMethod.code === "cc" && method.SystemRepoTransport.transportType === "rentCar");
				const ccValue = findCc === undefined ? null : findCc.comission;				

				if (pixValue || ccValue) {
					setRentCarCustomComission({
						pix:pixValue,
						cc:ccValue
					}); 
				} // end if
			} // end if				

			
			// Set Company Custom comissions

			// Company Train  custom comission
			if ((comissionRow.CompanyCustomComissions?.length ?? 0) > 0) {

				let findPix = comissionRow.CompanyCustomComissions.find(method => method.SystemRepoPaymentMethod.code === "pix" && method.SystemRepoTransport.transportType === "train");
				const pixValue = findPix === undefined ? null : findPix.comission;
				const pixExpireAt = findPix === undefined ? null : moment(findPix.expireAt).format("DD/MM/YYYY");

				let findCc = comissionRow.CompanyCustomComissions.find(method => method.SystemRepoPaymentMethod.code === "cc" && method.SystemRepoTransport.transportType === "train");
				const ccValue = findCc === undefined ? null : findCc.comission;	
				const ccExpireAt = findCc === undefined ? null : moment(findCc.expireAt).format("DD/MM/YYYY");

				if (pixValue || ccValue) {
					setTrainCompanyCustomComission({
						pix:{value: pixValue , expireAt: pixExpireAt},
						cc:{value: ccValue , expireAt: ccExpireAt}
					}); 
				} // end if
			} // end if

			// Company Bus custom comission
			if ((comissionRow.CompanyCustomComissions?.length ?? 0) > 0) {

				let findPix = comissionRow.CompanyCustomComissions.find(method => method.SystemRepoPaymentMethod.code === "pix" && method.SystemRepoTransport.transportType === "bus");
				const pixValue = findPix === undefined ? null : findPix.comission;
				const pixExpireAt = findPix === undefined ? null : moment(findPix.expireAt).format("DD/MM/YYYY");

				let findCc = comissionRow.CompanyCustomComissions.find(method => method.SystemRepoPaymentMethod.code === "cc" && method.SystemRepoTransport.transportType === "bus");
				const ccValue = findCc === undefined ? null : findCc.comission;				
				const ccExpireAt = findCc === undefined ? null : moment(findCc.expireAt).format("DD/MM/YYYY");

				if (pixValue || ccValue) {
					setBusCompanyCustomComission({
						pix:{value: pixValue , expireAt: pixExpireAt},
						cc:{value: ccValue , expireAt: ccExpireAt}
					}); 
				} // end if
			} // end if			

			
			// Company Transfer custom comission
			if ((comissionRow.CompanyCustomComissions?.length ?? 0) > 0) {

				let findPix = comissionRow.CompanyCustomComissions.find(method => method.SystemRepoPaymentMethod.code === "pix" && method.SystemRepoTransport.transportType === "transfer");
				const pixValue = findPix === undefined ? null : findPix.comission;
				const pixExpireAt = findPix === undefined ? null : moment(findPix.expireAt).format("DD/MM/YYYY");

				let findCc = comissionRow.CompanyCustomComissions.find(method => method.SystemRepoPaymentMethod.code === "cc" && method.SystemRepoTransport.transportType === "transfer");
				const ccValue = findCc === undefined ? null : findCc.comission;	
				const ccExpireAt = findCc === undefined ? null : moment(findCc.expireAt).format("DD/MM/YYYY");

				if (pixValue || ccValue) {
					setTransferCompanyCustomComission({
						pix:{value: pixValue , expireAt: pixExpireAt},
						cc:{value: ccValue , expireAt: ccExpireAt}
					}); 
				} // end if
			} // end if				
			
			// Company Rent Car custom comission
			if ((comissionRow.CompanyCustomComissions?.length ?? 0) > 0) {

				let findPix = comissionRow.CompanyCustomComissions.find(method => method.SystemRepoPaymentMethod.code === "pix" && method.SystemRepoTransport.transportType === "rentCar");
				const pixValue = findPix === undefined ? null : findPix.comission;
				const pixExpireAt = findPix === undefined ? null : moment(findPix.expireAt).format("DD/MM/YYYY");

				let findCc = comissionRow.CompanyCustomComissions.find(method => method.SystemRepoPaymentMethod.code === "cc" && method.SystemRepoTransport.transportType === "rentCar");
				const ccValue = findCc === undefined ? null : findCc.comission;		
				const ccExpireAt = findCc === undefined ? null : moment(findCc.expireAt).format("DD/MM/YYYY");		

				if (pixValue || ccValue) {
					setRentCarCompanyCustomComission({
						pix:{value: pixValue , expireAt: pixExpireAt},
						cc:{value: ccValue , expireAt: ccExpireAt}
					}); 
				} // end if
			} // end if				
		
			
	} // end if	
			
},[comissionRow]);


return (
<>
				
		<div style={{marginTop:"0px",marginBottom:"10px",textAlign:"left",fontSize:"18px",letterSpacing:"0.1em",lineHeight:"120%",fontWeight:"600",fontStyle:"normal",fontFamily:"Titillium Web",color:"#2E0249"}}> Pagamento com Cartão de crédito </div>
																		
		<div style={{display:"flex",alignItems:"center",justifyContent:"flex-start",gap:"20px",flexDirection:"row",flexWrap:"wrap",textAlign:"center",padding:"10px"}}>

		{/* LOADING ACTION  */}
		{loadingComissions &&
			<>
				<div style={{backgroundColor:"transparent", width:"100%",height:"unset",maxWidth:"1170px", marginTop:"10px",marginBottom:"20px",display:"flex",alignItems:"center",justifyContent:"flex-start",flexDirection:"row",gap:"20px"}}>
				
						<ProgressSpinner style={{width: '30px', height: '30px',marginRight: "20px",marginLeft:"20px"}} strokeWidth="4" />
						<div style={{fontSize:"22px",marginTop:"0px"}}> Carregando...Aguarde! </div>
																		
				</div>
			</>				
		}

		{!loadingComissions &&
		<>
			{/* Train comission CREDIT CARD */}
			<Card title={<div style={{backgroundColor:"#814DE5",fontSize:"12px",padding:"5px",color:"white",borderRadius:"10px"}}>Trem </div>} style={{width:"120px",height:"90px",backgroundColor:"whitesmoke"}}>

				{trainCompanyCustomComission.cc?.value	&&
				<>
					<div style={{fontSize:"18px"}}>{formatPercentageNumber(trainCompanyCustomComission.cc.value)}</div>
					<div style={{fontSize:"12px",color:"red"}}>
						Condição customizada <br />
						<small>Válida até {trainCompanyCustomComission.cc.expireAt}</small>
					</div>																		
				</>
				}

				{(!trainCompanyCustomComission.cc?.value && trainCustomComission.cc) &&
				<>
					<div style={{fontSize:"18px"}}>{formatPercentageNumber(trainCustomComission.cc)}</div>
				</>
				}	

				{(!trainCompanyCustomComission.cc?.value && !trainCustomComission.cc) &&
				<>
					<div style={{fontSize:"18px"}}>{formatPercentageNumber(generalComission.cc)}</div>
					<div style={{fontSize:"12px",color:"silver"}}>
						Comissão global
					</div>																			
				</>
				}		
			</Card>	
			
			{/* Bus comission CREDIT CARD */}
			<Card title={<div style={{backgroundColor:"#814DE5",fontSize:"12px",padding:"5px",color:"white",borderRadius:"10px"}}>Ônibus </div>}  style={{width:"120px",height:"90px",backgroundColor:"whitesmoke"}}>
				{busCompanyCustomComission.cc?.value &&
				<>
					<div style={{fontSize:"18px"}}>{formatPercentageNumber(busCompanyCustomComission.cc.value)}</div>
					<div style={{fontSize:"12px",color:"red"}}>
						Condição customizada <br />
						<small>Válida até {busCompanyCustomComission.cc.expireAt}</small>
					</div>																		
				</>
				}

				{(!busCompanyCustomComission.cc?.value && busCustomComission.cc) &&
				<>
					<div style={{fontSize:"18px"}}>{formatPercentageNumber(busCustomComission.cc)}</div>
				</>
				}	

				{(!busCompanyCustomComission.cc?.value && !busCustomComission.cc) &&
				<>
					<div style={{fontSize:"18px"}}>{formatPercentageNumber(generalComission.cc)}</div>
					<div style={{fontSize:"12px",color:"silver"}}>
						Comissão global
					</div>																			
				</>
				}																		
			</Card>	
			
			{/* Transfer comission CREDIT CARD */}
			<Card title={<div style={{backgroundColor:"#814DE5",fontSize:"12px",padding:"5px",color:"white",borderRadius:"10px"}}>Transfer </div>}  style={{width:"120px",height:"90px",backgroundColor:"whitesmoke"}}>
				{transferCompanyCustomComission.cc?.value &&
				<>
					<div style={{fontSize:"18px"}}>{formatPercentageNumber(transferCompanyCustomComission.cc.value)}</div>
					<div style={{fontSize:"12px",color:"red"}}>
						Condição customizada <br />
						<small>Válida até {transferCompanyCustomComission.cc.expireAt}</small>
					</div>																		
				</>
				}

				{(!transferCompanyCustomComission.cc?.value && transferCustomComission.cc) &&
				<>
					<div style={{fontSize:"18px"}}>{formatPercentageNumber(transferCustomComission.cc)}</div>
				</>
				}	

				{(!transferCompanyCustomComission.cc?.value && !transferCustomComission.cc) &&
				<>
					<div style={{fontSize:"18px"}}>{formatPercentageNumber(generalComission.cc)}</div>
					<div style={{fontSize:"12px",color:"silver"}}>
						Comissão global
					</div>																			
				</>
				}	
			</Card>	

			{/* RentCar comission CREDIT CARD */}
			<Card title={<div style={{backgroundColor:"#814DE5",fontSize:"12px",padding:"5px",color:"white",borderRadius:"10px"}}>Aluguel de carro </div>} style={{width:"120px",height:"90px",backgroundColor:"whitesmoke"}}>
				{rentcarCompanyCustomComission.cc?.value &&
				<>
					<div style={{fontSize:"18px"}}>{formatPercentageNumber(rentcarCompanyCustomComission.cc.value)}</div>
					<div style={{fontSize:"12px",color:"red"}}>
						Condição customizada <br />
						<small>Válida até {rentcarCompanyCustomComission.cc.expireAt}</small>
					</div>																		
				</>
				}

				{(!rentcarCompanyCustomComission.cc?.value && rentcarCustomComission.cc) &&
				<>
					<div style={{fontSize:"18px"}}>{formatPercentageNumber(rentcarCustomComission.cc)}</div>
				</>
				}	

				{(!rentcarCompanyCustomComission.cc?.value && !rentcarCustomComission.cc) &&
				<>
					<div style={{fontSize:"18px"}}>{formatPercentageNumber(generalComission.cc)}</div>
					<div style={{fontSize:"12px",color:"silver"}}>
						Comissão global
					</div>																			
				</>
				}	
			</Card>
		</>
		}

		</div>

		<div style={{marginTop:"30px",marginBottom:"10px",textAlign:"left",fontSize:"18px",letterSpacing:"0.1em",lineHeight:"120%",fontWeight:"600",fontStyle:"normal",fontFamily:"Titillium Web",color:"#2E0249"}}> Pagamento com PIX</div>
			
		<div style={{width:"578px",display:"flex",alignItems:"center",justifyContent:"flex-start",gap:"20px",flexDirection:"row",flexWrap:"wrap",textAlign:"center",padding:"10px"}}>

		{/* LOADING ACTION  */}
		{loadingComissions &&
			<>
				<div style={{backgroundColor:"transparent", width:"100%",height:"unset",maxWidth:"1170px", marginTop:"10px",marginBottom:"20px",display:"flex",alignItems:"center",justifyContent:"flex-start",flexDirection:"row",gap:"10px"}}>
				
						<ProgressSpinner style={{width: '30px', height: '30px',marginRight: "20px",marginLeft:"20px"}} strokeWidth="4" />
						<div style={{fontSize:"22px",marginTop:"0px"}}> Carregando...Aguarde! </div>
																		
				</div>
			</>				
		}

		{!loadingComissions &&
		<>
			{/* Train comission CREDIT CARD */}
			<Card title={<div style={{backgroundColor:"#814DE5",fontSize:"12px",padding:"5px",color:"white",borderRadius:"10px"}}>Trem </div>} style={{width:"120px",height:"90px",backgroundColor:"whitesmoke"}}>
				{trainCompanyCustomComission.pix?.value	&&
				<>
					<div style={{fontSize:"18px"}}>{formatPercentageNumber(trainCompanyCustomComission.pix.value)}</div>
					<div style={{fontSize:"12px",color:"red"}}>
						Condição customizada <br />
						<small>Válida até {trainCompanyCustomComission.pix.expireAt}</small>
					</div>																		
				</>
				}

				{(!trainCompanyCustomComission.pix?.value && trainCustomComission.pix) &&
				<>
					<div style={{fontSize:"18px"}}>{formatPercentageNumber(trainCustomComission.pix)}</div>
				</>
				}	

				{(!trainCompanyCustomComission.pix?.value && !trainCustomComission.pix) &&
				<>
					<div style={{fontSize:"18px"}}>{formatPercentageNumber(generalComission.pix)}</div>
					<div style={{fontSize:"12px",color:"silver"}}>
						Comissão global
					</div>																			
				</>
				}		
			</Card>	
			
			{/* Bus comission CREDIT CARD */}
			<Card title={<div style={{backgroundColor:"#814DE5",fontSize:"12px",padding:"5px",color:"white",borderRadius:"10px"}}>Ônibus </div>}  style={{width:"120px",height:"90px",backgroundColor:"whitesmoke"}}>
				{busCompanyCustomComission.pix?.value &&
				<>
					<div style={{fontSize:"18px"}}>{formatPercentageNumber(busCompanyCustomComission.pix.value)}</div>
					<div style={{fontSize:"12px",color:"red"}}>
						Condição customizada <br />
						<small>Válida até {busCompanyCustomComission.pix.expireAt}</small>
					</div>																		
				</>
				}

				{(!busCompanyCustomComission.pix?.value && busCustomComission.pix) &&
				<>
					<div style={{fontSize:"18px"}}>{formatPercentageNumber(busCustomComission.pix)}</div>
				</>
				}	

				{(!busCompanyCustomComission.pix?.value && !busCustomComission.pix) &&
				<>
					<div style={{fontSize:"18px"}}>{formatPercentageNumber(generalComission.pix)}</div>
					<div style={{fontSize:"12px",color:"silver"}}>
						Comissão global
					</div>																			
				</>
				}																		
			</Card>	
			
			{/* Transfer comission CREDIT CARD */}
			<Card title={<div style={{backgroundColor:"#814DE5",fontSize:"12px",padding:"5px",color:"white",borderRadius:"10px"}}>Transfer </div>}  style={{width:"120px",height:"90px",backgroundColor:"whitesmoke"}}>
				{transferCompanyCustomComission.pix?.value &&
				<>
					<div style={{fontSize:"18px"}}>{formatPercentageNumber(transferCompanyCustomComission.pix.value)}</div>
					<div style={{fontSize:"12px",color:"red"}}>
						Condição customizada <br />
						<small>Válida até {transferCompanyCustomComission.pix.expireAt}</small>
					</div>																		
				</>
				}

				{(!transferCompanyCustomComission.pix?.value && transferCustomComission.pix) &&
				<>
					<div style={{fontSize:"18px"}}>{formatPercentageNumber(transferCustomComission.pix)}</div>
				</>
				}	

				{(!transferCompanyCustomComission.pix?.value && !transferCustomComission.pix) &&
				<>
					<div style={{fontSize:"18px"}}>{formatPercentageNumber(generalComission.pix)}</div>
					<div style={{fontSize:"12px",color:"silver"}}>
						Comissão global
					</div>																			
				</>
				}	
			</Card>	

			{/* RentCar comission CREDIT CARD */}
			<Card title={<div style={{backgroundColor:"#814DE5",fontSize:"12px",padding:"5px",color:"white",borderRadius:"10px"}}>Aluguel de carro </div>} style={{width:"120px",height:"90px",backgroundColor:"whitesmoke"}}>
				{rentcarCompanyCustomComission.pix?.value &&
				<>
					<div style={{fontSize:"18px"}}>{formatPercentageNumber(rentcarCompanyCustomComission.pix.value)}</div>
					<div style={{fontSize:"12px",color:"red"}}>
						Condição customizada <br />
						<small>Válida até {rentcarCompanyCustomComission.pix.expireAt}</small>
					</div>																		
				</>
				}

				{(!rentcarCompanyCustomComission.pix?.value && rentcarCustomComission.pix) &&
				<>
					<div style={{fontSize:"18px"}}>{formatPercentageNumber(rentcarCustomComission.pix)}</div>
				</>
				}	

				{(!rentcarCompanyCustomComission.pix?.value && !rentcarCustomComission.pix) &&
				<>
					<div style={{fontSize:"18px"}}>{formatPercentageNumber(generalComission.cc)}</div>
					<div style={{fontSize:"12px",color:"silver"}}>
						Comissão global
					</div>																			
				</>
				}	
			</Card>
			</>
			}

		</div>
		
</>
)

});


export const DailyCreditHistoryPanel = connect(
	mapStateToProps,
	mapDispatchToProps
)((props) => {	

const { 
	selectedAgency,
	retrieveDailyCreditLimit
} = props;	

const [creditLimitRow, setCreditLimitRow] = React.useState([]);


// Loading
const [loading, setLoading] = React.useState(true);

// Execute when component opens
React.useEffect(() => {

	var props = {
		setLoading:setLoading,
		setRows:setCreditLimitRow
	}
	var data = {
	  agencyId: selectedAgency.id
	}	
	retrieveDailyCreditLimit(data,props);

	/*setTimeout(function() {

		setLoadingComissions(false);
		
	},2000);*/
			
},[]);

const bodyTemplate = (type,data) => {

	let rowValue = "Não definido";
	
	if (type == "limit") {		
	
		rowValue = (
		<>
		<div>{parseFloat(data).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} €</div>
		</>
		);
	}	
	
	
	if (type == "createdAt") {		

		rowValue = (
			<>
			<div>{moment(data).format("DD/MM/YYYY")}</div>
			</>
			);		
	}	

    return rowValue;

};
 


// Execute when row change
React.useEffect(() => {

	// Only runs if id is set
	if (creditLimitRow.length > 0) {


	} // end if	
			
},[creditLimitRow]);


return (
<>
				
		<div style={{marginTop:"0px",marginBottom:"10px",textAlign:"left",fontSize:"18px",letterSpacing:"0.1em",lineHeight:"120%",fontWeight:"600",fontStyle:"normal",fontFamily:"Titillium Web",color:"#2E0249"}}> Histórico de limite de crédito diário </div>
																		
		<div style={{display:"flex",alignItems:"center",justifyContent:"flex-start",gap:"20px",flexDirection:"row",flexWrap:"wrap",textAlign:"center",padding:"10px"}}>

		{/* LOADING ACTION  */}
		{loading &&
			<>
				<div style={{backgroundColor:"transparent", width:"100%",height:"unset",maxWidth:"1170px", marginTop:"10px",marginBottom:"20px",display:"flex",alignItems:"center",justifyContent:"flex-start",flexDirection:"row",gap:"20px"}}>
				
						<ProgressSpinner style={{width: '30px', height: '30px',marginRight: "20px",marginLeft:"20px"}} strokeWidth="4" />
						<div style={{fontSize:"22px",marginTop:"0px"}}> Carregando...Aguarde! </div>
																		
				</div>
			</>				
		}

		{!loading &&
		<>
			{((creditLimitRow?.length ?? 0) === 0) && (
			<>
				<div className="panel_data_value">Não há histórico de crédito.</div>
			</>
			)}

			{(creditLimitRow?.length ?? 0) > 0 && (
				<DataTable value={creditLimitRow} tableStyle={{ minWidth: '20rem',marginTop:"15px" }}>
					<Column field="limit" header="Limite de crédito" body={(data)=>bodyTemplate('limit',data.limit)}></Column>
					<Column field="createdAt" header="Criado em" body={(data)=>bodyTemplate('createdAt',data.createdAt)}></Column>
				</DataTable>
			)}
		</>
		}

		</div>

		
</>
)

});

export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(AgencyView);	
