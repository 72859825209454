import '../../Assets/Styles/home.css';
import '../../Assets/Styles/order.css';
import '../../Assets/Styles/orderStep2.css';
import React from "react";
import { useMediaQuery } from 'react-responsive'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {Link,useNavigate} from 'react-router-dom';

import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button'; 
import { Dialog } from 'primereact/dialog'; 
import { Steps } from 'primereact/steps';
import { ToggleButton } from 'primereact/togglebutton';
import { Tooltip } from 'primereact/tooltip';
import { AutoComplete } from 'primereact/autocomplete';
import { Calendar } from 'primereact/calendar';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Dropdown } from 'primereact/dropdown';
import { addLocale } from 'primereact/api';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Divider } from 'primereact/divider';
import { Skeleton } from 'primereact/skeleton';
import { Panel } from 'primereact/panel';
import { TabView, TabPanel } from 'primereact/tabview';
import { ProgressBar } from 'primereact/progressbar';
import { InputSwitch } from 'primereact/inputswitch';
import { Message } from 'primereact/message';
import { RadioButton } from 'primereact/radiobutton';
import { Accordion, AccordionTab } from 'primereact/accordion';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import ForkRightIcon from '@mui/icons-material/ForkRight';
import StarsIcon from '@mui/icons-material/Stars';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import LocalAirportIcon from '@mui/icons-material/LocalAirport';


import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

import multiTransportConcept from '../../Assets/Images/multiTransportConcept11.png'; 
import desktopConcept from '../../Assets/Images/desktopConcept.png'; 
import contactConcept from '../../Assets/Images/contactConcept.png'; 
import formPlaceholder from '../../Assets/Images/formPlaceholder.png'; 
import formTopIcons from '../../Assets/Images/formTopIcons.svg'; 
import cornerTop from '../../Assets/Images/cornerTop.svg';
import cornerBottom from '../../Assets/Images/cornerBottom.svg';

import contactWhatsappIcon from '../../Assets/Images/contactWhatsappIcon.svg';
import contactEmailIcon from '../../Assets/Images/contactEmailIcon.svg';
import contactPhoneIcon from '../../Assets/Images/contactPhoneIcon.svg';
import transportPicture from '../../Assets/Images/transportPicture.svg';
import rentcarPicture from '../../Assets/Images/rentcarPicture.svg';
import transferPicture from '../../Assets/Images/transferPicture.svg';
import transportIcon from '../../Assets/Images/transportIcons.svg';
import transferIcon from '../../Assets/Images/transferIcon.svg';
import rentcarIcon from '../../Assets/Images/rentcarIcon.svg';
import tooltipIcon from '../../Assets/Images/tooltipIcon.svg';
import iconAlert from '../../Assets/Images/iconAlert.svg';
import roteiroIcon from '../../Assets/Images/roteiroIcon.svg';
import reservaIcon from '../../Assets/Images/reservaIcon.svg';
import paymentIcon from '../../Assets/Images/paymentIcon.svg';
import ideaIcon from '../../Assets/Images/ideiaIcon.png';

import titleTrainIcon from '../../Assets/Images/titleTrainIcon.svg';
import titleBusIcon from '../../Assets/Images/titleBusIcon.svg';
import obbProviderLogo from '../../Assets/Images/obb-provider.svg';
import flixbusProviderLogo from '../../Assets/Images/flixbus-provider.png';
import titleSedanIcon from '../../Assets/Images/titleSedanIcon.svg';

// Load Actions
import * as AppActions from '../../Store/Actions/App'
import * as OrderActions from '../../Store/Actions/Order'
import * as RegisterActions from '../../Store/Actions/Register';

// Load Componentes
import MessageView from '../../Components/MessageModal';
import AlertView from '../../Components/AlertModal';
import ExpireView from '../../Components/ExpireModal';
import {
	validarCNPJ,
	validarCPF,
	validarEmail,
	validarTelefone,
	validarNome,
	maskCNPJ,
	maskCPF,
	maskPhone
} from '../../Components/Utils';

import {
	Callto,
	Whatsapp
} from '../../Components/Utils';

/*
import {
	stepsData
} from '../../Components/StaticData';*/

// export default function TransferSearchResult() {
const TransferSearchResult = (props) => {
	
// Set var from props
const {
	setDialog,
	dialog,
	retrieveAppAddressGooglePlaces,
	retrieveAppAddressesGoogleMatrixDistance,
	transportFilters,
	retrievePersonDataByDocument,
	routePanel,
	setRoutePanel,
	currentAgency
} = props;


//console.log("[transportFilters]--->"+JSON.stringify(transportFilters,null,2));


const [loading, setLoading] = React.useState(true); 
const [saving, setSaving] = React.useState(false); 
const [loadingContent, setLoadingContent] = React.useState(true); 
const [loadingContentTrain, setLoadingContentTrain] = React.useState(true); 

const [loadingContentTransfer, setLoadingContentTransfer] = React.useState(true); 

const [loadingContentBus, setLoadingContentBus] = React.useState(true); 

 const [counter, setCounter] = React.useState(1200);
 const [counterInitial, setCounterInitial] = React.useState(1200);
 
 // const [counter, setCounter] = React.useState(20); // for test. Force popup. 
 const [counterLabel, setCounterLabel] = React.useState("00:20:00");
 const [counterValue, setCounterValue] = React.useState(100);

const [loadingRightPanel, setLoadingRightPanel] = React.useState(true); 

// Right Panel Status
const [rightPanel, setRightPanel] = React.useState(false); 
const [rightPanelBlock, setRightPanelBlock] = React.useState(false); 
	
const isDesktopOrLaptop = useMediaQuery({
query: '(min-width: 1024px)'
})
const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1023px)' })

const [email, setEmail] = React.useState('');
const [password, setPassword] = React.useState('');
const [stillConnected, setStillConnected] = React.useState(false);
const onStillConnectedChange = (e) => {
	if (e.checked)
		setStillConnected(true);
	else
		setStillConnected(false);
}  // end function
	
// Alert control
const [alert, setAlert] = React.useState(false); 
const [alertTitle, setAlertTitle] = React.useState("Informações"); 
const [alertBody, setAlertBody] = React.useState("Conteúdo dinâmico"); 

// Expire Dialog control
const [alertExpire, setAlertExpire] = React.useState(false); 
const [alertTitleExpire, setAlertTitleExpire] = React.useState("Informações"); 
const [alertBodyExpire, setAlertBodyExpire] = React.useState("Conteúdo dinâmico"); 
const [alertActionExpire, setAlertActionExpire] = React.useState("restartTransferSearch"); 

const [forgotPassword, setForgotPassword] = React.useState(false); 		

// Transport selection
const [checkedSedan, setCheckedSedan] = React.useState(true);
const [checkedPrivateVan, setCheckedPrivateVan] = React.useState(true);
const [checkedSUV, setCheckedSuv] = React.useState(true);
const [checkedLocalDriver, setCheckedLocalDriver] = React.useState(true);
const [checkedPrivateBus, setCheckedPrivateBus] = React.useState(false);
const [checkedSharedVan, setCheckedSharedVan] = React.useState(false);
const [checkedLimo, setCheckedLimo] = React.useState(false);
const [checkedBus, setCheckedBus] = React.useState(false);
const [checkedTaxi, setCheckedTaxi] = React.useState(false);

const [departureCityLoading, setDepartureCityLoading] = React.useState(false);
const [destinationCityLoading, setDestinationCityLoading] = React.useState(false);
const [departureCityResult, setDepartureCityResult] = React.useState(false);
const [destinationCityResult, setDestinationCityResult] = React.useState(false);

const [departureLocalCountry, setDepartureLocalCountry] = React.useState("TESTE");
const [departureLocalCity, setDepartureLocalCity] = React.useState(null);
const [destinationLocalCountry, setDestinationLocalCountry] = React.useState(null);
const [destinationLocalCity, setDestinationLocalCity] = React.useState(null);

const [departureLocal, setDepartureLocal] = React.useState(null);

const [destinationLocal, setDestinationLocal] = React.useState(null);

const [destinationDate, setDestinationDate] = React.useState(null);
const [destinationTime, setDestinationTime] = React.useState(null);	

const [departureDate, setDepartureDate] = React.useState(null);
const [departureTime, setDepartureTime] = React.useState(null);

const [pickReturnDate, setPickReturnDate] = React.useState(false);
const [pickReturn, setPickReturn] = React.useState(false);

const [leaveDate, setLeaveDate] = React.useState(null);
const [returnDate, setReturnDate] = React.useState(null);

const [value, setValue] = React.useState('');
const [items, setItems] = React.useState([]);

/* Person select START */
const [menuOpened,setMenuOpened] = React.useState(false);
const [personAddLabel,setPersonAddLabel] = React.useState("1 Viajante"); // default
const [personAddLabelSimple,setPersonAddLabelSimple] = React.useState("1"); // default
const [personAddAdult,setPersonAddAdult] = React.useState(1); // default adult
const [personAddYoung,setPersonAddYoung] = React.useState(0); // default young
const [personAddTotal,setPersonAddTotal] = React.useState(1); 
const [personAddYoungAges,setPersonAddYoungAges] = React.useState([]); 
const [personAddElder,setPersonAddElder] = React.useState(0); // default elder
const [personAddElderAges,setPersonAddElderAges] = React.useState([]); 


const [luggageAddLabel,setLuggageAddLabel] = React.useState("1 Bagagem"); // default
const [luggageAdd,setLuggage] = React.useState(1); // default adult
const [menuLugOpened,setMenuLugOpened] = React.useState(false);
const [menuPriceOpened,setMenuPriceOpened] = React.useState(false);

const [selectedElderAge,setSelectedElderAge] = React.useState([]);
const [selectedYoungAge,setSelectedYoungAge] = React.useState([]); 
const [selectedElderAgeLabel,setSelectedElderAgeLabel] = React.useState("");
const [selectedYoungAgeLabel,setSelectedYoungAgeLabel] = React.useState(""); 

const [menuOpenedOrder,setMenuOrderOpened] = React.useState(false);

const [orderByLabel,setOrderByLabel] = React.useState("Ordenar por"); // default
const [orderByFieldLabel,setOrderByFieldLabel] = React.useState(""); // default

const [lastObjectId,setLastObjectId] = React.useState({}); // default
const [selectedTransportPrice,setSelectedTransportPrice] = React.useState("Carregando..."); // default
const [selectedTransportClass,setSelectedTransportClass] = React.useState("Carregando..."); // default

const [transportType,setTransportType] = React.useState(null); // default


const [checkedOption1, setCheckedOption1] = React.useState(false);
const [checkedOption2, setCheckedOption2] = React.useState(false);
const [checkedOption3, setCheckedOption3] = React.useState(false);


// temp
const [checked, setChecked] = React.useState(false);
const [seat, setSeat] = React.useState('');
const [checkedCPF, setCheckedCPF] = React.useState(true);
const [checkedTrain, setCheckedTrain] = React.useState(false);


/* Passengers*/
const [loadingPersonData, setPersonLoadingData] = React.useState(false);
const [personName, setPersonName] = React.useState('');
const [birthday, setBirthday] = React.useState('');
const [personDocument, setPersonDocument] = React.useState('');
const [personPhone, setPersonPhone] = React.useState('');

const [acceptTerm, setAcceptTerm] = React.useState(false);
const [autoFields, setAutoFields] = React.useState(true); // if true, fields are disabled
const [autoFieldsFill, setAutoFieldsFill] = React.useState(true); // if true, fields are auto filled. No manual.
const [autoFieldsFilled, setAutoFieldsFilled] = React.useState(false); // if true, fields was returned
const [autoPersonFieldsFilled, setAutoPersonFieldsFilled] = React.useState(false); // if true, fields was returned
const [documentReady, setDocumentReady] = React.useState(false); // if true, document is ok and ready
const [personDocumentReady, setPersonDocumentReady] = React.useState(false); // if true, document is ok and ready

const [routeName, setRouteName] = React.useState(null); 
const [routeStatus, setRouteStatus] = React.useState("waiting"); 

const [termsModal, setTermsModal] = React.useState(false); 

const [routePanelContent, setRoutePanelContent] = React.useState(false); 

let today = new Date();
let month = today.getMonth();
let year = today.getFullYear();
let minDate = new Date();

addLocale('br', {
	firstDayOfWeek: 1,
	dayNames: ['domingo', 'segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado'],
	dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
	dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
	monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
	monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
	today: 'Hoje',
	clear: 'Limpar'
});

const op = React.useRef(null);
const op2 = React.useRef(null);
const opLug = React.useRef(null);
const opPrice = React.useRef(null);

const onHide = () => {
setMenuOpened(false);
}  
const onShow = () => {
setMenuOpened(true);
}    

const onHideOrder = () => {
setMenuOrderOpened(false);
}  
const onShowOrder = () => {
setMenuOrderOpened(true);
}    

const onLugHide = () => {
setMenuLugOpened(false);
}  
const onLugShow = () => {
setMenuLugOpened(true);
}    


const onHidePrice = () => {
setMenuPriceOpened(false);
}  
const onShowPrice = () => {
setMenuPriceOpened(true);
}    
 

 
const setPerson = (operation,type) => {
	
	// Set total passengers
	let currentTotal = personAddAdult + personAddYoung + personAddElder;
	
	if (type == 'adult') {
		
		let newValue = operation == '+' ? personAddAdult + 1 : personAddAdult - 1;
		newValue = newValue < 0 ? 0 : newValue;
		if (currentTotal < 50 || operation == '-') {
			setPersonAddAdult(newValue);
		}
		
	} // end if
	else if (type == 'young') {
		
		let newValue = operation == '+' ? personAddYoung + 1 : personAddYoung - 1;
		newValue = newValue < 0 ? 0 : newValue;
		if (currentTotal < 50 || operation == '-') {
			setPersonAddYoung(newValue);
		}
		
		// reset ages
		if (operation == '-') { setSelectedYoungAge([]); }
		
	} // end if
	else if (type == 'elder') {
		
		let newValue = operation == '+' ? personAddElder + 1 : personAddElder - 1;
		newValue = newValue < 0 ? 0 : newValue;
		if (currentTotal < 50 || operation == '-') {
			setPersonAddElder(newValue);	
		}
		
		// reset ages
		if (operation == '-') { setSelectedElderAge([]); }		
		
	} // end if

	
} // end function
/* Person select END */


const generateTotalLabel = () => {

	// Set total passengers
	let currentTotal = personAddAdult + personAddYoung + personAddElder;

	if (personAddAdult == 1 && personAddYoung == 0 && personAddElder == 0) {
		var label = "1 Viajante ";	
	} // end if
	else if (personAddAdult > 1 && personAddYoung == 0 && personAddElder == 0) {
		var label = ""+personAddAdult+" Viajantes ";	
	} // end else if
	else if (currentTotal == 0) {
		var label = "Nenhum Viajante";
	} // end else if	
	else if (currentTotal == 1) {
		var label = ""+currentTotal+" Viajante ";
	} // end else if	
	else {
		var label = ""+currentTotal+" Viajantes";	
	} // end else
	
	// Set Person Control Label
	setPersonAddLabel(label);
	setPersonAddLabelSimple(currentTotal);
	
}


const processPickReturn = (value) => {
	
	if (value) {
		setPickReturnDate(true);
	} // end if
	else {
		setPickReturnDate(false);
	} // end else

	setPickReturn(value);		
	
}

// set Use of history for PUSH 
var navigate = useNavigate();
	
/* Execute recaptcha to retrieve token*/
const recaptchaRef = window.grecaptcha;
const executeReCaptcha = async () => {
	const token = await recaptchaRef.execute();
	return token;
}

 // Suggested by Laurent
React.useEffect(() => {
	 
	 if (counter > 0) {
		 setTimeout(function() {
			 setCounter(counter - 1)
			 setCounterLabel(secondsToHms(counter - 1));

			// Current %
			var currentPercent = (100 * counter) / counterInitial;
			setCounterValue(currentPercent);
			 
		 },1000);		 
	 } // end if
	 else {
		 
		setAlertExpire(true);
		setAlertTitleExpire("O resultado da busca expirou");
		setAlertBodyExpire("O resultado da busca incluindo preços e trechos expirou. Faça uma nova pesquisa, para ver preços e viagens atualizadas.");
		setAlertActionExpire("restartTransportSearch");
		 
	 } // end else
	 
 }, [counter]);

const secondsToHms = (d) => {
	
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1) : "00";
    var mDisplay = m > 0 ? m + (m == 1) : "00";
    var sDisplay = s > 0 ? s + (s == 1) : "00";

	let hour = ""+hDisplay+"";
	let minutes = ""+mDisplay+"";
	let seconds = ""+sDisplay+"";

    return hour.padStart(2, '0') +":"+ minutes.padStart(2, '0')+":"+ seconds.padStart(2, '0');
}


// Run after mount component
React.useEffect(() => {

	// Retrieve User Data Obj. 
	var result = localStorage.getItem("@memovo/userData", undefined,true);
	var userData = JSON.parse(result);  

	  // If token dont exist, redirect to onboarding or register
	  if (!userData) {
		  navigate("/home");
		  return;
	  } // end if
	  	  
	// If there is no filter, go back to filtering page
	if (!transportFilters.departureLocal) {  
		navigate("/order/transfer");
		return;
  } // end if	  
	  
	 setLoading(false);

  // set scroll to top
  window.scrollTo(0, 1);
  
  setRoutePanel(false);
  
// Load stored info
if (transportFilters.departureLocal) {

	setDepartureLocal(transportFilters.departureLocal);

	setDepartureDate(transportFilters.departureDate);
	setDepartureTime(transportFilters.departureTime);
	
	setDestinationLocal(transportFilters.destinationLocal);
	
	setDestinationDate(transportFilters.destinationDate);
	setDestinationTime(transportFilters.destionationTime);

	setPersonAddAdult(transportFilters.adultPassengers);
	setLuggage(transportFilters.luggageAdd);
	
  
} // end if


setTimeout(function() {
  
setLoadingContentTransfer(false);
setLoadingContent(false);
  
}, 4000);


 /*
  setTimeout(function() {
	  
	setLoadingContentTrain(false);
	setLoadingContent(false);
	  
  }, 4000);

  
  setTimeout(function() {
	  
	setLoadingContentBus(false);
	setLoadingContent(false);
	  
  }, 8000);*/
 
  
setTimeout(function() {
	
	var objPrice = "65,00 €";
	var objTransportType = "train";
	setTransportType(objTransportType);
	setSelectedTransportPrice(objPrice);
	
	setLoadingRightPanel(false);
	
},1500);  
  
    
  // Runs when UNMOUNT component
  return () => {
    
	setRoutePanel(false);
	
  };
  
}, []); // end useEffect 


// Extra values to a plain array
const convertAgeArray = (value) => {
	
	let finalString = "";
	for (var i = 0; i < value.length; i++) {
		
		finalString += value[i].value+" anos,";
		
	} // end for	
	
	var finalStringConcat = finalString.substr(0,finalString.length-1);

	return finalStringConcat;
	
}


// Execute generateTotalLabel if value changes
React.useEffect(() => {

	generateTotalLabel();
		  
}, [personAddAdult]); // end useEffect 


React.useEffect(() => {

	generateTotalLuggageLabel();
		  
}, [luggageAdd]); // end useEffect 


const stepsData = [
    {
        label: 'Tipo deslocamento',
        command: (event) => {
			console.log("[Clicou Step 1]--->");
        }
    },
     {
        label: 'Dados da Viagem',
         command: (event) => {
            console.log("[Clicou Step 2]--->");
        }
    },
    {
        label: 'Escolha do Transporte',
        command: (event) => {
            console.log("[Clicou Step 3]--->");
        }
    },
    {
        label: 'Confirmação de Dados',
        command: (event) => {
            console.log("[Clicou Step 5]--->");
        }
    },	
    {
        label: 'Confirmação de Reserva',
        command: (event) => {
            console.log("[Clicou Step 4]--->");
        }
    }
];

var youngAgesRangeArray = [];
for (var i = 0; i <= 25; i++) {
	youngAgesRangeArray.push({
		age:i+" anos",
		code:i
	}); 
}
const youngAges = [...youngAgesRangeArray];
const setYoungAge = (index,value) => {

	// Filter for exiting value. IF exist, replace
	var array = [...selectedYoungAge]; // clone
	var indexArray = array.filter(item => item.index != index);	
	
	var arrayObj = {
		index:index,
		value:value
	}
	var finalArray = [...indexArray,arrayObj];
		
	setSelectedYoungAge(finalArray);
}
var elderAgesRangeArray = [];
for (var i = 58; i <= 99; i++) {
	elderAgesRangeArray.push({
		age:i+" anos",
		code:i
	}); 
}
const elderAges = [...elderAgesRangeArray];
const setElderAge = (index,value) => {

	// Filter for exiting value. IF exist, replace
	var array = [...selectedElderAge]; // clone
	var indexArray = array.filter(item => item.index != index);	
	
	var arrayObj = {
		index:index,
		value:value
	}
	var finalArray = [...indexArray,arrayObj];
		
	setSelectedElderAge(finalArray);
}

/*
const checkAll = (e) => {
	setCheckedAll(e.value);
	setCheckedAirplane(false);
	setCheckedTrain(false);
	setCheckedBus(false);
}*/


// If there is no filter, go back to filtering page
if (!transportFilters.departureLocal) {  
	  return(null);
} // end if	 

const togglePanel = (e) => {
	
	// Clean last selectors
	if (lastObjectId.id) {
		
		var element = document.getElementById(lastObjectId.id);
		element.style.border = "0px";	
		
		let objString = lastObjectId.id;
		var tmp = objString.split("_");
		let lastElement = tmp[tmp.length - 1];
		
		var element = document.getElementById("resultBlock_"+lastElement+"");
		// element.style.border = "0px";
		element.style.filter = "unset";
		element.style.boxShadow = "unset";
	} 
	
	// set lastObject Id
	setLastObjectId(e.currentTarget);
			
	// Set current object bordes
	e.currentTarget.style.border = "1px solid purple";
	
	// Retrieve Price to update overall
	// ...
	
	// Get Data Attribute from selected button
	var buttonElement = e.currentTarget;
	var objToken = buttonElement.dataset.token;
	var objTariff = buttonElement.dataset.tariff;
	var objPrice = buttonElement.dataset.price;
	var objClass = buttonElement.dataset.class;
	var objTransportType = buttonElement.dataset.transporttype;
	
	// Set TransportType
	setTransportType(objTransportType);

	// Set Prices
	setSelectedTransportPrice(objPrice);
	
	// Set Class
	setSelectedTransportClass(objClass);	
	
	// Set result block id
	let objString = e.currentTarget.id;
	var tmp = objString.split("_");
	let lastElement = tmp[tmp.length - 1];
  	var element = document.getElementById("resultBlock_"+lastElement+"");
	//element.style.border = "1px solid purple";
	//element.style.filter = "drop-shadow(1px 1px 4px #9C9C9C)";	
	//element.style.boxShadow = "rgba(19, 41, 104, 0.2) 0px 2px 5px 0px;";
	element.style.boxShadow = "0px 2px 5px 0px rgba(19, 41, 104, 0.2)";

	
	// Set loading on
	setLoadingRightPanel(true);
	
	if (rightPanel) {
				
		/*setRightPanelBlock(false);
		
		setTimeout(function() {
			setRightPanel(false);
		},500);		*/
		
		setTimeout(function() {
			setLoadingRightPanel(false);
		},2000);			
		
	} // end if
	else {
		setRightPanel(true);
		
		setTimeout(function() {
			setRightPanelBlock(true);
			
			setTimeout(function() {
				setLoadingRightPanel(false);
			},2500);			
			
		},500);
		
	} // end else
		
}

const generateTotalLuggageLabel = () => {

	// Set total passengers
	let currentLugTotal = luggageAdd;

	if (luggageAdd == 1) {
		var label = "1 Bagagem ";	
	} // end if
	else if (luggageAdd > 1) {
		var label = ""+luggageAdd+" Bagagens ";	
	} // end else if
	else if (currentLugTotal == 0) {
		var label = "Nenhuma Bagagem";
	} // end else if	
	
	// Set Person Control Label
	setLuggageAddLabel(label);
		
}

/* Check if document is valid,. If yes, release button. If not, alert.*/
const handlePersonDocumentChange = (e) => {

	var currentValue = e.target.value;
	
	setPersonDocument(maskCPF(e.target.value));
	
	if (currentValue.length >= 14) {
		
		// check if cnpj is valid
		if (!validarCPF(currentValue)) {
			setAlert(true);
			setAlertTitle("Verifique o CPF");
			setAlertBody("O CPF informado é inválido. Verifique novamente.");	
			return;	
		} // end if	
		else {
			setPersonDocumentReady(true);
		} // end else
		
	} // end if
	else {
		setPersonDocumentReady(false);
		setAutoPersonFieldsFilled(false);
		setPersonName("");		
	} // end else
	
}  // end function


/* Check if document is valid,. If yes, release button. If not, alert.*/
const handlePersonDocumentForeignerChange = (e) => {

	var currentValue = e.target.value;
	
	setPersonDocument(e.target.value);
	
}  // end function



const retrievePersonDocumentInfo = () => {
	
	setPersonLoadingData(true);
	
	var props = {
		setSaving:setSaving,
		setAlert:setAlert,
		setAlertTitle:setAlertTitle,
		setAlertBody:setAlertBody,
		setAutoPersonFieldsFilled:setAutoPersonFieldsFilled,
		setPersonLoadingData:setPersonLoadingData,
		setPersonName:setPersonName,
		setBirthday:setBirthday
	}
	var data = {
	  personDocument: personDocument
	}
	retrievePersonDataByDocument(data,props);
	

} // end retrieveDocumentInfo


const toggleRoutePanel = () => {
	
	if (transportFilters.pickReturn) {
			setRouteStatus("generated"); // force generation 
	} // end if
	
	// Hide content if panel is opened
	if (routePanel) { setRoutePanelContent(false); }
	
	setRoutePanel(!routePanel);
	
	setTimeout(function() {
		setRoutePanelContent(!routePanel);
	}, 1500);
	
} // end function toggleRoutePanel


  return (
	<>
	<div id="loginSection" style={{marginTop:"0",alignItems:"flex-start"}} className={isTabletOrMobile ? "login__wrapper mobile" : "login__wrapper"} >
		<div style={{minHeight:"728px",height:"auto"}} className={isTabletOrMobile ? "login__wrapper_form_wrapper mobile" : "login__wrapper_form_wrapper"}> 		
			<div className={isTabletOrMobile ? "login__form_wrapper mobile" : "login__form_wrapper"} style={{alignItems:"flex-start"}}>
						
				<div style={{flex:1,margin:"2rem",marginTop:"50px",minHeight:"520px",marginLeft:"auto",marginRight:"auto"}}>
				
					<div style={{fontSize:"28px",textAlign:"center",letterSpacing:"0.1em",lineHeight:"120%",fontWeight:"400",fontStyle:"normal",fontFamily:"Titillium Web",color:"#2E0249",flex:"1"}}>Compra de transfer</div>
					
					<div style={{display:"flex",flexDirection:isTabletOrMobile ? "column" : "row",alignItems:"center",justifyContent:"center",gap:"15px",marginTop:"20px"}}> 
					
						<div style={{marginLeft:"auto",marginRight:"auto",display:"flex",flexDirection:isTabletOrMobile ? "column" : "column",alignItems:"center",justifyContent:"center",gap:"15px",width:"100%",paddingBottom:"20px"}}  className="selectBox">
					
							<div>
								<Steps model={stepsData} activeIndex={4} readOnly={false} />
							</div>
							
							<Divider type="dotted" style={{maxWidth:"1170px"}} />
							   
								<div className={loading ? "imagem-zoom serviceTransferSelector" : "imagem-zoom serviceTransferSelector animate"} style={{backgroundColor:"transparent", width:"100%",maxWidth:"1170px", minHeight:"400px",display:"flex"}}> 
					
								
									<div className="searchWrapper" style={{flex:1,width:"100%",height:"100%",margin:"10px",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
																			
										<Grid container spacing={2} style={{marginTop:"20px"}}>
										 
											<Grid className="leftPanel animate" item xs={12} sm={8} style={{display:"flex",flexDirection:"column",justifyContent:"flex-start",alignItems:"center"}}>
											 	
												 <Grid item xs={12} sm={12} style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",width:"100%",maxHeight:"70px",marginBottom:"25px"}}>
												
													<div style={{flex:1,display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"flex-start"}}>
													
														<div style={{marginBottom:"10px",color: "#2E0249", fontFamily: "Titillium Web", fontSize: "28px", fontStyle: "normal", fontWeight: 600, lineHeight: "120%", letterSpacing: "2.8px"}}>
																Reserva confirmada
														</div>
														<div style={{color: "rgba(34, 34, 34, 0.60)", textAlign: "left", fontFamily: "Titillium Web", fontSize: "18px", fontStyle: "normal", fontWeight: 400, lineHeight: "120%", letterSpacing: "1.8px"}}>										
																Sua reserva foi confirmada. Verifique os dados abaixo.
														</div>
														
													
													</div>
													
												</Grid>	

 

												<div style={{padding:"20px",paddingLeft:"0px",paddingBottom:"0",color:'#222',fontFamily:'Titillium Web',fontSize:'20px',fontStyle:'normal',fontWeight:600,lineHeight:'150%',letterSpacing:'2px',width:"100%",textAlign:"left",boxShadow:"unset"}}>Dados da reserva </div>
											
												<div id="mainInfo" style={{borderRadius:"10px",width:"100%",margin:"10px",backgroundColor:"#fff",minHeight:"125px",marginBottom:"10px",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",padding:"15px",cursor:"initial"}}>

													<Grid container spacing={1} style={{alignItems:"flex-start"}}>

														<div style={{width:"100%",display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center", gap:"35px",padding:"20px"}}>
														 <Grid item >
											  
																	<div style={{width:"100%",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",marginBottom:"15px"}}> 
																	
																		<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '16px',lineHeight: '120%',letterSpacing: '0.1em',color: '#814DE5',marginBottom:"10px"}}>Número de Reserva</div>
																		
																		<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '16px',lineHeight: '120%',letterSpacing: '0.1em', display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
																		
																			<div style={{flex:1}}>	
																				 <Message severity="warn" style={{textAlign:"left",marginBottom:"20px",fontSize:"16px"}} text="Aguardando pagamento" />		
																			</div>
																		
																		</div>

																	</div>
																	
														 </Grid>
														
														<Grid item >
														
																<div style={{width:"100%",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",marginBottom:"10px"}}> 
																
																	<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '16px',lineHeight: '120%',letterSpacing: '0.1em',color: '#814DE5',marginBottom:"10px"}}>Voucher da reserva</div>										
																
																	<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '16px',lineHeight: '120%',letterSpacing: '0.1em', display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
																	
																		<div style={{flex:1}}>	
																			 <Message severity="warn" style={{textAlign:"left",marginBottom:"20px",fontSize:"16px"}} text="Aguardando pagamento" />
																		</div>
																		
																	</div>

																</div>												
																	
														 </Grid>
														 </div>

														 <div style={{width:"100%",display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center", gap:"35px",padding:"20px",paddingTop:"0px"}}>
														 <Grid item  >
											  
																	<div style={{width:"100%",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",marginBottom:"10px"}}> 
																	
																		<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '16px',lineHeight: '120%',letterSpacing: '0.1em',color: '#814DE5',marginBottom:"10px"}}>Data da reserva</div>
																		
																		<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '16px',lineHeight: '120%',letterSpacing: '0.1em', display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
																		
																			<div style={{flex:1}}>	
																			{transportFilters ? new Date().toLocaleDateString('pt-BR', { weekday: 'short',  year: 'numeric', month: 'long', day: 'numeric' }) : "Carregando..."}
																			</div>
																		
																		</div>
																		

																	</div>
																	
														 </Grid>

														 <Grid item  >
											  
																	<div style={{width:"100%",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",marginBottom:"10px"}}> 
																	
																		<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '16px',lineHeight: '120%',letterSpacing: '0.1em',color: '#814DE5',marginBottom:"10px"}}>Código interno da reserva</div>
																		
																		<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '16px',lineHeight: '120%',letterSpacing: '0.1em', display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
																		
																			<div style={{flex:1}}>	
																				2024.0801.01
																			</div>
																		
																		</div>
																		

																	</div>
																	
														 </Grid>	

														 </div>														 
																												  
													</Grid>	
										
												
												</div>	

												<div style={{padding:"20px",paddingLeft:"0px",paddingBottom:"0",color:'#222',fontFamily:'Titillium Web',fontSize:'20px',fontStyle:'normal',fontWeight:600,lineHeight:'150%',letterSpacing:'2px',width:"100%",textAlign:"left",boxShadow:"unset"}}>Dados da viagem </div>
											
												<div id="mainInfo" style={{borderRadius:"10px",width:"100%",margin:"10px",backgroundColor:"#fff",minHeight:"125px",marginBottom:"10px",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",padding:"15px",cursor:"initial"}}>

													<Grid container spacing={1} style={{alignItems:"flex-start"}}>

														<div style={{width:"100%",display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"flex-start", gap:"35px",padding:"20px"}}>

														 <Grid item style={{width:"40%"}}>
											  
																	<div style={{width:"100%",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",marginBottom:"15px"}}> 
																	
																		<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '16px',lineHeight: '120%',letterSpacing: '0.1em',color: '#814DE5',marginBottom:"10px"}}>Local de Partida</div>
																		
																		<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '16px',lineHeight: '120%',letterSpacing: '0.1em', display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
																		
																			<div style={{flex:1}}>	
																				{transportFilters ? transportFilters.departureLocal.description : "Carregando..."}
																			</div>
																		
																		</div>

																	</div>
																	
														 </Grid>
														  
														 
														<Grid item  style={{textAlign:"left",alignSelf:"center"}}>
															<i className={transportFilters.pickReturn ? "pi pi-arrow-right-arrow-left" : "pi pi-arrow-right" } style={{ color: 'slateblue',fontSize:"30px",width:"100%",height:"auto"}}></i>
														</Grid>
														
														<Grid item style={{width:"40%"}}>
														
																<div style={{width:"100%",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",marginBottom:"10px"}}> 
																
																	<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '16px',lineHeight: '120%',letterSpacing: '0.1em',color: '#814DE5',marginBottom:"10px"}}>Local de Destino</div>										
																
																	<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '16px',lineHeight: '120%',letterSpacing: '0.1em', display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
																	
																		<div style={{flex:1}}>	
																				{transportFilters ? transportFilters.destinationLocal.description : "Carregando..."}
																		</div>
																		
																	</div>

																</div>												
																	
														 </Grid>
														 </div>
														 
														 <div style={{width:"100%",display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center", gap:"35px",padding:"20px",paddingTop:"0px"}}>
														 <Grid item  >
											  
																	<div style={{width:"100%",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",marginBottom:"10px"}}> 
																	
																		<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '16px',lineHeight: '120%',letterSpacing: '0.1em',color: '#814DE5',marginBottom:"10px"}}>Data e horário</div>
																		
																		<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '16px',lineHeight: '120%',letterSpacing: '0.1em', display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
																		
																			<div style={{flex:1}}>	
																			{transportFilters ? transportFilters.departureDate.toLocaleDateString('pt-BR', { weekday: 'short',  year: 'numeric', month: 'long', day: 'numeric' }) : "Carregando..."} às {transportFilters ? transportFilters.departureTime : "Carregando..."}
																			</div>
																		
																		</div>

																	</div>
																	
														 </Grid>	


														 <Grid item  style={{marginLeft:"25px"}}>
											  
																	{transportFilters.pickReturn &&
																	<div style={{width:"100%",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",marginBottom:"10px"}}> 
																	
																		<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '16px',lineHeight: '120%',letterSpacing: '0.1em',color: '#814DE5',marginBottom:"10px"}}>Data de retorno</div>
																		
																		<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '16px',lineHeight: '120%',letterSpacing: '0.1em', display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
																		
																			<div style={{flex:1}}>	
																				{transportFilters ? transportFilters.returnDate.toLocaleDateString('pt-BR', { weekday: 'short',  year: 'numeric', month: 'long', day: 'numeric' }) : "Carregando..."}
																			</div>
																		
																		</div>

																	</div>
																	}
																	
														 </Grid>
														 </div>
														  
													</Grid>	
										
												
												</div>


																																 
												{/* One way Trip */}
												<div style={{padding:"20px",paddingLeft:"0px",paddingBottom:"0",color:'#222',fontFamily:'Titillium Web',fontSize:'20px',fontStyle:'normal',fontWeight:600,lineHeight:'150%',letterSpacing:'2px',width:"100%",textAlign:"left",boxShadow:"unset"}}>Transfer selecionado <span style={{marginLeft:"40px"}}>Sedan <img src={titleSedanIcon} style={{marginLeft:"0px",width:"18px",height:"auto"}} className="noSelect" /></span></div>
											
												<div id="resultBlock_1" style={{borderRadius:"10px",width:"100%",margin:"10px",backgroundColor:"#fff",minHeight:"125px",marginBottom:"10px",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",padding:"15px",cursor:"initial"}}>
													
													<div style={{flex:1,display:"flex",flexDirection:"row",alignItems:"stretch",justifyContent:"flex-start",gap:"25px",padding:"15px"}}>
													
														<div style={{flex:1,display:"flex",flexDirection:"column",alignItems:"flex-start",justifyContent:"center",minWidth:"238px"}}>
															
															<div style={{color: "#222", fontFamily: "Titillium Web", fontSize: 16, fontStyle: "bold", fontWeight: 600, lineHeight: "120%", letterSpacing: "1.6px",textAlign:"left"}}>Toyota Prius <small style={{color:"silver",fontSize:"12px"}}>ou similar</small></div>															
															<div style={{flex:3,width:"100%",marginTop:"10px"}}><img src="https://www.booktaxibcn.com/images/typecar/economy.png" style={{width:"100%",height:"auto"}} /></div>
															
														</div>	
														
														<div style={{flex:3,display:"flex",flexDirection:"column",alignItems:"flex-start",justifyContent:"center",gap:"12px"}}>

															<div style={{flex:1,color: "#222", fontFamily: "Titillium Web", fontSize: 24, fontStyle: "normal", fontWeight: 600, lineHeight: "120%", letterSpacing: "2.4px"}}>Book Taxi Group</div>
															<div style={{flex:1}}>
																
																<Button style={{float:"left",margin:"5px",marginLeft:"0px",height:"27px",border:"1px solid #2E0249",borderRadius:"4px",color: "#2E0249", textAlign: "center", fontFamily: 'Titillium Web', fontSize: 13, fontStyle: "normal", fontWeight: "bold", lineHeight: "26px", letterSpacing: "0.46px"}} label=" Tempo máx de espera: 20min" size="small" outlined />				
																<Button style={{float:"left",margin:"5px",marginLeft:"0px",height:"27px",border:"1px solid #2E0249",borderRadius:"4px",color: "#2E0249", textAlign: "center", fontFamily: 'Titillium Web', fontSize: 13, fontStyle: "normal", fontWeight: "bold", lineHeight: "26px", letterSpacing: "0.46px"}} label="Classificação 4.3" size="small" severity="secondary" outlined />				
																	

															</div>

															<div style={{flex:1,marginTop:"7px",marginBottom:"7px"}}>

																<div style={{flex:1,display:"flex",flexDirection:"column",alignItems:"flex-start",justifyContent:"flex-start",gap:"10px"}}>
																		<div style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center",gap:"4px"}}>
																			<div><i className="pi pi-users" style={{ width:"24px",height:"24px",fontSize:"20px",color:'#814DE5'}}></i></div>
																			<div style={{marginRight:"10px",fontFamily:'Titillium Web',fontStyle:'normal',fontWeight:700,fontSize:'16px',lineHeight:'120%',textAlign:'center',letterSpacing:'1.6px',color:'#222'}}>{personAddLabel}</div>
																		</div>																		
																		<div style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center",gap:"4px"}}>
																			<div><i className="pi pi-briefcase" style={{ fwidth:"24px",height:"24px",fontSize:"20px",color:'#814DE5'}}></i></div>
																			<div style={{marginRight:"10px",fontFamily:'Titillium Web',fontStyle:'normal',fontWeight:700,fontSize:'16px',lineHeight:'120%',textAlign:'center',letterSpacing:'1.6px',color:'#222'}}>{luggageAddLabel}</div>
																		</div>
																</div>

															</div>
															<div style={{flex:1}}>
																	

																<div style={{flex:1,display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"flex-start",gap:"15px",flexWrap:"wrap"}}>
																			<div style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center",gap:"4px"}}>
																				<div><svg width="18" height="auto" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" className="iconify iconify--emojione-monotone" preserveAspectRatio="xMidYMid meet" fill="" stroke="">
																				<g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
																				<g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M38.478 42.632c-4.644-1.466-3.322-2.633 1.11-4.298c2.123-.799.832-2.484.89-3.832c.026-.617 2.452-.494 2.276-2.874c-.124-1.676-3.816-4.064-4.822-5.083c-.581-.588 1.184-2.197-.059-3.612c-1.697-1.934-1.965-5.299-2.992-7.181c0 0 .783-1.196.183-1.876c-5.176-5.859-24.491-5.321-29.427 3.302c-5.541 9.68-5.615 23.059 5.906 30.267C16.667 50.65 10.104 62 10.104 62h20.319c0-1.938-2.266-8.89 1.7-8.578c3.446.271 7.666.122 7.292-3.77c-.113-1.174-.246-2.231.574-3.204c.82-.972 2.007-2.706-1.511-3.816" fill="#2E0249"></path><path d="M43.129 40.805L62 43.277v-4.943z" fill="#2E0249"></path><path d="M58.46 57.081l2.024-4.281l-17.355-9.368z" fill="#2E0249"></path><path d="M60.484 28.766l-2.024-4.282l-15.331 13.651z" fill="#2E0249"></path></g></svg></div>
																				<div style={{marginLeft:"5px",color: "rgba(34, 34, 34, 0.60)", fontFamily: "Titillium Web", fontSize: 14, fontStyle: "normal", fontWeight: 400, lineHeight: "120%", letterSpacing: "1.4px",textAlign:"left"}}>English speaking driver</div>
																			</div>																		

																			<div style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center",gap:"4px"}}>
																				<div><svg width="18" height="auto" fill="#2E0249" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M7.493,22.862a1,1,0,0,0,1.244-.186l11-12A1,1,0,0,0,19,9H13.133l.859-6.876a1,1,0,0,0-1.8-.712l-8,11A1,1,0,0,0,5,14H9.612l-2.56,7.684A1,1,0,0,0,7.493,22.862ZM6.964,12l4.562-6.273-.518,4.149A1,1,0,0,0,12,11h4.727l-6.295,6.867,1.516-4.551A1,1,0,0,0,11,12Z"></path></g></svg></div>
																				<div style={{marginLeft:"5px",color: "rgba(34, 34, 34, 0.60)", fontFamily: "Titillium Web", fontSize: 14, fontStyle: "normal", fontWeight: 400, lineHeight: "120%", letterSpacing: "1.4px",textAlign:"left"}}>In-seat power</div>
																			</div>

																			<div style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center",gap:"4px"}}>
																				<div><svg xmlns="http://www.w3.org/2000/svg" width="14" height="auto" viewBox="0 0 30 32" fill="none">
																				<path d="M16.026 0.547852V4.57785L20.052 2.25285L21.052 3.98585L16.026 6.88685V13.8149L22.026 10.3509V4.54785H24.026V9.19585L27.517 7.18185L28.517 8.91385L25.026 10.9289L29.052 13.2529L28.052 14.9859L23.026 12.0829L17.026 15.5469L23.027 19.0119L28.052 16.1099L29.052 17.8419L25.026 20.1659L28.517 22.1819L27.517 23.9139L24.026 21.8989V26.5479H22.026V20.7439L16.026 17.2789V24.2079L21.052 27.1099L20.052 28.8419L16.026 26.5168V30.5479H14.026V26.5168L10 28.8419L9.00004 27.1099L14.026 24.2079V17.2789L8.02604 20.7429V26.5479H6.02604V21.8979L2.536 23.9139L1.536 22.1819L5.02504 20.1659L1 17.8419L2 16.1099L7.02504 19.0109L13.025 15.5469L7.02604 12.0829L2 14.9859L1 13.2529L5.02604 10.9289L1.536 8.91385L2.536 7.18185L6.02604 9.19585V4.54785H8.02604V10.3509L14.026 13.8149V6.88685L9.00004 3.98585L10 2.25285L14.026 4.57785V0.547852H16.026Z" fill="#2E0249" stroke="black"/>
																				</svg></div>
																				<div style={{marginLeft:"5px",color: "rgba(34, 34, 34, 0.60)", fontFamily: "Titillium Web", fontSize: 14, fontStyle: "normal", fontWeight: 400, lineHeight: "120%", letterSpacing: "1.4px",textAlign:"left"}}>Air-conditioning</div>
																			</div>	

																			<div style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center",gap:"4px"}}>
																				<div><svg width="18" height="auto" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																				<g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
																				<g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
																				<g id="SVGRepo_iconCarrier">
																					<path d="M10 13.0002C10.5523 13.0002 11 12.5525 11 12.0002C11 11.448 10.5523 11.0002 10 11.0002C9.44771 11.0002 9 11.448 9 12.0002C9 12.5525 9.44771 13.0002 10 13.0002Z" fill="#2E0249"></path>
																					<path d="M15 12.0002C15 12.5525 14.5523 13.0002 14 13.0002C13.4477 13.0002 13 12.5525 13 12.0002C13 11.448 13.4477 11.0002 14 11.0002C14.5523 11.0002 15 11.448 15 12.0002Z" fill="#2E0249"></path>
																					<path d="M8.29289 15.2931C8.68006 14.906 9.30573 14.9026 9.69699 15.2832C9.8449 15.415 10.021 15.5177 10.1972 15.6058C10.5749 15.7947 11.1728 16.0002 12 16.0002C12.8272 16.0002 13.4251 15.7947 13.8028 15.6058C13.979 15.5177 14.1551 15.415 14.303 15.2832C14.6943 14.9026 15.3199 14.906 15.7071 15.2931C16.0976 15.6837 16.0976 16.3168 15.7071 16.7074C15.6303 16.784 15.5522 16.8517 15.4125 16.9565C15.245 17.0821 15.0067 17.2399 14.6972 17.3947C14.0749 17.7058 13.1728 18.0002 12 18.0002C10.8272 18.0002 9.92507 17.7058 9.30278 17.3947C8.99327 17.2399 8.75496 17.0821 8.58749 16.9565C8.44826 16.8521 8.37026 16.7843 8.29359 16.7081C7.90307 16.3175 7.90236 15.6837 8.29289 15.2931Z" fill="#2E0249"></path>
																					<path fillRule="evenodd" clipRule="evenodd" d="M13.1296 2.80381C13.295 3.09712 13.4047 3.51797 13.1972 4.07117C12.8047 4.02434 12.4051 4.00024 12 4.00024C7.59784 4.00024 3.86005 6.84476 2.52438 10.796C1.07592 11.1461 0 12.4508 0 14.0068C0 15.5645 1.0783 16.8703 2.52918 17.2186C3.86899 21.1625 7.6031 24.0002 12 24.0002C16.3969 24.0002 20.131 21.1625 21.4708 17.2186C22.9217 16.8703 24 15.5645 24 14.0068C24 12.4508 22.9241 11.1461 21.4756 10.796C20.4747 7.83506 18.1249 5.4956 15.1574 4.50892C15.4491 3.53208 15.3113 2.60099 14.8718 1.82153C14.2627 0.741235 13.079 0.000244141 12 0.000244141C11.0671 0.000244141 10.3617 0.35319 9.89902 0.700448C9.66924 0.872899 9.49579 1.04612 9.37674 1.18029C9.31687 1.24776 9.2697 1.30655 9.23506 1.35211C8.88381 1.81421 8.94196 2.49769 9.44638 2.83302C9.9818 3.18895 10.4984 2.92957 10.8727 2.5077C10.92 2.45437 10.9962 2.37759 11.0996 2.30004C11.3031 2.1473 11.5976 2.00024 12 2.00024C12.2562 2.00024 12.8225 2.25915 13.1296 2.80381ZM19.9318 15.5313L21.004 15.2739C21.5763 15.1365 22 14.6186 22 14.0068C22 13.3956 21.5773 12.8782 21.0058 12.7401L19.934 12.4811L19.5809 11.4365C18.5116 8.27312 15.5186 6.00024 12 6.00024C8.48136 6.00024 5.48839 8.27312 4.41906 11.4365L4.06595 12.4811L2.99417 12.7401C2.42271 12.8782 2 13.3956 2 14.0068C2 14.6186 2.42366 15.1365 2.99604 15.2739L4.06821 15.5313L4.42289 16.5753C5.49555 19.7328 8.48558 22.0002 12 22.0002C15.5144 22.0002 18.5044 19.7328 19.5771 16.5753L19.9318 15.5313Z" fill="#2E0249"></path>
																				</g>
																				</svg>
																				</div>
																				<div style={{marginLeft:"5px",color: "rgba(34, 34, 34, 0.60)", fontFamily: "Titillium Web", fontSize: 14, fontStyle: "normal", fontWeight: 400, lineHeight: "120%", letterSpacing: "1.4px",textAlign:"left"}}>Child booster (5+ years) (Optional)</div>
																			</div>	

																			<div style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center",gap:"4px"}}>
																				<div><svg width="18" height="auto" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																				<g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
																				<g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
																				<g id="SVGRepo_iconCarrier">
																					<path d="M10 13.0002C10.5523 13.0002 11 12.5525 11 12.0002C11 11.448 10.5523 11.0002 10 11.0002C9.44771 11.0002 9 11.448 9 12.0002C9 12.5525 9.44771 13.0002 10 13.0002Z" fill="#2E0249"></path>
																					<path d="M15 12.0002C15 12.5525 14.5523 13.0002 14 13.0002C13.4477 13.0002 13 12.5525 13 12.0002C13 11.448 13.4477 11.0002 14 11.0002C14.5523 11.0002 15 11.448 15 12.0002Z" fill="#2E0249"></path>
																					<path d="M8.29289 15.2931C8.68006 14.906 9.30573 14.9026 9.69699 15.2832C9.8449 15.415 10.021 15.5177 10.1972 15.6058C10.5749 15.7947 11.1728 16.0002 12 16.0002C12.8272 16.0002 13.4251 15.7947 13.8028 15.6058C13.979 15.5177 14.1551 15.415 14.303 15.2832C14.6943 14.9026 15.3199 14.906 15.7071 15.2931C16.0976 15.6837 16.0976 16.3168 15.7071 16.7074C15.6303 16.784 15.5522 16.8517 15.4125 16.9565C15.245 17.0821 15.0067 17.2399 14.6972 17.3947C14.0749 17.7058 13.1728 18.0002 12 18.0002C10.8272 18.0002 9.92507 17.7058 9.30278 17.3947C8.99327 17.2399 8.75496 17.0821 8.58749 16.9565C8.44826 16.8521 8.37026 16.7843 8.29359 16.7081C7.90307 16.3175 7.90236 15.6837 8.29289 15.2931Z" fill="#2E0249"></path>
																					<path fillRule="evenodd" clipRule="evenodd" d="M13.1296 2.80381C13.295 3.09712 13.4047 3.51797 13.1972 4.07117C12.8047 4.02434 12.4051 4.00024 12 4.00024C7.59784 4.00024 3.86005 6.84476 2.52438 10.796C1.07592 11.1461 0 12.4508 0 14.0068C0 15.5645 1.0783 16.8703 2.52918 17.2186C3.86899 21.1625 7.6031 24.0002 12 24.0002C16.3969 24.0002 20.131 21.1625 21.4708 17.2186C22.9217 16.8703 24 15.5645 24 14.0068C24 12.4508 22.9241 11.1461 21.4756 10.796C20.4747 7.83506 18.1249 5.4956 15.1574 4.50892C15.4491 3.53208 15.3113 2.60099 14.8718 1.82153C14.2627 0.741235 13.079 0.000244141 12 0.000244141C11.0671 0.000244141 10.3617 0.35319 9.89902 0.700448C9.66924 0.872899 9.49579 1.04612 9.37674 1.18029C9.31687 1.24776 9.2697 1.30655 9.23506 1.35211C8.88381 1.81421 8.94196 2.49769 9.44638 2.83302C9.9818 3.18895 10.4984 2.92957 10.8727 2.5077C10.92 2.45437 10.9962 2.37759 11.0996 2.30004C11.3031 2.1473 11.5976 2.00024 12 2.00024C12.2562 2.00024 12.8225 2.25915 13.1296 2.80381ZM19.9318 15.5313L21.004 15.2739C21.5763 15.1365 22 14.6186 22 14.0068C22 13.3956 21.5773 12.8782 21.0058 12.7401L19.934 12.4811L19.5809 11.4365C18.5116 8.27312 15.5186 6.00024 12 6.00024C8.48136 6.00024 5.48839 8.27312 4.41906 11.4365L4.06595 12.4811L2.99417 12.7401C2.42271 12.8782 2 13.3956 2 14.0068C2 14.6186 2.42366 15.1365 2.99604 15.2739L4.06821 15.5313L4.42289 16.5753C5.49555 19.7328 8.48558 22.0002 12 22.0002C15.5144 22.0002 18.5044 19.7328 19.5771 16.5753L19.9318 15.5313Z" fill="#2E0249"></path>
																				</g>
																				</svg>
																				</div>
																				<div style={{marginLeft:"5px",color: "rgba(34, 34, 34, 0.60)", fontFamily: "Titillium Web", fontSize: 14, fontStyle: "normal", fontWeight: 400, lineHeight: "120%", letterSpacing: "1.4px",textAlign:"left"}}>Child booster (1-5 years) (Optional)</div>
																			</div>																																

																</div>																
																
																
															</div>						
															
														</div>	

														<div style={{display:"flex",flexDirection:"column",alignItems:"end",justifyContent:"flex-start"}}>
														
															<div style={{flex:1,display:"flex",flexDirection:"column",alignItems:"end",justifyContent:"flex-start",gap:"15px"}}>
																	<div  style={{color: "#814DE5", textAlign: "right", fontFamily: "Titillium Web", fontSize: 28, fontStyle: "normal", fontWeight: 700, lineHeight: "120%", letterSpacing: "2.8px"}}>65,00 &euro;
																	</div>																		
																	<div style={{color: "#222", textAlign: "right", fontFamily: "Titillium Web", fontSize: 16, fontStyle: "normal", fontWeight: 600, lineHeight: "120%", letterSpacing: "1.8px"}}>Tempo viagem: 25min</div>
															</div>

															
														</div>																												
														
													</div>
												
												</div>



												{/* Seat Reservation One way */}
												<div style={{padding:"20px",paddingLeft:"0px",paddingBottom:"0",color:'#222',fontFamily:'Titillium Web',fontSize:'20px',fontStyle:'normal',fontWeight:600,lineHeight:'150%',letterSpacing:'2px',width:"100%",textAlign:"left",boxShadow:"unset"}}>Opcionais selecionados</div>
											
												<div style={{borderRadius:"10px",width:"100%",margin:"10px",backgroundColor:"#fff",minHeight:"125px",marginBottom:"10px",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",padding:"15px",cursor:"initial"}}>
													
												<div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"flex-start",gap:"10px",marginBottom:"10px",fontSize:"14px",textAlign:"left"}}>

														<div style={{color:"#2E0249",fontFamily:"Titillium Web",fontSize:"14px",fontStyle:"normal",fontWeight:400,lineHeight:"120%",letterSpacing:"2.4px",marginBottom:"10px",fontWeight:"regular"}}>Se desejar alterar retorne para a etapa anterior.</div>
															
														<div style={{padding:"10px",paddingBottom:"0",color:'#222',fontFamily:'Titillium Web',fontSize:'14px',fontStyle:'normal',fontWeight:400,lineHeight:'150%',letterSpacing:'2px',display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",gap:"10px"}}>
															<div style={{flex:1}}>Child booster (1-5 years) (Optional)</div>
															<div style={{display:"flex",flexDirection:"row",justifyContent:"flex-end",alignItems:"center",marginLeft:"auto",marginRight:"auto"}}><div style={{fontFamily:'Titillium Web',fontStyle:'bold',fontWeight:400,fontSize:'14px',lineHeight:'120%',textAlign:'right',letterSpacing:'0.1em',color:'#814DE5',padding:0,height:"46px",display:"flex",justifyContent:"center",alignItems:"center",marginRight:"10px"}}>+13,90 &euro;</div><div><InputSwitch checked={checkedOption1} onChange={(e) => {setCheckedOption1(e.value)}} style={{display:"flex",justifyContent:"center",alignItems:"center"}} disabled={true} /></div></div>
														</div>

														<div style={{padding:"10px",paddingBottom:"0",color:'#222',fontFamily:'Titillium Web',fontSize:'14px',fontStyle:'normal',fontWeight:400,lineHeight:'150%',letterSpacing:'2px',display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",gap:"10px"}}>
															<div style={{flex:1}}>Child booster (5+ years) (Optional)</div>
															<div style={{display:"flex",flexDirection:"row",justifyContent:"flex-end",alignItems:"center",marginLeft:"auto",marginRight:"auto"}}><div style={{fontFamily:'Titillium Web',fontStyle:'bold',fontWeight:400,fontSize:'14px',lineHeight:'120%',textAlign:'right',letterSpacing:'0.1em',color:'#814DE5',padding:0,height:"46px",display:"flex",justifyContent:"center",alignItems:"center",marginRight:"10px"}}>+13,90 &euro;</div><div><InputSwitch checked={true} onChange={(e) => {setCheckedOption2(e.value)}} style={{display:"flex",justifyContent:"center",alignItems:"center"}} disabled={true} /></div></div>
														</div>	

														<div style={{padding:"10px",paddingBottom:"0",color:'#222',fontFamily:'Titillium Web',fontSize:'14px',fontStyle:'normal',fontWeight:400,lineHeight:'150%',letterSpacing:'2px',display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",gap:"10px"}}>
															<div style={{flex:1}}>Meet & Greet (Obrigatório)</div>
															<div style={{display:"flex",flexDirection:"row",justifyContent:"flex-end",alignItems:"center",marginLeft:"auto",marginRight:"auto"}}><div style={{fontFamily:'Titillium Web',fontStyle:'bold',fontWeight:400,fontSize:'14px',lineHeight:'120%',textAlign:'right',letterSpacing:'0.1em',color:'#814DE5',padding:0,height:"46px",display:"flex",justifyContent:"center",alignItems:"center",marginRight:"10px"}}>0,00 &euro;</div><div><InputSwitch checked={true} disabled={true} onChange={(e) => {setCheckedOption3(e.value)}} style={{display:"flex",justifyContent:"center",alignItems:"center"}} /></div></div>
														</div>																																										
														{transportFilters.departureLocal.types.includes("airport") &&
														<div style={{padding:"10px",paddingBottom:"0",color:'#222',fontFamily:'Titillium Web',fontSize:'14px',fontStyle:'normal',fontWeight:400,lineHeight:'150%',letterSpacing:'2px',display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",gap:"10px"}}>
															<div style={{flex:1}}><strong>Reserva Garantida</strong> Voo atrasado? Esperamos 70 minutos a partir do horário solicitado! Por favor, entre em contato imediatamente se você perder uma conexão e precisar ser colocado em um voo diferente daquele que você forneceu.</div>
															<div style={{flex:1,display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center",marginLeft:"auto",marginRight:"auto"}}><div style={{fontFamily:'Titillium Web',fontStyle:'bold',fontWeight:400,fontSize:'14px',lineHeight:'120%',textAlign:'right',letterSpacing:'0.1em',color:'#814DE5',padding:0,height:"46px",display:"flex",justifyContent:"center",alignItems:"center",marginRight:"10px",color:"green"}}>Incluso</div><div><InputSwitch checked={true} disabled={true} onChange={(e) => {setCheckedOption3(e.value)}} style={{display:"flex",justifyContent:"center",alignItems:"center"}} /></div></div>
														</div>
														}																														

														</div>														
											
												
												</div>													
												

												{/* Aditional info */}
												<div style={{padding:"20px",paddingLeft:"0px",paddingBottom:"0",color:'#222',fontFamily:'Titillium Web',fontSize:'20px',fontStyle:'normal',fontWeight:600,lineHeight:'150%',letterSpacing:'2px',width:"100%",textAlign:"left",boxShadow:"unset"}}>Informações adicionais</div>
											
												<div style={{borderRadius:"10px",width:"100%",margin:"10px",backgroundColor:"#fff",minHeight:"125px",marginBottom:"10px",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",padding:"15px",cursor:"initial",gap:"10px"}}>
													
													{transportFilters.departureLocal.types.includes("airport") &&
													<div style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"flex-start",gap:"10px",marginBottom:"10px",fontSize:"14px",textAlign:"left"}}>

														<div style={{flex:1,padding:"0px",paddingBottom:"0px",color:'#222',fontFamily:'Titillium Web',fontSize:'16px',fontStyle:'bold',fontWeight:600,lineHeight:'150%',letterSpacing:'2px',textAlign:"left"}}>Número do Vôo</div>	
																
														<div style={{flex:3,padding:"0px",paddingTop:"0",marginTop:"0px",width:"100%",color:"#2E0249",fontFamily:"Titillium Web",fontSize:"16px",fontStyle:"normal",fontWeight:400}}>GO 1234</div>
														
													</div>
													}	

													<div style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"flex-start",gap:"10px",marginBottom:"10px",fontSize:"14px",textAlign:"left"}}>

														<div style={{flex:1,padding:"0px",paddingBottom:"0px",color:'#222',fontFamily:'Titillium Web',fontSize:'16px',fontStyle:'bold',fontWeight:600,lineHeight:'150%',letterSpacing:'2px',textAlign:"left"}}>Instruções especiais</div>	
																
														<div style={{flex:3,padding:"0px",paddingTop:"0",marginTop:"0px",width:"100%",color:"#2E0249",fontFamily:"Titillium Web",fontSize:"16px",fontStyle:"normal",fontWeight:400}}>Bloco 2 Apto 2002. Interfonar na portaria e pedir para entrar.</div>
														
													</div>														

												</div>	
													

												{/* Total Summary */}
												<div style={{padding:"20px",paddingLeft:"0px",paddingBottom:"0",color:'#222',fontFamily:'Titillium Web',fontSize:'20px',fontStyle:'normal',fontWeight:600,lineHeight:'150%',letterSpacing:'2px',width:"100%",textAlign:"left",boxShadow:"unset"}}>Resumo dos Valores</div>
											
												<div style={{borderRadius:"10px",width:"100%",margin:"10px",backgroundColor:"#fff",minHeight:"125px",marginBottom:"10px",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",padding:"15px",cursor:"initial",textAlign:"left"}}>


												<div style={{padding:"20px",paddingBottom:"0px",color:'#222',fontFamily:'Titillium Web',fontSize:'16px',fontStyle:'bold',fontWeight:600,lineHeight:'150%',letterSpacing:'2px'}}>Ida</div>	
														
														<div style={{padding:"10px",paddingTop:"0",marginTop:"5px",width:"100%"}}>
														
																<div style={{color:"#2E0249",fontFamily:"Titillium Web",fontSize:"16px",fontStyle:"normal",fontWeight:400,width:"100%",display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column",textAlign:"left"}}>

																		<div style={{width:"100%",padding:"0px",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}> 
																			<div style={{width:"100%",padding:"10px",paddingLeft:"20px",paddingBottom:"0px",display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"stretch",gap:"20px"}}> 
																				<div style={{width:"50%"}}>Transfer</div>
																				<div> 53,00 &euro; </div>
																			</div>
																			<div style={{width:"100%",padding:"10px",paddingTop:"0px",paddingBottom:"0px",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"flex-start",paddingLeft:"40px",fontSize:"14px"}}> 
																			{personAddAdult > 0 &&
																			<div style={{display:"block",marginBottom:"5px"}}>{personAddAdult} x passageiros</div>
																			}
																			
																			{luggageAdd > 0 &&
																			<div style={{display:"block",marginBottom:"5px"}}>{luggageAdd} x bagagens</div>
																			}
																			</div>
																		</div>
																		<div style={{width:"100%",paddingLeft:"0px",paddingRight:"0px",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}> 
																			<div style={{width:"100%",padding:"10px",paddingLeft:"20px",paddingBottom:"0px",display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"stretch",gap:"20px"}}>  
																				<div style={{width:"50%"}}>Child booster (5+ years) (Optional)</div>
																				<div> 13,90 &euro; </div>
																			</div>
																		</div>
																		<div style={{width:"100%",paddingLeft:"0px",paddingRight:"0px",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}> 
																			<div style={{width:"100%",padding:"10px",paddingLeft:"20px",paddingBottom:"20px",display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"stretch",gap:"20px",fontSize:"18px"}}> 
																				<div style={{width:"50%"}}>
																				<strong>Total</strong>
																				<small> (taxas incluídas)</small></div>
																				<div> <strong>65,90 &euro;</strong> </div>
																			</div>
																		</div>
																																	
																	</div>														
																											
														
														</div>
										
												
												</div>												
																								
											 </Grid>
											 
											 
											 <Grid item xs={12} sm={4} className="rightPanel animate">
											 
												 <div style={{position:"sticky",zIndex:"9999",top:"120px"}}>
												 
												{loadingRightPanel &&
												<>
													<div style={{backgroundColor:"transparent", width:"100%",maxWidth:"1170px", marginTop:"30px",marginBottom:"50px",display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"row"}}>
													
															<ProgressSpinner style={{width: '40px', height: '40px',marginRight: "10px",marginLeft: "10px",marginTop:"10px"}} strokeWidth="4" />
															<div style={{fontSize:"16px",marginTop:"10px"}}> Carregando... </div>
																											
													</div>
													
													<Skeleton width="100%" height="8rem" ></Skeleton>
													<br />
													<Skeleton width="100%" height="8rem" ></Skeleton>
													<br />
													<Skeleton width="100%" height="8rem" ></Skeleton>
													
												</>
												}												 
												 
												 
												{!loadingRightPanel &&
												<>		
												
													{/* Continue Button Replication */}
													{!currentAgency.agencyCode &&
													<>
													<div style={{marginTop:"20px",height:"110px",minHeight:"110px",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"stretch"}}>
														
														<div style={{backgroundColor:"#2E0249",flex:"1.5",borderRadius:"6px 0px 0px 6px",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",paddingLeft:"21px"}}>
														
														<div style={{textAlign:"left",marginTop:"5px",marginBottom:"5px",color:'rgba(255, 255, 255, 0.80)',fontFamily:'Titillium Web',fontSize:'14px',fontStyle:'normal',fontWeight:400,lineHeight:'120%',letterSpacing:'1.4px'}}>Ida</div>
															<div style={{textAlign:"left",marginBottom:"5px",color:'#FFF',fontFamily:'Titillium Web',fontSize:'24px',fontStyle:'normal',fontWeight:700,lineHeight:'120%',letterSpacing:'2.4px'}}>{selectedTransportPrice}
																															
																<Button icon="pi pi-search-plus" rounded text severity="secondary" aria-label="Search" style={{width:"1.7rem",height:"1.7rem",margin:"0",padding:"0",marginLeft:"5px"}} onClick={!menuPriceOpened ? (e) => {opPrice.current.toggle(e);e.currentTarget.blur()} : (e) => {opPrice.current.toggle(e);e.currentTarget.blur()} } />
																
																<OverlayPanel className="overlayPanel" onHide={onHidePrice} onShow={onShowPrice} ref={opPrice} style={{padding:"0 !important",backgroundColor:"#FFFFFF",overflow:"auto",zIndex:"9",display:"flex",justifyContent:"flex-start",alignItems:"center",width:"350px"}}>
																
																	<div style={{color:"#2E0249",fontFamily:"Titillium Web",fontSize:"16px",fontStyle:"normal",fontWeight:400,width:"100%",display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column"}}>

																		<div style={{width:"100%",padding:"0px",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}> 
																			<div style={{width:"100%",padding:"10px",display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"stretch",gap:"20px"}}> 
																				<div style={{width:"70%"}}>Transfer ({personAddLabel})</div>
																				<div> 65,00 &euro; </div>
																			</div>
																			<div style={{width:"100%",padding:"10px",paddingTop:"0px",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"flex-start",paddingLeft:"40px",fontSize:"14px"}}> 
																			{personAddAdult > 0 &&
																			<div style={{display:"block",marginBottom:"5px"}}>{personAddAdult} x passageiros</div>
																			}
																			
																			{luggageAdd > 0 &&
																			<div style={{display:"block",marginBottom:"5px"}}>{luggageAdd} x bagagens</div>
																			}

																			</div>
																		</div>
																		<div style={{width:"100%",paddingLeft:"0px",paddingRight:"0px",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}> 
																			<div style={{width:"100%",padding:"10px",display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"stretch",gap:"20px"}}>  
																				<div style={{width:"70%"}}>Opcionais: Child booster (5+ years) (Optional)</div>
																				<div> 13,90 &euro; </div>
																			</div>
																		</div>
																		<div style={{width:"100%",paddingLeft:"0px",paddingRight:"0px",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}> 
																			<div style={{width:"100%",padding:"10px",display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"stretch",gap:"20px"}}> 
																				<div style={{width:"70%"}}><strong>Total</strong> <small>(taxas incluídas)</small></div>
																				<div> <strong>78,90 &euro;</strong> </div>
																			</div>
																		</div>
																																	
																	</div>
																</OverlayPanel>
															</div>
															<div style={{textAlign:"left",marginBottom:"5px",color:'rgba(255, 255, 255, 0.80)',fontFamily:'Titillium Web',fontSize:'14px',fontStyle:'normal',fontWeight:400,lineHeight:'120%',letterSpacing:'1.4px'}}>{personAddLabel} | {luggageAddLabel}</div>	
															
														</div>

														<div style={{backgroundColor:"#814DE5",flex:"1",borderRadius:"0px 6px 6px 0px",display:"flex",justifyContent:"center",alignItems:"center"}}>
														
														{!transportFilters.pickReturn &&
														<>
															<Button  className="buttonLeftPanel"  label="Efetuar" style={{flexDirection:"column",fontFamily:"Titillium Web",fontSize:"24px",fontStyle:"bold",fontWeight:800,lineHeight:"120%",letterSpacing:"2.4px",height:"100%",justifyContent:"center"}} onClick={()=>navigate("/order/transfer/reservation/payment")}><div style={{display:"block",color:'#FFF',textAlign:'center',fontFamily:'Titillium Web',fontSize:'14px',fontStyle:'normal',fontWeight:400,lineHeight:'120%',letterSpacing:'1.4px'}}>o pagamento agora</div></Button>
														</>
														}
														
														{transportFilters.pickReturn &&
														<>
															<Button  className="buttonLeftPanel"  label="Efetuar" style={{flexDirection:"column",fontFamily:"Titillium Web",fontSize:"24px",fontStyle:"bold",fontWeight:800,lineHeight:"120%",letterSpacing:"2.4px",height:"100%",justifyContent:"center"}} onClick={()=>navigate("/order/transport/reservation/route/payment")}><div style={{display:"block",color:'#FFF',textAlign:'center',fontFamily:'Titillium Web',fontSize:'14px',fontStyle:'normal',fontWeight:400,lineHeight:'120%',letterSpacing:'1.4px'}}>o pagamento agora</div></Button>
														</>
														}

														</div>
														
													</div>
													</>
													}	

													{currentAgency.agencyCode &&
													<>
													<div style={{marginTop:"20px",height:"110px",minHeight:"110px",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"stretch"}}>
														
														<div style={{backgroundColor:"#2E0249",flex:"1",borderRadius:"6px 0px 0px 6px",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",paddingLeft:"21px"}}>
														
															<div style={{textAlign:"left",marginTop:"5px",marginBottom:"5px",color:'rgba(255, 255, 255, 0.80)',fontFamily:'Titillium Web',fontSize:'14px',fontStyle:'normal',fontWeight:400,lineHeight:'120%',letterSpacing:'1.4px'}}>
															
																{transportFilters.pickReturn &&
																	<>Ida e Volta</>
																}

																{!transportFilters.pickReturn &&
																	<>Ida</>
																}															
															
															</div>
															<div style={{textAlign:"left",marginBottom:"5px",color:'#FFF',fontFamily:'Titillium Web',fontSize:'24px',fontStyle:'normal',fontWeight:700,lineHeight:'120%',letterSpacing:'2.4px'}}>{selectedTransportPrice}
															</div>
															<div style={{textAlign:"left",marginBottom:"5px",color:'rgba(255, 255, 255, 0.80)',fontFamily:'Titillium Web',fontSize:'14px',fontStyle:'normal',fontWeight:400,lineHeight:'120%',letterSpacing:'1.4px'}}>{personAddLabel}</div>
															
														</div>

														<div style={{backgroundColor:"#814DE5",flex:"1",borderRadius:"0px 6px 6px 0px",display:"flex",justifyContent:"center",alignItems:"center",padding:"5px",fontSize:"14px",color:"#FFF"}}>
														
														O pagamento deverá ser feito pela agência através do painel de controle. 

														</div>
														
													</div>
													</>
													}													
												
													{/* Continue Button Replication */}
													<div style={{marginTop:"20px",height:"110px",minHeight:"110px",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"stretch"}}>
																												
														<div style={{backgroundColor:"transparent",flex:"1",borderRadius:"6px 6px 6px 6px",display:"flex",border:"2px solid #2E0249",justifyContent:"center",alignItems:"center"}}>
														
															<Button  className="buttonLeftPanel" label={transportFilters.pickReturn ? "Abrir Roteiro" : "Iniciar um Roteiro"} icon={(options) => <img src={roteiroIcon} style={{width:"24px",height:"auto"}} className="noSelect" />} style={{flexDirection:"row",gap:"10px",fontFamily:"Titillium Web",fontSize:"20px",fontStyle:"regular",fontWeight:600,lineHeight:"120%",letterSpacing:"2.4px",height:"100%",justifyContent:"center",backgroundColor:"transparent",borderColor:"transparent",color:"#2E0249"}} onClick={()=>toggleRoutePanel()}></Button>
																
														</div>														
														
													</div>	

													{/* Continue Button Replication */}
													<div style={{marginTop:"20px",height:"110px",minHeight:"110px",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"stretch"}}>
															
													
														<div style={{backgroundColor:"transparent",flex:"1",borderRadius:"6px 6px 6px 6px",display:"flex",justifyContent:"center",alignItems:"center",border: "2px solid #814DE5"}}>
															<Button  className="buttonLeftPanel" icon={(options) => <img src={reservaIcon} style={{width:"24px",height:"auto"}} className="noSelect" />}  iconPos="left" label="Iniciar outra Reserva" style={{flexDirection:"row",gap:"10px",fontFamily:"Titillium Web",fontSize:"20px",fontStyle:"regular",fontWeight:600,lineHeight:"120%",letterSpacing:"2.4px",height:"100%",justifyContent:"center",backgroundColor:"transparent",borderColor:"transparent",color:"#814DE5"}} onClick={()=>navigate("/order")}></Button>
														</div>														
														
													</div>													
										
													{/* Continue Button Replication */}
													{/* Continue Button Replication
													<div style={{marginTop:"20px",height:"50px",minHeight:"50px",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"stretch"}}>
															
														<div style={{backgroundColor:"transparent",borderColor:"transparent",flex:"1",borderRadius:"6px 6px 6px 6px",display:"flex",justifyContent:"center",alignItems:"center"}}>
															<Button text className="buttonLeftPanel" icon="pi pi-download" iconPos="right" label="Ir para início" style={{flexDirection:"row",gap:"10px",color:'#814DE5',fontFeatureSettings:"'clig' off, 'liga' off",fontFamily:'Roboto',fontSize:'18px',fontStyle:'normal',fontWeight:500,lineHeight:'26px',letterSpacing:'0.46px',height:"100%",justifyContent:"center",backgroundColor:"transparent",borderColor:"transparent",color:"#814DE5"}} onClick={()=>navigate("/order/transport/reservation/confirm")}></Button>
														</div>														
														
													</div>	
													<div style={{marginTop:"10px",height:"50px",minHeight:"50px",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"stretch"}}>
															
														<div style={{backgroundColor:"transparent",borderColor:"transparent",flex:"1",borderRadius:"6px 6px 6px 6px",display:"flex",justifyContent:"center",alignItems:"center"}}>
															<Button text className="buttonLeftPanel"  icon="pi pi-send" iconPos="right" label="Enviar reserva por Email" style={{flexDirection:"row",gap:"10px",color:'#814DE5',fontFeatureSettings:"'clig' off, 'liga' off",fontFamily:'Roboto',fontSize:'18px',fontStyle:'normal',fontWeight:500,lineHeight:'26px',letterSpacing:'0.46px',height:"100%",justifyContent:"center",backgroundColor:"transparent",borderColor:"transparent",color:"#814DE5"}} onClick={()=>navigate("/order/transport/reservation/confirm")}></Button>
														</div>														
														
													</div>*/}														
													
												</>
												}
													
												</div>
												
												
											 </Grid>
											 
											  
										</Grid>	
								
									</div>
								
								</div>


						</div>						

					</div>				
						
				
				</div>
			
			</div> 
		</div>
	  <div className={routePanel == true ? "routePanel animate" : "routePanel"} style={{backgroundColor:"#2E0249",color:"#FFF",justifyContent:"flex-start",alignItems:"flex-start",top:"70px",overflow:"scroll",paddingBottom:"50px"}}>
	  
	  <Button icon="pi pi-times" text aria-label="Close Panel" onClick={() =>setRoutePanel(false)} style={{color:"#814DE5",fontSize:"14px",position:"absolute",left:"10px",top:"10px"}} />
		  		  
		<div container style={{minWidth:"34vw",width:"100%",marginTop:"0px",padding:"35px",display:"block"}}>
		
			{routeStatus == "waiting" &&
			<>
			<div  style={{display:"flex",flexDirection:"column",justifyContent:"flex-start",alignItems:"center"}}>
					<img src={formTopIcons} className="contatoSection__wrapper_content_formTop_image noSelect" />
				
					<div className="formTitle" style={{color:"#FFF",fontSize:"1.2rem"}}>Iniciando um Roteiro</div>
					<div className="formSubTitle" style={{color:"#FFF",fontSize:"1rem",textAlign:"left"}}> Tem certeza que deseja criar um roteiro e adicionar o trecho ao mesmo? </div>
					
					{/*<span className="p-float-label" style={{marginBottom:"1.875rem",padding:"1.5rem",width:"85%",textAlign:"center"}}>
						<label style={{fontSize:"18px"}}>Nome do Roteiro:  <strong>Roteiro 20231016-00001</strong> </label>
					</span>*/}	

					<div>
						<div className="formSubTitle" style={{color:"#FFF",fontSize:"0.9rem",textAlign:"left"}}>Ao prosseguir, o roteiro será criado e você poderá adicionar mais transportes.</div>					
					
						<Button rounded outlined className="pT10" label="Fechar" onClick={()=>toggleRoutePanel(false)} />
						<Button rounded className="pT10" label="Prosseguir" loading={saving} disabled={saving} onClick={()=>setRouteStatus("generated")} style={{marginLeft:"15px"}} />
					</div>
			 </div>
			 </>
			}
			 
			{routeStatus == "generated" &&
			<>
			<div  style={{display:"flex",flexDirection:"column",justifyContent:"flex-start",alignItems:"center"}}>
			
					<img src={formTopIcons} className="contatoSection__wrapper_content_formTop_image noSelect" />
				
					<div className="formTitle" style={{color:"#FFF",fontSize:"1.2rem"}}>O Roteiro foi criado!</div>
					<div className="formSubTitle" style={{color:"#FFF",fontSize:"1rem",textAlign:"left"}}>Adicione mais um transporte ao roteiro ou faça isso depois. Ao adicionar transporte o roteiro ficará ativo.</div>
					
					<div style={{width:"100%"}}>
					
						<div className="formSubTitle" style={{marginBottom:"2rem",color:"#FFF",fontSize:"1rem",textAlign:"center",fontWeight:"bold"}}>Roteiro 20231016-00001</div>					
					
					
						<div style={{width:"100%",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>

										{/* Card 1 START*/}
										<div style={{width:"100%",display:"flex",flexdirection:"row",alignItems:"stretch",justifyContent:"center"}}>

												<div style={{width:"40px",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center",height:"auto"}}>
											
													<div style={{flex:1,display:"flex",backgroundColor:'rgb(161, 169, 195,0)',width:'2px'}}>
														
													</div>
													
													<div style={{flex:1,display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"stretch"}}>
																				
															<div style={{display:'flex',flex:'1 1 0%',flexDirection:'column',WebkitBoxPack:'justify',justifyContent:'center',fontSize:'14px',WebkitBoxAlign:'center',alignItems:'center',textAlign:'center'}}>
															
															<div style={{flex:1,display:"flex",backgroundColor:'rgb(161, 169, 195,0)',width:'2px'}}></div>
																
																<div style={{width:'15px',height:'14px',marginTop:"2px",marginBottom:"5px"}}><svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
																	<path d="M8.36602 13.5C7.98112 14.1667 7.01888 14.1667 6.63397 13.5L0.138783 2.25C-0.246116 1.58333 0.235009 0.75 1.00481 0.749999L13.9952 0.749998C14.765 0.749998 15.2461 1.58333 14.8612 2.25L8.36602 13.5Z" fill="white"/></svg></div>

																<div style={{flex:1,display:"flex",backgroundColor:'rgb(161, 169, 195,0)',width:'2px'}}></div>	
																
															</div>	
															
													</div>
													
													<div style={{flex:1,display:"flex",backgroundColor:'rgb(161, 169, 195,0)',width:'2px'}}>
														
													</div>

												</div>

												{/* Current Trip Card */}											
												<div style={{flex:1,borderRadius:"10px",width:"100%",margin:"0px",backgroundColor:"#fff",marginBottom:"15px",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",padding:"10px",cursor:"initial",gap:"5px"}}>
											
												<div style={{textAlign:"left",padding:"5px"}}>
													<span style={{textAlign:"left",color:"#2E0249",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '16px',lineHeight: '120%',letterSpacing: '0.1em'}}>Transfer <img src={titleSedanIcon} style={{marginLeft:"5px",width:"18px",height:"auto"}} className="noSelect" /></span>
													
													<Divider type="dotted" style={{margin:0,marginTop:"0.5rem",marginBottom:"0.5rem"}} />
												</div>		
												
												<div style={{flex:1,display:"flex",flexDirection:"row",alignItems:"stretch",justifyContent:"flex-start"}}>
													
														<div style={{flex:"1",display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center", gap:"15px",padding:"5px"}}>
																
																 <div>
																		<div style={{width:"100%",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",marginBottom:"5px"}}> 
																			
																			<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '15px',lineHeight: '120%',letterSpacing: '0.1em', display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
																			
																				<div style={{flex:1,color:"#2E0249"}}>	
																					<span style={{fontWeight:"bold"}}>{transportFilters ? transportFilters.departureLocalCountry.name : "Carregando..."}</span>, {transportFilters ? transportFilters.departureLocalCity.name : "Carregando..."}											
																				</div>
																			
																			</div>

																		</div>
																 </div>
																  
																<div  style={{textAlign:"left",alignSelf:"center"}}>
																	<i className="pi pi-arrow-right" style={{ color: 'slateblue',fontSize:"18px",width:"100%",height:"auto"}}></i>
																</div>
																
																<div>
															
																	<div style={{width:"100%",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",marginBottom:"5px"}}> 

																		<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '15px',lineHeight: '120%',letterSpacing: '0.1em', display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
																			<div style={{flex:1,color:"#2E0249"}}>	
																					<span style={{fontWeight:"bold"}}>{transportFilters ? transportFilters.destinationLocalCountry.name : "Carregando..."}</span>, {transportFilters ? transportFilters.destinationLocalCity.name : "Carregando..."}			
																			</div>
																		</div>

																	</div>												
																			
																 </div>
																 
															 </div>
																										 
													
													</div>			

													<div style={{flex:1,display:"flex",flexDirection:"row",alignItems:"stretch",justifyContent:"flex-start"}}>
													
														<div style={{flex:2,width:"100%",display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center", gap:"15px",padding:"5px"}}>
																
															 <div>
																		<div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"stretch",justifyContent:"flex-start",marginBottom:"5px"}}> 
																			
																			<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '15px',lineHeight: '120%',letterSpacing: '0.1em', display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
																			
																				<div style={{flex:1,color:"#2E0249",fontWeight:"normal"}}>	
																					{transportFilters ? transportFilters.leaveDate.toLocaleDateString('pt-BR', { year: 'numeric', month: 'long', day: 'numeric' }) : "Carregando..."} às 09:00	
																				</div>
																			
																			</div>

																		</div>
																 </div>
																 																 
															 </div>
																			
															<div style={{textAlign:"right",flex:1.2}}>
															
																<Message className="cardLabelStatus" severity="warn" style={{textAlign:"left",marginBottom:"0px"}} text="Aguardando pagamento" />	
															
															</div>	
													
													</div>													
													

													<div style={{textAlign:"left"}}>
														
														<Panel headerTemplate={(options) => {return (<Button iconPos="right" icon="pi pi-angle-down" size="small" rounded text aria-label="Menu" label="Mais detalhes" style={{fontFamily:'Titillium Web',fontStyle:'normal',fontWeight:400,fontSize:'14px',lineHeight:'120%',textAlign:'center',letterSpacing:'0.1em',color:'#814DE5',padding:0}}  onClick={options.onTogglerClick} />)}} toggleable collapsed={true} className="cardPanel">
															<p className="m-0">
															
																<div style={{width:"100%",display:"flex",flexDirection:"row"}}>
																
																	<div style={{width:"45%",display:"flex",flexDirection:"row",    justifyContent:"space-between",alignItems:"center"}}>
																	
																		<div style={{color:'#222',fontFamily:'Titillium Web',fontSize:'14px',fontStyle:'normal',fontWeight:600,lineHeight:'120%',letterSpacing:'2.4px'}}>
																		09:00
																		</div>

																		<div style={{color:'rgb(161, 169, 195)',fontSize:'14px',display:'flex',WebkitBoxAlign:'center',alignItems:'center',justifyContent:'space-evenly',textAlign:'center',width:'100%'}}>
																			<div style={{backgroundColor:'rgb(161, 169, 195)',WebkitBoxFlex:2,flexGrow:2,height:'1px',margin:'auto 4px',borderRadius:'1px',opacity:0.45,height:'2px',margin:'auto 10px',width:"20px"}}></div>
																			<div>3h40m</div>
																			<div style={{backgroundColor:'rgb(161, 169, 195)',WebkitBoxFlex:2,flexGrow:2,height:'1px',margin:'auto 4px',borderRadius:'1px',opacity:0.45,height:'2px',margin:'auto 10px',width:"20px"}}></div>
																		</div>
																		
																	</div>
																	
																	<div style={{width:"45%",color:'#222',fontFamily:'Titillium Web',fontSize:'14px',fontStyle:'normal',fontWeight:600,lineHeight:'120%',letterSpacing:'2.4px'}}>
																	12:44
																	</div>
																
																</div>
																	
																<div style={{width:"100%",display:"flex",flexDirection:"row"}}>
																
																	<div style={{width:"45%",color:'rgba(34, 34, 34, 0.60)',fontFamily:'Titillium Web',fontSize:'14px',fontStyle:'normal',fontWeight:400,lineHeight:'120%',letterSpacing:'1.4px'}}>
																	Paris Gare du Nord
																	</div>

																	<div style={{width:"45%",color:'rgba(34, 34, 34, 0.60)',fontFamily:'Titillium Web',fontSize:'14px',fontStyle:'normal',fontWeight:400,lineHeight:'120%',letterSpacing:'1.4px'}}>
																	 Amesterdan Central
																	</div>
																   
																</div>	
															
															</p>
														</Panel>														
														
													</div>												
												
												</div>
												
												
											</div>
											{/* Card 1 END */}
											
											{/* Card 2 START */}
											{transportFilters.pickReturn &&
											<>
											<div style={{width:"100%",display:"flex",flexdirection:"row",alignItems:"stretch",justifyContent:"center"}}>

												<div style={{width:"40px",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center",height:"auto"}}>
											
													<div style={{flex:1,display:"flex",backgroundColor:'rgb(161, 169, 195)',width:'2px'}}>
														
													</div>
													
													<div style={{flex:1,display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"stretch"}}>
																				
															<div style={{display:'flex',flex:'1 1 0%',flexDirection:'column',WebkitBoxPack:'justify',justifyContent:'center',fontSize:'14px',WebkitBoxAlign:'center',alignItems:'center',textAlign:'center'}}>
															
															<div style={{flex:1,display:"flex",backgroundColor:'rgb(161, 169, 195)',width:'2px'}}></div>
																
																<div style={{width:'15px',height:'15px',marginTop:"2px",marginBottom:"2px"}}><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
																<circle cx="7.5" cy="7.5" r="6" fill="#814DE5" stroke="white" stroke-width="3"/>
																</svg></div>

																<div style={{flex:1,display:"flex",backgroundColor:'rgb(161, 169, 195,0)',width:'2px'}}></div>	
																
															</div>	
															
													</div>
													
													<div style={{flex:1,display:"flex",backgroundColor:'rgb(161, 169, 195,0)',width:'2px'}}>
														
													</div>

												</div>

												{/* Current Trip Card */}											
												<div style={{flex:1,borderRadius:"10px",width:"100%",margin:"0px",backgroundColor:"#fff",marginBottom:"15px",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",padding:"10px",cursor:"initial",gap:"5px"}}>
											
												<div style={{textAlign:"left",padding:"5px"}}>
													<span style={{textAlign:"left",color:"#2E0249",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '16px',lineHeight: '120%',letterSpacing: '0.1em'}}>Trem <img src={titleTrainIcon} style={{marginLeft:"5px",width:"18px",height:"auto"}} className="noSelect" /></span>
													
													<Divider type="dotted" style={{margin:0,marginTop:"0.5rem",marginBottom:"0.5rem"}} />
												</div>		
												
												<div style={{flex:1,display:"flex",flexDirection:"row",alignItems:"stretch",justifyContent:"flex-start"}}>
													
														<div style={{flex:"1",display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center", gap:"15px",padding:"5px"}}>
																
																 <div>
																		<div style={{width:"100%",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",marginBottom:"5px"}}> 
																			
																			<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '15px',lineHeight: '120%',letterSpacing: '0.1em', display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
																			
																				<div style={{flex:1,color:"#2E0249"}}>	
																					<span style={{fontWeight:"bold"}}>{transportFilters ? transportFilters.destinationLocalCountry.name : "Carregando..."}</span>, {transportFilters ? transportFilters.destinationLocalCity.name : "Carregando..."}									
																				</div>
																			
																			</div>

																		</div>
																 </div>
																  
																<div  style={{textAlign:"left",alignSelf:"center"}}>
																	<i className="pi pi-arrow-right" style={{ color: 'slateblue',fontSize:"18px",width:"100%",height:"auto"}}></i>
																</div>
																
																<div>
															
																	<div style={{width:"100%",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",marginBottom:"5px"}}> 

																		<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '15px',lineHeight: '120%',letterSpacing: '0.1em', display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
																			<div style={{flex:1,color:"#2E0249"}}>	
																					<span style={{fontWeight:"bold"}}>{transportFilters ? transportFilters.departureLocalCountry.name : "Carregando..."}</span>, {transportFilters ? transportFilters.departureLocalCity.name : "Carregando..."}
																			</div>
																		</div>

																	</div>												
																			
																 </div>
																 
															 </div>
																										 
													
													</div>			

													<div style={{flex:1,display:"flex",flexDirection:"row",alignItems:"stretch",justifyContent:"flex-start"}}>
													
														<div style={{flex:2,width:"100%",display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center", gap:"15px",padding:"5px"}}>
																
															 <div>
																		<div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"stretch",justifyContent:"flex-start",marginBottom:"5px"}}> 
																			
																			<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '15px',lineHeight: '120%',letterSpacing: '0.1em', display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
																			
																				<div style={{flex:1,color:"#2E0249",fontWeight:"normal"}}>	
																					{transportFilters ? transportFilters.returnDate.toLocaleDateString('pt-BR', { year: 'numeric', month: 'long', day: 'numeric' }) : "Carregando..."} às 09:00	
																				</div>
																			
																			</div>

																		</div>
																 </div>
																 																 
															 </div>
																			
															<div style={{textAlign:"right",flex:1.2}}>
															
																<Message className="cardLabelStatus" severity="warn" style={{textAlign:"left",marginBottom:"0px"}} text="Aguardando pagamento" />	
															
															</div>	
													
													</div>													
													

													<div style={{textAlign:"left"}}>
														
														<Panel headerTemplate={(options) => {return (<Button iconPos="right" icon="pi pi-angle-down" size="small" rounded text aria-label="Menu" label="Mais detalhes" style={{fontFamily:'Titillium Web',fontStyle:'normal',fontWeight:400,fontSize:'14px',lineHeight:'120%',textAlign:'center',letterSpacing:'0.1em',color:'#814DE5',padding:0}}  onClick={options.onTogglerClick} />)}} toggleable collapsed={true} className="cardPanel">
															<p className="m-0">
															
																<div style={{width:"100%",display:"flex",flexDirection:"row"}}>
																
																	<div style={{width:"45%",display:"flex",flexDirection:"row",    justifyContent:"space-between",alignItems:"center"}}>
																	
																		<div style={{color:'#222',fontFamily:'Titillium Web',fontSize:'14px',fontStyle:'normal',fontWeight:600,lineHeight:'120%',letterSpacing:'2.4px'}}>
																		09:00
																		</div>

																		<div style={{color:'rgb(161, 169, 195)',fontSize:'14px',display:'flex',WebkitBoxAlign:'center',alignItems:'center',justifyContent:'space-evenly',textAlign:'center',width:'100%'}}>
																			<div style={{backgroundColor:'rgb(161, 169, 195)',WebkitBoxFlex:2,flexGrow:2,height:'1px',margin:'auto 4px',borderRadius:'1px',opacity:0.45,height:'2px',margin:'auto 10px',width:"20px"}}></div>
																			<div>3h40m</div>
																			<div style={{backgroundColor:'rgb(161, 169, 195)',WebkitBoxFlex:2,flexGrow:2,height:'1px',margin:'auto 4px',borderRadius:'1px',opacity:0.45,height:'2px',margin:'auto 10px',width:"20px"}}></div>
																		</div>
																		
																	</div>
																	
																	<div style={{width:"45%",color:'#222',fontFamily:'Titillium Web',fontSize:'14px',fontStyle:'normal',fontWeight:600,lineHeight:'120%',letterSpacing:'2.4px'}}>
																	12:44
																	</div>
																
																</div>
																	
																<div style={{width:"100%",display:"flex",flexDirection:"row"}}>
																
																	<div style={{width:"45%",color:'rgba(34, 34, 34, 0.60)',fontFamily:'Titillium Web',fontSize:'14px',fontStyle:'normal',fontWeight:400,lineHeight:'120%',letterSpacing:'1.4px'}}>
																	Paris Gare du Nord
																	</div>

																	<div style={{width:"45%",color:'rgba(34, 34, 34, 0.60)',fontFamily:'Titillium Web',fontSize:'14px',fontStyle:'normal',fontWeight:400,lineHeight:'120%',letterSpacing:'1.4px'}}>
																	 Amesterdan Central
																	</div>
																   
																</div>	
															
															</p>
														</Panel>														
														
													</div>												
												
												</div>
												
												
											</div>
											</>
											}
											{/* Card 2 END */}

											
										
						</div>
									
						<div style={{marginTop:"10px"}}>
											
								{/* Before Transport Hint */}											
								<div style={{borderRadius:"10px",width:"100%",border:"3px dashed #5b377d",margin:"0px",backgroundColor:"transparent",marginBottom:"15px",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",padding:"0px",cursor:"initial",gap:"5px"}}>
								
									<div style={{padding:"5px"}}>
										<div style={{display:"flex",alignItems:"center",justifyContent:"flex-start",textAlign:"left",color:"#FFF",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '16px',lineHeight: '120%',letterSpacing: '0.1em'}}><img src={ideaIcon} style={{marginLeft:"5px",width:"60px",height:"auto"}} className="noSelect" /> Que tal adicionar um transfer antes ou depois do Trem? </div>

									</div>		
								
								</div>			
						
						</div>

					</div>
					
					<div style={{marginTop:"15px"}}>

						<Button text className="pT10" label="Fechar" onClick={()=>toggleRoutePanel(false)} />
						<Button rounded outlined className="pT10" label="Voltar para início" onClick={()=>navigate("/order")} style={{marginLeft:"15px"}} />
						<Button rounded className="pT10" label="Adicionar Transporte" loading={saving} disabled={saving} onClick={()=>navigate("/order/route")} style={{marginLeft:"15px"}} />
					</div>
			 </div>	
			 </>
			}			 
			 
		</div>
		  
	  </div>
	  
    </div>
	
	<div id="contatoSection" className="contatoSection__wrapper">

		<div className={isTabletOrMobile ? "contatoSection__wrapper_content_wrapper mobile" : "contatoSection__wrapper_content_wrapper"}  >

			<div className="flex-1 dFlex aItemsC jContentE"> 
			
				<div className="textBlockMargin contatoSection__wrapper_content_text" >
				
					<div className="contatoSection__wrapper_content_header">Central de Relacionamento</div>
				
					Estamos a disposição para atender às suas necessidades e responder às suas perguntas. Conheça as principais formas de entrar em contato conosco:
					
					<div style={{marginTop: "2.8125rem", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "stretch", gap: "15px"}}>

						<div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center",gap:"15px"}}>
							<div><img src={contactWhatsappIcon} style={{width:"49px",height:"auto"}} className="noSelect" /></div>
							<div className="contatoOptionText"><Whatsapp phone="552140209749" style={{color:"#FFF",textDecoration:"none"}}>Whatsapp 4020-9749</Whatsapp></div>
						</div>

						<div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center",gap:"15px"}}>
							<div><img src={contactPhoneIcon} style={{width:"49px",height:"auto"}} className="noSelect"  /></div>
							<div className="contatoOptionText"><Callto phone="+552140209749" style={{color:"#FFF",textDecoration:"none"}}>Telefone 4020-9749</Callto></div>
						</div>
						
						<div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center",gap:"15px"}}>
							<div><img src={contactEmailIcon} style={{width:"49px",height:"auto"}} className="noSelect"  /></div>
							<div className="contatoOptionText"><Button label="Enviar mensagem" rounded className="pT10 mR10 headerButton tColorWhite w100" style={{marginBottom:"0.625rem"}} onClick={()=>setDialog('messageModal',true)} /></div>
						</div>
						

					</div>					
				
				</div> 
				
			</div>
			
			<div className="tAlignL flex-1_5"> 
				<img src={contactConcept} className="contatoSection__wrapper_content_image noSelect" />
			</div>			
			
		</div>
			<AlertView
				alert={alert}
				setAlert={setAlert}
				alertTitle={alertTitle}
				alertBody={alertBody}	
				scrollBlock={false}				
			/>	
			<ExpireView
				alertExpire={alertExpire}
				setAlertExpire={setAlertExpire}
				alertTitleExpire={alertTitleExpire}
				alertBodyExpire={alertBodyExpire}	
				alertActionExpire={alertActionExpire}	
				scrollBlock={false}				
			/>	
			<MessageView
				alert={alert}
				setAlert={setAlert}
				alertTitle={alertTitle}
				alertBody={alertBody}	
				scrollBlock={false}				
			/>	
    </div>
	
	<div id="memovoSection" className="memovoSection__wrapper">
	
		<div className={isTabletOrMobile ? "memovoSection__wrapper_content_wrapper mobile" : "memovoSection__wrapper_content_wrapper"}>
		
			<div className="flex-1"> 
				<img src={desktopConcept} className="memovoSection__wrapper_content_image noSelect" />
			</div>
			<div className="flex-1 dFlex aItemsC jContentS"> 
			
				<div className="textBlockMargin memovoSection__wrapper_content_text" >
				
					<div className="memovoSection__wrapper_content_header">Inovando o Mercado B2B de Mobilidade</div>
									
					A MEMOVO é uma plataforma B2B inovadora que facilita a busca e comercialização de serviços de transporte e mobilidade. Pioneira como marketplace B2B, oferece passagens aéreas, trens, ônibus, aluguel de veículos e transfers em um só lugar, permitindo a criação de roteiros completos de mobilidade. Com interface amigável e processo simples, a plataforma proporciona agilidade e eficiência aos profissionais do setor, atendendo às necessidades dos usuários e impulsionando o crescimento do seu negócio.
				
				</div> 
				
			</div>
			
		</div>
		
    </div>	
	
	</>
  );
    
}


const mapStateToProps = store => ({
  dialog:store.appReducer.dialog,
  routePanel:store.appReducer.routePanel,
  transportFilters:store.orderReducer.transportFilters,
  currentAgency:store.appReducer.currentAgency
 });
const mapDispatchToProps = dispatch => bindActionCreators({ ...AppActions,...OrderActions,...RegisterActions }, dispatch);

export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(TransferSearchResult);	


