import '../../Assets/Styles/home.css';
import '../../Assets/Styles/order.css';
import React from "react";
import { useMediaQuery } from 'react-responsive'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {Link,useNavigate} from 'react-router-dom';

import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button'; 
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import Grid from '@mui/material/Grid';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { OverlayPanel } from 'primereact/overlaypanel';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Skeleton } from 'primereact/skeleton';
import { BreadCrumb } from 'primereact/breadcrumb';
import { SelectButton } from 'primereact/selectbutton';
import { AutoComplete } from "primereact/autocomplete";
import moment from "moment";

// Load Actions
import * as AppActions from '../../Store/Actions/App'
import * as OrderActions from '../../Store/Actions/Order'

import AboutUs from '../../Components/layout/AboutUs';
import ContactUs from '../../Components/layout/ContactUs';
import RoomSelector from '../../Components/RoomSelector';

// Load Componentes
import AlertView from '../../Components/AlertModal';

import continentsAfricaImage from '../../Assets/Images/continents-africa.png'; 
import continentsEuropeImage from '../../Assets/Images/continents-europe.png'; 


// export default function HomePage() {
const HomePage = (props) => {
	
// Set var from props
const {
	setDialog,
	dialog,
	masterUser,
	currentAgency,
	retrieveDropdownCountries,
	retrieveDropdownCities,
	retrieveDropdownProperties,
	setSearchResToken,
	searchResultToken,
	processLocalitySearch,
	processPropertySearch
} = props;


const [loadingResults, setLoadingResults] = React.useState(false); 
const [loading, setLoading] = React.useState(true); 
const [saving, setSaving] = React.useState(false); 

const [contentLoading, setContentLoading] = React.useState(true); 
const [contentLoaded, setContentLoaded] = React.useState(false); 
const [hostingOption, setHostingOptions] = React.useState(false); 
const [currentStep, setCurrentStep] = React.useState(1); // 1 continent - 2 country - 3 city  - 4 options

const [currentContinent, setCurrentContinent] = React.useState({}); // id and label
const [currentCountry, setCurrentCountry] = React.useState({}); // id and label
const [currentCity, setCurrentCity] = React.useState({}); // id and label

const [breadcrumbArray, setBreadcrumbArray] = React.useState([]); // id and label
	
const isDesktopOrLaptop = useMediaQuery({
query: '(min-width: 1024px)'
})
const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1023px)' })

const [email, setEmail] = React.useState('');
const [password, setPassword] = React.useState('');
const [stillConnected, setStillConnected] = React.useState(false);
const onStillConnectedChange = (e) => {
	if (e.checked)
		setStillConnected(true);
	else
		setStillConnected(false);
}  // end function
	
// Alert control
const [alert, setAlert] = React.useState(false); 
const [alertTitle, setAlertTitle] = React.useState("Informações"); 
const [alertBody, setAlertBody] = React.useState("Conteúdo dinâmico"); 

const [currentPlaces, setCurrentPlaces] = React.useState([]); 		

const [hostsAndRooms, setHostsAndRooms] = React.useState(null); 
const [rooms, setRooms] = React.useState([{ adults: 1, children: [] }]); // Default at least 1 adult

const [dateRange, setDateRange] = React.useState(null); 
const [dateRangeDays, setDateRangeDays] = React.useState(null); 
const [formattedValue, setFormattedValue] = React.useState('');


const [filterByCity, setFilterByCity] = React.useState(null); 
const [filterByCountry, setFilterByCountry] = React.useState(null); 
const [filterByProperty, setFilterByProperty] = React.useState(null); 

const [showCancelButton, setShowCancelButton] = React.useState(true); 
const [counterStatus, setCounterStatus] = React.useState(false); 

const options = [{label:'Por localidade',value:'locality'},{label:'Por propriedade',value:'property'}];
const [searchOption, setSearchOption] = React.useState(options[0].value);
const setSearchMethod = (value) => {
	setSearchOption(value);
}

const calendarRef = React.useRef(null);
const overlayPanelRef = React.useRef(null);


const [countriesList, setCountriesList] = React.useState([]); 
const searchCountries = (event=null) => {
	
	var props = {
		setRows:setCountriesList
	}
	var data = {
	  keyword:event ? event.query : null
	}
	retrieveDropdownCountries(data,props);	

}

const [citiesList, setCitiesList] = React.useState([]); 
const searchCities = (event) => {

	var props = {
		setRows:setCitiesList
	}
	var data = {
	  keyword:event ? event.query : null,
	  countryCode:filterByCountry
	}
	retrieveDropdownCities(data,props);	

}
const processSelectedCity = (e) => {

	if (!filterByCountry) {

		let countryObj = {
			code:e.value.country_code,
			name:e.value.country
		}
		setFilterByCountry(countryObj);
		setFilterByCity(e.value);

	} else {
		setFilterByCity(e.value);
	}
	
}


const [propertiesList, setPropertiesList] = React.useState([]); 
const searchProperties = (event) => {

	var props = {
		setRows:setPropertiesList
	}
	var data = {
	  keyword:event ? event.query : null,
	}
	retrieveDropdownProperties(data,props);		

}

const today = new Date(); 

const secondsToHms = (d) => {
	d = Number(d);
  
	// Calcula horas, minutos e segundos
	const h = Math.floor(d / 3600);
	const m = Math.floor((d % 3600) / 60);
	const s = Math.floor(d % 60);
  
	// Formata para 2 dígitos sempre
	const hDisplay = h.toString().padStart(2, "0");
	const mDisplay = m.toString().padStart(2, "0");
	const sDisplay = s.toString().padStart(2, "0");
  
	// Retorna o formato mm:ss (sem as horas no momento)
	return `${mDisplay}:${sDisplay}`;
  };
  
// Função para converter segundos no formato mm:ss
const convertSecondsToHms = (seconds) => {
	return moment.utc(seconds * 1000).format("mm:ss");
}  


const handleRoomSelection = (value) => {
	setHostsAndRooms(value);
	overlayPanelRef.current.hide();
};

const handleClearSelection = () => {
	setRooms([{ adults: 1, children: [] }]);
	// setHostsAndRooms(null);	
	overlayPanelRef.current.hide();
};


// Check if everything is ok with seleciton
const handleCheckSelectedOptions = (rooms) => {

	// Check if any children dont have defined age
	for (let i = 0; i < rooms.length; i++) {
		for (let j = 0; j < rooms[i].children.length; j++) {
			if (rooms[i].children[j].age === "") {
	
				setAlertTitle("Ops! Defina a idade da(s) criança(s)");
				setAlertBody("É necessário que você defina as idades das crianças que foram adicionadas. Abra o seletor de quartos e informe a idade das crianças.");
				setAlert(true);	
								
				handleUpdateInputValue(rooms); // update current selection
				return false;
			}
		}
	}



}

const handleUpdateInputValue = (rooms) => {
	const adultCount = rooms.reduce((acc, room) => acc + room.adults, 0);
	const childCount = rooms.reduce((acc, room) => acc + room.children.length, 0);

	const adultLabel = adultCount > 1 ? 'adultos' : 'adulto';
	const childLabel = childCount > 1 ? 'crianças' : 'criança';
	const roomLabel = rooms.length > 1 ? 'quartos' : 'quarto';

	const parts = [];
	if (adultCount > 0) parts.push(`${adultCount} ${adultLabel}`);
	if (childCount > 0) parts.push(`${childCount} ${childLabel}`);
	if (rooms.length > 0 && (adultCount > 0 || childCount > 0)) parts.push(`${rooms.length} ${roomLabel}`);

	setHostsAndRooms(parts.join(', '));	
};

const handleClickScroll = (target) => {
		
	const element = document.getElementById(target);
	if (element) {
		// 👇 Will scroll smoothly to the top of the next section
		var elementPosition = element.offsetTop;
		element.scrollIntoView({ top: elementPosition - 70,behavior: 'smooth' });
	}
	};  

React.useEffect(() => {
	if (!hostsAndRooms) {
		setHostsAndRooms(null);
	}	
}, [hostsAndRooms]);


React.useEffect(() => {
	handleUpdateInputValue(rooms);
}, [rooms]);


React.useEffect(() => {

	if (dateRange && dateRange[0] && dateRange[1]) {
		const diffTime = Math.abs(dateRange[1] - dateRange[0]);
		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
		const formattedRange = formatDateRange(dateRange);
		let nightLabel = diffDays == 1 ? "noite" : "noites";
		setFormattedValue(`${formattedRange} (${diffDays} ${nightLabel})`);

		// set number os days
		setDateRangeDays(diffDays);

	} else {
		setFormattedValue('');
	}

}, [dateRange]);


const formatDateRange = (range) => {
	if (range && range[0] && range[1]) {
		const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
		return `${range[0].toLocaleDateString('pt-BR', options)} - ${range[1].toLocaleDateString('pt-BR', options)}`;
	}
	return '';
};

  
const Countdown = (props) => {
	const { startTime, stop } = props;
	const [counter, setCounter] = React.useState(startTime);
	const [counterInitial] = React.useState(startTime);
  
	const [counterLabel, setCounterLabel] = React.useState(() => convertSecondsToHms(startTime));
  
	React.useEffect(() => {
	  if (counter > 0 && !stop) {
		const timer = setTimeout(() => {
		  setCounter((prevCounter) => {
			const newCounter = prevCounter - 1;
			setCounterLabel(secondsToHms(newCounter));
			return newCounter;
		  });
		}, 1000);
  
		return () => clearTimeout(timer);
	  }
	}, [counter, stop]);
  
	return (
	  <>
		{counter > 0 && !stop ? (
		  <>Aguarde até {counterLabel} para processarmos a sua solicitação...</>
		) : (
		  <>Tudo certo! Redirecionando para resultado...</>
		)}
	  </>
	);
  }; 

const customFooter = () => {
	return (<>
		<div className="p-clearfix" style={{ width: '100%',textAlign:"left" }}>
		 <button type="button" onClick={() => calendarRef.current.hide()} className="p-button p-component p-button-text p-button-plain" style={{ float: 'right' }}>
                    Fechar
        </button>
		</div>

		<div className="p-clearfix" style={{ width: '100%',textAlign:"right" }}>
			<button type="button" onClick={() => setDateRange(null)} className="p-button p-component p-button-text p-button-plain" style={{fontWeight:"bold"}}>
				Limpar
			</button>
		</div>
		</>
	);
};

const customFooterInline = () => {
	return (<>
		<div className="p-clearfix" style={{ width: '100%',textAlign:"right" }}>
			<button type="button" onClick={() => setDateRange(null)} className="p-button p-component p-button-text p-button-plain" style={{fontWeight:"bold"}}>
				Limpar
			</button>
		</div>
		</>
	);
};

// Set calendar locale
addLocale('br', {
	firstDayOfWeek: 1,
	dayNames: ['domingo', 'segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado'],
	dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
	dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
	monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
	monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
	today: 'Hoje',
	clear: 'Limpar'
});

const continentIcon = () => {
	return (
	<svg style={{marginRight:"0.5rem"}} xmlns="http://www.w3.org/2000/svg" width="1.5rem" height="auto" viewBox="0 0 24 24" fill="none">
	<g clip-path="url(#clip0_322_932)">
		<path d="M12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2ZM14 13.4L12.436 14.651C12.3811 14.6949 12.3361 14.75 12.3039 14.8125C12.2717 14.875 12.2531 14.9436 12.2492 15.0138C12.2453 15.084 12.2563 15.1543 12.2814 15.22C12.3066 15.2856 12.3453 15.3453 12.395 15.395L13.634 16.634C13.8739 16.8742 14.0487 17.1715 14.142 17.498L14.317 18.111C14.3896 18.3682 14.5185 18.606 14.6944 18.8072C14.8703 19.0083 15.0888 19.1678 15.334 19.274C16.2906 18.8345 17.1513 18.211 17.867 17.439L17.633 15.562C17.5921 15.2354 17.4711 14.924 17.281 14.6554C17.0909 14.3868 16.8374 14.1692 16.543 14.022L15.073 13.286C14.902 13.2004 14.71 13.1657 14.5198 13.1859C14.3296 13.2061 14.1492 13.2804 14 13.4ZM12 4C10.776 3.99896 9.56823 4.27922 8.46981 4.81916C7.37139 5.35909 6.41175 6.14425 5.665 7.114L5.5 7.335V9.02C5.49987 9.6305 5.68601 10.2265 6.03355 10.7284C6.38109 11.2304 6.87349 11.6143 7.445 11.829L7.623 11.889L8.913 12.284C10.286 12.704 11.623 11.587 11.49 10.188L11.471 10.043L11.296 8.994C11.2573 8.76163 11.3019 8.52307 11.4219 8.32037C11.542 8.11767 11.7297 7.96384 11.952 7.886L12.06 7.856L12.672 7.716C13.0192 7.63663 13.3471 7.48851 13.6361 7.28038C13.9252 7.07225 14.1696 6.80833 14.355 6.50419C14.5404 6.20005 14.663 5.86185 14.7155 5.50955C14.7681 5.15726 14.7496 4.79801 14.661 4.453C13.8061 4.15212 12.9063 3.99894 12 4Z" fill="white"/>
	</g>
	<defs>
		<clipPath id="clip0_322_932">
		<rect width="24" height="24" fill="white"/>
		</clipPath>
	</defs>
	</svg>	
	);
}

const countryIcon = (color) => {

	return (	
		<svg style={{marginRight:"0.5rem"}} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
	<g clip-path="url(#clip0_322_895)">
		<path d="M5 4.99996C5.93464 4.08383 7.19124 3.57068 8.5 3.57068C9.80876 3.57068 11.0654 4.08383 12 4.99996C12.9346 5.9161 14.1912 6.42925 15.5 6.42925C16.8088 6.42925 18.0654 5.9161 19 4.99996V14C18.0654 14.9161 16.8088 15.4293 15.5 15.4293C14.1912 15.4293 12.9346 14.9161 12 14C11.0654 13.0838 9.80876 12.5707 8.5 12.5707C7.19124 12.5707 5.93464 13.0838 5 14V4.99996Z" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		<path d="M5 21V14" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
	</g>
	<defs>
		<clipPath id="clip0_322_895">
		<rect width="24" height="24" fill={color}/>
		</clipPath>
	</defs>
	</svg>	
	);
}

// set Use of history for PUSH 
var navigate = useNavigate();
	
/* Execute recaptcha to retrieve token*/
const recaptchaRef = window.grecaptcha;
const executeReCaptcha = async () => {
	const token = await recaptchaRef.execute();
	return token;
}

// Run after mount component
React.useEffect(() => {

	// Retrieve User Data Obj. 
	var result = localStorage.getItem("@memovo/userData", undefined,true);
	var userData = JSON.parse(result);  

	  // If token dont exist, redirect to onboarding or register
	  if (!userData) {
		  navigate("/home");
		  return;
	  } // end if
	  
  // set scroll to top
  window.scrollTo(0, 1);


  // Retrieve all countries
  searchCountries();

  // Reset Search token
  setSearchResToken(null);

  
  // set loading false
  setLoading(false);

  setCurrentPlaces(continents);

  setTimeout(function() {

	setContentLoading(false);

		setTimeout(function() {
			setContentLoaded(true);		
		},300);
			
  },2000);

    
  // Runs when UNMOUNT component
  return () => {

	setContentLoading(true); 
	setContentLoaded(false); 
	setHostingOptions(false); 
	setCurrentStep(1); 

	setCurrentContinent({}); 
	setCurrentCountry({}); 
	setCurrentCity({}); 
	setBreadcrumbArray([]); 

	setCurrentPlaces([]); 		
	setHostsAndRooms(null); 
	setRooms([{ adults: 1, children: [] }]); 
		
	setDateRange(null); 
	setFormattedValue('');
		
	setFilterByCity(null); 
	setFilterByCountry(null); 	
	setFilterByProperty(null);

	// Reset filters
	// ...
	
  };
  
}, []); // end useEffect 

const clearOptions = () => {

	setHostsAndRooms(null); 
	setRooms([{ adults: 1, children: [] }]);	
	setDateRange(null); 
	setFormattedValue('');

	setFilterByCity(null); 
	setFilterByCountry(null); 

}

// Navigate to Method
const navigateTo = (target) => {
	navigate(target);
}

// currentlayer can be continent, country or city -- direction can be forward or backward -- targetId should be target id if forward
const navigateDeeper = (currentLayer,direction,targetId=null) => {

	setContentLoaded(false);
	setTimeout(function() {
		if (currentStep != 3 && (direction == 'forward' || direction == 'backward')) {
			setContentLoading(true);
		}
	},1000);
	
	if (currentLayer == "continent") {

		if (direction == "forward") {
			setCurrentPlaces(countriesEurope);
			setCurrentContinent({id:"1",label:"Europa"});

			let currentArray = [...breadcrumbArray]
			currentArray[0] = {
				label: 'Europa',
				template: () => <div className="breadcrumbSelectedLabel">Europa</div>
			};
			currentArray[1] = {
				label: 'Selecione o país',
				template: () => <div className="breadcrumbCurrentLabel">Selecione o país</div>
			};			
			setBreadcrumbArray(currentArray);

			setCurrentStep(2);
		} else {
			setCurrentPlaces(continents);
			setCurrentContinent({});
			setCurrentCountry({}); 
			setCurrentCity({}); 
			setBreadcrumbArray([]); 			

			let currentArray = [];
			setBreadcrumbArray(currentArray);

			setCurrentStep(1);
		}

	} 
	else if (currentLayer == "country") {

		if (direction == "forward") {
			setCurrentPlaces(citiesPortugal);
			setCurrentCountry({id:"1",label:"Portugual"});

			let currentArray = [...breadcrumbArray]
			currentArray[1] = {
				label: 'Portugal',
				template: () => <div className="breadcrumbSelectedLabel">Portugal</div>
			};
			currentArray[2] = {
				label: 'Selecione a cidade',
				template: () => <div className="breadcrumbCurrentLabel">Selecione a cidade</div>
			};				
			setBreadcrumbArray(currentArray);

			setCurrentStep(3);
		} else {

			setCurrentPlaces(countriesEurope);
			setCurrentCountry({});

			let currentArray = [...breadcrumbArray]
			currentArray.splice(1, 2);
			currentArray[1] = {
				label: 'Selecione o país',
				template: () => <div className="breadcrumbCurrentLabel">Selecione o país</div>
			};				
			setBreadcrumbArray(currentArray);

			setCurrentStep(2);
		}		

	}
	else if (currentLayer == "city") {

		if (direction == "forward") {

			// setCurrentPlaces([]);
			setCurrentCity({id:"1",label:"Porto"});

			let currentArray = [...breadcrumbArray]
			currentArray[2] = {
				label: 'Porto',
				template: () => <div className="breadcrumbSelectedLabel">Porto</div>
			};
			currentArray[3] = {
				label: 'Selecione dados da estadia',
				template: () => <div className="breadcrumbCurrentLabel">Selecione dados da estadia</div>
			};				
			setBreadcrumbArray(currentArray);

			// add little delay for interface
			setTimeout(function() {
				setCurrentStep(4);
			},1000);			

		} else {
			
			setCurrentPlaces(citiesPortugal);
			setCurrentCity({});

			let currentArray = [...breadcrumbArray]
			currentArray.splice(2, 1);
			currentArray[2] = {
				label: 'Selecione a cidade',
				template: () => <div className="breadcrumbCurrentLabel">Selecione a cidade</div>
			};				
			setBreadcrumbArray(currentArray);

			setCurrentStep(3);
		}		

	}
	
	// Only turn loading on if not last step
		setTimeout(function() {
			setContentLoading(false);
			handleClickScroll('navigationSection');
				setTimeout(function() {
					setContentLoaded(true);
					
				},300);
		},2000);
	
}

const continents = [
	{
		"name": "Europa",
		"image": continentsEuropeImage,
		"type":"continent",
		"id":"1"
	  },	
	{
	  "name": "América do Sul",
	  "image": "https://images.unsplash.com/photo-1519085360753-af0119f7cbe7",
		"type":"continent",
		"id":"1"
	},
	{
	  "name": "América do Norte",
	  "image": "https://images.unsplash.com/photo-1498050108023-c5249f4df085",
		"type":"continent",
		"id":"1"
	},
	{
		"name": "América central",
		"image": "https://images.unsplash.com/photo-1571974712961-cdbf5cdb1134",
		"type":"continent",
		"id":"1"
	},	
	{
	  "name": "África",
	  "image": continentsAfricaImage,
		"type":"continent",
		"id":"1"
	},
	{
	  "name": "Ásia",
	  "image": "https://images.unsplash.com/photo-1534447677768-be436bb09401",
		"type":"continent",
		"id":"1"
	},
	{
	  "name": "Oceania",
	  "image": "https://images.unsplash.com/photo-1527549993586-dff825b37782",
		"type":"continent",
		"id":"1"
	}
];

const countriesEurope = [
	{
	  "name": "Alemanha",
	  "image": "https://images.unsplash.com/photo-1586099832444-9d82e8d63c26",
	  "type": "country",
		"id":"1"
	},
	{
	  "name": "França",
	  "image": "https://images.unsplash.com/photo-1522098543979-ffc7ad0ebf8d",
	  "type": "country",
		"id":"1"
	},
	{
	  "name": "Itália",
	  "image": "https://images.unsplash.com/photo-1508050919630-b135583b29af",
	  "type": "country",
		"id":"1"
	},
	{
	  "name": "Espanha",
	  "image": "https://images.unsplash.com/photo-1517336714731-489689fd1ca8",
	  "type": "country",
		"id":"1"
	},
	{
	  "name": "Reino Unido",
	  "image": "https://images.unsplash.com/photo-1558908017-3c3e30c9fc57",
	  "type": "country",
		"id":"1"
	},
	{
	  "name": "Portugal",
	  "image": "https://images.unsplash.com/photo-1507699622108-4be3abd695ad",
	  "type": "country",
		"id":"1"
	},
	{
	  "name": "Países Baixos",
	  "image": "https://images.unsplash.com/photo-1552323446-004496791169",
	  "type": "country",
		"id":"1"
	},
	{
	  "name": "Suíça",
	  "image": "https://images.unsplash.com/photo-1558470557-3970d48734ab",
	  "type": "country",
		"id":"1"
	},
	{
	  "name": "Suécia",
	  "image": "https://images.unsplash.com/photo-1518733057094-95b53130d57c",
	  "type": "country",
		"id":"1"
	},
	{
	  "name": "Grécia",
	  "image": "https://images.unsplash.com/photo-1501455076662-830cb88b4c30",
	  "type": "country",
		"id":"1"
	},
	{
	  "name": "Noruega",
	  "image": "https://images.unsplash.com/photo-1506748686214-e9df14d4d9d0",
	  "type": "country",
		"id":"1"
	},
	{
	  "name": "Dinamarca",
	  "image": "https://images.unsplash.com/photo-1562157977-ed9a78161d42",
	  "type": "country",
		"id":"1"
	},
	{
	  "name": "Áustria",
	  "image": "https://images.unsplash.com/photo-1509205477833-cd621b8ac4d1",
	  "type": "country",
		"id":"1"
	},
	{
	  "name": "Bélgica",
	  "image": "https://images.unsplash.com/photo-1519817914152-22f33d30b4dc",
	  "type": "country",
		"id":"1"
	},
	{
	  "name": "Finlândia",
	  "image": "https://images.unsplash.com/photo-1544185310-0f25e5b23839",
	  "type": "country",
		"id":"1"
	}
  ];

const citiesPortugal = [
{
	"name": "Lisboa",
	"image": "https://images.unsplash.com/photo-1587135566474-3921a4e9ae23",
	"type": "city",
	"id":"1"
},
{
	"name": "Porto",
	"image": "https://images.unsplash.com/photo-1520186994231-1eaab45a74ca",
	"type": "city",
	"id":"1"
},
{
	"name": "Coimbra",
	"image": "https://images.unsplash.com/photo-1605455673151-4fd8d3a8e7d5",
	"type": "city",
	"id":"1"
},
{
	"name": "Braga",
	"image": "https://images.unsplash.com/photo-1601901390678-09f1e6bdde78",
	"type": "city",
	"id":"1"
},
{
	"name": "Faro",
	"image": "https://images.unsplash.com/photo-1602457276972-d3cb0c463b32",
	"type": "city",
	"id":"1"
},
{
	"name": "Sintra",
	"image": "https://images.unsplash.com/photo-1601911815447-449741230c59",
	"type": "city",
	"id":"1"
},
{
	"name": "Évora",
	"image": "https://images.unsplash.com/photo-1593717485254-5f84b76b4f42",
	"type": "city",
	"id":"1"
},
{
	"name": "Aveiro",
	"image": "https://images.unsplash.com/photo-1601487606791-bc9d70c55e30",
	"type": "city",
	"id":"1"
},
{
	"name": "Guimarães",
	"image": "https://images.unsplash.com/photo-1598464223013-26f9e5b8f440",
	"type": "city",
	"id":"1"
},
{
	"name": "Funchal",
	"image": "https://images.unsplash.com/photo-1600547495408-f0ee8b5b4cf5",
	"type": "city",
	"id":"1"
}
];
  
const breadcrumbItems = [...breadcrumbArray];
 
let searchTimer;
const handleTimeout = (action='start') => {

	if (action == 'cancel') {
		clearTimeout(searchTimer);
	} else {

		searchTimer = setTimeout(function() {

			setAlertTitle("Tempo esgotado na busca");
			setAlertBody("Infelizmente o tempo máximo da busca foi atingido. Por favor tente novamente.");
			setAlert(true);	
			return;

		},59000);

	}

};


// Search properties method
const executeSearch = () => {

	// IF searchType is Location, Check if COUNTRY is selected
	if (searchOption == 'locality') {

		if (!filterByCountry) {
			setAlertTitle("Verifique as informações");
			setAlertBody("Para prosseguir com a pesquisa é necessário informar ao menos o país.");
			setAlert(true);	
			return;		
		}

	} 
	// IF searchType is property, Check if PROPERTY is selected
	else  {
		
		if (!filterByProperty) {
			setAlertTitle("Verifique as informações");
			setAlertBody("Para prosseguir com a pesquisa é necessário informar a propriedade desejada.");
			setAlert(true);	
			return;		
		}

	}

	let countryProperties = filterByCountry.propertiesQuantity;
	if (countryProperties > 100 && !filterByCity) {
		setAlertTitle("Selecione uma cidade");
		setAlertBody("O país selecionado tem muitas propriedades. Para prosseguir com a pesquisa é necessário informar uma cidade no país selecionado.");
		setAlert(true);	
		return;		
	}	
	// Check if Range date is selected
	if (!dateRange) {
		setAlertTitle("Verifique as informações");
		setAlertBody("Para prosseguir com a pesquisa é necessário informar a data de chegada e de saída da estadia.");
		setAlert(true);	
		return;		
	}	

	// Check integrity of dates (AVOID HACKING)
	const firstDate = moment(dateRange[0]).startOf('hour'); // Normalizando a comparação para início da hora
	const secondDate = moment(dateRange[1]).startOf('hour');
	const nowPlus72Hours = moment().add(72, 'hours').startOf('hour'); // Normalizando para início da hora
	const minDateString = moment(nowPlus72Hours).format("DD/MM/YYYY");
	
	// Verificar se a primeira data é igual a 72 horas a partir de agora
	if (!firstDate.isSameOrAfter(nowPlus72Hours)) {
		setAlertTitle("Verifique as informações");
		setAlertBody(`A data de chegada é inferior a data mínima: ${minDateString} `);
		setAlert(true);	
		return;	
	} 
	
	// Verificar se a segunda data é maior que a primeira
	if (!secondDate.isAfter(firstDate)) {
		setAlertTitle("Verifique as informações");
		setAlertBody("A data de saíd anão pode ser inferior a data de chegada");
		setAlert(true);	
		return;	
	} 

	// Check if Kids age was selected
	for (let i = 0; i < rooms.length; i++) {
		for (let j = 0; j < rooms[i].children.length; j++) {
			if (rooms[i].children[j].age === "") {
	
				setAlertTitle("Ops! Defina a idade da(s) criança(s)");
				setAlertBody("Para prosseguir é necessário que você defina as idades das crianças que foram adicionadas aos quartos. Abra o seletor de quartos e informe a(s) idade(s).");
				setAlert(true);	
				return false;
			}
		}
	}

	// IF location navigation, check if at least country and city are selected
	// ...[TO-DO]	

	// Set loading ON and timer ON
	setLoadingResults(true);
	handleTimeout();	

		// Prepare props and data
		var props = {
			setAlertTitle:setAlertTitle,
			setAlertBody:setAlertBody,
			setAlert:setAlert,
			navigate:navigate,
			setLoadingResults:setLoadingResults,
			handleTimeout:handleTimeout,
			setSearchResToken:setSearchResToken,
			setCounterStatus:setCounterStatus
		}
		var data = {
			searchOption:searchOption,
			country:filterByCountry,
			city:filterByCity,
			dateRange:dateRange,
			dateRangeDays:dateRangeDays,
			property:filterByProperty,
			rooms:rooms
		}		
		// if searchType is location
		if (searchOption == 'locality') {
			processLocalitySearch(data,props);
		} 
		// if searchType is property
		else {
			processPropertySearch(data,props);
		}

}


  return (
	<>
		{loadingResults &&
		<>
		<div className="wrapper" style={{backgroundColor:"#00000066",zIndex:"999999",position:"fixed",left:0,top:0,display:"flex",justifyContent:"center",alignItems:"center",width:"100%",height:"100vh"}}>

				<div style={{backgroundColor:"#FFFFFF",display:"flex",justifyContent:"center",alignItems:"center",width:"50%",height:"45vh",borderRadius:"60px",padding:"30px"}}>

					<div style={{flex:1,display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",width:"30vw"}}>
						<ProgressSpinner />
						<div className="formTitle" style={{marginBottom:"15px",marginTop:"15px"}}>Procurando hotéis...Aguarde! </div>
						<div className="formSubTitle">Na DREAM4 você encontra os melhores quartos em hotéis 4 estrelas...</div>		

						<div style={{marginTop:"30px",display:"flex",flexDirectin:"row",justifyContent:"center",alignItems:"cnter",gap:"15px"}}>
							
							<div style={{display:"flex",justifyContent:"center",alignItems:"center"}}><div style={{flex:1}}><Countdown stop={counterStatus} startTime={120} onTimeout={handleTimeout} /></div></div>
							
							{/*showCancelButton &&
								<Button label="Cancelar busca" outlined rounded className="pT10 mR10 headerButton" style={{float:"unset"}} onClick={() => {setLoadingResults(false);handleTimeout('cancelar')}}  />
							*/}

							</div>	
					</div>

				</div>

		</div>
		</>
		}

	<div id="loginSection" style={{marginTop:"0",paddingTop:"0px",paddingBottom:"0px",position:"relative"}} className={isTabletOrMobile ? "login__wrapper mobile" : "login__wrapper"} >

		{/* Add layer above selections if master and did not select agency */}
		{!masterUser && !currentAgency.agencyCode &&
		<>
		<div style={{position:"absolute",top:0,left:0,height:"100%",width:"100%",backgroundColor:"rgb(255,255,255,0.90)",zIndex:9,borderRadius:"10px",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",gap:"10px", borderRadius:"30px"}}>
			<div>
				<svg fill="#814DE5" height="85px" width="85px" version="1.1" viewBox="0 0 24 24">
					<g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
					<g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
					<g id="SVGRepo_iconCarrier">
						<g id="select">
							<path d="M14.8,24l-3.3-4.3l-3.2,4.2L5.8,6.9l16,7.2L16.4,16l3.2,4.3L14.8,24z M11.6,16.4l3.6,4.8l1.6-1.3L13.1,15l3.3-1.1l-8.1-3.6 l1.3,8.7L11.6,16.4z"></path>
							<path d="M4,18H0v-4h2v2h2V18z M2,12H0V6h2V12z M18,10h-2V6h2V10z M18,4h-2V2h-2V0h4V4z M2,4H0V0h4v2H2V4z M12,2H6V0h6V2z"></path>
						</g>
					</g>
				</svg>
			</div>
			<div style={{fontSize:"22px",textAlign:"center",letterSpacing:"0.1em",lineHeight:"120%",fontWeight:"600",fontStyle:"normal",fontFamily:"Titillium Web",color:"#814DE5"}}> Selecione uma agência <br /> para prosseguir com a busca </div>
		</div>
		</>
		}

		<div style={{minHeight:"unset",height:"unset"}} className={isTabletOrMobile ? "login__wrapper_form_wrapper mobile" : "login__wrapper_form_wrapper"}> 		
			<div className={isTabletOrMobile ? "login__form_wrapper mobile" : "login__form_wrapper"} style={{alignItems:"flex-start"}}>
						
				<div style={{flex:1,minHeight:"220px",marginLeft:"auto",marginRight:"auto"}}>
					
					<div className="searchWrapper" style={{padding: "3rem",display: "flex",flexDirection: "column",justifyContent: "center",alignItems: "flex-start",gap: "1rem",width: "100%"}}> 


						<div lassName="searchWrapperTitle" style={{color: "#1D2939",fontSize: "2rem",fontStyle: "normal",fontWeight: 700,lineHeight: "2.12938rem",fontFamily:"Poppins",flex:"1"}} className={loading ? "selectBoxTitle" : "selectBoxTitle animate"}>Onde e como será a estadia?</div>

						<div lassName="searchWrapperDescription" style={{color: "#475467",fontSize: "1.125rem",fontStyle: "normal",fontWeight: 400,lineHeight: "160.5%",LetterSpacing: "0.1125rem",fontFamily:"Titillium Web",flex:"1",textAlign:"left"}} className={loading ? "selectBoxTitle" : "selectBoxTitle animate"}>Informe os dados da hospedagem para pesquisa de opções disponíveis. </div>

						<div className="searchWrapperInput" style={{flex:"1",width:"100%",marginTop:"20px"}}>

							<div style={{width:"100%",maxWidth:"1440px",display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"flex-start",marginBottom:"30px"}}>
								<SelectButton value={searchOption} onChange={(e) => {setSearchMethod(e.value)}} options={options} optionLabel="label" optionValue="value" unselectable={false} allowEmpty={false}  />
							</div>

							<div container style={{width:"100%",maxWidth:"1440px",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",gap:"10px"}}>
								
								<div style={{flex:searchOption != 'locality' ? "1.5" : "1"}}>
								{searchOption == 'locality' && (<span className="p-float-label" style={{position:"relative"}}>
										{/*<Dropdown showClear value={filterByCountry} onChange={(e) => setFilterByCountry(e.target.value)} options={[{"code":1,name:"Brazil"},{"code":3,name:"Portugal"},{"code":9,name:"Espanha"}]} optionLabel="name" placeholder="Selecione o Status" className="w100" style={{textAlign:"left"}}></Dropdown>*/}
										<AutoComplete forceSelection={true} showClear={true} showEmptyMessage={true} delay={500} minLength={3} placeholder="Digite ao menos 3 caracteres" className="w100 searchInput" style={{textAlign:"left"}} field="name" value={filterByCountry} suggestions={countriesList} completeMethod={searchCountries} onChange={(e) => {setFilterByCountry(e.value);setFilterByCity(null)}}  dropdown />
										<label htmlFor="companyLegalName">País<span className="requiredField">*</span></label>
										{filterByCountry && (
											<Button
											icon="pi pi-times"
											className="p-button-rounded p-button-text"
											onClick={()=>{setFilterByCountry(null);setFilterByCity(null)}}
											style={{
												position: 'absolute',
												top: '50%',
												right: '50px',
												transform: 'translateY(-50%)',
												zIndex: 1,
												padding: '0',
												width: '24px',
												height: '24px',
											}}
											aria-label="Clear"
											/>
										)}
									</span>)
								}

								{searchOption != 'locality' && (<span className="p-float-label" style={{position:"relative"}}>
										{/*<Dropdown showClear value={filterByProperty} onChange={(e) => setFilterByProperty(e.target.value)} options={[{"code":1,name:"Clube Med Rio das pedras"},{"code":3,name:"Portugal"},{"code":9,name:"Espanha"}]} optionLabel="name" placeholder="Selecione o Status" className="w100" style={{textAlign:"left"}}></Dropdown>*/}										
										<AutoComplete forceSelection={true} showClear={true} showEmptyMessage={true} delay={1000} minLength={4} placeholder="Digite ao menos 4 caracteres" className="w100 searchInput" style={{textAlign:"left"}} field="name" value={filterByProperty} suggestions={propertiesList} completeMethod={searchProperties} onChange={(e) => setFilterByProperty(e.value)}  />
										<label htmlFor="companyLegalName">Hotel (Exemplo: Nome do hotel, cidade e/ou país)<span className="requiredField">*</span></label>
										{filterByProperty && (
											<Button
											icon="pi pi-times"
											className="p-button-rounded p-button-text"
											onClick={()=>setFilterByProperty(null)}
											style={{
												position: 'absolute',
												top: '50%',
												right: '10px',
												transform: 'translateY(-50%)',
												zIndex: 1,
												padding: '0',
												width: '24px',
												height: '24px',
											}}
											aria-label="Clear"
											/>
										)}

									</span>)
								}								
								</div>
								
								{searchOption == 'locality' && (<div style={{flex:"1"}}>
									<div className="p-float-label" style={{position:"relative"}}>
										{/*<Dropdown showClear value={filterByCity} onChange={(e) => setFilterByCity(e.target.value)} options={[{"code":1,name:"Rio de Janeiro"},{"code":3,name:"São Paulo"},{"code":9,name:"Porto Alegre"}]} optionLabel="name" placeholder="Selecione o Status" className="w100" style={{textAlign:"left"}}></Dropdown>*/}										
										<AutoComplete forceSelection={true} showClear={true} showEmptyMessage={true} delay={500} minLength={3} placeholder="Digite ao menos 3 caracteres" className="w100 searchInput" style={{textAlign:"left",flex:1}} field="name" value={filterByCity} suggestions={citiesList} completeMethod={searchCities} onChange={(e) => setFilterByCity(e.value)} onSelect={(e) => processSelectedCity(e)} dropdown={filterByCountry ? true : false} />
										<label htmlFor="companyFantasyName">Cidade</label>
										{filterByCity && (
											<Button
											icon="pi pi-times"
											className="p-button-rounded p-button-text"
											onClick={()=>setFilterByCity(null)}
											style={{
												position: 'absolute',
												top: '50%',
												right: filterByCountry ? '50px' : '10px',
												transform: 'translateY(-50%)',
												zIndex: 1,
												padding: '0',
												width: '24px',
												height: '24px',
											}}
											aria-label="Clear"
											/>
										)}

									</div>
								</div>)}

								<div style={{flex:"1.2"}}>
									<span className="p-float-label">
										<Calendar value={dateRange}  minDate={new Date(today.setHours(today.getHours() + 72))}  onChange={(e) => setDateRange(e.value)}  locale="br"  id="rangeDate" style={{width:"100%"}} inputStyle={{width:"100%"}} showIcon dateFormat="dd/mm/yy" selectionMode="range" readOnlyInput hideOnRangeSelection numberOfMonths={2} footerTemplate={customFooter} ref={calendarRef} />
										<label htmlFor="companyEmail">Data de chegada e de saída<span className="requiredField">*</span></label>

										{/* Rende input oiver orioginal to show number of nights */}
										{dateRange &&
										<input 
											type="text" 
											value={formattedValue} 
											readOnly 
											style={{ position: "absolute",top: "1px",left: "4px",height: "95%",width: "86%",border: "none",background: "transparent",pointerEvents: "none",paddingLeft: "1rem",fontSize: "1rem",color: "#495057",padding: ".75rem .75rem",appearance: "none",borderRadius: "6px",backgroundColor: "#FFF", fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'"}} 
										/>
										}

									</span>
								</div>
								<div style={{flex:"1.2"}}>
									<span className="p-float-label">
										<InputText id="hosts" autoComplete="new-password" disabled={saving} className="w100" value={hostsAndRooms} onChange={(e) => setHostsAndRooms(e.target.value)} readOnly onClick={(e) => overlayPanelRef.current.toggle(e)}  />

											<OverlayPanel ref={overlayPanelRef}  dismissable={true} showCloseIcon onHide={() => handleCheckSelectedOptions(rooms)}  style={{maxWidth:"18vw",width:"18vw",maxHeight:"65vh"}} >
												<RoomSelector onSelection={handleRoomSelection} setRooms={setRooms} initialRooms={rooms} updateInputValue={handleUpdateInputValue} overlay={true} />
											</OverlayPanel>										
										
										{/*hostsAndRooms && 
										<Button 
											icon="pi pi-times" 
											className="p-button-text" 
											onClick={handleClearSelection} 
											style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }} 
										/>
										*/}

										<label htmlFor="companyPhone">Hospedes e quartos <span className="requiredField">*</span></label>
									</span>

								</div>

								<div style={{flex:"0.5"}}>
									<Button label="Pesquisar" rounded className="pT10 mR10 headerButton tColorWhite w100" onClick={()=>{executeSearch()}} />
								</div>							
								
							</div>
							
							
						</div>												  
							
							  

	
					</div>				
				
				</div>

			</div> 
		</div>
    </div>


	<div id="navigationSection" style={{marginTop:"0",paddingTop:"0px",paddingBottom:"0px",position:"relative"}} className={isTabletOrMobile ? "login__wrapper mobile" : "login__wrapper"} >


			{/* Add layer above selections if master and did not select agency */}
			{!masterUser && !currentAgency.agencyCode &&
			<>
			<div style={{position:"absolute",top:0,left:0,height:"100%",width:"100%",backgroundColor:"rgb(234 241 255 / 72%)",zIndex:9,borderRadius:"10px",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",gap:"10px", borderRadius:"30px"}}>
				<div>
					<svg fill="#814DE5" height="85px" width="85px" version="1.1" viewBox="0 0 24 24">
						<g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
						<g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
						<g id="SVGRepo_iconCarrier">
							<g id="select">
								<path d="M14.8,24l-3.3-4.3l-3.2,4.2L5.8,6.9l16,7.2L16.4,16l3.2,4.3L14.8,24z M11.6,16.4l3.6,4.8l1.6-1.3L13.1,15l3.3-1.1l-8.1-3.6 l1.3,8.7L11.6,16.4z"></path>
								<path d="M4,18H0v-4h2v2h2V18z M2,12H0V6h2V12z M18,10h-2V6h2V10z M18,4h-2V2h-2V0h4V4z M2,4H0V0h4v2H2V4z M12,2H6V0h6V2z"></path>
							</g>
						</g>
					</svg>
				</div>
				<div style={{fontSize:"22px",textAlign:"center",letterSpacing:"0.1em",lineHeight:"120%",fontWeight:"600",fontStyle:"normal",fontFamily:"Titillium Web",color:"#814DE5"}}> Selecione uma agência <br /> para prosseguir com a navegação </div>
			</div>
			</>
			}

			<div style={{minHeight:"600px",height:"auto"}} className={isTabletOrMobile ? "login__wrapper_form_wrapper mobile" : "login__wrapper_form_wrapper"}> 		
				<div className={isTabletOrMobile ? "login__form_wrapper mobile" : "login__form_wrapper"} style={{alignItems:"flex-start"}}>
							
					<div style={{flex:1,minHeight:"594px",marginLeft:"auto",marginRight:"auto"}}>
						
					<div className="searchWrapper" style={{padding: "3rem",display: "flex",flexDirection: "column",justifyContent: "center",alignItems: "flex-start",gap: "1rem",width: "100%"}}> 
		
						{/* Add layer above selections if master and did not select agency */}
						{!masterUser && !currentAgency.agencyCode &&
						<>
						<div style={{position:"absolute",top:0,left:0,height:"100%",width:"100%",backgroundColor:"rgb(255,255,255,0.92)",zIndex:9,borderRadius:"10px",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",gap:"10px"}}>
							<div>
								<svg fill="#814DE5" height="85px" width="85px" version="1.1" viewBox="0 0 24 24">
									<g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
									<g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
									<g id="SVGRepo_iconCarrier">
										<g id="select">
											<path d="M14.8,24l-3.3-4.3l-3.2,4.2L5.8,6.9l16,7.2L16.4,16l3.2,4.3L14.8,24z M11.6,16.4l3.6,4.8l1.6-1.3L13.1,15l3.3-1.1l-8.1-3.6 l1.3,8.7L11.6,16.4z"></path>
											<path d="M4,18H0v-4h2v2h2V18z M2,12H0V6h2V12z M18,10h-2V6h2V10z M18,4h-2V2h-2V0h4V4z M2,4H0V0h4v2H2V4z M12,2H6V0h6V2z"></path>
										</g>
									</g>
								</svg>
							</div>
							<div style={{fontSize:"22px",textAlign:"center",letterSpacing:"0.1em",lineHeight:"120%",fontWeight:"600",fontStyle:"normal",fontFamily:"Titillium Web",color:"#814DE5"}}> Selecione uma agência <br /> para prosseguir </div>
						</div>
						</>
						}


						<div lassName="searchWrapperTitle" style={{marginBottom:"30px",color: "#1D2939",fontSize: "2rem",fontStyle: "normal",fontWeight: 700,lineHeight: "2.12938rem",fontFamily:"Poppins",flex:"1"}} className={loading ? "selectBoxTitle" : "selectBoxTitle animate"}>Navegue pelas opções de estadia</div>

						{currentStep > 1 &&
							<BreadCrumb model={breadcrumbItems} />
						}

						<div className="searchWrapperInput" style={{maxWidth:"1440px",width:"100%",flex:"1",marginTop:"30px",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"flex-start"}}>
							
							<div className="navigationBreadcrumb" style={{width:"100%",flex:1,display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center",gap:"1rem"}}>

									<div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
										<Button className={currentStep == 1 ? "navigationButton current" : "navigationButton previous"} type="button" icon={continentIcon} onClick={currentStep > 1 ? () => navigateDeeper('continent','backward') : null} style={{fontWeight:"bold",borderRadius:"16px"}}>
											Continentes
										</Button>

									</div>
									<div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
											<path d="M1.41016 12.2539L21.6184 12.2539M21.6184 12.2539L13.9675 19.7656M21.6184 12.2539L13.9675 4.74211" stroke="#814DE5" stroke-width="2"/>
										</svg>
									</div>	

									<div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
										<Button className={currentStep == 2 ? "navigationButton current" : currentStep < 2 ? "navigationButton next" : "navigationButton previous"} type="button" outlined icon={currentStep >= 2 ? countryIcon('#FFF') : countryIcon('#814DE5')} onClick={currentStep > 2 ? () => navigateDeeper('country','backward') : null} style={{fontWeight:"bold",borderRadius:"16px"}}>
											Países
										</Button>
									</div>
									<div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
											<path d="M1.41016 12.2539L21.6184 12.2539M21.6184 12.2539L13.9675 19.7656M21.6184 12.2539L13.9675 4.74211" stroke="#814DE5" stroke-width="2"/>
										</svg>
									</div>		

									<div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
										<Button className={currentStep == 3 ? "navigationButton current" : currentStep < 3 ? "navigationButton next" : "navigationButton previous"} type="button" outlined icon={currentStep >= 3 ? countryIcon('#FFF') : countryIcon('#814DE5')} onClick={currentStep > 3 ? () => navigateDeeper('city','backward') : null} style={{fontWeight:"bold",borderRadius:"16px"}}>
											Cidades
										</Button>
									</div>
									<div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
											<path d="M1.41016 12.2539L21.6184 12.2539M21.6184 12.2539L13.9675 19.7656M21.6184 12.2539L13.9675 4.74211" stroke="#814DE5" stroke-width="2"/>
										</svg>
									</div>		

									<div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
										<Button className={currentStep == 4 ? "navigationButton current" : currentStep < 4 ? "navigationButton next" : "navigationButton previous"} type="button" outlined icon={currentStep >= 4 ? countryIcon('#FFF') : countryIcon('#814DE5')} style={{fontWeight:"bold",borderRadius:"16px",textWrap:"nowrap"}}>
											Dados da estadia
										</Button>
									</div>
									
							</div>
							
						</div>

						<div classname="navigationContentWrapper" style={{marginTop:"3rem",width:"100%",flex:1,display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center",gap:"34px",flexWrap:"wrap"}}>

						{/* LOADING ACTION  */}
						{contentLoading &&
							<>						
								<Skeleton width="275px" height="165px" style={{width: "275px",height: "165px",borderRadius: "1.5rem"}} ></Skeleton>
								<Skeleton width="275px" height="165px" style={{width: "275px",height: "165px",borderRadius: "1.5rem"}} ></Skeleton>
								<Skeleton width="275px" height="165px" style={{width: "275px",height: "165px",borderRadius: "1.5rem"}} ></Skeleton>
								<Skeleton width="275px" height="165px" style={{width: "275px",height: "165px",borderRadius: "1.5rem"}} ></Skeleton>
								<Skeleton width="275px" height="165px" style={{width: "275px",height: "165px",borderRadius: "1.5rem"}} ></Skeleton>
								<Skeleton width="275px" height="165px" style={{width: "275px",height: "165px",borderRadius: "1.5rem"}} ></Skeleton>
								<Skeleton width="275px" height="165px" style={{width: "275px",height: "165px",borderRadius: "1.5rem"}} ></Skeleton>
							</>				
						}
					
						{(!contentLoading && currentStep < 4) &&
						<>

						{currentPlaces.map((item)=> {

							let image = `https://picsum.photos/400/262?random=${Math.random()}`;

							return(
							<div className={contentLoaded ? "navigationItem animate flex-column justify-center align-items-center applyPointer" : "navigationItem  flex-column justify-center align-items-center applyPointer"} style={{gap:"1rem",marginBottom:"16px"}} onClick={()=>navigateDeeper(item.type,'forward',item.id)}>
								<div style={{width: "275px",height: "165px",borderRadius: "1.5rem",overflow:"hidden",display:"flex",alignIitems:"center",justifyContent:"center"}}>
									<img src={image} style={{flex:1,width:"275px"}} />
								</div>

								<div className="flex-row justify-center align-items-center w90" style={{marginLeft:"auto",marginRight:"auto"}}>
									<div style={{flex:1.5,color: "#1D2939",fontFamily: "Poppins",fontSize: "16px",fontStyle: "normal",fontWeight: 600,lineHeight: "34.07px",textAlign:"left"}}>{item.name}</div>
									<div style={{flex:1,textAlign:"right"}}>
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
												<path d="M1.41016 12.2539L21.6184 12.2539M21.6184 12.2539L13.9675 19.7656M21.6184 12.2539L13.9675 4.74211" stroke="#814DE5" stroke-width="2"/>
											</svg>									
									</div>
								</div>

							</div>
							);

						})}

						</>
						}


						{(!contentLoading && currentStep == 4) &&
						<>
							<div className="flex-row justify-center items-align-center" style={{gap:"20px",width:"100%",maxWidth:"1440px"}}>

								<div style={{flex:1,display:"flex",flexDirection:"column",gap:"10px"}}>
									
									<div style={{color: "#1D2939",fontFamily: "Poppins",fontSize: "16px",fontStyle: "normal",fontWeight: 400,lineHeight: "34.07px"}}>Data de chegada e de saída<span className="requiredField">*</span></div>

									<div style={{color: "#814DE5",fontFamily: "Poppins",fontSize: "16px",fontStyle: "normal",fontWeight: 400,lineHeight: "34.07px"}}>{formattedValue ? formattedValue : "Selecione a data de chegada e de saída"}</div>

									<Calendar value={dateRange} inline  minDate={new Date()}  onChange={(e) => setDateRange(e.value)}  locale="br"  id="rangeDate" style={{width:"100%"}} inputStyle={{width:"100%"}} showIcon dateFormat="dd/mm/yy" selectionMode="range" readOnlyInput hideOnRangeSelection numberOfMonths={2} footerTemplate={customFooterInline}  />
									
																
								</div>
								
								<div style={{flex:0.3,display:"flex",flexDirection:"column",gap:"10px"}}>

									<div style={{color: "#1D2939",fontFamily: "Poppins",fontSize: "16px",fontStyle: "normal",fontWeight: 400,lineHeight: "34.07px"}}>Hospedes e quartos <span className="requiredField">*</span></div>

									<div style={{color: "#814DE5",fontFamily: "Poppins",fontSize: "16px",fontStyle: "normal",fontWeight: 400,lineHeight: "34.07px"}}>{hostsAndRooms}</div>
									
									<RoomSelector onSelection={handleRoomSelection} setRooms={setRooms} initialRooms={rooms} updateInputValue={handleUpdateInputValue} overlay={false} />		

								</div>

							</div>	

							<div className="w100" style={{textalign:"center"}}>

								{currentStep == 4 &&
								<>
									<Button label="Limpar seleções" outlined rounded className="pT10 mR10 headerButton" style={{float:"unset"}} onClick={() => {navigateDeeper('continent','backward');clearOptions()}}  />
								</>
								}

								<Button label="Ver opções de hotéis" rounded className="pT10 mR10 headerButton tColorWhite" style={{float:"unset"}} onClick={()=>{setLoadingResults(true);handleTimeout()}} />

							</div>							
																			

						</>
						}


						</div>

					</div>				
					
					</div>

				</div> 
			</div>

    </div>	

		<AlertView
			alert={alert}
			setAlert={setAlert}
			alertTitle={alertTitle}
			alertBody={alertBody}	
			scrollBlock={false}				
		/>

		{/* Contac Us Section */}
		<ContactUs />

		{/* About Us Section */}
		<AboutUs />
	
	</>
  );
    
}

const mapStateToProps = store => ({
  dialog:store.appReducer.dialog,
  masterUser:store.appReducer.masterUser,
  currentAgency:store.appReducer.currentAgency,
  searchType:store.orderReducer.searchType,
  searchParams:store.orderReducer.searchParams,
  searchResultStatus:store.orderReducer.searchResultStatus,
  searchResultRowsNumber:store.orderReducer.searchResultRowsNumber,
  searchResultToken:store.orderReducer.searchResultToken,
  searchResultCurrentPage:store.orderReducer.searchResultCurrentPage,
  searchResultLimit:store.orderReducer.searchResultLimit,
  searchLocation:store.orderReducer.searchLocation,
  searchResultObj:store.orderReducer.searchResultObj  
 });
const mapDispatchToProps = dispatch => bindActionCreators({ ...AppActions,...OrderActions }, dispatch);

export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(HomePage);	


