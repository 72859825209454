import '../../Assets/Styles/home.css';
import '../../Assets/Styles/order.css';
import React from "react";
import { useMediaQuery } from 'react-responsive'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {Link,useNavigate} from 'react-router-dom';

import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button'; 
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { OverlayPanel } from 'primereact/overlaypanel';
import { MultiSelect } from 'primereact/multiselect';
import { Card } from 'primereact/card';
import { Chart } from 'primereact/chart';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Grid from '@mui/material/Grid';

import moment from "moment";

import multiTransportConcept from '../../Assets/Images/multiTransportConcept11.png'; 
import desktopConcept from '../../Assets/Images/desktopConcept.png'; 
import contactConcept from '../../Assets/Images/contactConcept.png'; 
import formPlaceholder from '../../Assets/Images/formPlaceholder.png'; 
import formTopIcons from '../../Assets/Images/formTopIcons.svg'; 
import cornerTop from '../../Assets/Images/cornerTop.svg';
import cornerBottom from '../../Assets/Images/cornerBottom.svg';

import contactWhatsappIcon from '../../Assets/Images/contactWhatsappIcon.svg';
import contactEmailIcon from '../../Assets/Images/contactEmailIcon.svg';
import contactPhoneIcon from '../../Assets/Images/contactPhoneIcon.svg';
import transportPicture from '../../Assets/Images/transportPicture.svg';
import rentcarPicture from '../../Assets/Images/rentcarPicture.svg';
import transferPicture from '../../Assets/Images/transferPicture.svg';
import transportIcon from '../../Assets/Images/transportIcons.svg';
import transferIcon from '../../Assets/Images/transferIcon.svg';
import rentcarIcon from '../../Assets/Images/rentcarIcon.svg';

import titleTrainIcon from '../../Assets/Images/titleTrainIcon.svg';
import titleBusIcon from '../../Assets/Images/titleBusIcon.svg';
import titleTransferIcon from '../../Assets/Images/titleBusIcon.svg';
import titleRentCarIcon from '../../Assets/Images/titleBusIcon.svg';

// Load Actions
import * as AppActions from '../../Store/Actions/App'
import * as UserActions from '../../Store/Actions/User'

import AboutUs from '../../Components/layout/AboutUs';
import ContactUs from '../../Components/layout/ContactUs';

// Load Componentes
import SideMenu from '../../Components/SideMenu';
import MessageView from '../../Components/MessageModal';
import AlertView from '../../Components/AlertModal';
import UserView from './UserModal';

import {
	validarCNPJ,
	validarCPF,
	validarEmail,
	validarTelefone,
	validarNome,
	maskCNPJ,
	maskCPF,
	maskPhone
} from '../../Components/Utils';

import {
	Callto,
	Whatsapp,

} from '../../Components/Utils';

// export default function Reservation() {
const Users = (props) => {
	
// Set var from props
const {
	setDialog,
	dialog,
	setUser,
	selectedUser,
	retrieveUsers,
	masterUser
} = props;

// Set Overlay obj
const op = React.useRef(null);

const [loading, setLoading] = React.useState(true); 
const [saving, setSaving] = React.useState(false); 
	
const isDesktopOrLaptop = useMediaQuery({
query: '(min-width: 1024px)'
})
const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1023px)' })
const isSpecialDesktop = useMediaQuery({ query: '(max-width: 1500px)' })

const [stillConnected, setStillConnected] = React.useState(false);
const onStillConnectedChange = (e) => {
	if (e.checked)
		setStillConnected(true);
	else
		setStillConnected(false);
}  // end function
	
// Alert control
const [alert, setAlert] = React.useState(false); 
const [alertTitle, setAlertTitle] = React.useState("Informações"); 
const [alertBody, setAlertBody] = React.useState("Conteúdo dinâmico"); 

// Basic filter fields  and controls
const [basicFilterStatus, setBasicFilterStatus] = React.useState(false);
const [basicFilter, setBasicFilter] = React.useState({});
const [filterValue, setFilterValue] = React.useState("");

// Advanced filter fields and controls
const [advancedFilterStatus, setAdvancedFilterStatus] = React.useState(false);
const [advancedFilter, setAdvancedFilter] = React.useState({});
const [advancedSearch, setAdvancedSearch] = React.useState(false);

// CUSTOM ADVANCED FILTER FIELDS
const [filterByReservationId, setFilterByReservationId] = React.useState("");
const [filterByAgencyName, setFilterByAgencyName] = React.useState("");
const [filterByAgencyDocument, setFilterByAgencyDocument] = React.useState("");
const [filterByCustomerName, setFilterByCustomerName] = React.useState("");
const [filterByCustomerDocument, setFilterByCustomerDocument] = React.useState("");
const [filterByUserName, setFilterByUserName] = React.useState("");
const [filterByTransportType, setFilterByTransportType] = React.useState([]);
const [filterByCreateAtFrom, setFilterByCreateAtFrom] = React.useState("");
const [filterByCreateAtTo, setFilterByCreateAtTo] = React.useState("");
const [filterByServiceAtFrom, setFilterByServiceAtFrom] = React.useState("");
const [filterByServiceAtTo, setFilterByServiceAtTo] = React.useState("");
const [filterByPaymentMethod, setFilterByPaymentMethod] = React.useState(null);
const [filterByStatus, setFilterByStatus] = React.useState(null);

// Table row selection
const [selectedItem, setSelectedItem] = React.useState(null);

// set this month dateFormat get current entire year as default
const currentDate = new Date();
const startYear = new Date(currentDate.getFullYear(), 0, 1); // 1 de Janeiro
const endYear = new Date(currentDate.getFullYear(), 11, 31); // 31 de Dezembro
var defaultRange = [startYear, endYear];

const [periodDate, setPeriodDate] = React.useState(defaultRange);

// Set Fake Data
//var rowsData = ReservationData;
const [rows, setRows] = React.useState([]);
const [totalRecords, setTotalRecords] = React.useState(0);
 
/* -------------------------------- */ 
/* Custom methods and options START */
/* -------------------------------- */

// Select reservation item method
const selectAgencyAction = (value) => {

	// Set local data
	setSelectedItem(value);	

	// Set reservation on store (index 0)
	setUser(value[0]);

	// Open Modal
	setDialog('userModal',true);
	
}

// Retrieve reservations
const retrieveItemsList = () => {
		
	var props = {
		setSaving:setSaving,
		setAlert:setAlert,
		setAlertTitle:setAlertTitle,
		setAlertBody:setAlertBody,
		setRows:setRows,
		setLoading:setLoading,
		setTotalRecords:setTotalRecords
	}
	var data = {
	  lazyState:lazyState
	}
	retrieveUsers(data,props);

} // end retrieveDocumentInfo
 
  
// Dropdown Values for Basic Search Fields
const [filter, setFilter] = React.useState(masterUser ? {
	"code": "5",
	"field":"agencyName",
	"name": "Nome da Agência"
} :  {
	"code": "2",
	"field":"customerName",
	"name": "Nome do Usuário"
  });
  
const FilterItems = masterUser ? [
  {
	"code": "5",
	"field":"agencyName",
	"name": "Nome da Agência"
  },  
  {
	"code": "2",
	"field":"customerName",
	"name": "Nome do Usuário"
  },   
   { 
	"code": "1",
	"field":"cpf",
	"name": "CPF"
  },   
   { 
	"code": "4",
	"field":"email",
	"name": "Email"
  }
] : [
  {
	"code": "2",
	"field":"customerName",
	"name": "Nome do Usuário"
  },   
   { 
	"code": "1",
	"field":"cpf",
	"name": "CPF"
  },   
   { 
	"code": "4",
	"field":"email",
	"name": "Email"
  }
];

const [dropdownFilterItems, setDropdownFilterItems] = React.useState(FilterItems);

// Datatable default state
const [lazyState, setlazyState] = React.useState({
	first: 0,
	rows: 10,
	page: 1,
	sortField: "createdAt",
	sortOrder: -1,
	filters: {
	}
});

// Default columns
const columnsValues = masterUser ? [
'userId',
'agency',
'name',
'contacts',
'profile',
'createdAt',
'status'
] : [
'userId',
'name',
'contacts',
'profile',
'createdAt',
'status'
];
const [visibleColumns, setVisibleColumns] = React.useState(columnsValues);

 const data = {
            labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
            datasets: [
                {
                    label: 'Aguardando pagamento',
                    backgroundColor: "orange",
                    borderColor: "orange",
                    data: [55, 59, 80, 81, 56, 55, 40]
                },
                {
                    label: 'Pagamento confirmado',
                    backgroundColor: "lightgreen",
                    borderColor:  "lightgreen",
                    data: [45, 21, 15, 19, 20, 27, 60]
                },
                {
                    label: 'Entrando em vigência',
                    backgroundColor: "blue",
                    borderColor:  "blue",
                    data: [28, 18, 15, 0, 10, 25, 45]
                },
                {
                    label: 'Canceladas Agência',
                    backgroundColor: "red",
                    borderColor:  "red",
                    data: [0, 11, 3, 0, 14, 8, 0]
                },
                {
                    label: 'Canceladas Sistema',
                    backgroundColor: "red",
                    borderColor:  "red",
                    data: [0, 9, 2, 0, 10, 10, 0]
                }				
            ]
        };
 const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                legend: {
                    labels: {
                        fontColor:  "black"
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color:  "black",
                        font: {
                            weight: 500
                        }
                    },
                    grid: {
                        display: false,
                        drawBorder: false
                    }
                },
                y: {
                    ticks: {
                        color: "black"
                    },
                    grid: {
                        color: "white",
                        drawBorder: false
                    }
                }
            }
        };

const [chartData, setChartData] = React.useState(data);
const [chartOptions, setChartOptions] = React.useState(options);

// Field value format and enrichement
const bodyTemplate = (type,data) => {

	let rowValue = "Não definido";

	if (type == "userId") {

		rowValue = (
		<>
		<div>{data.userId}</div>
		</>
		);	
		
	}
	
	if (type == "agency") {		

		rowValue = (
		<>
		<div>{data.legalName}</div>
		<div><small style={{color:"gray"}}>{data.fantasyName}</small></div>
		<div><small style={{color:"gray"}}>{data.document}</small></div>
		</>
		);	
		
	}
	
	if (type == "name") {	
			
		rowValue = (
		<>
		<div>{data.name}</div>
		<div><small style={{color:"gray"}}>{data.document}</small></div>
		</>
		);				
	}	
	
	if (type == "contacts") {	
		rowValue = (
		<>
		<div>{data.phone}</div>
		<div><small style={{color:"gray"}}>{data.email}</small></div>
		</>
		);		
	}	
	
	if (type == "creationDate") {		
		rowValue = moment(data.createdAt).format("DD/MM/YYYY")
	}	

	if (type == "profile") {		
		//rowValue = data.totalAmount;
		rowValue = (
		<>
		<div>{data}</div>
		</>
		);		
		
	}	

	if (type == "status") {		

		if (data.status == 1) {
			var activationStatus = "ativado";
			var activationStatusLabel = "Ativo";
		} // end if	
		else if (data.status == 2) {
			var activationStatus = "review";
			var activationStatusLabel = "Inativo";
		} // end if
		else if (data.status == 3) {
			var activationStatus = "bloqueado";
			var activationStatusLabel = "Bloqueado";
		} // end if	
		else if (data.status == 4) {
			var activationStatus = "encerrado";
			var activationStatusLabel = "Excluído";
		} // end if
			
		return (
		<>
		<div className={`product-badge status-${activationStatus}`}>{activationStatusLabel}</div>
		</>
		)	
		rowValue = data;
	}	
	
    return rowValue;
};
 
const applyBasicSearch = () => {
	
	// Check if search term is filled and with at least 3 chars
	if (!filterValue) {
		setAlert(true);
		setAlertTitle("Verifique as informações");
		setAlertBody("Você precisa preencher o termo a ser buscado pra prosseguir.");	
		return;
	} // end if
	
	if (filterValue.length < 3) {
		setAlert(true);
		setAlertTitle("Verifique as informações");
		setAlertBody("Para prosseguir, você precisa preencher o termo a ser buscado com ao menos 3 caracteres.");	
		return;
	} // end if	
 	
	// Set filter on
	setBasicFilterStatus(true);
	
	// Set advanced filter off
	setAdvancedFilterStatus(false);
	
	// Clean Advanced fields
	setFilterByReservationId("");
	setFilterByAgencyName("");
	setFilterByAgencyDocument("");
	setFilterByCustomerName("");
	setFilterByCustomerDocument("");
	setFilterByUserName("");
	setFilterByTransportType([]);
	setFilterByCreateAtFrom("");
	setFilterByCreateAtTo("");
	setFilterByServiceAtFrom("");
	setFilterByServiceAtTo("");
	setFilterByPaymentMethod("");
	setFilterByStatus("");		
	
	// Set filter to lazyData
	var currentData = {...lazyState}
	
	// Set current filter
	currentData.filters = {
		mode:"basic",
		term: filterValue,
		field: filter
	}
	
	// Set LazyState
	setlazyState(currentData);
	
} // end function

const resetSearch = (type)=> {
	
	if (type == "basic") {
		// Set filter on
		setBasicFilterStatus(false);
		
		// Clean Terms
		setFilterValue("");		
	} // end if
	else {
		// Set filter on
		setAdvancedFilterStatus(false);
		
		setFilterByReservationId("");
		setFilterByAgencyName("");
		setFilterByAgencyDocument("");
		setFilterByCustomerName("");
		setFilterByCustomerDocument("");
		setFilterByUserName("");
		setFilterByTransportType([]);
		setFilterByCreateAtFrom("");
		setFilterByCreateAtTo("");
		setFilterByServiceAtFrom("");
		setFilterByServiceAtTo("");
		setFilterByPaymentMethod("");
		setFilterByStatus("");		
		
	} // end else
	
	// Set filter to lazyData
	var currentData = {...lazyState}
	
	// Set current filter
	currentData.filters = {};
	
	// Set LazyState
	setlazyState(currentData);	
	
} // end fucntion

const applyAdvancedSearch = () => {
	
	// Check if at least one field is filled
	if (!filterByReservationId && !filterByAgencyName && !filterByAgencyDocument && !filterByCustomerName &&  !filterByCustomerDocument && !filterByUserName && filterByTransportType.length == 0 &&  !filterByCreateAtFrom && !filterByCreateAtTo && !filterByServiceAtFrom && !filterByServiceAtTo && !filterByPaymentMethod && !filterByStatus) {
		setAlert(true);
		setAlertTitle("Verifique as informações");
		setAlertBody("Você precisa preencher ao menos um campo pra prosseguir com a busca.");	
		return;
	} // end if	
	
	// Check if createdAt From and To are filled 
	if (filterByCreateAtFrom || filterByCreateAtTo) {
		if (!filterByCreateAtFrom || !filterByCreateAtTo) {
			setAlert(true);
			setAlertTitle("Verifique as informações");
			setAlertBody("Você precisa preencher ao os dois campos da data de criação para prosseguir.");	
			return;
		}
	} // end if

	// Check if createdAt From and To are filled 
	if (filterByServiceAtFrom || filterByServiceAtTo) {
		if (!filterByServiceAtFrom || !filterByServiceAtTo) {
			setAlert(true);
			setAlertTitle("Verifique as informações");
			setAlertBody("Você precisa preencher ao os dois campos da data do serviço para prosseguir.");	
			return;
		}
	} // end if	
	
	// Check company document if ok
	if (filterByCustomerDocument) {
		if (!validarCPF(filterByCustomerDocument)) {
			setAlert(true);
			setAlertTitle("Verifique as informações");
			setAlertBody("O CPF informado é inválido. Verifique novamente.");		
			return;
		}
	} // end if	

	// Check customer document if ok
	if (filterByAgencyDocument) {
		if (!validarCNPJ(filterByAgencyDocument)) {
			setAlert(true);
			setAlertTitle("Verifique as informações");
			setAlertBody("O CNPJ informado é inválido. Verifique novamente.");	
			return;
		}
	} // end if		
	 	
	// Set filter on
	setBasicFilterStatus(false);
	
	// Clean Basic Search term
	setFilterValue("");
	
	// Set advanced filter off
	setAdvancedFilterStatus(true);
	
	// Set filter to lazyData
	var currentData = {...lazyState}	
	
	var filters = {};
	filters.mode = "advanced"; 
	filters.reservationId = filterByReservationId ? filterByReservationId : null; 
	filters.agencyName = filterByAgencyName ? filterByAgencyName : null; 
	filters.agencyDocument = filterByAgencyDocument ? filterByAgencyDocument : null; 
	filters.customerName = filterByCustomerName ? filterByCustomerName : null; 
	filters.customerDocument = filterByCustomerDocument ? filterByCustomerDocument : null; 
	filters.userName = filterByUserName ? filterByUserName : null; 
	filters.transportType = filterByTransportType ? filterByTransportType : null; 
	filters.createAtFrom = filterByCreateAtFrom ? filterByCreateAtFrom : null; 
	filters.createAtTo = filterByCreateAtTo ? filterByCreateAtTo : null; 
	filters.serviceAtFrom = filterByServiceAtFrom ? filterByServiceAtFrom : null; 
	filters.serviceAtTo = filterByServiceAtTo ? filterByServiceAtTo : null; 
	filters.paymentMethod = filterByPaymentMethod ? filterByPaymentMethod : null; 
	filters.status = filterByStatus ? filterByStatus : null; 
	
	// Apply filter on Data from service 
	let _lazyParams = { ...lazyState, filters:{ fields: filters, mode:"advanced" } };
	setlazyState(_lazyParams);	
	
	// Closes panel
	setAdvancedSearch(!advancedSearch)
	
} // end function
 
/* -------------------------------- */ 
/* Custom methods and options END */
/* -------------------------------- */
 
 
/* -------------------- */ 
/* Common methods START */
/* -------------------- */

// Set selected columns
const onColumnsChange = (e) => {
	
	let _visibleColumns = [...visibleColumns];

	if (e.checked)
		_visibleColumns.push(e.value);
	else
		_visibleColumns.splice(_visibleColumns.indexOf(e.value), 1);

	setVisibleColumns(_visibleColumns);
}

// Datatable related controls START
const loadLazyData = () => {	
	setLoading(true);
	retrieveItemsList();	
};

// Run after mount component
React.useEffect(() => {

	// Retrieve User Data Obj. 
	var result = localStorage.getItem("@memovo/userData", undefined,true);
	var userData = JSON.parse(result);  

	  // If token dont exist, redirect to onboarding or register
	  if (!userData) {
		  navigate("/home");
		  return;
	  } // end if

  // set scroll to top
  window.scrollTo(0, 1);
  
  // Load list
  retrieveItemsList();
      
  // Runs when UNMOUNT component
  return () => {
    
	// unmount...	
	
  };
  
}, []); // end useEffect 

const onPage = (event) => {	
	setlazyState(event);
};

const onSort = (event) => {
	setlazyState(event);
};

const onFilter = (event) => {
	event['first'] = 0;
	setlazyState(event);
};
// Datatable related controls END

// Reacts when lazyState changes
React.useEffect(() => {
	loadLazyData();
}, [lazyState]);
 
// Set Currency format
let EURO = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'EUR',
});

// Set calendar locale
addLocale('br', {
	firstDayOfWeek: 1,
	dayNames: ['domingo', 'segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado'],
	dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
	dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
	monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
	monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
	today: 'Hoje',
	clear: 'Limpar'
});

// set Use of history for PUSH 
var navigate = useNavigate(); 

/* -------------------- */ 
/* Common methods END */
/* -------------------- */
 
  return (
	<>
	<div id="adminSection" style={{marginTop:"0"}} className={isTabletOrMobile ? "login__wrapper mobile" : "login__wrapper"} >
		<div style={{height:"auto"}} className={isTabletOrMobile ? "login__wrapper_form_wrapper mobile" : "login__wrapper_form_wrapper"}> 		
			<div className={isTabletOrMobile ? "login__form_wrapper mobile" : "login__form_wrapper"} style={{alignItems:"flex-start"}}>
						
				<div style={{flex:1,display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"flex-start",gap:"10px",padding:"40px",marginTop:"0px",height:"auto",marginLeft:"auto",marginRight:"auto",width:"100%",border:"0px solid red"}}>
				
						{/* Admin Side Menu */}
						<SideMenu state="open" />
						
						{/* Admin Content */}
						<div style={{flex:1,height:"100%",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
						
							<div style={{height:"64px",width:"100%",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
							
								<div style={{textAlign:"left",flex:2,color: '#2E0249', fontFamily: 'Titillium Web', fontSize: '36px', fontStyle: 'normal', fontWeight: 600, lineHeight: '120%', letterSpacing: '3.6px',padding:"10px"}}>Painel</div>
								
								<div style={{flex:5,display:"flex",flexDirection:"row",justifyContent:"end",alignItems:"center"}}>
								
									<div style={{padding:"10px",marginRight:"10px",fontSize:"16px"}}>  Período selecionado: </div>
									<div style={{backgroundColor:"#FFF",padding:"10px",borderTopLeftRadius:"20px",borderBottomLeftRadius:"20px",display:"flex",flexDirection:"row"}}> 	
									
										<Calendar showButtonBar locale="br" selectionMode="range" id="periodSelection" value={periodDate} onChange={(e) => {setPeriodDate(e.value)}}  style={{width:"100%"}} inputStyle={{width:"100%",borderTopRightRadius:0,borderBottomRightRadius:0,width:"100%"}} dateFormat="dd/mm/yy" readOnlyInput numberOfMonths={2} placeholder="Selecione o período" />
										
										<Button icon="pi pi-search" style={{borderTopLeftRadius:0,borderBottomLeftRadius:0,height:"47px",fontSize:"14px"}} loading={saving} onClick={()=>{console.log("PRESSED")}} />
										
									</div>
								
								</div>
							
							</div>
							
							
							<div style={{flex:1,width:"100%",backgroundColor:"#FFF"}}>
							
								<div style={{minHeight:"66vh",zIndex: 8,position:"relative"}}>

								{/* BLOCK REERVATIONS PANEL */}
								<div style={{margin:"30px",maxWidth:"90%"}}>
									
										<div style={{marginBottom:"20px",textAlign:"left",fontSize:"26px",letterSpacing:"0.1em",lineHeight:"120%",fontWeight:"600",fontStyle:"normal",fontFamily:"Titillium Web",color:"#2E0249"}}> Reservas </div>
										
										<div style={{display:"flex",alignItems:"center",justifyContent:"flex-start",gap:"20px",flexDirection:"row",flexWrap:"wrap"}}>
										
											<Card title={<div style={{backgroundColor:"orange",fontSize:"12px",padding:"5px",color:"white",borderRadius:"10px"}}>Aguardando Pagamento</div>} style={{width:"220px",maxHeight:"116px",backgroundColor:"whitesmoke"}}>
												<div style={{fontSize:"60px"}}>
													32
												</div>
											</Card>	
											

											<Card title={<div style={{backgroundColor:"lightgreen",fontSize:"12px",padding:"5px",color:"darkgreen",borderRadius:"10px"}}>Pagamento Confirmado</div>} style={{width:"220px",maxHeight:"116px",backgroundColor:"whitesmoke"}}>
												<div style={{fontSize:"60px"}}>
													1290
												</div>
											</Card>	
											

											<Card title={<div style={{backgroundColor:"red",fontSize:"12px",padding:"5px",color:"white",borderRadius:"10px"}}>Canceladas Agência</div>} style={{width:"220px",maxHeight:"116px",backgroundColor:"whitesmoke"}}>
												<div style={{fontSize:"60px"}}>
													21
												</div>
											</Card>													

											<Card title={<div style={{backgroundColor:"#7a0000",fontSize:"12px",padding:"5px",color:"white",borderRadius:"10px"}}>Canceladas Sistema</div>} style={{width:"220px",maxHeight:"116px",backgroundColor:"whitesmoke"}}>
												<div style={{fontSize:"60px"}}>
													9
												</div>
											</Card>	

											<Card title={<div style={{backgroundColor:"gray",fontSize:"12px",padding:"5px",color:"white",borderRadius:"10px"}}>Confirmação pendente</div>} style={{width:"220px",maxHeight:"116px",backgroundColor:"whitesmoke"}}>
												<div style={{fontSize:"60px"}}>
													3
												</div>
											</Card>	
											
											<Card title={<div style={{backgroundColor:"black",fontSize:"12px",padding:"5px",color:"white",borderRadius:"10px"}}>Sem disponibilidade</div>} style={{width:"220px",maxHeight:"116px",backgroundColor:"whitesmoke"}}>
												<div style={{fontSize:"60px"}}>
													0
												</div>
											</Card>												
										
										</div>
									
									</div>
															
								<div style={{margin:"30px",maxWidth:"90%",padding:"30px"}}>
									<div style={{marginBottom:"10px",textAlign:"center",fontSize:"20px",letterSpacing:"0.1em",lineHeight:"120%",fontWeight:"600",fontStyle:"normal",fontFamily:"Titillium Web",color:"#2E0249"}}> Reservas por status e período </div>
									<Chart type="bar" data={chartData} options={chartOptions} />
								</div>									
								
								{/* BLOCK AGENCIAS PANEL */}
								<div style={{margin:"30px",maxWidth:"90%"}}>
									
										<div style={{marginBottom:"20px",textAlign:"left",fontSize:"26px",letterSpacing:"0.1em",lineHeight:"120%",fontWeight:"600",fontStyle:"normal",fontFamily:"Titillium Web",color:"#2E0249"}}> Agências </div>
										
										<div style={{display:"flex",alignItems:"center",justifyContent:"flex-start",gap:"20px",flexDirection:"row",flexWrap:"wrap"}}>
										
											<Card title={<div style={{backgroundColor:"orange",fontSize:"12px",padding:"5px",color:"white",borderRadius:"10px"}}>Aguardando análise</div>} style={{width:"220px",maxHeight:"116px",backgroundColor:"whitesmoke"}}>
												<div style={{fontSize:"60px"}}>
													50
												</div>
											</Card>	
											

											<Card title={<div style={{backgroundColor:"red",fontSize:"12px",padding:"5px",color:"white",borderRadius:"10px"}}>Cadastros reprovados</div>} style={{width:"220px",maxHeight:"116px",backgroundColor:"whitesmoke"}}>
												<div style={{fontSize:"60px"}}>
													28
												</div>
											</Card>													

											<Card title={<div style={{backgroundColor:"lightgreen",fontSize:"12px",padding:"5px",color:"darkgreen",borderRadius:"10px"}}>Cadastros ativos</div>} style={{width:"220px",maxHeight:"116px",backgroundColor:"whitesmoke"}}>
												<div style={{fontSize:"60px"}}>
													236
												</div>
											</Card>	

											<Card title={<div style={{backgroundColor:"#7a0000",fontSize:"12px",padding:"5px",color:"white",borderRadius:"10px"}}>Cadastros inativos</div>} style={{width:"220px",maxHeight:"116px",backgroundColor:"whitesmoke"}}>
												<div style={{fontSize:"60px"}}>
													9
												</div>
											</Card>	

											<Card title={<div style={{backgroundColor:"gray",fontSize:"12px",padding:"5px",color:"white",borderRadius:"10px"}}>Cadastros Bloqueados</div>} style={{width:"220px",maxHeight:"116px",backgroundColor:"whitesmoke"}}>
												<div style={{fontSize:"60px"}}>
													3
												</div>
											</Card>	
											
											<Card title={<div style={{backgroundColor:"black",fontSize:"12px",padding:"5px",color:"white",borderRadius:"10px"}}>Cadastros Excluídos</div>} style={{width:"220px",maxHeight:"116px",backgroundColor:"whitesmoke"}}>
												<div style={{fontSize:"60px"}}>
													0
												</div>
											</Card>												
										
										</div>
									
									</div>
									
									
								{/* BLOCK CUSTOMERS PANEL */}
								<div style={{margin:"30px",maxWidth:"90%"}}>
									
										<div style={{marginBottom:"20px",textAlign:"left",fontSize:"26px",letterSpacing:"0.1em",lineHeight:"120%",fontWeight:"600",fontStyle:"normal",fontFamily:"Titillium Web",color:"#2E0249"}}> Clientes </div>
										
										<div style={{display:"flex",alignItems:"center",justifyContent:"flex-start",gap:"20px",flexDirection:"row",flexWrap:"wrap"}}>
										
											<Card title={<div style={{backgroundColor:"lightgreen",fontSize:"12px",padding:"5px",color:"darkgreen",borderRadius:"10px"}}>Cadastros ativos</div>} style={{width:"220px",maxHeight:"116px",backgroundColor:"#ffffed"}}>
												<div style={{fontSize:"60px"}}>
													1280
												</div>
											</Card>	

											<Card title={<div style={{backgroundColor:"red",fontSize:"12px",padding:"5px",color:"white",borderRadius:"10px"}}>Cadastros bloqueados</div>} style={{width:"220px",maxHeight:"116px",backgroundColor:"#ffffed"}}>
												<div style={{fontSize:"60px"}}>
													10
												</div>
											</Card>													
											
											<Card title={<div style={{backgroundColor:"black",fontSize:"12px",padding:"5px",color:"white",borderRadius:"10px"}}>Cadastros Excluídos</div>} style={{width:"220px",maxHeight:"116px",backgroundColor:"#ffffed"}}>
												<div style={{fontSize:"60px"}}>
													1
												</div>
											</Card>												
										
										</div>
									
									</div>	


									
									
									
									
								</div>							
							
							</div>
						
						</div>
						
				</div>
			
			</div> 
		</div>
		
		<UserView
			setDialog={setDialog}
			dialog={dialog}
			scrollBlock={false}
		/>			
		
    </div>
	
	{/* Contac Us Section */}
	<ContactUs />		

	{/* About Us Section */}
	<AboutUs />	
	
	</>
  );
    
}

const mapStateToProps = store => ({
  dialog:store.appReducer.dialog,
  masterUser:store.appReducer.masterUser
 });
const mapDispatchToProps = dispatch => bindActionCreators({ ...AppActions,...UserActions }, dispatch);

export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(Users);	


