import '../../Assets/Styles/register.css';
import React from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Button } from 'primereact/button'; 
import { Dialog } from 'primereact/dialog';
import {APIProvider, Map,Marker} from '@vis.gl/react-google-maps';
import { ProgressSpinner } from 'primereact/progressspinner';

// Load Actions
import * as AppActions from '../../Store/Actions/App';


const apiKey = 'AIzaSyBFNooJFvoHEGeQOJqUuekFHT1PRQ8TbCs';


// UpdateService dialog component
const MapView = (props) => {

// set vars from props
const { 
    dialog,
    setDialog,
	type,
	address,
    zoom,
    coordinates,
	scrollBlock
} = props;


const [loading, setLoading] = React.useState(true); 
const [center, setCenter] = React.useState(null);
const [markerCenter, setMarkerCenter] = React.useState(null);


const footerContent = (
	<div className="modalFooterButtonsEnd">

		<div>
			<Button label="Fechar" icon="pi pi-times" className="p-button-text" onClick={() => close()} />
		</div>
		
	</div>
);		
	
const geocodeAddress = async (address) => {
    const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${apiKey}`);
    const data = await response.json();
    if (data.status === 'OK') {
        const { lat, lng } = data.results[0].geometry.location;
        return { lat, lng };
    } else {
        throw new Error('Geocoding API error: ' + data.status);
    }
};

// Execute when dialog opens
React.useEffect(() => {

	// Only r]uns when  dialog opens
	if (dialog.target == 'mapviewModal' && dialog.status) {

        if (type == "address") {

        geocodeAddress(address)
            .then(coords => {
                setCenter(coords);
                setMarkerCenter(coords);
            })
            .catch(error => {
                console.error('Error fetching geocode:', error);
            });   
            
        } // end if
        else {
            setCenter(coordinates);
            setMarkerCenter(coordinates);
        } /// end else
		
	} // end if
	
	// Reset all fields when closes or open dialog
	// ...
			
},[dialog]);

// Execute when dialog opens
React.useEffect(() => {
	
	// Reset all fields when closes or open dialog
  // Runs when UNMOUNT component
  return () => {
	setCenter(null);
	setMarkerCenter(null);
  };
			
},[]);

const close = ()=> {

	setDialog("mapviewModal",false);
    setCenter(null);
    setMarkerCenter(null);
    
	setTimeout(function() {
		if (scrollBlock) {
			// document.body.classList.add('p-overflow-hidden');	
		} // end if
	},300); 

}
	
	return (
	<>
			<Dialog header="Mostrando local no mapa" closable={true} closeOnEscape={true} dismissableMask={true} visible={dialog.target == 'mapviewModal' && dialog.status} style={{ width: '55vw',height:'70vh' }} breakpoints={{ '1400px': '75vw','960px': '80vw', '641px': '100vw' }} onHide={() => close()} footer={footerContent} >
			
				<div className="w100 flex-row justify-center align-items-center" style={{padding:"1rem",height:"100%"}}>
                        
                    {!center && 1 == 2 &&
			                <div style={{backgroundColor:"transparent",display:"flex",justifyContent:"center",alignItems:"center",width:"50%",height:"45vh",padding:"30px"}}>

                                <div style={{flex:1,display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",width:"20vw",textAlign:"center"}}>
                                    <ProgressSpinner />
                                    <div className="formTitle" style={{marginBottom:"15px",marginTop:"15px",fontSize:"2rem"}}>Carregando mapa...Aguarde! </div>
                                </div>

                            </div>           
                    }

                    {center &&
                    <div style={{ flex:1,height: 'inherit', width: 'inherit' }}>
                    <APIProvider apiKey={apiKey}>
                        <Map
                        defaultZoom={zoom ? zoom : 11}
                        /*center={{lat: parseFloat(coordinates.lat), lng:parseFloat(coordinates.lng)}}*/
                        defaultCenter={center}
                        gestureHandling={'greedy'}
                        disableDefaultUI={false}
                        fullscreenControlOptions={false}
                        mapTypeControl={false}
                        streetViewControl={false}
                        fullscreenControl={false}
                        > 
                        <Marker position={{lat: parseFloat(markerCenter.lat), lng:parseFloat(markerCenter.lng)}} />
                        </Map>
                    </APIProvider>
                    </div>
                    }

			    </div>
            </Dialog>	
	</>	
	);
	
}

const mapStateToProps = store => ({
  dialog:store.appReducer.dialog
 });
const mapDispatchToProps = dispatch => bindActionCreators({ ...AppActions }, dispatch);

export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(MapView);	
