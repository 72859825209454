import '../../Assets/Styles/home.css';
import '../../Assets/Styles/order.css';
import '../../Assets/Styles/orderStep2.css';
import React from "react";
import { useMediaQuery } from 'react-responsive'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {Link,useNavigate} from 'react-router-dom';

import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button'; 
import { Dialog } from 'primereact/dialog';
import { Steps } from 'primereact/steps';
import { ToggleButton } from 'primereact/togglebutton';
import { Tooltip } from 'primereact/tooltip';
import { AutoComplete } from 'primereact/autocomplete';
import { Calendar } from 'primereact/calendar';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Dropdown } from 'primereact/dropdown';
import { addLocale } from 'primereact/api';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import ForkRightIcon from '@mui/icons-material/ForkRight';
import StarsIcon from '@mui/icons-material/Stars';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import LocalAirportIcon from '@mui/icons-material/LocalAirport';

import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

import multiTransportConcept from '../../Assets/Images/multiTransportConcept11.png'; 
import desktopConcept from '../../Assets/Images/desktopConcept.png'; 
import contactConcept from '../../Assets/Images/contactConcept.png'; 
import formPlaceholder from '../../Assets/Images/formPlaceholder.png'; 
import formTopIcons from '../../Assets/Images/formTopIcons.svg'; 
import cornerTop from '../../Assets/Images/cornerTop.svg';
import cornerBottom from '../../Assets/Images/cornerBottom.svg';

import contactWhatsappIcon from '../../Assets/Images/contactWhatsappIcon.svg';
import contactEmailIcon from '../../Assets/Images/contactEmailIcon.svg';
import contactPhoneIcon from '../../Assets/Images/contactPhoneIcon.svg';
import transportPicture from '../../Assets/Images/transportPicture.svg';
import rentcarPicture from '../../Assets/Images/rentcarPicture.svg';
import transferPicture from '../../Assets/Images/transferPicture.svg';
import transportIcon from '../../Assets/Images/transportIcons.svg';
import transferIcon from '../../Assets/Images/transferIcon.svg';
import rentcarIcon from '../../Assets/Images/rentcarIcon.svg';
import tooltipIcon from '../../Assets/Images/tooltipIcon.svg';

// Load Actions
import * as AppActions from '../../Store/Actions/App'
import * as OrderActions from '../../Store/Actions/Order'

// Load Componentes
import MessageView from '../../Components/MessageModal';
import AlertView from '../../Components/AlertModal';
import {
	validarCNPJ,
	validarCPF,
	validarEmail,
	validarTelefone,
	validarNome,
	maskCNPJ,
	maskCPF,
	maskPhone
} from '../../Components/Utils';

import {
	Callto,
	Whatsapp
} from '../../Components/Utils';

/*
import {
	stepsData
} from '../../Components/StaticData';*/

// export default function HomePage() {
const HomePage = (props) => {
	
// Set var from props
const {
	setDialog,
	dialog,
	retrieveAppAddressGooglePlaces,
	retrieveAppAddressesGoogleMatrixDistance,
	transportFilters,
	setTransportFilters
} = props;

const [loading, setLoading] = React.useState(true); 
const [saving, setSaving] = React.useState(false); 


console.log('transportFilters-->'+JSON.stringify(transportFilters,null,2));


const isDesktopOrLaptop = useMediaQuery({
query: '(min-width: 1024px)'
})
const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1023px)' })

const [email, setEmail] = React.useState('');
const [password, setPassword] = React.useState('');
const [stillConnected, setStillConnected] = React.useState(false);
const onStillConnectedChange = (e) => {
	if (e.checked)
		setStillConnected(true);
	else
		setStillConnected(false);
}  // end function
	
// Alert control
const [alert, setAlert] = React.useState(false); 
const [alertTitle, setAlertTitle] = React.useState("Informações"); 
const [alertBody, setAlertBody] = React.useState("Conteúdo dinâmico"); 

const [forgotPassword, setForgotPassword] = React.useState(false); 		

// Transport selection
const [checkedAll, setCheckedAll] = React.useState(false);
const [checkedAirplane, setCheckedAirplane] = React.useState(false);
const [checkedTrain, setCheckedTrain] = React.useState(false);
const [checkedBus, setCheckedBus] = React.useState(false);
const [directServices, setDirectServices] = React.useState(false);

const [departureLocal, setDepartureLocal] = React.useState(null);

const [departureDate, setDepartureDate] = React.useState(null);
const [departureTime, setDepartureTime] = React.useState(null);

const [destinationLocal, setDestinationLocal] = React.useState(null);

const [destinationDate, setDestinationDate] = React.useState(null);
const [destinationTime, setDestinationTime] = React.useState(null);	

const [pickReturnDate, setPickReturnDate] = React.useState(false);
const [pickReturn, setPickReturn] = React.useState(false);

const [leaveDate, setLeaveDate] = React.useState(null);
const [returnDate, setReturnDate] = React.useState(null);


const [value, setValue] = React.useState('');
const [items, setItems] = React.useState([]);

/* Person select START */
const [menuOpened,setMenuOpened] = React.useState(false);
const [personAddLabel,setPersonAddLabel] = React.useState("1 Adulto (Adicionar Mais Pessoas)"); // default
const [personAddAdult,setPersonAddAdult] = React.useState(1); // default adult
const [personAddYoung,setPersonAddYoung] = React.useState(0); // default young
const [personAddTotal,setPersonAddTotal] = React.useState(1); 
const [personAddYoungAges,setPersonAddYoungAges] = React.useState([]); 
const [personAddElder,setPersonAddElder] = React.useState(0); // default elder
const [personAddElderAges,setPersonAddElderAges] = React.useState([]); 

const [luggageAddLabel,setLuggageAddLabel] = React.useState("1 Bagagem (Adicionar Mais)"); // default
const [luggageAdd,setLuggage] = React.useState(1); // default adult
const [menuLugOpened,setMenuLugOpened] = React.useState(false);

const [selectedElderAge,setSelectedElderAge] = React.useState([]);
const [selectedYoungAge,setSelectedYoungAge] = React.useState([]); 

const [timeWarning,setTimeWarning] = React.useState(false); 

// temp options
const [timeOptions,setTimeOptions] = React.useState([]); 

let today = new Date();
let month = today.getMonth();
let year = today.getFullYear();
let minDate = new Date();

addLocale('br', {
	firstDayOfWeek: 1,
	dayNames: ['domingo', 'segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado'],
	dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
	dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
	monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
	monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
	today: 'Hoje',
	clear: 'Limpar'
});

const op = React.useRef(null);
const opLug = React.useRef(null);

const onHide = () => {
setMenuOpened(false);
}  
const onShow = () => {
setMenuOpened(true);
}    

const onLugHide = () => {
setMenuLugOpened(false);
}  
const onLugShow = () => {
setMenuLugOpened(true);
}    
 
const setPerson = (operation,type) => {
	
	// Set total passengers
	let currentTotal = personAddAdult + personAddYoung + personAddElder;
	
	if (type == 'adult') {
		
		let newValue = operation == '+' ? personAddAdult + 1 : personAddAdult - 1;
		newValue = newValue < 0 ? 0 : newValue;
		if (currentTotal < 50 || operation == '-') {
			setPersonAddAdult(newValue);
		}
		
	} // end if
	else if (type == 'young') {
		
		let newValue = operation == '+' ? personAddYoung + 1 : personAddYoung - 1;
		newValue = newValue < 0 ? 0 : newValue;
		if (currentTotal < 50 || operation == '-') {
			setPersonAddYoung(newValue);
		}
		
		// reset ages
		if (operation == '-') { setSelectedYoungAge([]); }
		
	} // end if
	else if (type == 'elder') {
		
		let newValue = operation == '+' ? personAddElder + 1 : personAddElder - 1;
		newValue = newValue < 0 ? 0 : newValue;
		if (currentTotal < 50 || operation == '-') {
			setPersonAddElder(newValue);	
		}
		
		// reset ages
		if (operation == '-') { setSelectedElderAge([]); }		
		
	} // end if

	
} // end function
/* Person select END */

const setLug = (operation,type) => {
	
	// Set total passengers
	let currentTotal = luggageAdd;
	
	let newValue = operation == '+' ? luggageAdd + 1 : luggageAdd - 1;
	newValue = newValue < 0 ? 0 : newValue;
	setLuggage(newValue);
	
} // end function
/* Person select END */

const generateTotalLabel = () => {

	// Set total passengers
	let currentTotal = personAddAdult + personAddYoung + personAddElder;

	if (personAddAdult == 1 && personAddYoung == 0 && personAddElder == 0) {
		var label = "1 Viajante (Adicionar Mais Pessoas)";	
	} // end if
	else if (personAddAdult > 1 && personAddYoung == 0 && personAddElder == 0) {
		var label = ""+personAddAdult+" Viajantes (Adicionar Mais Pessoas)";	
	} // end else if
	else if (currentTotal == 0) {
		var label = "Nenhum Viajante, (Adicionar Pessoas)";
	} // end else if	
	else if (currentTotal == 1) {
		var label = ""+currentTotal+" Viajante (Adicionar Mais Pessoas)";
	} // end else if	
	else {
		var label = ""+currentTotal+" Viajantes (Adicionar Mais Pessoas)";	
	} // end else
	
	// Set Person Control Label
	setPersonAddLabel(label);
	
}

const generateTotalLugLabel = () => {

	// Set total passengers
	let currentTotal = luggageAdd;

	if (luggageAdd == 1) {
		var label = "1 Bagagem (Adicionar Mais)";	
	} // end if
	else if (luggageAdd > 1) {
		var label = ""+luggageAdd+" Bagagens (Adicionar Mais)";	
	} // end else if
	else if (currentTotal == 0) {
		var label = "Nenhuma bagagem, (Adicionar bagagens)";
	} // end else if	
	
	// Set Person Control Label
	setLuggageAddLabel(label);
	
}


const search = (event) => {
		
	var data = {
		keyword: event.query
	} 
	var props = {
		setItems:setItems
	}
	retrieveAppAddressGooglePlaces(data,props);
	
}

const cleanField = (type,fieldValue) => {

	var value = fieldValue;
		
	if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
		// nothing happens
	} // end if
	else {
		if (type == "departure") {
			setDepartureLocal("");
		} // end if
		else {
			setDestinationLocal("");
		} // end else
	} // end else
	
}

const itemTemplate = (item) => {
	
	var icon = <LocationOnIcon />; // local
	if (item.types[0] == "point_of_interest") {
		icon = <StarsIcon />; // local
	} // end if
	else if (item.types[0] == "route") {
		icon = <ForkRightIcon />; // local
	} // end if	
	else if (item.types[0] == "premise") {
		icon = <LocationOnIcon />; // local
	} // end if
	else if (item.types[0] == "lodging") {
		icon = <LocationCityIcon />; // local
	} // end if
	else if (item.types[0] == "airport") {
		icon = <LocalAirportIcon />; // local
	} // end if	
	
	
	return (
		<div style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
			<div style={{padding:"5px"}}>{icon}</div>
			<div style={{flex:1,padding:"5px"}}>{item.description}</div>
		</div>
	);
};


const processPickReturn = (value) => {
	
	if (value) {
		setPickReturnDate(true);
	} // end if
	else {
		setPickReturnDate(false);
	} // end else

	setPickReturn(value);		
	
}

// set Use of history for PUSH 
var navigate = useNavigate();
	
/* Execute recaptcha to retrieve token*/
const recaptchaRef = window.grecaptcha;
const executeReCaptcha = async () => {
	const token = await recaptchaRef.execute();
	return token;
}

// Run after mount component
React.useEffect(() => {

	// Retrieve User Data Obj. 
	var result = localStorage.getItem("@memovo/userData", undefined,true);
	var userData = JSON.parse(result);  

	  // If token dont exist, redirect to onboarding or register
	  if (!userData) {
		  navigate("/home");
		  return;
	  } // end if

  // set scroll to top
  window.scrollTo(0, 1);

	// Generate temp times
	const times = [];
	for (let hour = 7; hour < 24; hour++) {
		for (let minute of [0, 15,30,45]) {
			const formattedTime = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
			times.push({ name: formattedTime, value: formattedTime });
		}
	}
	setTimeOptions(times);  
  
  setLoading(false);
  
  // Load stored info
  if (transportFilters.departureLocal) {

	setDepartureLocal(transportFilters.departureLocal);

	setDepartureDate(transportFilters.departureDate);
	setDepartureTime(transportFilters.departureTime);
	
	setDestinationLocal(transportFilters.destinationLocal);
	
	setDestinationDate(transportFilters.destinationDate);
	setDestinationTime(transportFilters.destionationTime);

	setPersonAddAdult(transportFilters.adultPassengers);
	setLuggage(transportFilters.luggageAdd);
	
  
} // end if


  // Runs when UNMOUNT component
  return () => {
    
	// unmount...	
	
  };
  
}, []); // end useEffect 


// Execute generateTotalLabel if value changes
React.useEffect(() => {

	generateTotalLabel();
	generateTotalLugLabel();
	  
}, [personAddAdult,personAddYoung,personAddElder,luggageAdd]); // end useEffect 


// Verifiy is any destination selected are airport or similar
React.useEffect(() => {

	try {

		if (departureLocal){
			var airportExist = departureLocal.types.includes("airport");
		} 
		else {
			var airportExist =false;
		}

		} catch(e) {
			console.log('e.message >>> '+JSON.stringify(e.message,null,2));
			var airportExist = false;
		}

	if (airportExist) {
		setTimeWarning(true);
	} // end if 
	else {
		setTimeWarning(false);
	} // end else
	  
}, [departureLocal,destinationLocal]); // end useEffect 



const stepsData = [
        {
            label: 'Tipo deslocamento',
            command: (event) => {
					navigate("/order");
            }
        },
        {
            label: 'Dados da Viagem'
        },
        {
            label: 'Escolha do Transporte',
            command: (event) => {
                console.log("[Clicou Step 3]--->");
            }
        },
        {
            label: 'Confirmação de Dados',
            command: (event) => {
                console.log("[Clicou Step 5]--->");
            }
        },	
        {
            label: 'Confirmação de Reserva',
            command: (event) => {
                console.log("[Clicou Step 4]--->");
            }
        }
];

var youngAgesRangeArray = [];
for (var i = 0; i <= 25; i++) {
	youngAgesRangeArray.push({
		age:i,
		code:i
	}); 
}
const youngAges = [...youngAgesRangeArray];
const setYoungAge = (index,value) => {

	// Filter for exiting value. IF exist, replace
	var array = [...selectedYoungAge]; // clone
	var indexArray = array.filter(item => item.index != index);	
	
	var arrayObj = {
		index:index,
		value:value
	}
	var finalArray = [...indexArray,arrayObj];
		
	setSelectedYoungAge(finalArray);
}
var elderAgesRangeArray = [];
for (var i = 58; i <= 99; i++) {
	elderAgesRangeArray.push({
		age:i,
		code:i
	}); 
}
const elderAges = [...elderAgesRangeArray];
const setElderAge = (index,value) => {

	// Filter for exiting value. IF exist, replace
	var array = [...selectedElderAge]; // clone
	var indexArray = array.filter(item => item.index != index);	
	
	var arrayObj = {
		index:index,
		value:value
	}
	var finalArray = [...indexArray,arrayObj];
		
	setSelectedElderAge(finalArray);
}

/*
const checkAll = (e) => {
	setCheckedAll(e.value);
	setCheckedAirplane(false);
	setCheckedTrain(false);
	setCheckedBus(false);
}*/

const executeTransferSearch = ()  => {
	
	// add data to store and set searching mode onChange
	//
	
	// Redirect to searchResultPage	
	navigate("/order/transfer/searchResult");	
	
}

const accept = () => {
	
	 setSaving(false);
	 setLoading(true);
	
	// Set filters and search mode on STORE (redux)
	var data = {
		
		departureLocal:departureLocal,
		destinationLocal:destinationLocal,
		departureDate:departureDate,
		departureTime:departureTime,
		luggageAdd:luggageAdd,
		adultPassengers:personAddAdult
	} 
	setTransportFilters(data);	
		
	 setTimeout(function() {executeTransferSearch()},1000);

}

const reject = () => {
	setSaving(false);
}

const confirmPrompt = () => {
	confirmDialog({
		message: 'Tem certeza que quer seguir com a busca com os dados informados?',
		header: 'Pronto para buscar um transfer? ',
		icon: 'pi pi-exclamation-triangle',
		accept,
		reject,
		onHide:setSaving(false),
		acceptLabel:"Sim, tenho certeza!",
		rejectLabel:"Não, vou revisar.",
		contentStyle:{maxWidth:"30vw",width:"30vw"}
	});
};

const searchTransfer = ()  => {
		
	// Check required fields
	if ( (typeof departureLocal !== 'object' && Array.isArray(value) && departureLocal === null) || (typeof destinationLocal !== 'object' && Array.isArray(destinationLocal) && destinationLocal === null) || !departureTime || !departureDate) {
		setAlert(true);
		setAlertTitle("Verifique as informações");
		setAlertBody("Um ou mais campos não estão preenchidos. É necessário que preencha todos os campos marcados com * para prosseguir com o cadastro.");	
		return;
	} // end if	
	
	let currentTotal = personAddAdult + personAddYoung + personAddElder;
	if (currentTotal <= 0) {
		setAlert(true);
		setAlertTitle("Verifique os passageiros");
		setAlertBody("É necessário ao menos um viajante para fazer a busca.");	
		return;		
	} // end if		
	
	setSaving(true);
	
	var data = {
		from: departureLocal.description,
		to: destinationLocal.description
	} 
	var props = {
		setAlert:setAlert,
		setAlertTitle:setAlertTitle,
		setAlertBody:setAlertBody,
		setLoading:setLoading,
		confirmPrompt:confirmPrompt
	}
	retrieveAppAddressesGoogleMatrixDistance(data,props);
	
}

 
  return (
	<>
	<div id="loginSection" style={{marginTop:"0"}} className={isTabletOrMobile ? "login__wrapper mobile" : "login__wrapper"} >
		<div style={{minHeight:"728px",height:"auto"}} className={isTabletOrMobile ? "login__wrapper_form_wrapper mobile" : "login__wrapper_form_wrapper"}> 		
			<div className={isTabletOrMobile ? "login__form_wrapper mobile" : "login__form_wrapper"} style={{alignItems:"flex-start"}}>
						
				<div style={{flex:1,margin:"2rem",marginTop:"50px",minHeight:"520px",marginLeft:"auto",marginRight:"auto"}}>
				
					<div style={{fontSize:"28px",textAlign:"center",letterSpacing:"0.1em",lineHeight:"120%",fontWeight:"400",fontStyle:"normal",fontFamily:"Titillium Web",color:"#2E0249",flex:"1"}}>Contratação de transfer</div>
					
					<div style={{display:"flex",flexDirection:isTabletOrMobile ? "column" : "row",alignItems:"center",justifyContent:"center",gap:"15px",marginTop:"20px"}}> 
					
						<div style={{marginLeft:"auto",marginRight:"auto",display:"flex",flexDirection:isTabletOrMobile ? "column" : "column",alignItems:"center",justifyContent:"center",gap:"15px",width:"100%",paddingBottom:"20px"}}  className="selectBox">
					
							<div>
								<Steps model={stepsData} activeIndex={1} readOnly={false} />
							</div>

							<div className={loading ? "imagem-zoom serviceTransferSelector" : "imagem-zoom serviceTransferSelector animate"} style={{borderRadius:"30px",backgroundColor:"#FFFFFF", width:"70vw",maxWidth:"1010px", minHeight:"400px",marginTop:"20px",display:"flex"}}> 
							
								<div className="searchWrapper" style={{flex:1,width:"100%",height:"100%",margin:"75px",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
										
										<div style={{width:"100%",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",marginBottom:"35px"}}> 
										
											<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '14px',lineHeight: '120%',letterSpacing: '0.1em', display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
																						
												<div style={{flex:1}}>										
													<span className="p-float-label"  >
														<AutoComplete itemTemplate={itemTemplate} field="description"  type="search" forceSelection emptyMessage="Nenhum endereço ou local encontrado" delay={1000} inputId="departureLocal" value={(typeof departureLocal === 'object' && !Array.isArray(departureLocal) && departureLocal !== null) ? departureLocal.description : departureLocal} suggestions={items} completeMethod={search} onChange={(e) => {setDepartureLocal(e.target.value); } }  minLength={3} placeholder="Aeroporto, endereço, lugar, nome de hotel..." style={{height:"56px",width:"100%"}} inputStyle={{width:"100%"}} />
														<label htmlFor="departureLocal">Pegar em <span className="requiredField">*</span></label>
														
														{/*<GooglePlacesAutocomplete
														  apiKey="AIzaSyCAHF7IiEvbJkvOdX5eqwAkQqD0IXvqSN4"
															 apiOptions={{ language: 'pt-BR', region: 'BR' }}
															 minLengthAutocomplete="3"
															 autocompletionRequest={{
																componentRestrictions: {
																country: [
																  'ad', 'al', 'at', 'ba', 'be', 'bg', 'by', 'ch', 'cy', 'cz', 'de', 'dk', 'ee', 'es', 'fi', 'fo', 'fr',
																  'gb', 'gg', 'gi', 'gr', 'hr', 'hu', 'ie', 'im', 'is', 'it', 'je', 'li', 'lt', 'lu', 'lv', 'mc', 'md',
																  'me', 'mk', 'mt', 'nl', 'no', 'pl', 'pt', 'ro', 'rs', 'ru', 'se', 'si', 'sj', 'sk', 'sm', 'ua', 'va'
																]
																}
															  }} 														  
															selectProps={{
															  value:departureLocal,
															  onChange: setDepartureLocal,
															  styles:{ input: (provided) => ({
																...provided,
																height:"56px",
																width:"100%"
															  })}
															}}	
														/>*/}														
														
													</span>													
												</div>
												
												<div style={{flex:1,marginLeft:"15px"}}>										
													<span className="p-float-label"  >
														<AutoComplete field="description" itemTemplate={itemTemplate} type="search" forceSelection emptyMessage="Nenhum endereço ou local encontrado" delay={1000} inputId="destinationLocal" value={(typeof destinationLocal === 'object' && !Array.isArray(destinationLocal) && destinationLocal !== null) ? destinationLocal.description : destinationLocal} suggestions={items} completeMethod={search} onChange={(e) => setDestinationLocal(e.target.value)} minLength={3} placeholder="Aeroporto, endereço, lugar, nome de hotel..." style={{height:"56px",width:"100%"}} inputStyle={{width:"100%"}} />
														<label htmlFor="destinationLocal">Deixar em <span className="requiredField">*</span></label>
														
														{/*<GooglePlacesAutocomplete
														  apiKey="AIzaSyCAHF7IiEvbJkvOdX5eqwAkQqD0IXvqSN4"
															 apiOptions={{ language: 'pt-BR', region: 'BR' }}
															 minLengthAutocomplete="3"
															 autocompletionRequest={{
																componentRestrictions: {
																country: [
																  'ad', 'al', 'at', 'ba', 'be', 'bg', 'by', 'ch', 'cy', 'cz', 'de', 'dk', 'ee', 'es', 'fi', 'fo', 'fr',
																  'gb', 'gg', 'gi', 'gr', 'hr', 'hu', 'ie', 'im', 'is', 'it', 'je', 'li', 'lt', 'lu', 'lv', 'mc', 'md',
																  'me', 'mk', 'mt', 'nl', 'no', 'pl', 'pt', 'ro', 'rs', 'ru', 'se', 'si', 'sj', 'sk', 'sm', 'ua', 'va'
																]
																}
															  }}															  
															selectProps={{
															  value:destinationLocal,
															  onChange: setDestinationLocal,
															  styles:{ input: (provided) => ({
																...provided,
																height:"56px",
																width:"100%"
															  })}
															}} 
														/>*/}															
														
													</span>														
												</div>												
											
											</div>

										</div>
										
										<div style={{width:"100%",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",marginBottom:"35px"}}> 
										
											<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '14px',lineHeight: '120%',letterSpacing: '0.1em', display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
											
												<div style={{flex:1}}>										
													<span className="p-float-label"  >
														<Calendar showButtonBar locale="br"  minDate={minDate} id="departureDate" value={departureDate} onChange={(e) => {setDepartureDate(e.value);setDestinationDate(null)}} style={{height:"56px",width:"100%"}} inputStyle={{width:"100%"}} showIcon dateFormat="dd/mm/yy"  />
														<label htmlFor="departureDate">Pegar no dia <span className="requiredField">*</span></label>
													</span>													
												</div>
												
												<div style={{flex:1,marginLeft:"15px",position:"relative"}}>
													{/*<span className="p-float-label"  >
														<Calendar locale="br" id="departureTime" value={departureTime} onChange={(e) => setDepartureTime(e.value)} icon="pi pi-clock" style={{height:"56px",width:"100%"}} inputStyle={{width:"100%"}} stepMinute={5} showIcon hourFormat="24" timeOnly />
														<label htmlFor="departureTime">Pegar às <span className="requiredField">*</span></label>
													</span>*/}	
													<span className="p-float-label"  >
														<Dropdown showClear  value={departureTime} id="departureTime" onChange={(e) => setDepartureTime(e.value)} options={timeOptions} optionLabel="name" style={{height:"56px",width:"100%",paddingTop:"5px"}} inputStyle={{width:"100%"}}  />
														<label htmlFor="lugs"> Pegar às <span className="requiredField">*</span> </label>
													</span>															
													
													{timeWarning &&
														<small style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",gap:"5px",position:"absolute",width:"100%",marginTop:"5px"}}> Para aeroportos adicione 1h após horário previsto de chegada </small>
													}	

												</div>
												
											</div>
										</div>
												
												
										<div style={{width:"100%",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",marginBottom:"35px",display:"none"}}> 
										
											<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '14px',lineHeight: '120%',letterSpacing: '0.1em', display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",position:"relative"}}>
											
												{pickReturnDate &&
												<>
												<div style={{flex:1}}>										
													<span className="p-float-label"  >
														<Calendar showButtonBar locale="br"  minDate={departureDate} id="destinationDate" value={destinationDate} onChange={(e) => {setDestinationDate(e.value);setReturnDate(null)}} style={{height:"56px",width:"100%"}} inputStyle={{width:"100%"}} showIcon dateFormat="dd/mm/yy"  />
														<label htmlFor="destinationDate">Retornar no dia </label>
													</span>													
												</div>
												
												<div style={{flex:1,marginLeft:"15px"}}>
													<span className="p-float-label"  >
														<Calendar locale="br" id="destinationTime" minHour={departureTime} value={destinationTime} onChange={(e) => setDestinationTime(e.value)} icon="pi pi-clock" style={{height:"56px",width:"100%"}} inputStyle={{width:"100%"}} stepMinute={5} showIcon hourFormat="24" timeOnly />
														<label htmlFor="destinationTime">Retornar às</label>
													</span>												
												</div>
												</>
												}

													<div className="flex align-items-center" style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",gap:"5px",position:pickReturnDate? "absolute" : "relative",left:pickReturnDate ? "45%" : "unset",marginTop:pickReturnDate? "100px" : "unset"}}>
														<Checkbox inputId="pickReturn" name="pickReturn" value="1"  onChange={e => processPickReturn(e.checked)}  checked={pickReturn} />
														<label htmlFor="pickReturn" className="ml-2" style={{fontFamily:'Titillium Web',fontStyle:'normal',fontWeight:400,fontSize:'14px',lineHeight:'24px',letterSpacing:'0.15px',color:'#2E0249'}}>Ida e volta</label> <div className="tooltip" data-pr-tooltip="Você pode adicionar uma data/hora de retorno saindo do mesmo local onde ficou" data-pr-position="bottom" data-pr-showdelay="300" ><img src={tooltipIcon} style={{width:"20px",height:"auto"}} className="noSelect" /></div> <Tooltip target=".tooltip" />
													</div>													
	
												
											</div>													

										</div>													
										
										<div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center",marginBottom:"50px"}}> 
										
											<div style={{flex:1,textAlign:"center",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '14px',lineHeight: '120%',letterSpacing: '0.1em', display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>					
												<div style={{width:"100%",flex:1,marginLeft:"15px"}}>									
															
													<div>
														<Button iconPos="right" icon={!menuOpened ? "pi pi-angle-down" : "pi pi-angle-up"} size="large" rounded text aria-label="Menu" onClick={!menuOpened ? (e) => {op.current.toggle(e);e.currentTarget.blur()} : (e) => {op.current.toggle(e);e.currentTarget.blur()} } label={personAddLabel} style={{fontFamily:'Titillium Web',fontStyle:'normal',fontWeight:600,fontSize:'14px',lineHeight:'120%',textAlign:'center',letterSpacing:'0.1em',color:'#814DE5'}} />
														<OverlayPanel className="overlayPanel" onHide={onHide} onShow={onShow} ref={op} style={{padding:"0 !important",backgroundColor:"#FFFFFF",minHeight:"10vh",width:"300px",maxHeight:"20vh",overflow:"auto"}}>
														
															<div style={{width:"100%",marginBottom:"2px",borderBottom:"1px solid silver",fontWeight:"600",display:"flex",alignItems:"center",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center",padding:"15px"}} >
															
																<div style={{flex:1,fontFamily:'Titillium Web',fontStyle:'normal',fontWeight:400,fontSize:'18px',lineHeight:'24px',letterSpacing:'0.15px',color:'#2E0249'}}>
																	<div style={{fontWeight:"bold"}}>Viajantes</div>
																</div>
																
																<div style={{flex:1,display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}> 
																	<Button style={{width:"32px",height:"32px"}} icon="pi pi-minus" rounded outlined aria-label="Mais" onClick={()=>setPerson('-','adult')} />
																	<span style={{flex:0.5,textAlign:"center",fontSize:"18px"}}> {personAddAdult} </span>
																	<Button style={{width:"32px",height:"32px"}} icon="pi pi-plus" rounded outlined aria-label="Mais" onClick={()=>setPerson('+','adult')} />
																</div>
															
															</div>					

														</OverlayPanel>													
													</div>

															
												</div>													
											
											</div>
											
											<div style={{flex:1,textAlign:"center",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '14px',lineHeight: '120%',letterSpacing: '0.1em', display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>					
												<div style={{width:"100%",flex:1,marginLeft:"15px"}}>										
															
													<div>
														<Button iconPos="right" icon={!menuLugOpened ? "pi pi-angle-down" : "pi pi-angle-up"} size="large" rounded text aria-label="Menu" onClick={!menuLugOpened ? (e) => {opLug.current.toggle(e);e.currentTarget.blur()} : (e) => {opLug.current.toggle(e);e.currentTarget.blur()} } label={luggageAddLabel} style={{fontFamily:'Titillium Web',fontStyle:'normal',fontWeight:600,fontSize:'14px',lineHeight:'120%',textAlign:'center',letterSpacing:'0.1em',color:'#814DE5'}} />
														<OverlayPanel className="overlayPanel" onHide={onLugHide} onShow={onLugShow} ref={opLug} style={{padding:"0 !important",backgroundColor:"#FFFFFF",minHeight:"10vh",width:"300px",maxHeight:"20vh",overflow:"auto"}}>
														
															<div style={{width:"100%",marginBottom:"2px",borderBottom:"1px solid silver",fontWeight:"600",display:"flex",alignItems:"center",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center",padding:"15px"}} >
															
																<div style={{flex:1,fontFamily:'Titillium Web',fontStyle:'normal',fontWeight:400,fontSize:'18px',lineHeight:'24px',letterSpacing:'0.15px',color:'#2E0249'}}>
																	<div style={{fontWeight:"bold"}}>Bagagens</div>
																</div>
																
																<div style={{flex:1,display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}> 
																	<Button style={{width:"32px",height:"32px"}} icon="pi pi-minus" rounded outlined aria-label="Mais" onClick={()=>setLug('-','adult')} />
																	<span style={{flex:0.5,textAlign:"center",fontSize:"18px"}}> {luggageAdd} </span>
																	<Button style={{width:"32px",height:"32px"}} icon="pi pi-plus" rounded outlined aria-label="Mais" onClick={()=>setLug('+','adult')} />
																</div>
															
															</div>					

														</OverlayPanel>													
													</div>

															
												</div>													
											
											</div>											

										</div>
										
										<div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center",gap:"20px"}}> 
											<ConfirmDialog />
											
											<div style={{textAlign:"left",flex:1}}>
												<Button label="Limpar formulário" icon="pi pi-trash" severity="danger" text />
											</div>												
											
											<Button rounded outlined className="pT10" label="Voltar" disabled={saving} onClick={()=>navigate("/order")} />
										
											<Button rounded className="pT10" label="Buscar transfer" loading={saving} disabled={saving} onClick={()=>searchTransfer()} />
										</div>
							
								</div>
							
							</div>


						</div>						

					</div>				
						
				
				</div>
			
			</div> 
		</div>
    </div>
	
	<div id="contatoSection" className="contatoSection__wrapper">

		<div className={isTabletOrMobile ? "contatoSection__wrapper_content_wrapper mobile" : "contatoSection__wrapper_content_wrapper"}  >

			<div className="flex-1 dFlex aItemsC jContentE"> 
			
				<div className="textBlockMargin contatoSection__wrapper_content_text" >
				
					<div className="contatoSection__wrapper_content_header">Central de Relacionamento</div>
				
					Estamos a disposição para atender às suas necessidades e responder às suas perguntas. Conheça as principais formas de entrar em contato conosco:
					
					<div style={{marginTop: "2.8125rem", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "stretch", gap: "15px"}}>

						<div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center",gap:"15px"}}>
							<div><img src={contactWhatsappIcon} style={{width:"49px",height:"auto"}} className="noSelect" /></div>
							<div className="contatoOptionText"><Whatsapp phone="552140209749" style={{color:"#FFF",textDecoration:"none"}}>Whatsapp 4020-9749</Whatsapp></div>
						</div>

						<div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center",gap:"15px"}}>
							<div><img src={contactPhoneIcon} style={{width:"49px",height:"auto"}} className="noSelect"  /></div>
							<div className="contatoOptionText"><Callto phone="+552140209749" style={{color:"#FFF",textDecoration:"none"}}>Telefone 4020-9749</Callto></div>
						</div>
						
						<div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center",gap:"15px"}}>
							<div><img src={contactEmailIcon} style={{width:"49px",height:"auto"}} className="noSelect"  /></div>
							<div className="contatoOptionText"><Button label="Enviar mensagem" rounded className="pT10 mR10 headerButton tColorWhite w100" style={{marginBottom:"0.625rem"}} onClick={()=>setDialog('messageModal',true)} /></div>
						</div>
						

					</div>					
				
				</div> 
				
			</div>
			
			<div className="tAlignL flex-1_5"> 
				<img src={contactConcept} className="contatoSection__wrapper_content_image noSelect" />
			</div>			
			
		</div>
			<AlertView
				alert={alert}
				setAlert={setAlert}
				alertTitle={alertTitle}
				alertBody={alertBody}	
				scrollBlock={false}				
			/>	
			<MessageView
				alert={alert}
				setAlert={setAlert}
				alertTitle={alertTitle}
				alertBody={alertBody}	
				scrollBlock={false}				
			/>	
    </div>
	
	<div id="memovoSection" className="memovoSection__wrapper">
	
		<div className={isTabletOrMobile ? "memovoSection__wrapper_content_wrapper mobile" : "memovoSection__wrapper_content_wrapper"}>
		
			<div className="flex-1"> 
				<img src={desktopConcept} className="memovoSection__wrapper_content_image noSelect" />
			</div>
			<div className="flex-1 dFlex aItemsC jContentS"> 
			
				<div className="textBlockMargin memovoSection__wrapper_content_text" >
				
					<div className="memovoSection__wrapper_content_header">Inovando o Mercado B2B de Mobilidade</div>
									
					A MEMOVO é uma plataforma B2B inovadora que facilita a busca e comercialização de serviços de transporte e mobilidade. Pioneira como marketplace B2B, oferece passagens aéreas, trens, ônibus, aluguel de veículos e transfers em um só lugar, permitindo a criação de roteiros completos de mobilidade. Com interface amigável e processo simples, a plataforma proporciona agilidade e eficiência aos profissionais do setor, atendendo às necessidades dos usuários e impulsionando o crescimento do seu negócio.
				
				</div> 
				
			</div>
			
		</div>
		
    </div>	
	
	</>
  );
    
}

const mapStateToProps = store => ({
	dialog:store.appReducer.dialog,
	transportFilters:store.orderReducer.transportFilters
   });
  const mapDispatchToProps = dispatch => bindActionCreators({ ...AppActions,...OrderActions }, dispatch);

export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(HomePage);	


