// src/components/HotelCards.js
import React, { useState, useEffect } from 'react';
import './AgencySelector.css';


import { FaBuilding, FaUser } from 'react-icons/fa';

const AgencySelector = ({masterUser,currentAgency,setCurrentAgencyAction}) => {

    if (!masterUser) {
        return(<div>NOPE</div>);        
    }

  return (
      <div className="user-card-select mousePointer">

        {!currentAgency.agencyCode &&
		<>
        <div className="company-info" onClick={()=>setCurrentAgencyAction()}>
            <div className="icon-wrapper">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5 4C5 2.89543 5.89543 2 7 2H17C18.1046 2 19 2.89543 19 4V21H13.5V18C13.5 17.4477 13.0523 17 12.5 17H11.5C10.9477 17 10.5 17.4477 10.5 18V21H5V4ZM8 5C8 4.44772 8.44772 4 9 4H10C10.5523 4 11 4.44772 11 5V6C11 6.55229 10.5523 7 10 7H9C8.44772 7 8 6.55228 8 6V5ZM14 4C13.4477 4 13 4.44772 13 5V6C13 6.55228 13.4477 7 14 7H15C15.5523 7 16 6.55229 16 6V5C16 4.44772 15.5523 4 15 4H14ZM8 9C8 8.44772 8.44772 8 9 8H10C10.5523 8 11 8.44772 11 9V10C11 10.5523 10.5523 11 10 11H9C8.44772 11 8 10.5523 8 10V9ZM9 12C8.44772 12 8 12.4477 8 13V14C8 14.5523 8.44772 15 9 15H10C10.5523 15 11 14.5523 11 14V13C11 12.4477 10.5523 12 10 12H9ZM13 13C13 12.4477 13.4477 12 14 12H15C15.5523 12 16 12.4477 16 13V14C16 14.5523 15.5523 15 15 15H14C13.4477 15 13 14.5523 13 14V13Z" fill="#D9D9D9"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5 4C5 2.89543 5.89543 2 7 2H17C18.1046 2 19 2.89543 19 4V21H13.5V18C13.5 17.4477 13.0523 17 12.5 17H11.5C10.9477 17 10.5 17.4477 10.5 18V21H5V4ZM8 5C8 4.44772 8.44772 4 9 4H10C10.5523 4 11 4.44772 11 5V6C11 6.55229 10.5523 7 10 7H9C8.44772 7 8 6.55228 8 6V5ZM14 4C13.4477 4 13 4.44772 13 5V6C13 6.55228 13.4477 7 14 7H15C15.5523 7 16 6.55229 16 6V5C16 4.44772 15.5523 4 15 4H14ZM8 9C8 8.44772 8.44772 8 9 8H10C10.5523 8 11 8.44772 11 9V10C11 10.5523 10.5523 11 10 11H9C8.44772 11 8 10.5523 8 10V9ZM9 12C8.44772 12 8 12.4477 8 13V14C8 14.5523 8.44772 15 9 15H10C10.5523 15 11 14.5523 11 14V13C11 12.4477 10.5523 12 10 12H9ZM13 13C13 12.4477 13.4477 12 14 12H15C15.5523 12 16 12.4477 16 13V14C16 14.5523 15.5523 15 15 15H14C13.4477 15 13 14.5523 13 14V13Z" fill="#814DE5"/>
                <rect x="13" y="8" width="3" height="3" rx="1" fill="white"/>
                <g opacity="0.3">
                    <path d="M3 22.9881C3 21.8901 3.89009 21 4.98807 21H19.0119C20.1099 21 21 21.8901 21 22.9881C21 22.9947 20.9947 23 20.9881 23H3.01193C3.00534 23 3 22.9947 3 22.9881Z" fill="#D9D9D9"/>
                    <path d="M3 22.9881C3 21.8901 3.89009 21 4.98807 21H19.0119C20.1099 21 21 21.8901 21 22.9881C21 22.9947 20.9947 23 20.9881 23H3.01193C3.00534 23 3 22.9947 3 22.9881Z" fill="#814DE5"/>
                </g>
            </svg>
            </div>
            <div className="select-agency-label">Selecione uma agência</div>
        </div>
        </>
        }

        {currentAgency.agencyCode &&
		<>
        <div className="company-info" onClick={()=>setCurrentAgencyAction()}>
            <div className="icon-wrapper">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5 4C5 2.89543 5.89543 2 7 2H17C18.1046 2 19 2.89543 19 4V21H13.5V18C13.5 17.4477 13.0523 17 12.5 17H11.5C10.9477 17 10.5 17.4477 10.5 18V21H5V4ZM8 5C8 4.44772 8.44772 4 9 4H10C10.5523 4 11 4.44772 11 5V6C11 6.55229 10.5523 7 10 7H9C8.44772 7 8 6.55228 8 6V5ZM14 4C13.4477 4 13 4.44772 13 5V6C13 6.55228 13.4477 7 14 7H15C15.5523 7 16 6.55229 16 6V5C16 4.44772 15.5523 4 15 4H14ZM8 9C8 8.44772 8.44772 8 9 8H10C10.5523 8 11 8.44772 11 9V10C11 10.5523 10.5523 11 10 11H9C8.44772 11 8 10.5523 8 10V9ZM9 12C8.44772 12 8 12.4477 8 13V14C8 14.5523 8.44772 15 9 15H10C10.5523 15 11 14.5523 11 14V13C11 12.4477 10.5523 12 10 12H9ZM13 13C13 12.4477 13.4477 12 14 12H15C15.5523 12 16 12.4477 16 13V14C16 14.5523 15.5523 15 15 15H14C13.4477 15 13 14.5523 13 14V13Z" fill="#D9D9D9"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5 4C5 2.89543 5.89543 2 7 2H17C18.1046 2 19 2.89543 19 4V21H13.5V18C13.5 17.4477 13.0523 17 12.5 17H11.5C10.9477 17 10.5 17.4477 10.5 18V21H5V4ZM8 5C8 4.44772 8.44772 4 9 4H10C10.5523 4 11 4.44772 11 5V6C11 6.55229 10.5523 7 10 7H9C8.44772 7 8 6.55228 8 6V5ZM14 4C13.4477 4 13 4.44772 13 5V6C13 6.55228 13.4477 7 14 7H15C15.5523 7 16 6.55229 16 6V5C16 4.44772 15.5523 4 15 4H14ZM8 9C8 8.44772 8.44772 8 9 8H10C10.5523 8 11 8.44772 11 9V10C11 10.5523 10.5523 11 10 11H9C8.44772 11 8 10.5523 8 10V9ZM9 12C8.44772 12 8 12.4477 8 13V14C8 14.5523 8.44772 15 9 15H10C10.5523 15 11 14.5523 11 14V13C11 12.4477 10.5523 12 10 12H9ZM13 13C13 12.4477 13.4477 12 14 12H15C15.5523 12 16 12.4477 16 13V14C16 14.5523 15.5523 15 15 15H14C13.4477 15 13 14.5523 13 14V13Z" fill="#00A3FF"/>
                    <rect x="13" y="8" width="3" height="3" rx="1" fill="white"/>
                    <g opacity="0.3">
                        <path d="M3 22.9881C3 21.8901 3.89009 21 4.98807 21H19.0119C20.1099 21 21 21.8901 21 22.9881C21 22.9947 20.9947 23 20.9881 23H3.01193C3.00534 23 3 22.9947 3 22.9881Z" fill="#D9D9D9"/>
                        <path d="M3 22.9881C3 21.8901 3.89009 21 4.98807 21H19.0119C20.1099 21 21 21.8901 21 22.9881C21 22.9947 20.9947 23 20.9881 23H3.01193C3.00534 23 3 22.9947 3 22.9881Z" fill="#00A3FF"/>
                    </g>
                </svg> 
            </div>
            <div className="company-name">{currentAgency.agencyName}</div>
        </div>
        <div className="user-info" onClick={()=>setCurrentAgencyAction()}>
            <div className="icon-wrapper">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                    <path d="M9 8C6.79086 8 5 6.20914 5 4C5 1.79086 6.79086 0 9 0C11.2091 0 13 1.79086 13 4C13 6.20914 11.2091 8 9 8Z" fill="#00A3FF"/>
                    <path d="M0.000651684 17.1992C0.388259 12.4265 4.26191 10 8.98334 10C13.7712 10 17.7049 12.2932 17.9979 17.2C18.0096 17.3955 17.9979 18 17.2467 18C13.5411 18 8.03472 18 0.727502 18C0.476712 18 -0.0204618 17.4592 0.000651684 17.1992Z" fill="#D9D9D9"/>
                    <path d="M0.000651684 17.1992C0.388259 12.4265 4.26191 10 8.98334 10C13.7712 10 17.7049 12.2932 17.9979 17.2C18.0096 17.3955 17.9979 18 17.2467 18C13.5411 18 8.03472 18 0.727502 18C0.476712 18 -0.0204618 17.4592 0.000651684 17.1992Z" fill="#00A3FF"/>
                </svg>                
            </div>
            <div className="user-name">{currentAgency.employeeName}</div>
        </div>
        </>
        }

        <div className="dropdown-icon" onClick={()=>setCurrentAgencyAction()}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <rect width="16" height="16" rx="8" fill="#814DE5"/>
            <path d="M8.62854 4.9421C8.62648 4.60092 8.3504 4.32484 8.00921 4.32278C7.66241 4.32068 7.38064 4.60215 7.38236 4.94895L7.39445 7.39441L4.94899 7.38232C4.60219 7.3806 4.32072 7.66237 4.32282 8.00917C4.32488 8.35036 4.60096 8.62644 4.94214 8.6285L7.62323 8.64468C8.18879 8.64809 8.64813 8.18875 8.64472 7.62319L8.62854 4.9421Z" fill="white" stroke="white" stroke-width="0.3"/>
         <path d="M7.37146 11.0579C7.37352 11.3991 7.6496 11.6752 7.99079 11.6772C8.33759 11.6793 8.61936 11.3979 8.61764 11.0511L8.60555 8.60559L11.051 8.61768C11.3978 8.6194 11.6793 8.33763 11.6772 7.99083C11.6751 7.64964 11.399 7.37356 11.0579 7.3715L8.37677 7.35532C7.81121 7.35191 7.35187 7.81125 7.35528 8.37681L7.37146 11.0579Z" fill="white" stroke="white" stroke-width="0.3"/>
        </svg>
        </div>
     </div>
  );


}

export default AgencySelector;
