import { touchRippleClasses } from "@mui/material";

export function validarCNPJ(cnpj) {
  cnpj = cnpj.replace(/[^\d]+/g, '');

  if (cnpj === '') return false;

  if (cnpj.length !== 14) return false;

  const numerosRepetidos = '00000000000000 11111111111111 22222222222222 33333333333333 44444444444444 55555555555555 66666666666666 77777777777777 88888888888888 99999999999999';
  if (numerosRepetidos.indexOf(cnpj) !== -1) return false;

  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  let digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;

  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }

  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== Number(digitos.charAt(0))) return false;

  tamanho += 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }

  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== Number(digitos.charAt(1))) return false;

  return true;
}

export function validarCPF(cpf) {
  cpf = cpf.replace(/[^\d]+/g, '');

  if (cpf === '') return false;

  if (cpf.length !== 11) return false;

  if (/^(\d)\1{10}$/.test(cpf)) return false;

  let soma = 0;
  for (let i = 1; i <= 9; i++) {
    soma += parseInt(cpf.charAt(i - 1)) * (11 - i);
  }

  let resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.charAt(9))) return false;

  soma = 0;
  for (let i = 1; i <= 10; i++) {
    soma += parseInt(cpf.charAt(i - 1)) * (12 - i);
  }

  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.charAt(10))) return false;

  return true;
}

export function validarEmail(email) {
  const regexEmail = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
  return regexEmail.test(email);
}

export function validarTelefone(telefone) {
  const regexTelefone = /^\(?(\d{2})\)?\s?(\d{4,5})\s?-?\s?(\d{4})$/;
  return regexTelefone.test(telefone);
}

export function validarNome(nome) {
  const regexNome = /^[a-zA-ZÀ-ú\s]+$/;
  return nome.length >= 3 && regexNome.test(nome);
}

export function validarNomeEmpresa(nome) {
  // Regex atualizado para incluir números e pontos
  const regexNome = /^[a-zA-ZÀ-ú0-9.\s]+$/;
  return nome.length >= 3 && regexNome.test(nome);
}

// 8 ou 9 digits for phone
export const maskPhone = (valueRaw) => {

   let value = String(valueRaw).replace(/\D/g, ""); // convert to sdtring if number

	if (value.length <= 10) {
	  return value
		.replace(/\D/g, "")
		.replace(/(\d{2})(\d)/, "($1) $2")
		.replace(/(\d{4})(\d)/, "$1-$2")
		.replace(/(-\d{4})(\d+?)$/, "$1");
	} // end if
	else {
	  return value
		.replace(/\D/g, "")
		.replace(/(\d{2})(\d)/, "($1) $2")
		.replace(/(\d{5})(\d)/, "$1-$2")
		.replace(/(-\d{4})(\d+?)$/, "$1");		
	} // end else
};

// Credit Card Mask
export const maskCreditcard = (value) => {
	
	var startWith = value.slice(0, 2);
	
	// If Amex
	if ((startWith == "34" || startWith == "37") ) { 
	  return value
		.replace(/\D/g, "")
		.replace(/(\d{4})(?=\d)?(\d{6})?(\d{5})/,"$1 $2 $3")	
	} // end if
	else {
	  return value
		.replace(/\D/g, "")
		.replace(/(\d{4})(?=\d)/, "$1 ")	  
		.replace(/(\d{4})(?=\d)/, "$1 ")
		.replace(/(\d{4})(?=\d)/, "$1 ")
		.replace(/(\d{4})(?=\d)/, "$1 ")
	} // end else
};

export const maskCPF = (value) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1");
};

export const maskCNPJ = (value) => {
  return value
    .replace(/\D+/g, '') 
    .replace(/(\d{2})(\d)/, '$1.$2') 
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2') 
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1') 
};

export const Callto = ({ phone, style, children }) => {
  return <a href={`tel:${phone}`} style={style}>{children}</a>;
};

export const Whatsapp = ({ phone, style, children }) => {
  return <a href={`http://api.whatsapp.com/send?phone=${phone}`} style={style} target="_blank">{children}</a>;
  
};

export const extractRFBListData = (jsonData)  => {

  let porte = '';
  let situacao = '';
  let cnae = '';

  // Percorre o array de objetos JSON
  jsonData.forEach(item => {
    // Extrai os dados de acordo com a chave
    switch (item.key) {
      case 'porte':
        // Extrai a descrição do porte
        const porteMatch = item.value.match(/descricao: (.*)/);
        if (porteMatch) {
          porte = porteMatch[1];
        }
        break;
      case 'situacao':
        // Extrai o nome e a data da situação
        const situacaoMatch = item.value.match(/nome: (.*) \| data: (.*)/);
        if (situacaoMatch) {
          situacao = `${situacaoMatch[1]} desde ${situacaoMatch[2]}`;
        }
        break;
      case 'cnae':

          // Expressão regular para extrair o valor de fiscal e descrição
          const fiscalRegex = /fiscal: (\d+)/;
          const descricaoRegex = /descricao: (.+?) \|/;

          // Executa as expressões regulares para obter os valores
          const fiscalMatch = item.value.match(fiscalRegex);
          const descricaoMatch = item.value.match(descricaoRegex);

          // Verifica se houve correspondência e extrai os valores
          const fiscal = fiscalMatch ? fiscalMatch[1] : null;
          const descricao = descricaoMatch ? descricaoMatch[1] : null;      

        if (fiscal && descricao) {
          cnae = fiscal+ ' ('+descricao+')';
        }     
        break;
      default:
        break;
    }
  });

  // Retorna os dados extraídos
  return {
    porte,
    situacao,
    cnae
  };
}

// Função para extrair os valores desejados
export const extractCnae = (value) => {
	// Expressões regulares para capturar os campos específicos
	const regexPatterns = {
	  fiscal: /fiscal:\s*([\d]+)/,
	  divisao: /divisao:\s*([\d]+)/,
	  grupo: /grupo:\s*([\d.]+)/,
	  classe: /classe:\s*([\d.-]+)/,
	  subClasse: /subClasse:\s*([\d\/-]+)/,
	  descricao: /descricao:\s*([^\|]+)/
	};
  
	// Extrair os valores usando as expressões regulares
	const result = {};
	for (const [key, regex] of Object.entries(regexPatterns)) {
	  const match = value.match(regex);
	  result[key] = match ? match[1].trim() : "Não encontrado";
	}
  
	// Retornar a estrutura desejada
	return {
		divisao: result.divisao,
		grupo: result.grupo,
		classe: result.classe,
		subClasse: result.subClasse,
		fiscal: result.fiscal,
		descricao: result.descricao
	};
  }

export const extractSimplesNacional = (value) => {
  // Expressões regulares para capturar os campos específicos
  const regexPatterns = {
    optante: /optante:\s*([^|]+)/,
    inicio: /inicio:\s*([\d\/]+)/,
    fim: /fim:\s*([\d\/]+)/
  };

  // Extrair os valores usando as expressões regulares
  const result = {};
  for (const [key, regex] of Object.entries(regexPatterns)) {
    const match = value.match(regex);
    result[key] = match ? match[1].trim() : "Não encontrado";
  }

  // Retornar a estrutura desejada
  return {
      optante: result.optante,
      inicio: result.inicio,
      fim: result.fim
  };
}

export const  extractMatrizEndereco = (value) => {
  // Expressões regulares para capturar os campos específicos
  const regexPatterns = {
    cep: /cep:\s*([\d-]+)/,
    tipo: /tipo:\s*([^|]+)/,
    logradouro: /logradouro:\s*([^|]+)/,
    numero: /numero:\s*([^|]+)/,
    complemento: /complemento:\s*([^|]+)/,
    bairro: /bairro:\s*([^|]+)/,
    cidade: /cidade:\s*([^|]+)/,
    uf: /uf:\s*([A-Z]{2})/
  };

  // Extrair os valores usando as expressões regulares
  const result = {};
  for (const [key, regex] of Object.entries(regexPatterns)) {
    const match = value.match(regex);
    result[key] = match ? match[1].trim() : "Não encontrado";
  }

  // Retornar a estrutura desejada
  return {
      cep: result.cep,
      tipo: result.tipo,
      logradouro: result.logradouro,
      numero: result.numero,
      complemento: result.complemento,
      bairro: result.bairro,
      cidade: result.cidade,
      uf: result.uf
  };
}

export const extractNaturezaJuridica = (value) => {
  // Expressões regulares para capturar os campos específicos
  const regexPatterns = {
    codigo: /codigo:\s*(\d+)/,
    descricao: /descricao:\s*([^|]+)/
  };

  // Extrair os valores usando as expressões regulares
  const result = {};
  for (const [key, regex] of Object.entries(regexPatterns)) {
    const match = value.match(regex);
    result[key] = match ? match[1].trim() : "Não encontrado";
  }

  // Retornar a estrutura desejada
  return {
      codigo: result.codigo,
      descricao: result.descricao
  };
} 

export const extractSituacao = (value) => {
  
  const regexPatterns = {
    id: /id:\s*(\d+)/,
    nome: /nome:\s*([^\|]+)/, // Ajuste aqui para capturar até o final da parte de nome
    data: /data:\s*([^\|]+)/
  };

  // Extrair os valores usando as expressões regulares
  const result = {};
  for (const [key, regex] of Object.entries(regexPatterns)) {
    const match = value.match(regex);
    result[key] = match ? match[1].trim() : "Não encontrado";
  }

  // Retornar a estrutura desejada
  return {
      id: result.id,
      nome: result.nome,
      data: result.data
  };

}

export const extractPorte = (value) => {
  // Expressões regulares para capturar os campos
  const regexPatterns = {
    id: /id:\s*(\d+)/,
    descricao: /descricao:\s*([^\|]+)/
  };

  // Extrair os valores usando as expressões regulares
  const result = {};
  for (const [key, regex] of Object.entries(regexPatterns)) {
    const match = value.match(regex);
    result[key] = match ? match[1].trim() : "Não encontrado";
  }

  // Retornar a estrutura desejada
  return {
      id: result.id,
      descricao: result.descricao
  };
}

export const formatCurrency = (value) => {

  var valorAlterado = value;
	valorAlterado = valorAlterado.replace(/\D/g, ""); // Remove todos os não dígitos
	valorAlterado = valorAlterado.replace(/(\d+)(\d{2})$/, "$1,$2"); // Adiciona a parte de centavos
	valorAlterado = valorAlterado.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1."); // Adiciona pontos a cada três dígitos
	//valorAlterado = "€ " + valorAlterado;
	return valorAlterado;

}

export const formatNumberCurrency = (value,currency='EUR') => {
  return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: currency }).format(value);
}

export const formatCurrencyNoSymbol = (value) => {

  var valorAlterado = String(value);
	valorAlterado = valorAlterado.replace(/\D/g, ""); // Remove todos os não dígitos
	valorAlterado = valorAlterado.replace(/(\d+)(\d{2})$/, "$1,$2"); // Adiciona a parte de centavos
	valorAlterado = valorAlterado.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1."); // Adiciona pontos a cada três dígitos
	valorAlterado = valorAlterado;
 
	return valorAlterado; 

}

export const formatCurrencyDecimals = (value) => {
  //if (!value) return '0,00';
  if (!value || value == '0,00') return '';

  // Garante que o valor é tratado como string e remove caracteres não numéricos
  let valorAlterado = String(value).replace(/\D/g, "");
  
  // Preenche com zeros à esquerda para garantir que haja pelo menos dois dígitos
  valorAlterado = valorAlterado.padStart(3, '0');
  
  // Extrai os centavos e a parte inteira
  const length = valorAlterado.length;
  const intPart = valorAlterado.slice(0, length - 2) || '0';
  const decimalPart = valorAlterado.slice(length - 2);
  
  // Formata a parte inteira com pontos de milhar, se necessário
  const formattedIntPart = parseInt(intPart).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  
  return `${formattedIntPart},${decimalPart}`;
};


export const formatCurrencyDecimalsWithLimit = (value,limit) => {
  //if (!value) return '0,00';
  if (!value || value == '0,00') return '';

    // Remove todos os caracteres não numéricos
    let valorAlterado = String(value).replace(/\D/g, "");
  
    // Preenche com zeros à esquerda para garantir que haja pelo menos dois dígitos
    valorAlterado = valorAlterado.padStart(3, '0');
  
    // Converte o valor alterado para número e checa o limite
    let numericValue = parseInt(valorAlterado, 10) / 100;
    if (numericValue > limit) {
      numericValue = limit;
    }
  
    // Converte o valor numérico de volta para string respeitando o formato de moeda
    valorAlterado = numericValue.toFixed(2).replace('.', ','); // Converte para string com duas casas decimais
    valorAlterado = valorAlterado.replace(/\B(?=(\d{3})+(?!\d))/g, "."); // Adiciona pontos a cada três dígitos
  
    return valorAlterado;
};




export const formatCurrencyNoSymbolLimit = (value, limit) => {
  var valorAlterado = String(value);
  valorAlterado = valorAlterado.replace(/\D/g, ""); // Remove todos os não dígitos
  valorAlterado = valorAlterado.replace(/(\d+)(\d{2})$/, "$1,$2"); // Adiciona a parte de centavos
  valorAlterado = valorAlterado.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1."); // Adiciona pontos a cada três dígitos

  // Converte para número e verifica se está dentro do limite
  let numericValue = parseFloat(valorAlterado.replace(/\./g, '').replace(',', '.'));
  if (numericValue > limit) {
    return limit.toFixed(2).replace('.', ',');
  }

  return valorAlterado;
}

export const formatCurrencyWithDecimal = (value) => {
  
  var texto = String(value);

  let valorNumerico = texto.replace(/[^0-9,]/g, "");

  // Converte vírgula para ponto para manipulação e depois reverte para o formato desejado
  //valorNumerico = valorNumerico.replace(",", ".");

  // Converte a string numérica para um número flutuante
  const numero = parseFloat(valorNumerico);

  // Verifica se o número é um NaN (não um número), se sim, retorna o original
  if (isNaN(numero)) return texto;

  // Formata o número com separadores de milhar e duas casas decimais
  let resultado = numero.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
  });

  // Converte novamente ponto para vírgula para o formato brasileiro
  resultado = resultado.replace(/\./g, "#").replace(/,/g, ",").replace(/#/g, ".");

  // Retorna o texto original substituindo o valor numérico pelo valor formatado
  return texto.replace(valorNumerico.replace(",", "."), resultado);
}

export const formatValueNoDecimals = (value) => {

  // Converte o valor para string para garantir que podemos usar o método replace
  let valueStr = String(value);

  // Substitui vírgulas por pontos para uniformizar o formato decimal
  valueStr = valueStr.replace(',', '.');

  // Converte a string tratada para um número flutuante
  const floatValue = parseFloat(valueStr);

  // Verifica se a conversão resultou em um número válido
  if (isNaN(floatValue)) {
    throw new Error('Invalid number format');
  }

  // Multiplica o valor por 100 e arredonda para remover as casas decimais
  const multipliedValue = Math.round(floatValue * 100);

  return String(multipliedValue);

}

export const formatPercentageNumber = (data) => {
  const number = parseFloat(data);
  if (!isNaN(number)) {
      return number.toFixed(1).replace(".", ",")+"%";
  }
  return "Valor não numérico"; // ou outra manipulação de erro de acordo com sua lógica de negócio
}

export const isJsonString = (str) => {
  if (typeof str !== 'string') {
        return false; // Não é uma string
  }
  
  try { 
    // Verifica se o valor é um objeto ou um array
    if (str.trim().startsWith('{') && str.trim().endsWith('}') || str.trim().startsWith('[') && str.trim().endsWith(']')) {
        const parsed = JSON.parse(str);
        return (typeof parsed === 'object' && parsed !== null);
      } else {
        return true;
      }
      
  } catch (error) {
      return true; // Não é um JSON válido mas ainda é uma string
  }
}

export const toJsonOrEmpty = (str) => {
  if (typeof str !== 'string' || str === null) {
    console.log('NOT A STRING');
      return {}; // Retorna um JSON vazio para entradas não-string ou null
  }

  try {
      return JSON.parse(str); // Tenta converter a string para JSON
  } catch (error) {
      return str; // Retorna a string pois não é um json
  }
}

export function createDateAsLocal(dateString) {
  const date = new Date(dateString);
  const userTimezoneOffset = date.getTimezoneOffset() * 60000;
  return new Date(date.getTime() + userTimezoneOffset);
}


export function padronizarDecimal(numeroString) {

  // Converter a string para número
  let numero = parseFloat(numeroString);
  
  // Verificar se o número é inteiro
  if (Number.isInteger(numero)) {
      // Se for inteiro, adiciona uma casa decimal ".0"
      return `${numero}.0`;
  } else {
      // Se já tiver uma casa decimal, retorna como está
      return numero.toString();
  }
}