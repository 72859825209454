import '../../Assets/Styles/register.css';
import React,{ useRef, useState } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive'

import { Button } from 'primereact/button'; 
import { Panel } from 'primereact/panel';

import contactConcept from '../../Assets/Images/contactConcept.png'; 
import contactWhatsappIcon from '../../Assets/Images/contactWhatsappIcon.svg';
import contactEmailIcon from '../../Assets/Images/contactEmailIcon.svg';
import contactPhoneIcon from '../../Assets/Images/contactPhoneIcon.svg';

import {
	Callto,
	Whatsapp
} from '../../Components/Utils';

// Load Actions
import * as AppActions from '../../Store/Actions/App'

import AlertView from '../../Components/AlertModal';
import MessageView from '../../Components/MessageModal';

const ContactUs = (props) => {

// Set var from props
const {
	setDialog,
	dialog
} = props;    

const [panelVisibility, setPanelVisibility] = useState({
    panel1: true,
    panel2: true,
    panel3: true,
    panel4: true,
    panel5: true,
    panel6: true,
    panel7: true,
    panel8: true,
    panel9: true,
    panel10: true,
});
// Alert control
const [alert, setAlert] = React.useState(false); 
const [alertTitle, setAlertTitle] = React.useState("Informações"); 
const [alertBody, setAlertBody] = React.useState("Conteúdo dinâmico"); 
const [selectedButton, setSelectedbutton] = React.useState(1); 

const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1023px)' });

const togglePanel = (panel) => {
    setPanelVisibility((prevState) => ({
        ...prevState,
        [panel]: !prevState[panel],
    }));
};


	return (
	<>
    <div id="contatoSection" className="contatoSection__wrapper">
        
        <div className={isTabletOrMobile ? "contatoSection__wrapper_content_wrapper mobile" : "contatoSection__wrapper_content_wrapper"}  style={{maxWidth:"1920px",gap:"2rem"}}>

            <div className="dFlex aItemsC justify-center" style={{marginLeft:"7%"}}> 
            
                <div className="textBlockMargin contatoSection__wrapper_content_text">
                
                    <div className="contatoSection__wrapper_content_header" style={{color:" #1D2939",fontFamily:"Titillium Web",fontSize:"32px",fontStyle:"normal",fontWeight: "400",lineHeight:"34.07px"}}>Dúvidas? <span style={{fontWeight:"700"}}>Entre em contato</span> conosco</div>
                
                    Estamos a disposição para atender às suas necessidades e responder às suas perguntas. Verifique primeiramente o nosso FAQ e se desejar, entre em contato por uma das formas oferecidas.
                    
                    <div style={{marginTop: "2.8125rem", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "stretch", gap: "15px"}}>

                        <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center",gap:"15px"}}>
                            <div><img src={contactWhatsappIcon} style={{width:"49px",height:"auto",filter:" grayscale(1) contrast(100) brightness(0)",mixBlendMode:"multiply"}} className="noSelect" /></div>
                            <div className="contatoOptionText"><Whatsapp phone="552140209749" style={{color:"#1D2939",textDecoration:"none"}}>Whatsapp 4020-9749</Whatsapp></div>
                        </div>

                        <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center",gap:"15px"}}>
                            <div><img src={contactPhoneIcon} style={{color:"#1D2939",width:"49px",height:"auto",filter:" grayscale(1) contrast(100) brightness(0)",mixBlendMode:"multiply"}} className="noSelect"  /></div>
                            <div className="contatoOptionText"><Callto phone="+552140209749" style={{color:"#1D2939",textDecoration:"none"}}>Telefone 4020-9749</Callto></div>
                        </div>
                        
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center",gap:"15px"}}>
                            <div><img src={contactEmailIcon} style={{width:"49px",height:"auto",filter:" grayscale(1) contrast(100) brightness(0)",mixBlendMode:"multiply"}} className="noSelect"  /></div>
                            <div className="contatoOptionText"><Button label="Enviar mensagem" rounded className="pT10 mR10 headerButton tColorWhite w100" style={{marginBottom:"0.625rem"}} onClick={()=>setDialog('messageModal',true)} /></div>
                        </div>
                        

                    </div>					
                
                </div> 
                
            </div>
            
            <div className="flex-row flex-1_3 dFlex justify-start align-items-stretch" style={{gap:"16px",marginLeft:"0px"}}> 
                
                <div style={{padding:"5px",gap:"16px"}} className="flex-column justify-center align-items-stretch">

                    <div className={selectedButton == 1 ? "faqButton selected" : "faqButton"} onClick={()=>setSelectedbutton(1)}>
                        <div className={selectedButton == 1 ? "faqButtonIcon selected" : "faqButtonIcon"}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M22.1654 18.1421C22.481 18.4577 23.0205 18.2331 23.0189 17.7868L23.0023 13.1053V5.52632C23.0023 4.13107 21.8712 3 20.4759 3H8.68647C7.29123 3 6.16016 4.13107 6.16016 5.52632V9H13C14.6569 9 16 10.3431 16 12V15.6316H19.6548L22.1654 18.1421Z" fill={selectedButton == 1 ? "#D9D9D9" : "#FFF"}/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M22.1654 18.1421C22.481 18.4577 23.0205 18.2331 23.0189 17.7868L23.0023 13.1053V5.52632C23.0023 4.13107 21.8712 3 20.4759 3H8.68647C7.29123 3 6.16016 4.13107 6.16016 5.52632V9H13C14.6569 9 16 10.3431 16 12V15.6316H19.6548L22.1654 18.1421Z" fill={selectedButton == 1 ? "#814DE5" : "#FFF"}/>
                            <g opacity="0.3">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.98438 18V13C1.98438 11.8954 2.87981 11 3.98438 11H11.9844C13.0889 11 13.9844 11.8954 13.9844 13V18C13.9844 19.1046 13.0889 20 11.9844 20H4.10011L2.85625 21.1905C2.53825 21.4949 2.01053 21.2695 2.01053 20.8293V18.3243C1.99332 18.2187 1.98438 18.1104 1.98438 18ZM6 14.5C6 14.2239 6.22386 14 6.5 14H11.5C11.7761 14 12 14.2239 12 14.5C12 14.7761 11.7761 15 11.5 15H6.5C6.22386 15 6 14.7761 6 14.5ZM9.5 16C9.22386 16 9 16.2239 9 16.5C9 16.7761 9.22386 17 9.5 17H11.5C11.7761 17 12 16.7761 12 16.5C12 16.2239 11.7761 16 11.5 16H9.5Z" fill={selectedButton == 1 ? "#D9D9D9" : "#FFF"}/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.98438 18V13C1.98438 11.8954 2.87981 11 3.98438 11H11.9844C13.0889 11 13.9844 11.8954 13.9844 13V18C13.9844 19.1046 13.0889 20 11.9844 20H4.10011L2.85625 21.1905C2.53825 21.4949 2.01053 21.2695 2.01053 20.8293V18.3243C1.99332 18.2187 1.98438 18.1104 1.98438 18ZM6 14.5C6 14.2239 6.22386 14 6.5 14H11.5C11.7761 14 12 14.2239 12 14.5C12 14.7761 11.7761 15 11.5 15H6.5C6.22386 15 6 14.7761 6 14.5ZM9.5 16C9.22386 16 9 16.2239 9 16.5C9 16.7761 9.22386 17 9.5 17H11.5C11.7761 17 12 16.7761 12 16.5C12 16.2239 11.7761 16 11.5 16H9.5Z" fill={selectedButton == 1 ? "#814DE5" : "#FFF"} />
                            </g>
                        </svg>
                        </div>
                        <div className={selectedButton == 1 ? "faqButtonTitle selected" : "faqButtonTitle"}>Cadastro de Agência</div>
                    </div>

                    <div className={selectedButton == 2 ? "faqButton selected" : "faqButton"} onClick={()=>setSelectedbutton(2)}>
                        <div className={selectedButton == 2 ? "faqButtonIcon selected" : "faqButtonIcon"}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8 3H6C4.89543 3 4 3.89543 4 5V21C4 22.1046 4.89543 23 6 23H18C19.1046 23 20 22.1046 20 21V5C20 3.89543 19.1046 3 18 3H16V3.5C16 4.32843 15.3284 5 14.5 5H9.5C8.67157 5 8 4.32843 8 3.5V3Z" fill={selectedButton == 2 ? "#814DE5" : "#FFF"}/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8 3H6C4.89543 3 4 3.89543 4 5V21C4 22.1046 4.89543 23 6 23H18C19.1046 23 20 22.1046 20 21V5C20 3.89543 19.1046 3 18 3H16V3.5C16 4.32843 15.3284 5 14.5 5H9.5C8.67157 5 8 4.32843 8 3.5V3Z" fill={selectedButton == 2 ? "#814DE5" : "#FFF"}/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13 2H14.5C14.7761 2 15 2.22386 15 2.5V3.5C15 3.77614 14.7761 4 14.5 4H9.5C9.22386 4 9 3.77614 9 3.5V2.5C9 2.22386 9.22386 2 9.5 2H11C11 1.44772 11.4477 1 12 1C12.5523 1 13 1.44772 13 2Z" fill={selectedButton == 2 ? "#814DE5" : "#FFF"}/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13 2H14.5C14.7761 2 15 2.22386 15 2.5V3.5C15 3.77614 14.7761 4 14.5 4H9.5C9.22386 4 9 3.77614 9 3.5V2.5C9 2.22386 9.22386 2 9.5 2H11C11 1.44772 11.4477 1 12 1C12.5523 1 13 1.44772 13 2Z" fill={selectedButton == 2 ? "#814DE5" : "#FFF"}/>
                            <rect x="10" y="9" width="7" height="2" rx="1" fill={selectedButton == 2 ? "#FFF" : "#814DE5"}/>
                            <rect x="10" y="9" width="7" height="2" rx="1" fill={selectedButton == 2 ? "#FFF" : "#814DE5"}/>
                            <rect x="7" y="9" width="2" height="2" rx="1" fill={selectedButton == 2 ? "#FFF" : "#814DE5"}/>
                            <rect x="7" y="9" width="2" height="2" rx="1" fill={selectedButton == 2 ? "#FFF" : "#814DE5"}/>
                            <rect x="7" y="13" width="2" height="2" rx="1" fill={selectedButton == 2 ? "#FFF" : "#814DE5"}/>
                            <rect x="7" y="13" width="2" height="2" rx="1" fill={selectedButton == 2 ? "#FFF" : "#814DE5"}/>
                            <rect x="10" y="13" width="7" height="2" rx="1" fill={selectedButton == 2 ? "#FFF" : "#814DE5"}/>
                            <rect x="10" y="13" width="7" height="2" rx="1" fill={selectedButton == 2 ? "#FFF" : "#814DE5"}/>
                            <rect x="7" y="17" width="2" height="2" rx="1" fill={selectedButton == 2 ? "#FFF" : "#814DE5"}/>
                            <rect x="7" y="17" width="2" height="2" rx="1" fill={selectedButton == 2 ? "#FFF" : "#814DE5"}/>
                            <rect x="10" y="17" width="7" height="2" rx="1" fill={selectedButton == 2 ? "#FFF" : "#814DE5"}/>
                            <rect x="10" y="17" width="7" height="2" rx="1" fill={selectedButton == 2 ? "#FFF" : "#814DE5"}/>
                        </svg>                        
                        </div>
                        <div className={selectedButton == 2 ? "faqButtonTitle selected" : "faqButtonTitle"}>Aprovação de Cadastro</div>
                    </div>

                    <div className={selectedButton == 3 ? "faqButton selected" : "faqButton"} onClick={()=>setSelectedbutton(3)}>
                        <div className={selectedButton == 3 ? "faqButtonIcon selected" : "faqButtonIcon"}>
                        <svg viewBox="0 0 24 24" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M7.5 18C8.32843 18 9 18.6716 9 19.5C9 20.3284 8.32843 21 7.5 21C6.67157 21 6 20.3284 6 19.5C6 18.6716 6.67157 18 7.5 18Z" stroke={selectedButton == 3 ? "#814DE5" : "#FFF"} stroke-width="1.5"></path> <path d="M16.5 18.0001C17.3284 18.0001 18 18.6716 18 19.5001C18 20.3285 17.3284 21.0001 16.5 21.0001C15.6716 21.0001 15 20.3285 15 19.5001C15 18.6716 15.6716 18.0001 16.5 18.0001Z" stroke={selectedButton == 3 ? "#814DE5" : "#FFF"} stroke-width="1.5"></path> <path d="M11 10.8L12.1429 12L15 9" stroke={selectedButton == 3 ? "#814DE5" : "#FFF"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M2 3L2.26121 3.09184C3.5628 3.54945 4.2136 3.77826 4.58584 4.32298C4.95808 4.86771 4.95808 5.59126 4.95808 7.03836V9.76C4.95808 12.7016 5.02132 13.6723 5.88772 14.5862C6.75412 15.5 8.14857 15.5 10.9375 15.5H12M16.2404 15.5C17.8014 15.5 18.5819 15.5 19.1336 15.0504C19.6853 14.6008 19.8429 13.8364 20.158 12.3075L20.6578 9.88275C21.0049 8.14369 21.1784 7.27417 20.7345 6.69708C20.2906 6.12 18.7738 6.12 17.0888 6.12H11.0235M4.95808 6.12H7" stroke={selectedButton == 3 ? "#814DE5" : "#FFF"} stroke-width="1.5" stroke-linecap="round"></path> </g></svg>                     
                        </div>
                        <div className={selectedButton == 3 ? "faqButtonTitle selected" : "faqButtonTitle"}>Compra de hospedagem</div>
                    </div>              


                </div> 
                <div style={{flex:"1",padding:"5px",marginRight:"30px",display:"flex",alignItems:"stretch"}}>
                    
                     <div style={{flex:"0.9",backgroundColor:"#fff",height:"auto",borderRadius:"24px"}}>

                        {selectedButton == 1 &&
                        <>

                        <Panel className="contactUsQuestion" style={{margin:"10px"}} header={
                            <div onClick={() => togglePanel('panel1')} style={{ cursor: 'pointer' }}>
                                Qual o tipo de empresa que pode se cadastrar e comprar na plataforma Dream4?
                            </div>
                        } toggleable collapsed={panelVisibility.panel1} onToggle={(e) => setPanelVisibility({ ...panelVisibility, panel1: e.value })}>
                            <p className="m0" style={{margin:0}}>
                               Apenas empresas haiblitadas para comercializar produtos de turismo podem se cadastrar na plataforma. É necessário que a empresa tenha um ou mais CNAEs referentes a área de turismo. Não insista caso sua empresa não se aplique pois o cadastro será reprovado. 
                            </p>
                        </Panel>

                        <Panel className="contactUsQuestion" style={{margin:"10px"}}  header={
                            <div onClick={() => togglePanel('panel2')} style={{ cursor: 'pointer' }}>
                                Não estou conseguindo fazer o cadastro pois está dizendo que o responsável técnico de minha empresa está errado.
                            </div>
                        } toggleable collapsed={panelVisibility.panel2} onToggle={(e) => setPanelVisibility({ ...panelVisibility, panel2: e.value })}>
                            <p className="m0" style={{margin:0}} >
                               Para prosseguir com o cadastro de sua empresa é necessário que o responsável técnico seja o mesmo do trazido da RFB automaticamente. Adotamos essa medida para evitar cadastro com divergências e acelerar o processo de cadastro. 
                            </p>
                        </Panel>

                        <Panel className="contactUsQuestion" style={{margin:"10px"}} header={
                            <div onClick={() => togglePanel('panel3')} style={{ cursor: 'pointer' }}>
                                Ao buscar os dados do meu cadastro existem dados que não vieram como esperado.
                            </div>
                        } toggleable collapsed={panelVisibility.panel3} onToggle={(e) => setPanelVisibility({ ...panelVisibility, panel3: e.value })}>
                            <p className="m0" style={{margin:0}} >
                               A plataforma Dream4 faz a busca dos dados da sua empresa, à partir do CNPJ, diretamente da RFB. Os dados refletem exatamente o que está na base de dados do governo. Caso entende que haja alguma discrepância é possível que tenha feito recentemente e não tenha refletido na base da RFB ainda. Pedimos que nesse caso entre em contato conosco para análise manual. 
                            </p>
                        </Panel>

                        <Panel className="contactUsQuestion" style={{margin:"10px"}} header={
                            <div onClick={() => togglePanel('panel4')} style={{ cursor: 'pointer' }}>
                               Ao tentar buscar os dados de minha empresa, está ocorrendo um erro e solicitando tentar novamente.
                            </div>
                        } toggleable collapsed={panelVisibility.panel4} onToggle={(e) => setPanelVisibility({ ...panelVisibility, panel4: e.value })}>
                            <p className="m0" style={{margin:0}}>
                               A plataforma Dream4 é integrada com diversos serviços externos que eventualmente podem ter instabilidades. Outra situação que pode ocorrer é probblema em sua internet. Solicitamos que desligue e ligue o seu computador para verificar se o problema não é sua internet. Caso o problema persista, entre em contato conosco. 
                            </p>
                        </Panel>   

                        </>
                        }

                        {selectedButton == 2 &&
                        <>

                        <Panel className="contactUsQuestion" style={{margin:"10px"}} header={
                            <div onClick={() => togglePanel('panel5')} style={{ cursor: 'pointer' }}>
                               Como funciona o processo de aprovação do meu cadastro?
                            </div>
                        } toggleable collapsed={panelVisibility.panel5} onToggle={(e) => setPanelVisibility({ ...panelVisibility, panel5: e.value })}>
                            <p className="m0" style={{margin:0}}>
                               O processo de análise de seu cadastro é simples. Após preenchimento e envio dos dados de sua agência, levaremos em média 72h para avaliar o seu cadastro, que pode ser aprovado ou reprovado. Para ambos os casos você será notificado via email. Dependendo do motivo da reprovação, você poderá fazer os ajustes e reenviar para análise.
                            </p>
                        </Panel>

                        <Panel className="contactUsQuestion" style={{margin:"10px"}}  header={
                            <div onClick={() => togglePanel('panel6')} style={{ cursor: 'pointer' }}>
                                Quais os motivos comuns de reprovação de um cadastro?
                            </div>
                        } toggleable collapsed={panelVisibility.panel6} onToggle={(e) => setPanelVisibility({ ...panelVisibility, panel6: e.value })}>
                            <p className="m0" style={{margin:0}} >
                              Normalmente um cadastro é reprovado quando tem dados inconsistentes, como por exemplo o responsável da empresa ser informado errado, ou o código CNAE da empresa não permitir atuar na área de turismo. Independente do motivo, você será informado.
                            </p>
                        </Panel>

                        <Panel className="contactUsQuestion" style={{margin:"10px"}} header={
                            <div onClick={() => togglePanel('panel7')} style={{ cursor: 'pointer' }}>
                                E se a análise de meu cadastro demorar mais do que 72h?
                            </div>
                        } toggleable collapsed={panelVisibility.panel7} onToggle={(e) => setPanelVisibility({ ...panelVisibility, panel7: e.value })}>
                            <p className="m0" style={{margin:0}} >
                               Eventualmente o prazo máximo de análise de seu cadastro pode ser maior. Se por acaso for o seu caso, pedimos que entra em contato via mensagem ou whastapp para analisar o status do mesmo. 
                            </p>
                        </Panel>

                        </>
                        }

                        {selectedButton == 3 &&
                        <>

                        <Panel className="contactUsQuestion" style={{margin:"10px"}} header={
                            <div onClick={() => togglePanel('panel8')} style={{ cursor: 'pointer' }}>
                                Quais são as formas de pagamento aceitas?
                            </div>
                        } toggleable collapsed={panelVisibility.panel8} onToggle={(e) => setPanelVisibility({ ...panelVisibility, panel8: e.value })}>
                            <p className="m0" style={{margin:0}}>
                               Permitimos a compra de hospedagem utilizando cartão de crédito ou PIX.  Aceitamos parcelamento em até 10x sem juros com parela mínima de R$ 100,00.
                            </p>
                        </Panel>

                        <Panel className="contactUsQuestion" style={{margin:"10px"}}  header={
                            <div onClick={() => togglePanel('panel9')} style={{ cursor: 'pointer' }}>
                               Quem deve ser o titular do cartão de crédito de minha compra?
                            </div>
                        } toggleable collapsed={panelVisibility.panel9} onToggle={(e) => setPanelVisibility({ ...panelVisibility, panel9: e.value })}>
                            <p className="m0" style={{margin:0}} >
                               Por motivo de segurança, o titular do cartão de crédito deve ser a mesma pessoa (nome e CPF) que fez a reserva da hospedagem. 
                            </p>
                        </Panel>

                        <Panel className="contactUsQuestion" style={{margin:"10px"}} header={
                            <div onClick={() => togglePanel('panel10')} style={{ cursor: 'pointer' }}>
                               É possível cancelar uma compra?
                            </div>
                        } toggleable collapsed={panelVisibility.panel10} onToggle={(e) => setPanelVisibility({ ...panelVisibility, panel10: e.value })}>
                            <p className="m0" style={{margin:0}}>
                               Sim, desde que respeitado os prazos máximos para cancelamento. Caso o cancelamento seja feito após o prazo máximo haverá uma multa. 
                            </p>
                        </Panel>   

                        </>
                        }                                                



                    </div>                     

                </div>

            </div>			
            
        </div>
            <MessageView
                alert={alert}
                setAlert={setAlert}
                alertTitle={alertTitle}
                alertBody={alertBody}	
                scrollBlock={false}				
            />		
            <AlertView
                alert={alert}
                setAlert={setAlert}
                alertTitle={alertTitle}
                alertBody={alertBody}	
                scrollBlock={false}	
            />            
    </div>											
	</>	
	);
	
}

   const mapStateToProps = store => ({
    dialog:store.appReducer.dialog
   });
  const mapDispatchToProps = dispatch => bindActionCreators({ ...AppActions }, dispatch);
  
  export default connect(
          mapStateToProps,
          mapDispatchToProps
      )(ContactUs);	
  
  
  


