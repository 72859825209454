import '../../Assets/Styles/register.css';
import React from "react";
import { useMediaQuery } from 'react-responsive'

import aboutUsConcept from '../../Assets/Images/aboutUsConcept.png'; 
import ImageGrid from '../../Components/HomeCards/ImageGrid';


const AboutUs = (props) => {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1023px)' });

	return (
	<>
		<div id="memovoSection" className="memovoSection__wrapper">
		
            <div className={isTabletOrMobile ? "memovoSection__wrapper_content_wrapper mobile" : "memovoSection__wrapper_content_wrapper"}>
            
                <div className="flex-1"> 
                    {/*<img src={aboutUsConcept} className="memovoSection__wrapper_content_image noSelect" style={{maxHeight:"545px",minWidth:"40vw"}} />*/}
                    <ImageGrid />
                </div>
                <div className="flex-1 dFlex aItemsC jContentS"> 
                
                    <div className="textBlockMargin memovoSection__wrapper_content_text" >
                    
                        <div className="memovoSection__wrapper_content_header">O que a <strong>Dream4 entrega</strong></div>
                                        
                        A Dream4 é uma plataforma B2B revolucionária que facilita a reserva de quartos de hotéis quatro estrelas ao redor do mundo. Com uma seleção criteriosa de acomodações, a Dream4 garante que cada hotel atenda aos mais altos padrões de qualidade e conforto. Pioneira em seu segmento, a plataforma oferece uma interface intuitiva e um processo de reserva simplificado, proporcionando eficiência e praticidade para os profissionais do setor. Ao centralizar as melhores opções de hospedagem em um único lugar, a Dream4 atende às demandas dos usuários e impulsiona o crescimento dos negócios, redefinindo a experiência de viagens corporativas.

                    
                    </div> 
                    
                </div>
                
            </div>
        
        </div>									
	</>	
	);
	
}

export default AboutUs;	
