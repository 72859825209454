// src/components/ImageCard.js
import React from 'react';
import './ImageCard.css';

const ImageCard = ({ image }) => {
    return (
        <div className="image-card">
            <img src={image} className="image-card-image" />
        </div>
    );
};

export default ImageCard;
