import React from "react";
import { useMediaQuery } from 'react-responsive'
import logo from './logo.svg';
import './App.css';

//theme
import "./Assets/Styles/theme-primereact.css"; 

//core
import "primereact/resources/primereact.min.css";

//icons
import "primeicons/primeicons.css";  

import DesktopLayout from './Layouts/Desktop';
import MobileLayout from './Layouts/Mobile';

import ReCAPTCHA from "react-google-recaptcha"
import * as Config from './Config/api';

function App() {

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1024px)'
  })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1023px)' })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })	
	
  const recaptchaRef = React.useRef(null);
	
  return (
    <div className="App">
		<div>
			{isDesktopOrLaptop && <DesktopLayout />}
			{isTabletOrMobile && <MobileLayout />}
		</div>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
		    badge="bottomleft" /*inline*/
        sitekey={Config.REACT_APP_SITE_KEY}
      />		
    </div>
  );
}

export default App;
