import '../../Assets/Styles/register.css';
import React from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Button } from 'primereact/button'; 
import { Dialog } from 'primereact/dialog';
import { Galleria } from 'primereact/galleria';

// Load Actions
import * as AppActions from '../../Store/Actions/App';

import {
	formatNumberCurrency
} from '../../Components/Utils';

// UpdateService dialog component
const ImageGalleryView = (props) => {

// set vars from props
const { 
    dialog,
    setDialog,
    setSelectedItem,
    showPropertyInfo,
	propertyId,
	scrollBlock
} = props;

const [loading, setLoading] = React.useState(true); 
const [images, setImages] = React.useState(null);
const [activeIndex, setActiveIndex] = React.useState(0);    

const galleria = React.useRef(null);

const footerContent = (
	<div className="modalFooterButtonsEnd">

		<div>
			<Button label="Fechar" icon="pi pi-times" className="p-button-text" onClick={() => close()} />
		</div>
		
	</div>
);		
	

// Execute when dialog opens
React.useEffect(() => {

	// Only r]uns when  dialog opens
	if (dialog.target == 'ImageGalleryViewModal' && dialog.status) {

        let randomImages = [
            {
                itemImageSrc: "https://picsum.photos/id/100/860/600",
                thumbnailImageSrc: "https://picsum.photos/id/100/300/240",
                alt: 'Imagem'
            },
            {
                itemImageSrc: "https://picsum.photos/id/101/860/600",
                thumbnailImageSrc: "https://picsum.photos/id/101/300/240",
                alt: 'Imagem'
            },            
            {
                itemImageSrc: "https://picsum.photos/id/102/860/600",
                thumbnailImageSrc: "https://picsum.photos/id/102/300/240",
                alt: 'Imagem'
            },
            {
                itemImageSrc: "https://picsum.photos/id/103/860/600",
                thumbnailImageSrc: "https://picsum.photos/id/103/300/240",
                alt: 'Imagem'
            },
            {
                itemImageSrc: "https://picsum.photos/id/104/860/600",
                thumbnailImageSrc: "https://picsum.photos/id/104/300/240",
                alt: 'Imagem'
            },            
            {
                itemImageSrc: "https://picsum.photos/id/115/860/600",
                thumbnailImageSrc: "https://picsum.photos/id/115/300/240",
                alt: 'Imagem'
            },   
            {
                itemImageSrc: "https://picsum.photos/id/106/860/600",
                thumbnailImageSrc: "https://picsum.photos/id/106/300/240",
                alt: 'Imagem'
            },
            {
                itemImageSrc: "https://picsum.photos/id/107/860/600",
                thumbnailImageSrc: "https://picsum.photos/id/107/300/240",
                alt: 'Imagem'
            },            
            {
                itemImageSrc: "https://picsum.photos/id/108/860/600",
                thumbnailImageSrc: "https://picsum.photos/id/108/300/240",
                alt: 'Imagem'
            },                                    
        ];
        setImages(randomImages);



		
	} // end if
	
	// Reset all fields when closes or open dialog
	// ...
			
},[dialog]);

const item =  {
        "id": "1",
        "mainImage": "https://picsum.photos/400/262?random=1",
        "details": {
            "name": "GA Palace Hotel & Spa, a XIXth-Century VillaAbre",
            "country": "Portugal",
            "city": "Porto",
            "suburb": "União de Freguesias do Centro",
            "distances": {
                "from_center": "0,7km",
                "metro": "100"
            },
            "booking_rating": "9,5",
            "daily_price": "450,00",
            "daily_price_number": 450,
            "amenities": [
                { "name": "Estacionamento", "id": "1" },
                { "name": "Wi-fi grátis", "id": "2" },
                { "name": "Aceita Pets", "id": "4" },
                { "name": "Transfer (Aeroporto)", "id": "5" },
                { "name": "Estacionamento", "id": "1" },
                { "name": "Wi-fi grátis", "id": "2" },
                { "name": "Restaurante", "id": "9" },
                { "name": "Estacionamento", "id": "1" },
                { "name": "Wi-fi grátis", "id": "2" },
                { "name": "Aceita Pets", "id": "4" },
                { "name": "Transfer (Aeroporto)", "id": "5" },
                { "name": "Estacionamento", "id": "1" },
                { "name": "Wi-fi grátis", "id": "2" }                                        
            ]
        }
    }

const close = ()=> {

	setDialog("ImageGalleryViewModal",false);

    setSelectedItem(null);

	setTimeout(function() {
		if (scrollBlock) {
			// document.body.classList.add('p-overflow-hidden');	
		} // end if
	},300);
    
}

const itemTemplate = (item) => {
    return <img src={item.itemImageSrc} alt={item.alt} style={{ width: '100%',height:"auto",borderRadius:"1rem",padding:"0.5rem"}} />;
};


	return (
	<>
			<Dialog className="galleryDialog" closable={true} closeOnEscape={true} dismissableMask={true} header={showPropertyInfo ? "Informações e fotos da propriedade" : "Fotos da propriedade"} visible={dialog.target == 'ImageGalleryViewModal' && dialog.status} style={{ width: '90vw',height:'90vh' }} breakpoints={{ '1400px': '75vw','960px': '80vw', '641px': '100vw' }} onHide={() => close()} footer={footerContent} >
			
				<div className="w100 flex-row justify-center align-items-stretch" style={{padding:"1rem",height:"100%",gap:"1rem"}}>
					
                    <div className="imageGallery_wrapper__left flex-column justify-stretch align-items-center" style={{flex:0.7}}>


                        <div className="imageGallery_details w100" style={{flex:showPropertyInfo ? 1 : "unset"}}>

                            <div  className="resultsCard_details__left flex-column justify-start align-items-start" style={{gap:"0.3rem",flex:1,padding:"0.5rem"}}>

                                <div className="resultsCard_details__name w100" style={{color: "#1D2939",fontFamily: "Poppins",fontSize: "1.1rem",
                                fontStyle: "normal",fontWeight: 600,lineHeight: "normal",textAlign:"left",display:"flex",flexDirection:"column",justifyContent:"start",alignItems:"center"}}>
                                    <div className="flex-row justify-start align-items-center w100" style={{flex:1}}>
                                        
                                        <div style={{flex:1,textAlign:"left"}}>{item.details.name}</div>
                                        <div className="resultsCard_details__ratingValue" style={{display: "flex",width: "3.875rem",height: "auto",padding: "0.625rem",flexDirection: "column",justifyContent: "center",alignItems: "center",gap: "0.625rem",borderRadius: "1.8125rem",background: "#00A3FF",color: "#FFF",fontFamily: "Titillium Web",fontSize: "1rem",fontStyle: "normal",fontWeight: 600,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>{item.details.booking_rating}</div>

                                    </div>

                                    <div className="resultsCard_details__location w100" style={{color: "#878787",fontFamily: "Poppins",fontSize: "1rem",fontStyle: "normal",fontWeight: 400,lineHeight: "normal",textAlign:"left"}}>{item.details.country}, {item.details.city} {item.details.suburb ? ", "+item.details.suburb : ""}</div>
                                </div>

                                {showPropertyInfo &&
                                <>
                                    {item.details.distances && (<div className="resultsCard_details__distance" style={{color: "#878787",fontFamily: "Titillium Web",fontSize: "0.9rem",fontStyle: "normal",fontWeight: 500,lineHeight: "normal",letterSpacing: "0.02875rem"}}>{item.details.distances.from_center ? item.details.distances.from_center+" do centro" : ""}</div>)}

                                    <div className="resultsCard_details__amenities">
                                        <ul style={{listStyleType: "none",textAlign:"left", columns: (item.details.amenities.length > 4) ? ((item.details.amenities.length > 10) ? 3 : 2) : 1, padding: 0,lineHeight: "200%",color: "#878787",fontFamily: "Poppins",fontSize: "0.8rem",fontStyle: "normal",fontWeight: 400}}>																	
                                        {item.details.amenities.map((itemInner, indexInner) =>(<li key={indexInner}><i style={{color:"#00A3FF"}} className="pi pi-check"></i> {itemInner.name}</li>))}
                                        </ul>
                                    </div>


                                    <div className="resultsCard_details__prices flex-column justify-start align-items-center w100" style={{gap:"0.5rem",textAlign:"left"}}>

                                        <div className="resultsCard_details__prices___dailyPrice w100" style={{color: "#878787",fontFamily: "Titillium Web",fontSize: "0.85rem",fontStyle: "normal",fontWeight: 400,lineHeight: "1.625rem",letterSpacing: "0.02875rem"}}>Diária: {formatNumberCurrency(item.details.daily_price_number)}  <br /> 5 diárias, 1 quarto</div>

                                        <div className="resultsCard_details__prices___dailyPrice w100" style={{color:" #814DE5",fontFamily: "Poppins",fontSize: "1.5rem",fontStyle: "normal",fontWeight: 600,lineHeight: "2.12938rem"}}><span style={{fontSize: "0.9375rem"}}>Total </span> {formatNumberCurrency(item.details.daily_price_number * 5)}</div>	

                                    </div>

                                    <div className="w100 flex-row justify-start align-items-center" style={{marginTop:"1rem",marginBottom:"1rem"}}>
                                            <Button label="Ver hotel em detalhes" rounded className="pT10 tColorWhite" onClick={()=>{console.log("VER HOTEL CLICK")}} />
                                    </div>	                                
                                </>
                                }


                            </div>

                        </div>

                        <div className="imageGallery_thumbs w100" style={{flex:0.7}}>

                            <div className="imageGallery grid" style={{ width:"100%",display:"flex",justifyContent:"start",alignItems:"start",flexDirection:"row",flexWrap:"wrap",gap:"0.75rem",padding:"0.5rem"}}>
                                {
                                    images && images.map((image, index) => {
                                        let imgEl = <img src={image.thumbnailImageSrc} alt={image.alt} style={{ cursor: 'pointer',width:"100%",height:"auto",flex:1 }} onClick={
                                            () => {setActiveIndex(index); galleria.current.show()}
                                        } />
                                        return (
                                            <div className={activeIndex == index ? "imageGallery_item selected" : "imageGallery_item"} key={index} style={{borderRadius:"1rem",width: "6.5rem",height:"5rem",overflow:"hidden", display:"flex",justifyContent:"center",alignItems:"center"}}>
                                                {imgEl}
                                            </div>
                                        )
                                    })
                                }
                            </div>

                        </div>

                    </div>

                    <div className="imageGallery_wrapper__right flex-column justify_center align-items-stretch w100" style={{flex:1}}>

                        <div style={{width:"100%",height:"100%",flex:1}}>
                            <Galleria  ref={galleria}   activeIndex={activeIndex} onItemChange={(e) => setActiveIndex(e.index)}  circular value={images} style={{display:"flex",justifyContent:"start",alignItems:"center",borderRadius:"1rem"}} showThumbnails={false}  showItemNavigators showIndicators showIndicatorsOnItem={true} indicatorsPosition="bottom" item={itemTemplate} />
                        </div>

                    </div>

			    </div>

            </Dialog>	
	</>	
	);
	
}

const mapStateToProps = store => ({
  dialog:store.appReducer.dialog
 });
const mapDispatchToProps = dispatch => bindActionCreators({ ...AppActions }, dispatch);

export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(ImageGalleryView);	
