// src/components/InfoCard.js
import React, { useEffect, useState } from 'react';
import './InfoCard.css';

const InfoCard = ({ icon, text, mousePosition, extraClass }) => {
    const [transform, setTransform] = useState({ x: 0, y: 0 });

    useEffect(() => {
        const { x, y } = mousePosition;
        const offsetX = (x - window.innerWidth / 2) / 50;
        const offsetY = (y - window.innerHeight / 2) / 50;
        setTransform({ x: offsetX, y: offsetY });
    }, [mousePosition]);

    return (
        <div
            className={extraClass ? "info-card "+extraClass : "info-card"}
            style={{ transform: `translate(${transform.x}px, ${transform.y}px)` }}
        >
            <div className="icon">
                <img src={icon} alt="icon" className="icon-image" />
            </div>
            <div className="text">{text}</div>
        </div>
    );
};

export default InfoCard;
