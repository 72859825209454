// src/components/HotelCards.js
import React, { useState, useEffect } from 'react';
import './HotelCards.css';
import InfoCard from './InfoCard';
import ImageCard from './ImageCard';

import cardIcon1 from '../../Assets/Images/cardIcon1.svg'; 
import cardIcon2 from '../../Assets/Images/cardIcon2.svg'; 
import cardIcon3 from '../../Assets/Images/cardIcon3.svg'; 



const getRandomImages = (num) => {
    const urls = [
        'https://picsum.photos/800/262?random=1',
        'https://picsum.photos/800/262?random=2',
        'https://picsum.photos/800/262?random=3',
        'https://picsum.photos/800/262?random=4',
    ];
    const shuffled = urls.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, num);
};

const randomImages = getRandomImages(4);

const HotelCards = () => {
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

    useEffect(() => {
        const handleMouseMove = (e) => {
            setMousePosition({ x: e.clientX, y: e.clientY });
        };

        document.addEventListener('mousemove', handleMouseMove);
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    return (
        <div className="hotel-cards">
            <div className="image-cards">
                <div className="image-row">
                    <ImageCard image={randomImages[0]} />
                    <ImageCard image={randomImages[1]} />
                </div>
                <div className="image-row">
                    <ImageCard image={randomImages[2]} />
                    <ImageCard image={randomImages[3]} />
                </div>
            </div>
            <div className="info-cards">
                <InfoCard icon={cardIcon1} text="Hotéis 4 estrelas" mousePosition={mousePosition} />
                <InfoCard icon={cardIcon2}  text="Avaliação mínima de 8" extraClass="displaced" mousePosition={mousePosition} />
                <InfoCard icon={cardIcon3}  text="Localização estratégica" mousePosition={mousePosition} />
            </div>
        </div>
    );
};

export default HotelCards;
