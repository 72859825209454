import React, { useState, useEffect } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import './RoomSelector.css';

const RoomSelector = ({ onSelection, setRooms, initialRooms, updateInputValue,overlay }) => {
    const [rooms, setRoomState] = useState(initialRooms.length > 0 ? initialRooms : [{ adults: 0, children: [] }]);

    const handleAddRoom = () => {
        const newRooms = [...rooms, { adults: 1, children: [] }];
        setRoomState(newRooms);
        setRooms(newRooms);
        updateInputValue(newRooms);
    };

    const handleRemoveRoom = (index) => {
        if (rooms.length > 1) {
            const newRooms = rooms.filter((_, i) => i !== index);
            setRoomState(newRooms);
            setRooms(newRooms);
            updateInputValue(newRooms);
        }
    };

    const handleAddAdult = (index) => {
        const newRooms = [...rooms];
        newRooms[index].adults += 1;
        setRoomState(newRooms);
        setRooms(newRooms);
        updateInputValue(newRooms);
    };

    const handleRemoveAdult = (index) => {
        const newRooms = [...rooms];
        if (newRooms[index].adults > 0) newRooms[index].adults -= 1;
        setRoomState(newRooms);
        setRooms(newRooms);
        updateInputValue(newRooms);
    };

    const handleAddChild = (index) => {
        const newRooms = [...rooms];
        newRooms[index].children.push({ age: '' });
        setRoomState(newRooms);
        setRooms(newRooms);
        updateInputValue(newRooms);
    };

    const handleRemoveChild = (roomIndex, childIndex) => {
        const newRooms = [...rooms];
        newRooms[roomIndex].children = newRooms[roomIndex].children.filter((_, i) => i !== childIndex);
        setRoomState(newRooms);
        setRooms(newRooms);
        updateInputValue(newRooms);
    };

    const handleChildAgeChange = (roomIndex, childIndex, age) => {
        const newRooms = [...rooms];
        newRooms[roomIndex].children[childIndex].age = age;
        setRoomState(newRooms);
        setRooms(newRooms);
        updateInputValue(newRooms);
    };

    useEffect(() => {
        updateInputValue(rooms);
    }, [rooms, updateInputValue]);
 
    return (
        <div className="room-selector">
            {rooms.map((room, roomIndex) => (
                <div key={roomIndex} className="room">
                    <div className="room-title prevent-select">Quarto {roomIndex + 1}</div>
                    <Divider />
                    <div className="room-controls">
                        <div className="room-each-control">
                            <h4 className="prevent-select">Adultos</h4>
                            <div className="control-buttons">
                                <Button icon="pi pi-minus" onClick={() => handleRemoveAdult(roomIndex)} disabled={rooms[roomIndex].adults === 1} />
                                <span className="quantity prevent-select">{room.adults}</span>
                                <Button icon="pi pi-plus" onClick={() => handleAddAdult(roomIndex)} />
                            </div>
                        </div>
                        <div className="room-each-control">
                            <h4 className="prevent-select">Crianças</h4>
                            <div className="control-buttons">
                                <Button icon="pi pi-minus" onClick={() => handleRemoveChild(roomIndex, room.children.length - 1)} disabled={room.children.length === 0} />
                                <span className="quantity prevent-select">{room.children.length}</span>
                                <Button icon="pi pi-plus" onClick={() => handleAddChild(roomIndex)} />
                            </div>
                        </div>
                    </div>
                    <div className="child-controls-wrapper">
                    {room.children.map((child, childIndex) => (
                        <div key={childIndex} className="child-controls">
                            <Dropdown
                                value={child.age}
                                options={[
                                    { label: "Menos de 1 ano", value: 0 },
                                    ...[...Array(17).keys()].map(age => ({ label: `${age + 1} ${age + 1 === 1 ? 'ano' : 'anos'}`, value: age + 1 }))
                                ]}
                                onChange={(e) => handleChildAgeChange(roomIndex, childIndex, e.value)}
                                placeholder="Idade"
                            />

                        </div>
                    ))}
                    </div>
                    {rooms.length > 1 && (
                        <Button label="Remover Quarto" text className="p-button-danger" onClick={() => handleRemoveRoom(roomIndex)} style={{textAlign: "center",fontFamily: "Poppins",fontSize: "0.875rem",fontStyle: "normal",fontWeight: 600,lineHeight: "120%",letterSpacing: "0.0875rem"}} />
                    )}
                </div>
            ))}
            <Button label="Adicionar Outro Quarto" text className="add-room-btn" onClick={handleAddRoom} style={{color: "#814DE5",textAlign: "center",fontFamily: "Poppins",fontSize: "0.875rem",fontStyle: "normal",fontWeight: 600,lineHeight: "120%",letterSpacing: "0.0875rem"}} />
        </div>
    );
};

export default RoomSelector;
