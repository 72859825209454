import * as types from '../actionTypes';

const initialState = { 
    searchType:"location",
    searchParams:{},
    searchResultStatus:null,
    searchResultRowsNumber:null,
    searchResultToken:null,
    searchResultCurrentPage:1,
    searchResultLimit:10,
    searchLocation:{},
    searchResultObj:{},
    searchFilters:{},
    searchCurrency:null,
    exchangeRates:{}	 
}
 
export const orderReducer = (state = initialState, action) => {
		
    switch (action.type) {
        case types.SET_SEARCHTYPE:		
            return { ...state, searchType: action.payload }	
        case types.SET_SEARCHPARAMS:
            return { ...state, searchParams: action.payload }
        case types.SET_SEARCHFILTERS:
            return { ...state, searchFilters: action.payload } 
        case types.SET_SEARCHCURRENCY:
            return { ...state, searchCurrency: action.payload }  
        case types.SET_EXCHANGERATES:
            return { ...state, exchangeRates: action.payload }                                     
        case types.SET_SEARCHRESSTATUS:
            return { ...state, searchResultStatus: action.payload }            	
        case types.SET_SEARCHRESROWSNUMBER:
            return { ...state, searchResultRowsNumber: action.payload }				
        case types.SET_SEARCHRESTOKEN:
            return { ...state, searchResultToken: action.payload }				
        case types.SET_SEARCHRESCURRENTPAGE:
            return { ...state, searchResultCurrentPage: action.payload }				
        case types.SET_SEARCHRESLIMIT:
            return { ...state, searchResultLimit: action.payload }				
        case types.SET_SEARCHLOCATION:
            return { ...state, searchLocation: action.payload }				
        case types.SET_SEARCHRESOBJECT:
            return { ...state, searchResultObj: action.payload }				                                
        default:
            return state
    }
}

