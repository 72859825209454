import '../../Assets/Styles/register.css';
import React from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Button } from 'primereact/button'; 
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';

import Box from '@mui/material/Box';

// Load Actions
import * as AppActions from '../../Store/Actions/App';

// UpdateService dialog component
const TermsView = (props) => {

// set vars from props
const { 
	termsModal,
	setTermsModal,
	scrollBlock
} = props;		

const [loading, setLoading] = React.useState(true); 


const footerContent = (
	<div className="modalFooterButtonsEnd">

		<div>
			<Button label="Fechar" icon="pi pi-times" className="p-button-text" onClick={() => close()} />
		</div>
		
	</div>
);		
	

// Execute when dialog opens
React.useEffect(() => {

	// Only r]uns when  dialog opens
	if (termsModal) {

		
	} // end if
	
	// Reset all fields when closes or open dialog
	// ...
			
},[termsModal]);

const close = ()=> {
	setTermsModal(false);

	setTimeout(function() {
		if (scrollBlock) {
			document.body.classList.add('p-overflow-hidden');	
		} // end if
	},300);
}
	
	return (
	<>
			<Dialog header="Termos de uso e condições da plataforma Dream4" visible={termsModal} style={{ width: '55vw' }} breakpoints={{ '1400px': '75vw','960px': '80vw', '641px': '100vw' }} onHide={() => close()} footer={footerContent} >
			
				<Box
				  sx={{
					width: "100%",
				  }}
				  className="terms"
				>
					<div className="termsModalIntro"> Conheça os termos de uso e condições da plataforma Dream4.</div>
					
					<Divider />
					
						<h2>1. Introdução</h2>
						<p>
							Bem-vindo ao Dream4, um site B2B de mobilidade e transporte. Ao utilizar nossos serviços, você concorda em cumprir os Termos de Uso e Condições estabelecidos neste documento. Por favor, leia-os cuidadosamente.
						</p>

						<h2>2. Acesso e utilização dos serviços</h2>
						<p>
							Ao utilizar os serviços do Dream4, você concorda em não utilizar nossos serviços de maneira ilegal, inapropriada ou prejudicial aos interesses da empresa ou de terceiros. Você é responsável por todas as ações e informações fornecidas enquanto estiver usando nossos serviços.
						</p>

						<h2>3. Conteúdo e direitos autorais</h2>
						<p>
							Todo o conteúdo disponibilizado no site Dream4, incluindo textos, imagens, logotipos, ícones e outros elementos, é de propriedade exclusiva do Dream4 ou de seus respectivos titulares. O uso indevido do conteúdo, sem a prévia autorização por escrito do Dream4 ou do titular dos direitos autorais, pode resultar em sanções civis e criminais.
						</p>

						<h2>4. Privacidade</h2>
						<p>
							O Dream4 está comprometido em proteger a privacidade dos usuários e clientes. Nossa Política de Privacidade explica como coletamos, utilizamos e protegemos as informações pessoais que você nos fornece. Ao utilizar nossos serviços, você concorda com a coleta e o uso de suas informações pessoais, conforme descrito em nossa Política de Privacidade.
						</p>

						<h2>5. Limitação de responsabilidade</h2>
						<p>
							O Dream4 não será responsável por quaisquer danos, diretos ou indiretos, decorrentes do uso ou da incapacidade de usar nossos serviços. Isso inclui, sem limitação, danos causados por atrasos, falhas no serviço, perda de informações, lucros cessantes, interrupção do negócio, entre outros. Além disso, o Dream4 não se responsabiliza por quaisquer problemas técnicos, incluindo falhas no sistema, vírus ou outros problemas relacionados ao acesso e uso de nosso site.
						</p>

						<h2>6. Alterações nos Termos de Uso e Condições</h2>					
						<p>
						O Dream4 reserva-se o direito de modificar, alterar ou atualizar os Termos de Uso e Condições a qualquer momento, sem aviso prévio. A continuação do uso dos nossos serviços após a publicação de tais alterações constitui a aceitação dessas mudanças. É sua responsabilidade revisar regularmente os Termos de Uso e Condições para se manter informado sobre possíveis alterações.
						</p>		

						<h2>7. Cancelamento e rescisão</h2>
						<p>
							O Dream4 reserva-se o direito de cancelar ou encerrar sua conta ou acesso aos nossos serviços a qualquer momento, sem aviso prévio, se você violar estes Termos de Uso e Condições. Caso isso ocorra, você concorda em não criar uma nova conta sem a nossa permissão expressa por escrito. O Dream4 não será responsável por qualquer perda ou dano causado pelo cancelamento ou rescisão de sua conta ou acesso aos nossos serviços.
						</p>

						<h2>8. Legislação aplicável e jurisdição</h2>
						<p>
							Estes Termos de Uso e Condições são regidos pelas leis do país em que o Dream4 opera. Qualquer disputa, controvérsia ou reclamação relacionada a esses Termos de Uso e Condições será resolvida exclusivamente pelos tribunais competentes do país em questão.
						</p>

						<h2>9. Contato</h2>
						<p>
							Caso você tenha alguma dúvida, sugestão ou reclamação relacionada aos Termos de Uso e Condições do Dream4, entre em contato conosco através do e-mail: [email protected]
						</p>

						<p>
							Agradecemos por utilizar o Dream4 e esperamos que nossos serviços atendam às suas expectativas e necessidades de mobilidade e transporte.
						</p>
						
			</Box>
            </Dialog>	
	</>	
	);
	
}

const mapStateToProps = store => ({
  dialog:store.appReducer.dialog
 });
const mapDispatchToProps = dispatch => bindActionCreators({ ...AppActions }, dispatch);

export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(TermsView);	
