import '../../Assets/Styles/home.css';
import '../../Assets/Styles/order.css';
import React from "react";
import { useMediaQuery } from 'react-responsive'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {Link,useNavigate} from 'react-router-dom';

import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button'; 
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { OverlayPanel } from 'primereact/overlaypanel';
import { MultiSelect } from 'primereact/multiselect';
import { Card } from 'primereact/card';

import { Panel } from 'primereact/panel';
import Box from '@mui/material/Box';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Grid from '@mui/material/Grid';

import moment from "moment";

import multiTransportConcept from '../../Assets/Images/multiTransportConcept11.png'; 
import desktopConcept from '../../Assets/Images/desktopConcept.png'; 
import contactConcept from '../../Assets/Images/contactConcept.png'; 
import formPlaceholder from '../../Assets/Images/formPlaceholder.png'; 
import formTopIcons from '../../Assets/Images/formTopIcons.svg'; 
import cornerTop from '../../Assets/Images/cornerTop.svg';
import cornerBottom from '../../Assets/Images/cornerBottom.svg';

import contactWhatsappIcon from '../../Assets/Images/contactWhatsappIcon.svg';
import contactEmailIcon from '../../Assets/Images/contactEmailIcon.svg';
import contactPhoneIcon from '../../Assets/Images/contactPhoneIcon.svg';
import transportPicture from '../../Assets/Images/transportPicture.svg';
import rentcarPicture from '../../Assets/Images/rentcarPicture.svg';
import transferPicture from '../../Assets/Images/transferPicture.svg';
import transportIcon from '../../Assets/Images/transportIcons.svg';
import transferIcon from '../../Assets/Images/transferIcon.svg';
import rentcarIcon from '../../Assets/Images/rentcarIcon.svg';

import titleTrainIcon from '../../Assets/Images/titleTrainIcon.svg';
import titleBusIcon from '../../Assets/Images/titleBusIcon.svg';
import titleTransferIcon from '../../Assets/Images/titleBusIcon.svg';
import titleRentCarIcon from '../../Assets/Images/titleBusIcon.svg';

// Load Actions
import * as AppActions from '../../Store/Actions/App'
import * as UserActions from '../../Store/Actions/User'

import AboutUs from '../../Components/layout/AboutUs';
import ContactUs from '../../Components/layout/ContactUs';

// Load Componentes
import SideMenu from '../../Components/SideMenu';
import MessageView from '../../Components/MessageModal';
import AlertView from '../../Components/AlertModal';
import UserView from './UserModal';

import {
	validarCNPJ,
	validarCPF,
	validarEmail,
	validarTelefone,
	validarNome,
	maskCNPJ,
	maskCPF,
	maskPhone
} from '../../Components/Utils';

import {
	Callto,
	Whatsapp,

} from '../../Components/Utils';

// export default function Reservation() {
const Users = (props) => {
	
// Set var from props
const {
	setDialog,
	dialog,
	setUser,
	selectedUser,
	retrieveUsers,
	masterUser
} = props;

// Set Overlay obj
const op = React.useRef(null);

const isDesktopOrLaptop = useMediaQuery({
query: '(min-width: 1024px)'
})
const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1023px)' })
const isSpecialDesktop = useMediaQuery({ query: '(max-width: 1500px)' })

const [stillConnected, setStillConnected] = React.useState(false);
const onStillConnectedChange = (e) => {
	if (e.checked)
		setStillConnected(true);
	else
		setStillConnected(false);
}  // end function
	
const [loading, setLoading] = React.useState(true); 
const [saving, setSaving] = React.useState(false); 

const [termsModal, setTermsModal] = React.useState(false); 

const [companyDocument, setCompanyDocument] = React.useState('07.760.188/0001-15');

const [companyLegalName, setCompanyLegalName] = React.useState('SM TRAVEL AGENCIA DE VIAGENS E TURISMO LTDA');
const [companyFantasyName, setCompanyFantasyName] = React.useState('Não informado');
const [companyEmail, setCompanyEmail] = React.useState('administrativo@smi.net.br');
const [companyPhone, setCompanyPhone] = React.useState('(21) 2517-4848');
const [companyState, setCompanyState] = React.useState('RJ');
const [companyCity, setCompanyCity] = React.useState('Rio de Janeiro');
const [personCompanyName, setPersonCompanyName] = React.useState('Newton Carvalheira Baptista Vieira');
const [status, setStatus] = React.useState('Ativo');

const [companyStateAuto, setCompanyStateAuto] = React.useState('RJ');
const [companyCityAuto, setCompanyCityAuto] = React.useState('Rio de Janeiro');

const [personName, setPersonName] = React.useState('Newton Carvalheira Baptista Vieira');
const [personDocument, setPersonDocument] = React.useState('628.713.827-00');
const [personPhone, setPersonPhone] = React.useState('(21) 98424-3110');
const [email, setEmail] = React.useState('newton@smilatam.net.br');
const [emailConfirm, setEmailConfirm] = React.useState('newton@smilatam.net.br');
const [password, setPassword] = React.useState('');
const [newPassword, setNewPassword] = React.useState('');
const [newPasswordConfirm, setNewPasswordConfirm] = React.useState('');

const [profile, setProfile] = React.useState('Gestão');

const [acceptTerm, setAcceptTerm] = React.useState(false);
const [autoFields, setAutoFields] = React.useState(true); // if true, fields are disabled
const [autoFieldsFill, setAutoFieldsFill] = React.useState(true); // if true, fields are auto filled. No manual.
const [autoFieldsFilled, setAutoFieldsFilled] = React.useState(false); // if true, fields was returned
const [autoPersonFieldsFilled, setAutoPersonFieldsFilled] = React.useState(false); // if true, fields was returned
const [documentReady, setDocumentReady] = React.useState(false); // if true, document is ok and ready
const [personDocumentReady, setPersonDocumentReady] = React.useState(false); // if true, document is ok and ready

const [loadingData, setLoadingData] = React.useState(false);
const [loadingPersonData, setPersonLoadingData] = React.useState(false);
const [cityLoading, setCityLoading] = React.useState(false);
const [cityResult, setCityResult] = React.useState(false);
const [screen, setScreen] = React.useState('form');

// Alert control
const [validateRegistry, setValidateRegistry] = React.useState(false); 
const [alert, setAlert] = React.useState(false); 
const [alertTitle, setAlertTitle] = React.useState("Informações"); 
const [alertAction, setAlertAction] = React.useState(""); 
const [alertBody, setAlertBody] = React.useState("Conteúdo dinâmico"); 

// Basic filter fields  and controls
const [basicFilterStatus, setBasicFilterStatus] = React.useState(false);
const [basicFilter, setBasicFilter] = React.useState({});
const [filterValue, setFilterValue] = React.useState("");

// Advanced filter fields and controls
const [advancedFilterStatus, setAdvancedFilterStatus] = React.useState(false);
const [advancedFilter, setAdvancedFilter] = React.useState({});
const [advancedSearch, setAdvancedSearch] = React.useState(false);

// CUSTOM ADVANCED FILTER FIELDS
const [filterByReservationId, setFilterByReservationId] = React.useState("");
const [filterByAgencyName, setFilterByAgencyName] = React.useState("");
const [filterByAgencyDocument, setFilterByAgencyDocument] = React.useState("");
const [filterByCustomerName, setFilterByCustomerName] = React.useState("");
const [filterByCustomerDocument, setFilterByCustomerDocument] = React.useState("");
const [filterByUserName, setFilterByUserName] = React.useState("");
const [filterByTransportType, setFilterByTransportType] = React.useState([]);
const [filterByCreateAtFrom, setFilterByCreateAtFrom] = React.useState("");
const [filterByCreateAtTo, setFilterByCreateAtTo] = React.useState("");
const [filterByServiceAtFrom, setFilterByServiceAtFrom] = React.useState("");
const [filterByServiceAtTo, setFilterByServiceAtTo] = React.useState("");
const [filterByPaymentMethod, setFilterByPaymentMethod] = React.useState(null);
const [filterByStatus, setFilterByStatus] = React.useState(null);

// Table row selection
const [selectedItem, setSelectedItem] = React.useState(null);

// set this month dateFormat
const [periodDate, setPeriodDate] = React.useState(null);

// Set Fake Data
//var rowsData = ReservationData;
const [rows, setRows] = React.useState([]);
const [totalRecords, setTotalRecords] = React.useState(0);
 
 	// Suggested password panel
    const PasswordTip = (
        <>
            <Divider />
            <p className="mt-1" style={{fontSize:"0.9rem"}}>Sugestão de senha</p>
            <ul className="pl-2 ml-2 mt-0 line-height-3" style={{fontSize:"0.8rem"}}>
                <li>Ao menos 1 letra maiúscula</li>
				<li>Ao menos 1 letra minúscula</li>
                <li>Ao menos 1 número </li>
				<li>Ao menos 1 símbolo ! # $ % & * + - = ? @ </li>
                <li>De 6 a 12 caracteres</li>
            </ul>
        </>
    );
 
/* -------------------------------- */ 
/* Custom methods and options START */
/* -------------------------------- */

// Select reservation item method
const selectAgencyAction = (value) => {

	// Set local data
	setSelectedItem(value);	

	// Set reservation on store (index 0)
	setUser(value[0]);

	// Open Modal
	setDialog('userModal',true);
	
}

// Retrieve reservations
const retrieveItemsList = () => {
		
	var props = {
		setSaving:setSaving,
		setAlert:setAlert,
		setAlertTitle:setAlertTitle,
		setAlertBody:setAlertBody,
		setRows:setRows,
		setLoading:setLoading,
		setTotalRecords:setTotalRecords
	}
	var data = {
	  lazyState:lazyState
	}
	retrieveUsers(data,props);

} // end retrieveDocumentInfo
 
  
// Dropdown Values for Basic Search Fields
const [filter, setFilter] = React.useState(masterUser ? {
	"code": "5",
	"field":"agencyName",
	"name": "Nome da Agência"
} :  {
	"code": "2",
	"field":"customerName",
	"name": "Nome do Usuário"
  });
  
const FilterItems = masterUser ? [
  {
	"code": "5",
	"field":"agencyName",
	"name": "Nome da Agência"
  },  
  {
	"code": "2",
	"field":"customerName",
	"name": "Nome do Usuário"
  },   
   { 
	"code": "1",
	"field":"cpf",
	"name": "CPF"
  },   
   { 
	"code": "4",
	"field":"email",
	"name": "Email"
  }
] : [
  {
	"code": "2",
	"field":"customerName",
	"name": "Nome do Usuário"
  },   
   { 
	"code": "1",
	"field":"cpf",
	"name": "CPF"
  },   
   { 
	"code": "4",
	"field":"email",
	"name": "Email"
  }
];

const [dropdownFilterItems, setDropdownFilterItems] = React.useState(FilterItems);

// Datatable default state
const [lazyState, setlazyState] = React.useState({
	first: 0,
	rows: 10,
	page: 1,
	sortField: "createdAt",
	sortOrder: -1,
	filters: {
	}
});

// Default columns
const columnsValues = masterUser ? [
'userId',
'agency',
'name',
'contacts',
'profile',
'createdAt',
'status'
] : [
'userId',
'name',
'contacts',
'profile',
'createdAt',
'status'
];
const [visibleColumns, setVisibleColumns] = React.useState(columnsValues);

// Field value format and enrichement
const bodyTemplate = (type,data) => {

	let rowValue = "Não definido";

	if (type == "userId") {

		rowValue = (
		<>
		<div>{data.userId}</div>
		</>
		);	
		
	}
	
	if (type == "agency") {		

		rowValue = (
		<>
		<div>{data.legalName}</div>
		<div><small style={{color:"gray"}}>{data.fantasyName}</small></div>
		<div><small style={{color:"gray"}}>{data.document}</small></div>
		</>
		);	
		
	}
	
	if (type == "name") {	
			
		rowValue = (
		<>
		<div>{data.name}</div>
		<div><small style={{color:"gray"}}>{data.document}</small></div>
		</>
		);				
	}	
	
	if (type == "contacts") {	
		rowValue = (
		<>
		<div>{data.phone}</div>
		<div><small style={{color:"gray"}}>{data.email}</small></div>
		</>
		);		
	}	
	
	if (type == "creationDate") {		
		rowValue = moment(data.createdAt).format("DD/MM/YYYY")
	}	

	if (type == "profile") {		
		//rowValue = data.totalAmount;
		rowValue = (
		<>
		<div>{data}</div>
		</>
		);		
		
	}	

	if (type == "status") {		

		if (data.status == 1) {
			var activationStatus = "ativado";
			var activationStatusLabel = "Ativo";
		} // end if	
		else if (data.status == 2) {
			var activationStatus = "review";
			var activationStatusLabel = "Inativo";
		} // end if
		else if (data.status == 3) {
			var activationStatus = "bloqueado";
			var activationStatusLabel = "Bloqueado";
		} // end if	
		else if (data.status == 4) {
			var activationStatus = "encerrado";
			var activationStatusLabel = "Excluído";
		} // end if
			
		return (
		<>
		<div className={`product-badge status-${activationStatus}`}>{activationStatusLabel}</div>
		</>
		)	
		rowValue = data;
	}	
	
    return rowValue;
};
 
const applyBasicSearch = () => {
	
	// Check if search term is filled and with at least 3 chars
	if (!filterValue) {
		setAlert(true);
		setAlertTitle("Verifique as informações");
		setAlertBody("Você precisa preencher o termo a ser buscado pra prosseguir.");	
		return;
	} // end if
	
	if (filterValue.length < 3) {
		setAlert(true);
		setAlertTitle("Verifique as informações");
		setAlertBody("Para prosseguir, você precisa preencher o termo a ser buscado com ao menos 3 caracteres.");	
		return;
	} // end if	
 	
	// Set filter on
	setBasicFilterStatus(true);
	
	// Set advanced filter off
	setAdvancedFilterStatus(false);
	
	// Clean Advanced fields
	setFilterByReservationId("");
	setFilterByAgencyName("");
	setFilterByAgencyDocument("");
	setFilterByCustomerName("");
	setFilterByCustomerDocument("");
	setFilterByUserName("");
	setFilterByTransportType([]);
	setFilterByCreateAtFrom("");
	setFilterByCreateAtTo("");
	setFilterByServiceAtFrom("");
	setFilterByServiceAtTo("");
	setFilterByPaymentMethod("");
	setFilterByStatus("");		
	
	// Set filter to lazyData
	var currentData = {...lazyState}
	
	// Set current filter
	currentData.filters = {
		mode:"basic",
		term: filterValue,
		field: filter
	}
	
	// Set LazyState
	setlazyState(currentData);
	
} // end function

const resetSearch = (type)=> {
	
	if (type == "basic") {
		// Set filter on
		setBasicFilterStatus(false);
		
		// Clean Terms
		setFilterValue("");		
	} // end if
	else {
		// Set filter on
		setAdvancedFilterStatus(false);
		
		setFilterByReservationId("");
		setFilterByAgencyName("");
		setFilterByAgencyDocument("");
		setFilterByCustomerName("");
		setFilterByCustomerDocument("");
		setFilterByUserName("");
		setFilterByTransportType([]);
		setFilterByCreateAtFrom("");
		setFilterByCreateAtTo("");
		setFilterByServiceAtFrom("");
		setFilterByServiceAtTo("");
		setFilterByPaymentMethod("");
		setFilterByStatus("");		
		
	} // end else
	
	// Set filter to lazyData
	var currentData = {...lazyState}
	
	// Set current filter
	currentData.filters = {};
	
	// Set LazyState
	setlazyState(currentData);	
	
} // end fucntion

const applyAdvancedSearch = () => {
	
	// Check if at least one field is filled
	if (!filterByReservationId && !filterByAgencyName && !filterByAgencyDocument && !filterByCustomerName &&  !filterByCustomerDocument && !filterByUserName && filterByTransportType.length == 0 &&  !filterByCreateAtFrom && !filterByCreateAtTo && !filterByServiceAtFrom && !filterByServiceAtTo && !filterByPaymentMethod && !filterByStatus) {
		setAlert(true);
		setAlertTitle("Verifique as informações");
		setAlertBody("Você precisa preencher ao menos um campo pra prosseguir com a busca.");	
		return;
	} // end if	
	
	// Check if createdAt From and To are filled 
	if (filterByCreateAtFrom || filterByCreateAtTo) {
		if (!filterByCreateAtFrom || !filterByCreateAtTo) {
			setAlert(true);
			setAlertTitle("Verifique as informações");
			setAlertBody("Você precisa preencher ao os dois campos da data de criação para prosseguir.");	
			return;
		}
	} // end if

	// Check if createdAt From and To are filled 
	if (filterByServiceAtFrom || filterByServiceAtTo) {
		if (!filterByServiceAtFrom || !filterByServiceAtTo) {
			setAlert(true);
			setAlertTitle("Verifique as informações");
			setAlertBody("Você precisa preencher ao os dois campos da data do serviço para prosseguir.");	
			return;
		}
	} // end if	
	
	// Check company document if ok
	if (filterByCustomerDocument) {
		if (!validarCPF(filterByCustomerDocument)) {
			setAlert(true);
			setAlertTitle("Verifique as informações");
			setAlertBody("O CPF informado é inválido. Verifique novamente.");		
			return;
		}
	} // end if	

	// Check customer document if ok
	if (filterByAgencyDocument) {
		if (!validarCNPJ(filterByAgencyDocument)) {
			setAlert(true);
			setAlertTitle("Verifique as informações");
			setAlertBody("O CNPJ informado é inválido. Verifique novamente.");	
			return;
		}
	} // end if		
	 	
	// Set filter on
	setBasicFilterStatus(false);
	
	// Clean Basic Search term
	setFilterValue("");
	
	// Set advanced filter off
	setAdvancedFilterStatus(true);
	
	// Set filter to lazyData
	var currentData = {...lazyState}	
	
	var filters = {};
	filters.mode = "advanced"; 
	filters.reservationId = filterByReservationId ? filterByReservationId : null; 
	filters.agencyName = filterByAgencyName ? filterByAgencyName : null; 
	filters.agencyDocument = filterByAgencyDocument ? filterByAgencyDocument : null; 
	filters.customerName = filterByCustomerName ? filterByCustomerName : null; 
	filters.customerDocument = filterByCustomerDocument ? filterByCustomerDocument : null; 
	filters.userName = filterByUserName ? filterByUserName : null; 
	filters.transportType = filterByTransportType ? filterByTransportType : null; 
	filters.createAtFrom = filterByCreateAtFrom ? filterByCreateAtFrom : null; 
	filters.createAtTo = filterByCreateAtTo ? filterByCreateAtTo : null; 
	filters.serviceAtFrom = filterByServiceAtFrom ? filterByServiceAtFrom : null; 
	filters.serviceAtTo = filterByServiceAtTo ? filterByServiceAtTo : null; 
	filters.paymentMethod = filterByPaymentMethod ? filterByPaymentMethod : null; 
	filters.status = filterByStatus ? filterByStatus : null; 
	
	// Apply filter on Data from service 
	let _lazyParams = { ...lazyState, filters:{ fields: filters, mode:"advanced" } };
	setlazyState(_lazyParams);	
	
	// Closes panel
	setAdvancedSearch(!advancedSearch)
	
} // end function
 
/* -------------------------------- */ 
/* Custom methods and options END */
/* -------------------------------- */
 
 
/* -------------------- */ 
/* Common methods START */
/* -------------------- */

// Set selected columns
const onColumnsChange = (e) => {
	
	let _visibleColumns = [...visibleColumns];

	if (e.checked)
		_visibleColumns.push(e.value);
	else
		_visibleColumns.splice(_visibleColumns.indexOf(e.value), 1);

	setVisibleColumns(_visibleColumns);
}

// Datatable related controls START
const loadLazyData = () => {	
	setLoading(true);
	retrieveItemsList();	
};

// Run after mount component
React.useEffect(() => {

	// Retrieve User Data Obj. 
	var result = localStorage.getItem("@memovo/userData", undefined,true);
	var userData = JSON.parse(result);  

	  // If token dont exist, redirect to onboarding or register
	  if (!userData) {
		  navigate("/home");
		  return;
	  } // end if

  // set scroll to top
  window.scrollTo(0, 1);
  
  // Load list
  retrieveItemsList();
      
  // Runs when UNMOUNT component
  return () => {
    
	// unmount...	
	
  };
  
}, []); // end useEffect 

const onPage = (event) => {	
	setlazyState(event);
};

const onSort = (event) => {
	setlazyState(event);
};

const onFilter = (event) => {
	event['first'] = 0;
	setlazyState(event);
};
// Datatable related controls END

// Reacts when lazyState changes
React.useEffect(() => {
	loadLazyData();
}, [lazyState]);
 
// Set Currency format
let EURO = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'EUR',
});

// Set calendar locale
addLocale('br', {
	firstDayOfWeek: 1,
	dayNames: ['domingo', 'segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado'],
	dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
	dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
	monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
	monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
	today: 'Hoje',
	clear: 'Limpar'
});

// set Use of history for PUSH 
var navigate = useNavigate(); 

/* -------------------- */ 
/* Common methods END */
/* -------------------- */
 
  return (
	<>
	<div id="adminSection" style={{marginTop:"0"}} className={isTabletOrMobile ? "login__wrapper mobile" : "login__wrapper"} >
		<div style={{height:"auto"}} className={isTabletOrMobile ? "login__wrapper_form_wrapper mobile" : "login__wrapper_form_wrapper"}> 		
			<div className={isTabletOrMobile ? "login__form_wrapper mobile" : "login__form_wrapper"} style={{alignItems:"flex-start"}}>
						
				<div style={{flex:1,display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"flex-start",gap:"10px",padding:"40px",marginTop:"0px",height:"auto",marginLeft:"auto",marginRight:"auto",width:"100%",border:"0px solid red"}}>
				
						{/* Admin Side Menu */}
						<SideMenu state="open" />
						
						{/* Admin Content */}
						<div style={{flex:1,height:"100%",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
						
							<div style={{height:"64px",width:"100%",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
							
								<div style={{textAlign:"left",flex:2,color: '#2E0249', fontFamily: 'Titillium Web', fontSize: '36px', fontStyle: 'normal', fontWeight: 600, lineHeight: '120%', letterSpacing: '3.6px',padding:"10px"}}>Perfil do seu usuário</div>
								
								<div style={{flex:5,display:"flex",flexDirection:"row",justifyContent:"end",alignItems:"center"}}>
								
							
								
								</div>
							
							</div>
							
							
							<div style={{flex:1,width:"100%",backgroundColor:"#FFF"}}>
							
								<div className="card" style={{minHeight:"66vh",zIndex: 8,position:"relative"}}>
			
									<Box
									  sx={{
										width: "80%",
										padding:"50px"
									  }}
									>
										<div className="registerModalIntro"> <i>Só é possível atualizar o telefone e a senha do usuário. <br /> Caso precise atualizar outra informação entre em contato.</i></div>	

										<Divider />
										  
										  
											<Panel header="Dados do usuário" style={{marginBottom:"20px"}}>

												<Grid container spacing={5} style={{marginTop:"-20px"}}>

											
												  <Grid item xs={12} sm={6}>
													<span className="p-float-label" >
														<InputText text id="agency" autoComplete="new-password" disabled={true} value={companyLegalName} style={{width:"100%"}} />
														<label htmlFor="agency">Vinculado a agência</label>
													</span>		
												  </Grid>
											
												  <Grid item xs={12} sm={6}>
													<span className="p-float-label" >
														<InputText id="personName" autoComplete="new-password" disabled={true} value={personName} style={{width:"100%"}} />
														<label htmlFor="peronName">Nome completo</label>
													</span>		
												  </Grid>
												  
												<Grid item xs={12} sm={6} style={{display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center"}}>
													<div className="p-float-label w100">
														<InputText id="personDocument" autoComplete="new-password" disabled={true}  value={personDocument} className="w100" style={{borderTopRightRadius:0,borderBottomRightRadius:0}} />
														<label htmlFor="personDocument">CPF</label>
													</div>
												 </Grid>												  
												  
												<Grid item xs={12} sm={6}>
													<span className="p-float-label" >
														<InputText id="email" autoComplete="new-password" disabled={true} value={email} className="w100" />
														<label htmlFor="email">E-mail</label>
													</span>		
												  </Grid>												  
												  
												  <Grid item xs={12} sm={6}>
													<span className="p-float-label" >
														<InputText id="personPhone" autoComplete="new-password" disabled={saving ? true : false} value={personPhone} className="w100" />
														<label htmlFor="personPhone">Telefone Celular</label>
													</span>	
												  </Grid>

												<Grid item xs={12} sm={6}>
													<span className="p-float-label" >
														<InputText id="email" autoComplete="new-password" disabled={true} value={profile} className="w100" />
														<label htmlFor="email">Perfil</label>
													</span>		
												  </Grid>	
												  
												<Grid item xs={12} sm={6}>
													<span className="p-float-label" >
														<InputText id="email" autoComplete="new-password" disabled={true} value={status} className="w100" />
														<label htmlFor="email">Status</label>
													</span>		
												  </Grid>													  
												 												  
												  
												</Grid>

											</Panel>											  
										  
											<Panel header="Dados de acesso">

												<Grid container spacing={5} style={{marginTop:"-20px"}}>
												  <Grid item xs={12} sm={6}>
													<span className="p-float-label" >
														<InputText id="email" autoComplete="new-password" disabled={true} value={email} onChange={(e) => setEmail(e.target.value)} style={{width:"100%"}} />
														<label htmlFor="email">E-mail (login)</label>
													</span>		
												  </Grid>
												  <Grid item xs={12} sm={6} style={{textAlign:"left",display:"flex",justifyContent:"flex-start",alignItems:"center"}}>

													<small><i className="pi pi-exclamation-triangle" style={{color:"orange",marginRight:"5px"}}></i> Caso queira alterar o email de login entre em contato com o suporte.</small>
													
												  </Grid>
												  
												  <Grid item xs={12} sm={12} style={{paddingTop:0,marginBottom:0}}>
												  <Divider align="center" style={{marginBottom:0}}>
													<div className="">
														<p><strong>Para alterar a senha</strong>, digite a senha atual, a nova senha e confirme a nova senha.</p>
													</div>
												  </Divider>
												  </Grid>
												  
												  <Grid item xs={12} sm={6} style={{paddingTop:"20px"}}>
													<span className="p-float-label">
														<Password inputId="password" autoComplete="new-password" disabled={saving ? true : false} toggleMask value={password} onChange={(e) => setPassword(e.target.value)}  style={{width:"100%"}} feedback={true} tooltip="Informe a sua senha atual" tooltipOptions={{ position: 'bottom',event: 'focus' }} />
														<label htmlFor="password">Senha Atual<span className="requiredField">*</span></label>
													</span>	
												  </Grid>												  
												  <Grid item xs={12} sm={6} style={{paddingTop:"20px"}}>
													<span className="p-float-label">
														<Password inputId="password" autoComplete="new-password" disabled={saving ? true : false} toggleMask value={newPassword} onChange={(e) => setNewPassword(e.target.value)}  style={{width:"100%"}} feedback={true} promptLabel="Escolha uma senha" weakLabel="Senha fraca" mediumLabel="Senha média" strongLabel="Senha forte" footer={PasswordTip} tooltip="A senha tem que ser no mínimo média" tooltipOptions={{ position: 'bottom',event: 'focus' }} />
														<label htmlFor="password">Nova Senha <span className="requiredField">*</span></label>
													</span>	
												  </Grid>
												  <Grid item xs={12} sm={6}>
													<span className="p-float-label" >
														<Password inputId="passwordConfirm" autoComplete="new-password" disabled={saving ? true : false} toggleMask value={newPasswordConfirm} onChange={(e) => setNewPasswordConfirm(e.target.value)}  style={{width:"100%"}} feedback={false}  />
														<label htmlFor="passwordConfirm">Confirme a nova senha <span className="requiredField">*</span></label>
													</span>	
												  </Grid>
												</Grid>

											</Panel>	

								<div className="modalFooterButtons" style={{marginTop:"20px",textAlign:"right"}}>

									<div className="modalFooterButtonsRight">
										<Button label="Gravar alterações" icon="pi pi-check" loading={saving} onClick={() => console.log("UPDATE")} />
									</div>
									
								</div>												
						
								<AlertView
									alert={alert}
									setAlert={setAlert}
									alertTitle={alertTitle}
									alertBody={alertBody}
									alertAction={alertAction}
									scrollBlock={true}				
								/>
								</Box>
								
									
								</div>							
							
							</div>
						
						</div>
						
				</div>
			
			</div> 
		</div>
		
		<UserView
			setDialog={setDialog}
			dialog={dialog}
			scrollBlock={false}
		/>			
		
    </div>
	
	{/* Contac Us Section */}
	<ContactUs />		

	{/* About Us Section */}
	<AboutUs />
	
	</>
  );
    
}

const mapStateToProps = store => ({
  dialog:store.appReducer.dialog,
  masterUser:store.appReducer.masterUser
 });
const mapDispatchToProps = dispatch => bindActionCreators({ ...AppActions,...UserActions }, dispatch);

export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(Users);	


